import auth0 from 'auth0-js';

// local/session storage keys
const REDIRECT_KEY = 'redirect_to';
const LOGOUT_MESSAGE_KEY = 'logout_message';
const SESSION_KEY = 'auth_session';

// auth0 parameters
const CLIENT_ID = 'fJiwZWbgzCkWOUQ4afYKHSiiB3MllmRT';
const CLIENT_DOMAIN = 'audiencemob.auth0.com';
const AUDIENCE = 'http://api.amob.io';
const SCOPES = 'openid profile email staff';
const REDIRECT = process.env.NODE_ENV === 'production' ?
  'https://buyers.amob.io/callback'
  : 'http://localhost:3000/callback';
const RETURN_TO = process.env.NODE_ENV === 'production' ?
  'https://buyers.amob.io/login'
  : 'http://localhost:3000/login';

// Stores the path that a user tried to navigate to
// when they did not have a valid id_token before
// being redirected to the login page
export function capturePath(path) {
  sessionStorage.setItem(REDIRECT_KEY, path);
}

// Get the captured redirect url stored in sessionStorage
export function getRedirectPage() {
  return sessionStorage.getItem(REDIRECT_KEY) || '/';
}

// Clear the captured redirect url stored in sessionStorage
function clearRedirectPage() {
  sessionStorage.removeItem(REDIRECT_KEY);
}

// Set the logout message in sessionStorage
function setLogoutMessage(message) {
  sessionStorage.setItem(LOGOUT_MESSAGE_KEY, message);
}

// FIXME: this is stupid, but react-router v4 is stupider
export function getLogoutMessage() {
  return sessionStorage.getItem(LOGOUT_MESSAGE_KEY);
}

// Clear the logout message stored in sessionStorage
function clearLogoutMessage() {
  sessionStorage.removeItem(LOGOUT_MESSAGE_KEY);
}

// Set the auth session in localStorage
function setAuthSession(token) {
  localStorage.setItem(SESSION_KEY, JSON.stringify(token));
}

// Get the auth session from localStorage
function getAuthSession() {
  const cachedSession = localStorage.getItem(SESSION_KEY);
  if (cachedSession) {
    return JSON.parse(cachedSession);
  }
  return null;
}

// Clear the auth session in localStorage
function clearAuthSession() {
  localStorage.removeItem(SESSION_KEY);
}

/**
 * Authentication class.
 */
class Auth {
  constructor() {
    this.auth0 = new auth0.WebAuth({
      clientID: CLIENT_ID,
      domain: CLIENT_DOMAIN,
      audience: AUDIENCE,
      scope: SCOPES,
      redirectUri: window.location.host && window.location.host.includes('sunday.market') ? 'https://tractor.sunday.market/callback' : REDIRECT, // TODO
      responseType: 'token id_token'
    });

    this.profile = null;
    this.idToken = null;
    this.accessToken = null;
    this.expiresIn = null;

    // Retrieve cached auth session from localStorage
    this.getCachedSession();
  }

  getCachedSession = () => {
    const cachedSession = getAuthSession();
    if (cachedSession) {
      this.setSession(cachedSession, true);
    }
  }

  getProfile = () => {
    return this.profile;
  }

  getIdToken = () => {
    return this.idToken;
  }

  getAccessToken = () => {
    return this.accessToken;
  }

  handleAuthentication() {
    return new Promise((resolve, reject) => {
      this.auth0.parseHash((err, authResult) => {
        if (err) {
          setLogoutMessage(`Error: ${err.errorDescription}`);
          return reject(err);
        }
        if (!authResult || !authResult.idToken) {
          setLogoutMessage('Error: Invalid token.');
          return reject(err);
        }
        this.setSession(authResult);
        return resolve();
      });
    });
  }

  setSession(session, fromCache = false) {
    this.accessToken = session.accessToken;
    this.idToken = session.idToken;
    this.profile = session.idTokenPayload || session.profile;
    this.expiresIn = fromCache ? session.expiresIn : (session.expiresIn * 1000) + new Date().getTime();

    // Cache the session in localStorage
    setAuthSession({
      accessToken: this.accessToken,
      idToken: this.idToken,
      profile: this.profile,
      expiresIn: this.expiresIn
    });
  }

  isAuthenticated() {
    return new Date().getTime() < this.expiresIn;
  }

  signIn = () => {
    clearLogoutMessage();
    this.auth0.authorize();
  }

  signOut() {
    // Redirects to the Auth0 logout endpoint
    this.auth0.logout({
      returnTo: window.location.host && window.location.host.includes('sunday.market') ? 'https://tractor.sunday.market/login' : RETURN_TO, // TODO
      clientID: CLIENT_ID,
    });

    this.accessToken = null;
    this.idToken = null;
    this.profile = null;
    this.expiresIn = null;

    // Clear the cached session
    clearAuthSession();
    clearRedirectPage();
    setLogoutMessage('Logout successful');
  }

  async silentAuth() {
    setLogoutMessage('Restoring session...');
    // If the session expires in less than 3 hours, renew it
    if (this.expiresIn - new Date().getTime() >= (3 * 60 * 60 * 1000)) {
      return true;
    }
    return new Promise((resolve, reject) => {
      this.auth0.checkSession({}, (err, session) => {
        if (err) {
          console.log('Silent auth FAILED', err)
          if (err.error === 'login_required') {
            setLogoutMessage('Your session has expired. Please re-authenticate.');
          } else {
            setLogoutMessage('There was a problem authenticating: ' + err.error);
          }
          clearAuthSession();
          return reject(err);
        }
        this.setSession(session);
        return resolve();
      });
    });
  }
}

// Expose module as a singleton
const authClient = new Auth();

export default authClient;
