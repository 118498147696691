import React from 'react';
import PropTypes from 'prop-types';

import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';

const styles = () => ({
  paper: {
    width: '80%',
    maxHeight: 435,
  },
});

class ConfirmDialog extends React.Component {

  handleCancel = () => {
    this.props.onCancel();
  };

  handleOk = () => {
    this.props.onConfirm();
  };

  render() {
    const { values, okText, cancelText, promptText, onConfirm, onCancel, children, ...other } = this.props;

    return (
      <Dialog
        maxWidth="xs"
        aria-labelledby="confirmation-dialog-title"
        {...other}
        onClose={this.handleCancel}
      >
        <DialogTitle id="confirmation-dialog-title">{this.props.title}</DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1">
            {promptText}
          </Typography>
          {values
            && (
              <List>
                {values.map((item) => {
                  return (
                    <ListItem key={item.id} dense>
                      <ListItemText primary={item.name} secondary={item.id} />
                    </ListItem>
                  );
                }, this)}
              </List>
            )}
          {!!children && children}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCancel} color="primary">
            {cancelText}
          </Button>
          <Button onClick={this.handleOk} color="primary">
            {okText}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

ConfirmDialog.defaultProps = {
  title: 'Are you sure?',
  cancelText: 'Cancel',
  okText: 'OK',
  values: [],
  children: null,
};

ConfirmDialog.propTypes = {
  title: PropTypes.string,
  cancelText: PropTypes.string,
  okText: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  values: PropTypes.array,
  children: PropTypes.any,
  promptText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};

export default withStyles(styles)(ConfirmDialog);
