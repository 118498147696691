import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import useCallbackCancel from 'hooks/useCallbackCancel';
import makeStyles from '@mui/styles/makeStyles';
import { useFilter } from 'contexts/FilterProvider';
import KinkTable from 'core/Components/Tables/KinkTable';
import useRefresh from 'hooks/useRefresh';
import BBotAPI from 'services/api/bbot';

const getUrlLink = (row) => {
  const date = moment.tz(row.start_date, 'YYYY-MM-DD HH:mm', 'America/Vancouver').format('YYYY-MM-DD');
  return `/webstats/campaign/?site=${row.site}&source=${row.source}&campaign=${row.campaign}&startDate=${date}&endDate=${date}`;
};

const initialDate = moment().subtract(1, 'year').format('YYYY-MM-DD');

const useStyles = makeStyles({
  paper: {
    margin: '0 auto',
    maxWidth: 3000,
    marginTop: '10px',
    marginBottom: '10px',
  },
});

const columns = [
  { title: 'Date', name: 'start_date', type: 'dateHour', width: 145, compare: (a, b) => (new Date(a).getTime() - new Date(b).getTime()) },
  { title: 'Site', name: 'site', width: 120 },
  { title: 'Source', name: 'source', width: 70 },
  // { title: 'Campaign', name: 'campaign', width: 250 },
  { title: 'Campaign', name: 'campaign_link', width: 300, getCellValue: (row) => <a href={getUrlLink(row)} target="_blank" rel="noreferrer">{row.campaign}</a> },
  { title: 'Type', name: 'type', width: 165 },
  { title: 'User', name: 'user', width: 145 },
  { title: 'Old Budget', name: 'oldBudget', type: 'smallcurrency', width: 110 },
  { title: 'New Budget', name: 'newBudget', type: 'smallcurrency', width: 110 },
  { title: 'Old Value', name: 'oldValue', width: 110 },
  { title: 'New Value', name: 'newValue', width: 110 },
  { title: 'Completed', name: 'proceced', width: 80, getCellValue: row => (row.proceced ? 'Yes' : '') },
];

const ListCampaignHistory = ({ camp }) => {
  const { search } = useLocation();
  const campaign = camp || new URLSearchParams(search).get('campaign') || null;
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState(initialDate);
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
  const { filter } = useFilter();
  const firstRender = useRef(true);
  const classes = useStyles();

  const getAPIData = useCallbackCancel(async (cancelToken) => {
    setIsLoading(true);

    const params = {
      startDate,
      endDate: moment(endDate).add(1, 'day').format('YYYY-MM-DD'),
      campaign,
    };
    await Promise.all([
      BBotAPI.job.getHistory(params, cancelToken),
    ]).then(([
      responseHistory,
    ]) => {
      const history = responseHistory.data;
      history.forEach(row => {
        if (row.type === 'RESTART') {
          row.oldBudget = row.initial_budget;
          row.type = 'PAUSER-TODAY';
        } else if (row.type === 'RESTART-CPC') {
          row.oldBudget = row.initial_budget;
          row.type = 'PAUSER-TODAY-CPC';
        } else if (row.type === 'REDUCER-IBOT') {
          row.oldBudget = row.initial_budget;
          row.newBudget = row.max_budget;
          row.type = 'REDUCER-HOURLY';
        } else if (row.type === 'REDUCER-DAILY-IBOT') {
          row.oldBudget = row.initial_budget;
          row.newBudget = row.max_budget;
          row.type = 'REDUCER-DAILY';
        } else if (row.type === 'EXCLUSION') {
          row.oldValue = row.value;
          row.newValue = row.account;
          row.type = 'Placement Block';
        } else if (row.type === 'INCREASER-IBOT') {
          row.oldBudget = row.initial_budget;
          row.newBudget = row.max_budget;
          row.type = 'INCREASER-DAILY';
        } else if (row.type === 'UPDATE') {
          row.oldBudget = row.initial_budget;
          row.newBudget = row.value;
          row.type = row.user ? `MANUAL - ${row.user.toUpperCase()}` : 'MANUAL';
        } else if (row.type === 'PAUSE-IBOT') {
          row.oldBudget = row.initial_budget;
          row.newBudget = row.initial_budget;
          row.type = 'PAUSER-BAD';
        } else if (row.type === 'KILLER-IBOT') {
          row.oldBudget = row.initial_budget;
          row.newBudget = row.initial_budget;
          row.type = 'KILLED';
        } else if (row.type === 'INCREASER-HOURLY-IBOT') {
          row.oldBudget = row.initial_budget;
          row.newBudget = row.max_budget;
          row.type = 'INCREASER-HOURLY';
        } else if (row.type === 'DAY_PARTING') {
          row.newValue = `${Number(row.value) ? 'ADD' : 'REMOVE'}`;
        } else if (row.type === 'STRATEGY') {
          row.oldValue = row.fallback_budget; // old Strategy
          row.newValue = row.value; // new Strategy
          row.oldBudget = row.max_budget; // old target
          row.newBudget = row.initial_budget; // new target
        } else if (row.type === 'UPDATE_URL') {
          row.oldValue = row.max_budget;
          row.newValue = row.value;
        } else if (row.type === 'REDUCER-BID-IBOT') {
          row.oldValue = row.initial_budget;
          row.newValue = row.max_budget;
        } else if (row.type === 'INCREASER-BID-IBOT') {
          row.oldValue = row.initial_budget;
          row.newValue = row.max_budget;
        } else if (row.type === 'FLIPPER-BUDGET-IBOT') {
          row.oldBudget = row.initial_budget;
          row.newBudget = row.max_budget;
        } else if (row.type === 'FLIPPER-STRATEGY-IBOT') {
          row.newBudget = row.fallback_budget;
          row.oldValue = row.initial_budget;
          row.newValue = row.max_budget;
        } else if (row.type === 'BUDGET-TOO-HIGH-IBOT') {
          row.oldBudget = row.initial_budget;
          row.newBudget = row.max_budget;
        } else if (row.type === 'CONVERSION_TARGET') {
          row.type = 'CONVERSION';
          row.oldValue = row.max_budget;
          row.newValue = row.value;
        } else if (row.type === 'PLACEMENT-REDUCER-IBOT') {
          row.type = `PLACEMENT-REDUCER ${row.fallback_budget}`;
          row.oldValue = row.initial_budget;
          row.newValue = row.max_budget;
        } else if (row.type === 'REDUCER-CPC-DAILY-IBOT') {
          row.oldValue = row.initial_budget;
          row.newValue = row.max_budget;
        } else if (row.type === 'INCREASER-CPC-DAILY-IBOT') {
          row.oldValue = row.initial_budget;
          row.newValue = row.max_budget;
        } else if (row.type === 'PAUSE-PLACEMENT-IBOT') {
          row.type = 'PAUSED PLACEMENT';
          row.oldValue = row.max_budget;
          row.newValue = row.initial_budget;
        } else if (row.type === 'GOOGLE-LOG-SYNC' || row.type.includes('CAMPAIGN')) {
          row.newValue = row.value;
          row.oldValue = row.initial_budget;
        }
      });

      setData(history);
      setIsLoading(false);
    });
  }, [campaign, endDate, startDate]);

  const dateRange = () => {
    if (moment().format('YYYY-MM-DD') === moment(startDate).format('YYYY-MM-DD')) {
      return 'Today';
    } if (moment().subtract(1, 'day').format('YYYY-MM-DD') === moment(startDate).format('YYYY-MM-DD')) {
      return 'Yesterday';
    } if (moment(startDate).format('YYYY-MM-DD') !== moment(endDate).format('YYYY-MM-DD')) {
      return `${moment(startDate).format('YYYY-MM-DD')} - ${moment(endDate).format('YYYY-MM-DD')}`;
    }
    return moment(startDate).format('YYYY-MM-DD');
  };

  useEffect(() => {
    getAPIData();
  }, [getAPIData]);

  useEffect(() => {
    if (!firstRender.current) {
      setStartDate(filter.startDate);
      setEndDate(filter.endDate);
    }
  }, [filter.startDate, filter.endDate]);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    }
  }, []);

  useRefresh(getAPIData);

  return (
    <div className={classes.paper}>
      <KinkTable
        tableKey="changes_log"
        title={`Changes History - ${dateRange()}`}
        data={data}
        columns={columns}
        defaultSortColumn="start_date"
        loading={isLoading}
        actionColumnWidth={145}
        defaultPageSize={100}
        filtering
        search
        actionColumnFirst
      />
    </div>
  );
};

export default ListCampaignHistory;

ListCampaignHistory.propTypes = {
  camp: PropTypes.string,
};

ListCampaignHistory.defaultProps = {
  camp: null,
};
