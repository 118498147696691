import React, { useEffect, useReducer } from 'react';
import Axios from 'axios';
import moment from 'moment';
import PropTypes from 'prop-types';
import MiniTable from 'core/Components/Tables/MiniTable';
import makeStyles from '@mui/styles/makeStyles';

import useCallbackCancel from 'hooks/useCallbackCancel';

import { useFilter } from 'contexts/FilterProvider';
import WebstatsAPI from 'services/api/webstats';
import JuicyXAPI from 'services/api/juicyx';

const useStyles = makeStyles(() => ({ paper: { margin: '0 auto', flex: 1 } }));
const initialState = { data: [], loading: true };

const ListCampaignsAdjuster = ({ source, campaign, site }) => {
  const classes = useStyles();
  const { filter } = useFilter();

  const [state, setState] = useReducer(
    (oldState, newState) => ({ ...oldState, ...newState }),
    initialState
  );

  const updateData = useCallbackCancel(
    async (cancelToken) => {
      setState({ loading: true });

      let startDate = filter.startDate;
      const endDate = filter.endDate;

      if (
        moment(endDate).format('YYYY-MM-DD')
        === moment(startDate).format('YYYY-MM-DD')
      ) {
        startDate = moment(endDate)
          .tz('America/Vancouver')
          .subtract(6, 'day')
          .format('YYYY-MM-DD 00:00');
      }

      const params = {
        timezone: filter.timezone,
        startDate,
        endDate,
        site,
        source,
        campaign,
      };

      await Promise.all([
        WebstatsAPI.gazillions.getCampaignDailyStats(params, cancelToken),
        WebstatsAPI.gazillions.getCampaignDeviceStats(params, cancelToken),
        JuicyXAPI.data.getConversionsCampaignPerDay(params, cancelToken),
      ])
        .then(([response, responseDevice, responseCPA]) => {
          let data = response.data.results;
          const dataDevice = responseDevice.data.results;
          const juicyCPAToAdd = responseCPA.data;

          const fields = [];

          if (!data || !data.length) {
            setState({ data: [], loading: false });
            return;
          }

          if (source && campaign) {
            data = data.filter(row => row.source === source && row.campaign === campaign);
          }

          data = data.map((row) => {
            row.cpa_revenue = 0;
            row.search_revenue = 0;

            if (juicyCPAToAdd && juicyCPAToAdd.length) {
              juicyCPAToAdd.forEach(juicy => {
                if (juicy.campaign === row.campaign) {
                  row[`${juicy.category}_revenue`] = juicy.revenue;
                  row[`${juicy.category}_clicks`] = juicy.clicks;
                  row[`${juicy.category}_conversions`] = juicy.conversions;
                  row[`${juicy.category}_rps`] = row[`${juicy.category}_revenue`] / row[`${juicy.category}_clicks`];
                }
              });
            }

            const allKeys = Object.keys(row);

            row.siteRevenue = 0;
            // Gets the revenue from all the keys that ends with _revenue
            // if we add a new field on the cronut project, its going to automatically be summed
            const revenueKeys = allKeys.filter(key => key.endsWith('_revenue'));
            revenueKeys.forEach(key => {
              // TODO: We temporarily need to remove vidazoo revenue while we are debugging vidazoo vs ay_vidazoo, otherwise we would double count (After we remove the vidazoo_revenue colkumn we can delete this line)
              if (key === 'vidazoo_revenue') return;
              if (key === 'content_revenue' || key === 'cpa_revenue' || key === 'search_revenue') return;
              row.siteRevenue += row[key];
            });

            // Calculates the percentage of each revenue
            revenueKeys.forEach(key => {
              if (key === 'content_revenue') return; // || key === 'cpa_revenue' || key === 'search_revenue') return;
              row[`${key}_percent`] = (row[key] / row.siteRevenue) * 100;
            });

            if (row.sourse === 'mg') {
              row.siteProfit = row.siteRevenue - row.spend;
            } else {
              row.siteProfit = row.siteRevenue - row.spend_pst;
            }

            row.totalProfit = row.siteProfit + row.cpa_revenue + row.search_revenue;
            row.siteMargin = (100 * (row.siteProfit / row.siteRevenue)).toFixed(0);
            row.totalRevenue = (row.siteRevenue + row.cpa_revenue + row.search_revenue);
            row.totalMargin = (100 * (row.totalProfit / row.totalRevenue)).toFixed(0);

            const impressionsKeys = allKeys.filter((key) => key.endsWith('_impressions'));
            row.totalImpressions = 0;
            impressionsKeys.forEach((key) => {
              row.totalImpressions += row[key];
            });

            row.cpm = (row.siteRevenue / row.totalImpressions) * 1000;
            row.assertive_cpm = (row.assertive_revenue / row.assertive_impressions) * 1000;

            const sessionsKeys = allKeys.filter((key) => key.endsWith('_sessions'));
            row.totalSessions = 0;
            sessionsKeys.forEach((key) => {
              row.totalSessions += row[key];
            });

            row.rps = row.siteRevenue / row.totalSessions;
            row.session_profit = row.rps - row.cps;
            row.session_margin = (100 * ((row.rps - row.cps) / row.rps)).toFixed(0);
            row.click_loss = row.clicks === 0 ? 0 : (100 * ((row.clicks - row.totalSessions) / row.clicks)).toFixed(0);
            row.deviceData = [{ day: row.date.substr(0, 10) }];

            dataDevice
              .filter(
                (rowDevice) => rowDevice.date.substr(0, 10) === row.date.substr(0, 10)
                    && rowDevice.source === row.source
                    && rowDevice.campaign === row.campaign
              )
              .forEach((rowDevice) => {
                const os = rowDevice.os;
                row.deviceData[0][os] = rowDevice.assertive_sessions;
                if (!fields.includes(os)) {
                  fields.push(os);
                }
              });

            return row;
          });

          setState({ data, loading: false });
        })
        .catch((error) => {
          if (Axios.isCancel(error)) return;
          console.error(error);
        });
    },
    [campaign, filter.endDate, filter.startDate, filter.timezone, site, source]
  );

  useEffect(() => {
    updateData();
  }, [updateData]);

  return (
    <div className={classes.paper}>
      <MiniTable
        data={state.data}
        loading={state.loading}
      />
    </div>
  );
};

ListCampaignsAdjuster.propTypes = {
  source: PropTypes.string,
  campaign: PropTypes.string,
  site: PropTypes.string,
};

ListCampaignsAdjuster.defaultProps = {
  source: '',
  campaign: '',
  site: '',
};

export default ListCampaignsAdjuster;
