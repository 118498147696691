import React from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  container: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  spinnerContainer: {
    position: 'absolute',
    margin: 'auto',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    marginTop: '10px'
  },
  loadingContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px',
  },
});

const Loading = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.spinnerContainer}>
        <div className={classes.loadingContainer}>
          <CircularProgress />
          <div>
            Loading...
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loading;
