import { useCallback, useEffect, useRef } from 'react';
import Axios from 'axios';

/**
 * Receives a callback function and it dependencies, automatically handle cancels if the function is called again
 * or if the component unmounts
 * @param {function} callback A callback function
 * @param {array} callbackDependencies The callback dependencies
 */
const useCallbackCancel = (callback, callbackDependencies) => {
  const cancelDataRequest = useRef();

  const callbackFunction = useCallback(async (...args) => {
    try {
      if (cancelDataRequest.current) {
        // Cancel the request if the callback is called again and the old request is still active
        cancelDataRequest.current();
      }
      const cancelToken = new Axios.CancelToken((c) => {
        cancelDataRequest.current = c;
      });

      await callback(cancelToken, ...args);
    } catch (e) {
      if (Axios.isCancel(e)) {
        // Ignore the error if it's a cancel
        return;
      }
      console.error(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...callbackDependencies]);

  useEffect(() => {
    return () => {
      // Cancel the request if the component unmouts
      if (cancelDataRequest.current) {
        cancelDataRequest.current();
      }
    };
  }, []);

  return callbackFunction;
};

export default useCallbackCancel;
