import request from './request';

const routes = Object.freeze({
  campaigns: '/bbot/campaigns',
  images: '/bbot/images',
  publishers: '/bbot/publishers',
  builder: '/bbot/builder',
  job: '/bbot/job',
  facebookJob: '/bbot/facebook_job',
  googleJob: '/bbot/google_job',
  yahooJob: '/bbot/yahoo_job',
  baiduJob: '/bbot/baidu_job',
  mgidJob: '/bbot/mgid_job',
  taboolaJob: '/bbot/taboola_job',
  outbrainJob: '/bbot/outbrain_job',
  settings: '/bbot/settings',
  autobuilder: '/bbot/autobuilder',
});

const BBotAPI = {
  campaigns: {
    lookup: (params) => request({
      url: `${routes.campaigns}/lookup`,
      method: 'get',
      params
    }),
    list: (params) => request({
      url: `${routes.campaigns}/list`,
      method: 'get',
      params
    }),
    history: (params) => request({
      url: `${routes.campaigns}/history`,
      method: 'get',
      params
    }),
    averageClickloss: (params) => request({
      url: `${routes.campaigns}/average-clickloss`,
      method: 'get',
      params
    }),
    notesHistory: (params) => request({
      url: `${routes.campaigns}/notes-history`,
      method: 'get',
      params
    }),
    createNote: (data) => request({
      url: `${routes.campaigns}/create-note`,
      method: 'post',
      data
    }),
    editNote: (data) => request({
      url: `${routes.campaigns}/edit-note`,
      method: 'post',
      data
    }),
    deleteNote: (data) => request({
      url: `${routes.campaigns}/delete-note`,
      method: 'post',
      data
    }),
  },
  images: {
    list: (params, cancelToken) => request({
      url: `${routes.images}/list`,
      method: 'get',
      params,
      cancelToken
    }),
  },
  publishers: {
    history: (params) => request({
      url: `${routes.publishers}/history`,
      method: 'get',
      params
    }),
    exclusionList: (params) => request({
      url: `${routes.publishers}/exclusionlist`,
      method: 'get',
      params,
    }),
    clearPublishers: (params) => request({
      url: `${routes.publishers}/clear`,
      method: 'get',
      params
    }),
    deletePublisherRow: (params) => request({
      url: `${routes.publishers}/delete`,
      method: 'get',
      params
    }),
  },
  builder: {
    zipFiles: (data, onUploadProgress) => request({
      url: `${routes.builder}/zip`,
      onUploadProgress,
      method: 'put',
      data
    }),
    upload: (data, onUploadProgress) => request({
      url: `${routes.builder}/upload`,
      onUploadProgress,
      method: 'put',
      data
    }),
    getImages: (params, cancelToken) => request({
      url: `${routes.builder}/images`,
      method: 'get',
      params,
      cancelToken
    }),
    getImagesTotalByContent: (params, cancelToken) => request({
      url: `${routes.builder}/images_total`,
      method: 'get',
      params,
      cancelToken
    }),
    deleteImages: (data) => request({
      url: `${routes.builder}/delete_images`,
      method: 'post',
      data
    }),
    updateImages: (data) => request({
      url: `${routes.builder}/update_images`,
      method: 'post',
      data
    }),
    getHeadlines: (params, cancelToken) => request({
      url: `${routes.builder}/headlines`,
      method: 'get',
      params,
      cancelToken
    }),
    addHeadlines: (data, onUploadProgress) => request({
      url: `${routes.builder}/add_headlines`,
      onUploadProgress,
      method: 'post',
      data
    }),
    updateHeadlines: (data) => request({
      url: `${routes.builder}/update_headlines`,
      method: 'post',
      data
    }),
    deleteHeadlines: (data) => request({
      url: `${routes.builder}/delete_headlines`,
      method: 'post',
      data
    }),
    getCustomConversions: (data) => request({
      url: `${routes.builder}/custom_conversions`,
      method: 'get',
      data
    }),

  },
  job: {
    create: (data) => request({
      url: `${routes.job}/create`,
      method: 'post',
      data
    }),
    batchCreate: (data) => request({
      url: `${routes.job}/create-batch`,
      method: 'post',
      data
    }),
    getHistory: (params, cancelToken) => request({
      url: `${routes.job}/get_history`,
      method: 'get',
      params,
      cancelToken
    }),
  },
  facebookJob: {
    create: (data) => request({
      url: `${routes.facebookJob}/create`,
      method: 'post',
      data
    }),
    delete: (data) => request({
      url: `${routes.facebookJob}/delete`,
      method: 'post',
      data
    }),
    setStatus: (data) => request({
      url: `${routes.facebookJob}/set_status`,
      method: 'post',
      data
    }),
    retry: (data) => request({
      url: `${routes.facebookJob}/retry`,
      method: 'post',
      data
    }),
    clone: (data) => request({
      url: `${routes.facebookJob}/clone`,
      method: 'post',
      data
    }),
    getJobList: (params, cancelToken) => request({
      url: `${routes.facebookJob}/job_list`,
      method: 'get',
      params,
      cancelToken
    }),
    getJobById: (params, cancelToken) => request({
      url: `${routes.facebookJob}/job_id`,
      method: 'get',
      params,
      cancelToken
    }),
    getJobCount: (params, cancelToken) => request({
      url: `${routes.facebookJob}/job_count`,
      method: 'get',
      params,
      cancelToken
    }),
    getDailyJobCount: (params, cancelToken) => request({
      url: `${routes.facebookJob}/daily_job_count`,
      method: 'get',
      params,
      cancelToken
    }),
    updateBudget: (data) => request({
      url: `${routes.facebookJob}/update_budget`,
      method: 'post',
      data
    }),
    listAllByColumn: (params, cancelToken) => request({
      url: `${routes.facebookJob}/list_column`,
      method: 'get',
      params,
      cancelToken
    }),
    createLink: (params, cancelToken) => request({
      url: `${routes.facebookJob}/createlink`,
      method: 'get',
      params,
      cancelToken
    }),
  },
  googleJob: {
    create: (data) => request({
      url: `${routes.googleJob}/create`,
      method: 'post',
      data
    }),
    delete: (data) => request({
      url: `${routes.googleJob}/delete`,
      method: 'post',
      data
    }),
    setStatus: (data) => request({
      url: `${routes.googleJob}/set_status`,
      method: 'post',
      data
    }),
    retry: (data) => request({
      url: `${routes.googleJob}/retry`,
      method: 'post',
      data
    }),
    getJobList: (params, cancelToken) => request({
      url: `${routes.googleJob}/job_list`,
      method: 'get',
      params,
      cancelToken
    }),
    getJobById: (params, cancelToken) => request({
      url: `${routes.googleJob}/job_id`,
      method: 'get',
      params,
      cancelToken
    }),
    getJobCount: (params, cancelToken) => request({
      url: `${routes.googleJob}/job_count`,
      method: 'get',
      params,
      cancelToken
    }),
    getDailyJobCount: (params, cancelToken) => request({
      url: `${routes.googleJob}/daily_job_count`,
      method: 'get',
      params,
      cancelToken
    }),
    updateBudget: (data) => request({
      url: `${routes.googleJob}/update_budget`,
      method: 'post',
      data
    }),
    getCampaignList: (params, cancelToken) => request({
      url: `${routes.googleJob}/campaigns`,
      method: 'get',
      params,
      cancelToken
    }),
    createAssetJob: (data) => request({
      url: `${routes.googleJob}/create_asset_job`,
      method: 'post',
      data
    }),
    listAllByColumn: (params, cancelToken) => request({
      url: `${routes.googleJob}/list_column`,
      method: 'get',
      params,
      cancelToken
    }),
    createLink: (params, cancelToken) => request({
      url: `${routes.googleJob}/createlink`,
      method: 'get',
      params,
      cancelToken
    }),
    /**
     * @param {object} params accountId: account.customerId
     * @returns .data -- array of conversion action objects
     */
    getConversionActions: (params, cancelToken) => request({
      url: `${routes.googleJob}/conversion_actions`,
      method: 'get',
      params,
      cancelToken
    }),

  },
  yahooJob: {
    create: (data) => request({
      url: `${routes.yahooJob}/create`,
      method: 'post',
      data
    }),
    delete: (data) => request({
      url: `${routes.yahooJob}/delete`,
      method: 'post',
      data
    }),
    setStatus: (data) => request({
      url: `${routes.yahooJob}/set_status`,
      method: 'post',
      data
    }),
    getJobList: (params, cancelToken) => request({
      url: `${routes.yahooJob}/job_list`,
      method: 'get',
      params,
      cancelToken
    }),
    getJobCount: (params, cancelToken) => request({
      url: `${routes.yahooJob}/job_count`,
      method: 'get',
      params,
      cancelToken
    }),
    getDailyJobCount: (params, cancelToken) => request({
      url: `${routes.yahooJob}/daily_job_count`,
      method: 'get',
      params,
      cancelToken
    }),
    updateBudget: (data) => request({
      url: `${routes.yahooJob}/update_budget`,
      method: 'post',
      data
    }),
    listAllByColumn: (params, cancelToken) => request({
      url: `${routes.yahooJob}/list_column`,
      method: 'get',
      params,
      cancelToken
    }),
  },
  baiduJob: {
    create: (data) => request({
      url: `${routes.baiduJob}/create`,
      method: 'post',
      data
    }),
    delete: (data) => request({
      url: `${routes.baiduJob}/delete`,
      method: 'post',
      data
    }),
    setStatus: (data) => request({
      url: `${routes.baiduJob}/set_status`,
      method: 'post',
      data
    }),
    clone: (data) => request({
      url: `${routes.baiduJob}/clone`,
      method: 'post',
      data
    }),
    retry: (data) => request({
      url: `${routes.baiduJob}/retry`,
      method: 'post',
      data
    }),
    getJobList: (params, cancelToken) => request({
      url: `${routes.baiduJob}/job_list`,
      method: 'get',
      params,
      cancelToken
    }),
    getJobById: (params, cancelToken) => request({
      url: `${routes.baiduJob}/job_id`,
      method: 'get',
      params,
      cancelToken
    }),
    getJobCount: (params, cancelToken) => request({
      url: `${routes.baiduJob}/job_count`,
      method: 'get',
      params,
      cancelToken
    }),
    getDailyJobCount: (params, cancelToken) => request({
      url: `${routes.baiduJob}/daily_job_count`,
      method: 'get',
      params,
      cancelToken
    }),
    updateBudget: (data) => request({
      url: `${routes.baiduJob}/update_budget`,
      method: 'post',
      data
    }),
    listAllByColumn: (params, cancelToken) => request({
      url: `${routes.baiduJob}/list_column`,
      method: 'get',
      params,
      cancelToken
    }),
  },
  taboolaJob: {
    create: (data) => request({
      url: `${routes.taboolaJob}/create`,
      method: 'post',
      data
    }),
    delete: (data) => request({
      url: `${routes.taboolaJob}/delete`,
      method: 'post',
      data
    }),
    setStatus: (data) => request({
      url: `${routes.taboolaJob}/set_status`,
      method: 'post',
      data
    }),
    clone: (data) => request({
      url: `${routes.taboolaJob}/clone`,
      method: 'post',
      data
    }),
    retry: (data) => request({
      url: `${routes.taboolaJob}/retry`,
      method: 'post',
      data
    }),
    getJobList: (params, cancelToken) => request({
      url: `${routes.taboolaJob}/job_list`,
      method: 'get',
      params,
      cancelToken
    }),
    getJobById: (params, cancelToken) => request({
      url: `${routes.taboolaJob}/job_id`,
      method: 'get',
      params,
      cancelToken
    }),
    getJobCount: (params, cancelToken) => request({
      url: `${routes.taboolaJob}/job_count`,
      method: 'get',
      params,
      cancelToken
    }),
    getDailyJobCount: (params, cancelToken) => request({
      url: `${routes.taboolaJob}/daily_job_count`,
      method: 'get',
      params,
      cancelToken
    }),
    updateBudget: (data) => request({
      url: `${routes.taboolaJob}/update_budget`,
      method: 'post',
      data
    }),
    listAllByColumn: (params, cancelToken) => request({
      url: `${routes.taboolaJob}/list_column`,
      method: 'get',
      params,
      cancelToken
    }),
  },
  mgidJob: {
    create: (data) => request({
      url: `${routes.mgidJob}/create`,
      method: 'post',
      data
    }),
    delete: (data) => request({
      url: `${routes.mgidJob}/delete`,
      method: 'post',
      data
    }),
    setStatus: (data) => request({
      url: `${routes.mgidJob}/set_status`,
      method: 'post',
      data
    }),
    clone: (data) => request({
      url: `${routes.mgidJob}/clone`,
      method: 'post',
      data
    }),
    retry: (data) => request({
      url: `${routes.mgidJob}/retry`,
      method: 'post',
      data
    }),
    getJobList: (params, cancelToken) => request({
      url: `${routes.mgidJob}/job_list`,
      method: 'get',
      params,
      cancelToken
    }),
    getJobById: (params, cancelToken) => request({
      url: `${routes.mgidJob}/job_id`,
      method: 'get',
      params,
      cancelToken
    }),
    getJobCount: (params, cancelToken) => request({
      url: `${routes.mgidJob}/job_count`,
      method: 'get',
      params,
      cancelToken
    }),
    getDailyJobCount: (params, cancelToken) => request({
      url: `${routes.mgidJob}/daily_job_count`,
      method: 'get',
      params,
      cancelToken
    }),
    updateBudget: (data) => request({
      url: `${routes.mgidJob}/update_budget`,
      method: 'post',
      data
    }),
    listAllByColumn: (params, cancelToken) => request({
      url: `${routes.mgidJob}/list_column`,
      method: 'get',
      params,
      cancelToken
    }),
  },
  outbrainJob: {
    create: (data) => request({
      url: `${routes.outbrainJob}/create`,
      method: 'post',
      data
    }),
    delete: (data) => request({
      url: `${routes.outbrainJob}/delete`,
      method: 'post',
      data
    }),
    setStatus: (data) => request({
      url: `${routes.outbrainJob}/set_status`,
      method: 'post',
      data
    }),
    clone: (data) => request({
      url: `${routes.outbrainJob}/clone`,
      method: 'post',
      data
    }),
    retry: (data) => request({
      url: `${routes.outbrainJob}/retry`,
      method: 'post',
      data
    }),
    getJobList: (params, cancelToken) => request({
      url: `${routes.outbrainJob}/job_list`,
      method: 'get',
      params,
      cancelToken
    }),
    getJobById: (params, cancelToken) => request({
      url: `${routes.outbrainJob}/job_id`,
      method: 'get',
      params,
      cancelToken
    }),
    getJobCount: (params, cancelToken) => request({
      url: `${routes.outbrainJob}/job_count`,
      method: 'get',
      params,
      cancelToken
    }),
    getDailyJobCount: (params, cancelToken) => request({
      url: `${routes.outbrainJob}/daily_job_count`,
      method: 'get',
      params,
      cancelToken
    }),
    updateBudget: (data) => request({
      url: `${routes.outbrainJob}/update_budget`,
      method: 'post',
      data
    }),
    listAllByColumn: (params, cancelToken) => request({
      url: `${routes.outbrainJob}/list_column`,
      method: 'get',
      params,
      cancelToken
    }),
  },
  settings: { // settings for rules
    getSettingByName: (params, cancelToken) => request({
      url: `${routes.settings}/name`,
      method: 'get',
      params,
      cancelToken
    }),
    getSettingsByNames: (params, cancelToken) => request({
      url: `${routes.settings}/names`,
      method: 'get',
      params,
      cancelToken
    }),
    updateSetting: (data) => request({
      url: `${routes.settings}/update`,
      method: 'post',
      data
    }),
  },
  autobuilder: {
    listNegativeWords: (params, cancelToken) => request({
      url: `${routes.autobuilder}/negative_words`,
      method: 'get',
      params,
      cancelToken
    }),
    updateNegativeWords: (data) => request({
      url: `${routes.autobuilder}/negative_words`,
      method: 'put',
      data
    }),
    getAutoBuilderDefaultSettings: (params, cancelToken) => request({
      url: `${routes.autobuilder}/default`,
      method: 'get',
      params,
      cancelToken
    }),
    getAutoBuilderSettings: (params, cancelToken) => request({
      url: `${routes.autobuilder}/settings`,
      method: 'get',
      params,
      cancelToken
    }),
    updateAutoBuilderDefaultSettings: (data) => request({
      url: `${routes.autobuilder}/update-default`,
      method: 'post',
      data
    }),
    updateAutoBuilderSettings: (data) => request({
      url: `${routes.autobuilder}/update-settings`,
      method: 'post',
      data
    }),
    launchManual: (data) => request({
      url: `${routes.autobuilder}/manual-launch`,
      method: 'post',
      data
    }),
  }
};

export default BBotAPI;
