import { useRef, useEffect } from 'react';
import { useFilter } from 'contexts/FilterProvider';

const waitTime = 5000;

/**
 * Receives a callback function and calls it when the Refresh Button is clicked
 * Waits for the waitTime before being able to call the callback function again
 * @param {function} callback A callback function
 */
const useRefresh = (callback) => {
  const { shouldRefresh } = useFilter();
  const isFirstCallRef = useRef(true);
  const firstRender = useRef(true);

  useEffect(() => {
    if (!firstRender.current) { // Don't load on first render
      if (isFirstCallRef.current) {
        callback();
        isFirstCallRef.current = false;
      }
    }

    const t = setTimeout(() => {
      isFirstCallRef.current = true;
    }, waitTime);

    return () => {
      clearTimeout(t)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRefresh])

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    }
  }, []);

};

export default useRefresh;
