import React from 'react';
import PropTypes from 'prop-types';

import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'rgba(0, 0, 0, 1)',
    maxWidth: 500,
    border: '1px solid #dadde9',
  },
}));

const ImagerHover = ({ image, width, height, alt, widthHover, heightHover, lazy }) => {
  if (typeof image !== 'string' || image === '') return <></>;
  return (
    <CustomTooltip title={(
      <img src={image} alt={`${alt} enlarge`} height={heightHover} width={widthHover} loading={lazy ? 'lazy' : 'eager'} />
    )}
    >
      <img src={image} alt={alt} width={width} height={height} loading={lazy ? 'lazy' : 'eager'} />
    </CustomTooltip>
  );
};

ImagerHover.propTypes = {
  image: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  widthHover: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  heightHover: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  alt: PropTypes.string,
  lazy: PropTypes.bool,
};

ImagerHover.defaultProps = {
  width: '50px',
  height: 'auto',
  widthHover: 'auto',
  heightHover: 'auto',
  alt: '',
  image: '',
  lazy: false,
};

function propsAreEqual(prev, next) {
  const result = prev.image === next.image;
  return result;
}

export default React.memo(ImagerHover, propsAreEqual);
