/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable max-len */
import React, { Fragment, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Axios from 'axios';

import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { dataFormatter } from 'core/Components/Tables/TableFilters';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import withStyles from '@mui/styles/withStyles';
import Accordion from '@mui/material/Accordion';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';

import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import SearchIcon from '@mui/icons-material/Search';
import PublicIcon from '@mui/icons-material/Public';

import useCallbackCancel from 'hooks/useCallbackCancel';
import CronutJuicyAPI from 'services/api/cronutJuicy';
import SparkChart from 'core/Components/Charts/SparkChart';
import WebstatsAPI from 'services/api/webstats';
import useRefresh from 'hooks/useRefresh';
import Loader from 'core/Components/Loader';

import { deepClone, precisionRound } from 'core/Functions/utilities';

const styles = theme => ({
  card: {
    flexGrow: 1,
    minWidth: 175,
    overflow: 'auto',
    paddingBottom: '5px',
    whiteSpace: 'nowrap',
    margin: `${theme.spacing(1)} !important`
  },
  bold: {
    fontWeight: 700
  },
  ucfirst: {
    fontWeight: 'bold',
    textTransform: 'capitalize'
  },
  padrside: {
    paddingRight: '5px',
  },
  padlside: {
    paddingLeft: '5px',
  },
  padsides: {
    paddingLeft: '5px',
    paddingRight: '5px'
  },
  smallButton: {
    paddingLeft: '0px',
    paddingRight: '0px',
    textTransform: 'capitalize',
    height: '10px',
    minHeight: '10px',
    minWidth: 'unset'
  },
  borderTop: {
    borderTop: '1px solid #999999'
  },
  nowrap: {
    whiteSpace: 'nowrap'
  },
  borderRight: {
    borderRight: '1px solid #999999',
  },
  borderLeft: {
    borderLeft: '1px solid #999999',
  },
  hoverTr: {
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.05)'
    }
  }
});

const titleOverrides = {
  keywee_est: 'Keywee',
  keywee_pst: 'keywee (PST)',
  outbrain_engage: 'Outbrain',
  mgid_native: 'Mgid',
  juicyx: 'JuicyX',
  facebook: 'meta',
  lockerdome: 'LD',
  investingfuel: 'if',
  bfill: 'Backfill'
};

const sourceMapping = {
  fb_ab: 'facebook',
  fb_jp: 'facebook',
  fb_k_p: 'keywee_pst',
  fb_k: 'keywee_est',
  ga_k: 'keywee_est',
  ld: 'lockerdome',
  me: 'baidu',
  mg: 'mgid',
  gm: 'yahoo',
  ga: 'google',
  dv: 'dv360',
  ob: 'outbrain',
  sm: 'organic',
  'dlvr.it': 'dlvr.it',
  msn: 'msn',
  bfill: 'bfill',
  legacybf: 'bfill',
  gambf: 'bfill',
  organic: 'organic',
  tb: 'taboola',
  AdGarden: 'AdGarden',
};

const initialFieldValues = {
  supply: [
    'google',
    'dv360',
    'facebook',
    'keywee_est',
    'keywee_pst',
    'lockerdome',
    'baidu',
    'mgid',
    'yahoo',
    'outbrain',
    'gazillions',
    'investingfuel',
    'mdrntoday',
    'heroinvesting',
    'followsports',
    'kaleandcardio',
    'organic',
    'juicyx',
    'dlvr.it',
    'msn',
    'bfill',
    'taboola'
  ],
  summary: {},
  summaryTotal: {},
  summarySynced: {},
  chartData: {},
  isLoading: false,
  filter: 'all',
};

const SupplySummary = (props) => {
  const [state, setState] = useReducer((oldState, newState) => ({ ...oldState, ...newState }), { ...deepClone(initialFieldValues), ...props });

  const colorize = (value) => {
    value = String(value);
    value = Number(value.replace('$', '').replace('%', ''));
    const style = {};
    if (value < 0) {
      style.color = 'rgb(200, 0, 0)';
    } else if (value === 0 || value === 100) {
      style.color = 'rgb(211,211,211)';
    } else {
      style.color = 'rgb(0, 200, 0)';
    }
    return style;
  };

  const updateCampaignData = useCallbackCancel(async (cancelToken) => {
    setState({ isLoading: true });

    const summarySpend = {};
    const summaryRevenue = {};
    const summaryCPA = {};
    const summaryCPARate = {};
    const summaryContent = {};
    const summaryContentRate = {};
    const summarySearch = {};
    const summarySearchRate = {};
    const summaryRevenueJuicy = {};
    const summaryVideo = {};
    const summaryProfit = {};
    const summaryRates = {};
    const summaryRateRevenue = {};
    const summaryRateProfit = {};

    state.supply.forEach(row => {
      summarySpend[row] = 0;
      summaryRevenue[row] = 0;
      summaryCPA[row] = 0;
      summaryCPARate[row] = 0;
      summaryContent[row] = 0;
      summaryContentRate[row] = 0;
      summarySearch[row] = 0;
      summarySearchRate[row] = 0;
      summaryRevenueJuicy[row] = 0;
      summaryVideo[row] = 0;
      summaryProfit[row] = 0;
      summaryRateRevenue[row] = 0;
      summaryRateProfit[row] = 0;
    });

    const params = {
      timezone: props.timezone,
      startDate: moment(props.startDate).format('YYYY-MM-DD 00:00:00'),
      endDate: moment(props.endDate).format('YYYY-MM-DD 23:59:59'),
      site: props.site === 'juicyx' ? '' : props.site,
      filter: state.filter,
    };

    const paramsSourceGraph = {
      startDate: moment(props.endDate).subtract(6, 'days').format('YYYY-MM-DD 00:00:00'),
      endDate: moment(props.endDate).format('YYYY-MM-DD 23:59:59'),
      site: props.site === 'juicyx' ? '' : props.site,
      filter: state.filter,
    };

    await Promise.all([
      props.shouldLastSynced && WebstatsAPI.gazillions.getSyncedSummary(params, cancelToken),
      CronutJuicyAPI.stats.getSourceSummaryData(params, cancelToken),
      CronutJuicyAPI.stats.getSourceProfitDaily(paramsSourceGraph, cancelToken),
    ]).then(([
      getSynced,
      cronutJuicyRes,
      getProfitSourceDaily,
    ]) => {

      // Checks the last synced data
      let summarySynced = {};
      if (getSynced) {
        const synced = getSynced.data.results;
        if (synced && synced.length) {
          summarySynced = synced[0];
        }
      }
      // --------------------------------------------

      // Get the stats for the daily profit graphs
      const dailyChartSource = getProfitSourceDaily.data.results;
      const aggregatedChart = {};
      dailyChartSource.forEach(row => {
        const date = row.date.substr(0, 10);
        let sourceKey = sourceMapping[row.source] || sourceMapping.organic;
        if (props.site === 'summary') {
          if (row.organization && row.site) {
            // sourceKey = row.organization + ' - ' + row.site;
            sourceKey = 'AdGarden';
          }
        } else if (props.site === 'juicyx') {
          // skip non juicyx rows
          if (!row.organization) {
            return;
          }
          sourceKey = row.organization + ' - ' + row.domain;
        } else if ((props.site !== 'ask') && row.organization) {
          // skip juicyx rows
          return;
        }

        if (aggregatedChart[date] === undefined) {
          aggregatedChart[date] = {
            [sourceKey]: 0
          };
        }
        if (aggregatedChart[date][sourceKey] === undefined) {
          aggregatedChart[date][sourceKey] = 0;
        }

        const profitAux = row.revenue - row.spend || 0;
        const rateAux = row.rate ? row.rate : 1;

        if (sourceKey === 'AdGarden') {
          const juicy = (row.cpa + row.search + row.content) * (1 - rateAux);
          aggregatedChart[date][sourceKey] += precisionRound(row.revenue + juicy);
        } else if (props.site === 'juicyx') {
          const juicy = (row.cpa + row.search + row.content);
          aggregatedChart[date][sourceKey] += precisionRound(row.revenue + juicy);
        } else {
          const juicy = (row.cpa + row.search);
          aggregatedChart[date][sourceKey] += precisionRound(profitAux + juicy);
        }
      });

      // -------------------------------------------------------------------------------------

      // Get the Summary Stats
      const newSupply = state.supply;
      const summary = {}; // Summary of each supply/source

      const summaryTotal = { // Total of all Sources
        revenue: 0,
        spend: 0,
        profit: 0,
        margin: 0,
        clicks: 0,
        sessions: 0,
        estimated: 0,
        display: 0,
        cpa: 0,
        content: 0,
        search: 0,
        cpaRate: 0,
        contentRate: 0,
        searchRate: 0,
        video: 0,
        rate_profit: 0
      };

      const cronutJuicy = cronutJuicyRes.data.results;
      if (cronutJuicy && cronutJuicy.length) {
        cronutJuicy.forEach(row => {
          // Mapped all sources, so we dont need a IF for every source, and if there is no source it will be mapped to organic
          let sourceKey = sourceMapping[row.source] || sourceMapping.organic;
          if (props.site === 'summary') {
            if (row.organization) {
              sourceKey = 'AdGarden';
            }
          } else if (props.site === 'juicyx') {
            // skip non juicyx rows
            if (!row.organization) {
              return;
            }
            sourceKey = row.organization + ' - ' + row.site;
          } else if (props.site !== 'ask' && row.organization) {
            // skip juicyx rows
            return;
          }

          // summaryTotal.display += row.revenue;
          if (sourceKey === 'AdGarden') {
            summaryTotal.cpa += row.rate ? row.cpa * (1 - row.rate) : row.cpa;
            summaryTotal.content += row.rate ? row.content * (1 - row.rate) : row.content;
            summaryTotal.search += row.rate ? row.search * (1 - row.rate) : row.search;
            summaryTotal.display += (row.revenue - (row.rate ? row.content * (1 - row.rate) : row.content));
          } else {
            summaryTotal.cpa += row.rate ? row.cpa * row.rate : row.cpa;
            summaryTotal.content += row.rate ? row.content * row.rate : row.content;
            summaryTotal.search += row.rate ? row.search * row.rate : row.search;
            summaryTotal.display += (row.revenue - (row.rate ? row.content * row.rate : row.content));
          }

          summaryTotal.cpaRate += row.rate ? row.cpa * (1 - row.rate) : row.cpa;
          summaryTotal.contentRate += row.rate ? row.content * (1 - row.rate) : row.content;
          summaryTotal.searchRate += row.rate ? row.search * (1 - row.rate) : row.search;

          summaryTotal.video += row.video;

          if (props.site === 'juicyx') {
            summaryTotal.revenue += row.cpa + row.content + row.search;
          } else if (sourceKey === 'AdGarden') {
            // summaryTotal.revenue += (row.cpa + row.content + row.search) * (1 - row.rate);
          } else {
            // row.revenue already includes content
            summaryTotal.revenue += row.revenue + row.cpa + row.search + row.video;
          }
          summaryTotal.spend += row.spend;

          if (sourceKey === 'AdGarden') {
            summaryTotal.profit += (row.cpa + row.content + row.search) * (1 - row.rate);
            summaryTotal.rate_profit += (row.cpa + row.content + row.search) * (1 - row.rate);
          } else {
            summaryTotal.profit += row.profit;
          }

          summaryTotal.sessions += row.sessions;
          summaryTotal.clicks += row.clicks;

          summaryTotal.rate_profit += row.profit;

          // if (row.source === undefined || row.source === null) {
          //   console.log('wtf, supply summary no source?', row)
          // return row;
          // }

          // if (props.site === 'summary' && sourceKey === 'organic') {
          //   console.log('wtf, organic?', row)
          //   // return row;
          // }

          if (!state.supply.includes(sourceKey)) {
            newSupply.push(sourceKey);
            summarySpend[sourceKey] = 0;
            summaryRevenue[sourceKey] = 0;
            summaryCPA[sourceKey] = 0;
            summaryCPARate[sourceKey] = 0;
            summaryContent[sourceKey] = 0;
            summaryContentRate[sourceKey] = 0;
            summarySearch[sourceKey] = 0;
            summarySearchRate[sourceKey] = 0;
            summaryRevenueJuicy[sourceKey] = 0;
            summaryVideo[sourceKey] = 0;
            summaryProfit[sourceKey] = 0;
            // summaryRates[sourceKey] = row.rate;
            summaryRateRevenue[sourceKey] = 0;
            summaryRateProfit[sourceKey] = 0;
          }

          if (sourceKey !== 'AdGarden') {
            summarySpend[sourceKey] += row.spend;
            summaryRevenue[sourceKey] += row.revenue;
            summaryCPA[sourceKey] += row.rate ? row.cpa * row.rate : row.cpa;
            summaryCPARate[sourceKey] += row.rate ? row.cpa * (1 - row.rate) : row.cpa;
            summaryContent[sourceKey] += row.rate ? row.content * row.rate : row.content;
            summaryContentRate[sourceKey] += row.rate ? row.content * (1 - row.rate) : row.content;
            summarySearch[sourceKey] += row.rate ? row.search * row.rate : row.search;
            summarySearchRate[sourceKey] += row.rate ? row.search * (1 - row.rate) : row.search;
            summaryRevenueJuicy[sourceKey] += (row.cpa + row.content + row.search);
            summaryVideo[sourceKey] += row.video;
            summaryProfit[sourceKey] += row.profit;

            summaryRates[sourceKey] = row.rate;

            summaryRateRevenue[sourceKey] += row.revenue;
            summaryRateProfit[sourceKey] += row.profit;
          } else {
            summaryCPA[sourceKey] += row.rate ? row.cpa * (1 - row.rate) : row.cpa;
            summaryContent[sourceKey] += row.rate ? row.content * (1 - row.rate) : row.content;
            summarySearch[sourceKey] += row.rate ? row.search * (1 - row.rate) : row.search;
            summaryProfit[sourceKey] += (row.cpa + row.content + row.search) * (1 - row.rate);
            summaryRateProfit[sourceKey] += (row.cpa + row.content + row.search) * (1 - row.rate);
            summaryRates[sourceKey] = row.rate;
          }
        });

        // Calculates the summary of each supply/source
        Object.keys(summaryRevenue).forEach(key => {
          summary[`${key}_spend`] = summarySpend[key];

          if (props.site === 'juicyx') {
            summary[`${key}_revenue`] = summaryRevenueJuicy[key];
            summary[`${key}_revenue_rate`] = summaryCPARate[key] + summaryContentRate[key] + summarySearchRate[key];
          } else {
            // row.revenue already includes content
            summary[`${key}_revenue`] = summaryRevenue[key] + summaryCPA[key] + summarySearch[key] + summaryVideo[key];
            if (key === 'AdGarden') summary[`${key}_revenue`] = 0;
            summary[`${key}_revenue_rate`] = summaryRevenue[key] + summaryCPARate[key] + summarySearchRate[key] + summaryVideo[key];
          }

          if (key === 'AdGarden') {
            summary[`${key}_display`] = 0;
            summary[`${key}_display_percent`] = 0;
          } else {
            summary[`${key}_display`] = (summaryRevenue[key] - summaryContent[key]);
            summary[`${key}_display_percent`] = ((summaryRevenue[key] - summaryContent[key]) / summary[`${key}_revenue`]) * 100;
          }

          summary[`${key}_cpa`] = summaryCPA[key];
          summary[`${key}_cpa_rate`] = summaryCPARate[key];
          summary[`${key}_cpa_percent`] = (summaryCPA[key] / summary[`${key}_revenue`]) * 100;
          summary[`${key}_content`] = summaryContent[key];
          summary[`${key}_content_rate`] = summaryContentRate[key];
          summary[`${key}_content_percent`] = (summaryContent[key] / summary[`${key}_revenue`]) * 100;
          summary[`${key}_search`] = summarySearch[key];
          summary[`${key}_search_rate`] = summarySearchRate[key];
          summary[`${key}_search_percent`] = (summarySearch[key] / summary[`${key}_revenue`]) * 100;
          summary[`${key}_video`] = summaryVideo[key];
          summary[`${key}_video_percent`] = (summaryVideo[key] / summary[`${key}_revenue`]) * 100;
          summary[`${key}_revenue_percent`] = (summary[`${key}_revenue`] / summary.revenue) * 100;
          summary[`${key}_profit`] = summaryProfit[key];
          summary[`${key}_margin`] = 100 * (summary[`${key}_profit`] / (summary[`${key}_revenue`]));
          summary[`${key}_rate`] = summaryRates[key];
          summary[`${key}_rate_profit`] = summaryRateProfit[key];
          summary[`${key}_rate_margin`] = 100 * (summary[`${key}_rate_profit`] / (summary[`${key}_revenue`]));
        });

        // Calculates the total of all sources
        summaryTotal.margin = 100 * (summaryTotal.profit / summaryTotal.revenue);
        summaryTotal.display_percent = (summaryTotal.display / summaryTotal.revenue) * 100;
        summaryTotal.cpa_percent = (summaryTotal.cpa / summaryTotal.revenue) * 100;
        summaryTotal.content_percent = (summaryTotal.content / summaryTotal.revenue) * 100;
        summaryTotal.search_percent = (summaryTotal.search / summaryTotal.revenue) * 100;
        summaryTotal.video_percent = (summaryTotal.video / summaryTotal.revenue) * 100;
        summaryTotal.rate_margin = 100 * (summaryTotal.rate_profit / summaryTotal.revenue);
      }

      setState({
        supply: newSupply,
        summary,
        summaryTotal,
        summarySynced,
        chartData: { aggregatedData: aggregatedChart },
        isLoading: false
      });
    }).catch(error => {
      if (Axios.isCancel(error)) return;
      console.error(error);
    });
  }, [props.endDate, props.shouldLastSynced, props.site, props.startDate, props.timezone, state.filter, state.supply]);

  const filterCampaignsBy = (event, key) => {
    event.preventDefault();
    event.stopPropagation();

    setState({ filter: key });
    if (props.onChange !== undefined) {
      props.onChange({ target: { name: 'campaignFilter', value: key } });
    }
  };

  const filterSupplyBy = (key) => {
    if (props.onChange !== undefined) {
      props.onChange({ target: { name: 'supplyFilter', value: key } });
    }
  };

  useEffect(() => {
    if (props.site !== 'summary') {
      setState({ filter: 'content' });
    }
  }, [props.site]);

  useEffect(() => {
    updateCampaignData();
  }, [updateCampaignData]);

  useRefresh(updateCampaignData);

  const getChartColor = (entry) => {
    const color = entry.count === 0 ? '#D3D3D3' : (entry.count < 0 ? '#FF5B25' : '#6689A1');
    return color;
  };

  const summaryTotalJuicyx = state.supply
    .map((key) => (state.summary[`${key}_profit`] * (1 - state.summary[`${key}_rate`])))
    .filter(val => !Number.isNaN(val))
    .reduce((acc, val) => acc + val, 0);

  const SummaryColumn = ({ value, id, slots = {}, component }) => {
    if (props.hideColumns.includes(id)) return <Fragment />;

    return {
      header: component || <td align="center" className={`${props.classes.bold} ${props.classes.padsides}`} {...slots?.td}>{value}</td>,
      body: component || (
        <td {...slots?.td}>
          <Typography align="right" inline={+true} className={`${props.classes.padrside}`} {...slots?.typography}>
            {value}
          </Typography>
        </td>
      ),
      footer: component || (
        <td {...slots?.td}>
          <Typography align="right" inline={+true} className={`${props.classes.padrside} ${props.classes.bold}`} {...slots?.typography}>
            {value}
          </Typography>
        </td>
      )
    };
  };

  return (
    <Accordion
      style={{ height: '100%' }}
      className={props.classes.card}
      expanded={state.isAccordion}
      onChange={props.disableAccordion ? undefined : () => setState({ isAccordion: !state.isAccordion })}
    >
      <AccordionSummary
        expandIcon={props.accordionIcon ? props.accordionIcon : undefined}
      >
        <Typography variant="h5" component="h2" align="center">
          {props.title && (<span>{props.title}</span>)}
          {props.site === 'summary' && (
            <>
              <Tooltip title="Show All">
                <IconButton onClick={(event) => filterCampaignsBy(event, 'all')} sx={{ color: state.filter === 'all' ? '#223998' : '' }}>
                  <PublicIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Show Content">
                <IconButton onClick={(event) => filterCampaignsBy(event, 'content')} sx={{ color: state.filter === 'content' ? '#223998' : '' }}>
                  <MenuBookIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Show Performance">
                <IconButton onClick={(event) => filterCampaignsBy(event, 'direct')} sx={{ color: state.filter === 'direct' ? '#223998' : '' }}>
                  <ShoppingCartIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Show Search">
                <IconButton onClick={(event) => filterCampaignsBy(event, 'search')} sx={{ color: state.filter === 'search' ? '#223998' : '' }}>
                  <SearchIcon />
                </IconButton>
              </Tooltip>
            </>
          )}
        </Typography>

      </AccordionSummary>
      <AccordionDetails>
        <Loader loading={state.isLoading}>
          <table>
            <tbody>
              {/* Start of Header Row */}
              <tr>
                <td />
                {SummaryColumn({ id: 'revenue', value: 'Revenue' }).header}
                {SummaryColumn({ id: 'juicyx_rate', value: 'Rate' }).header}
                {SummaryColumn({ id: 'juicyx_profit', value: 'Profit' }).header}
                {SummaryColumn({ id: 'cpa_rate', value: 'CPA' }).header}
                {SummaryColumn({ id: 'cpa_percentage_rate', value: 'CPA%' }).header}
                {SummaryColumn({ id: 'search_rate', value: 'SearchRate' }).header}
                {SummaryColumn({ id: 'search_percentage_rate', value: 'Search%Rate' }).header}
                {SummaryColumn({ id: '-', value: '-' }).header}
                {SummaryColumn({ id: 'spend', value: 'Spend' }).header}
                {SummaryColumn({ id: '=', value: '=' }).header}
                {SummaryColumn({ id: 'profit', value: 'Profit' }).header}
                {SummaryColumn({ id: 'margin', value: 'Margin' }).header}
                {SummaryColumn({ id: 'display', value: 'Display' }).header}
                {SummaryColumn({ id: 'display_percentage', value: 'Display%' }).header}
                {SummaryColumn({ id: 'cpa', value: 'CPA' }).header}
                {SummaryColumn({ id: 'cpa_percentage', value: 'CPA%' }).header}
                {SummaryColumn({ id: 'content_rate', value: 'Rate' }).header}
                {SummaryColumn({ id: 'content', value: 'Content' }).header}
                {SummaryColumn({ id: 'content_percentage', value: 'Content%' }).header}
                {SummaryColumn({ id: 'search_percentage', value: 'Search%' }).header}
                {SummaryColumn({ id: 'search', value: 'Search' }).header}
                {SummaryColumn({ id: 'video', value: 'Video' }).header}
                {SummaryColumn({ id: 'video_percentage', value: 'Video%' }).header}
                {SummaryColumn({ id: 'daily_profit', value: 'Daily Profit' }).header}
                {SummaryColumn({ id: 'last_synced', value: 'Last Synced' }).header}
              </tr>

              {/* Start of stats by Source */}
              {state.supply.filter(row => state.summary[`${row}_spend`] >= 11
                || state.summary[`${row}_revenue`] >= 11
                || state.summary[`${row}_cpa`] > 1
                || state.summary[`${row}_content`] > 1
                || state.summary[`${row}_search`] > 1)
                .sort((a, b) => state.summary[`${b}_revenue`] - state.summary[`${a}_revenue`])
                .map((row) => {
                  return (
                    <tr key={`${props.site}_${row}`} className={props.classes.hoverTr}>
                      <td>
                        <Typography align="left" inline={+true} className={`${props.classes.ucfirst} ${props.classes.nowrap}`}>
                          <Button color="secondary" className={props.classes.smallButton} onClick={() => filterSupplyBy(row)}>
                            {titleOverrides[row] !== undefined ? titleOverrides[row] : row}:
                          </Button>
                        </Typography>
                      </td>
                      {SummaryColumn({ id: 'revenue', value: dataFormatter.currencyInteger({ value: state.summary[`${row}_revenue`] }) }).body}
                      {SummaryColumn({
                        id: 'juicyx_rate', slots: {
                          typography: {
                            className: `${props.classes.padlside} ${props.classes.padrside}`,
                            style: colorize(100 * (1 - state.summary[`${row}_rate`]))
                          }, td: { className: props.classes.borderLeft }
                        },
                        value: dataFormatter.middlepercent({ value: 100 * (1 - state.summary[`${row}_rate`]) })
                      }).body}
                      {SummaryColumn({
                        id: 'juicyx_profit',
                        slots: {
                          typography: { className: `${props.classes.padrside}` },
                          td: { className: props.classes.borderRight }
                        },
                        value: dataFormatter.currencyInteger({ value: state.summary[`${row}_profit`] * (1 - state.summary[`${row}_rate`]) })
                      }).body}
                      {SummaryColumn({
                        id: 'cpa_rate',
                        slots: { td: { className: props.classes.padsides } },
                        value: dataFormatter.currencyInteger({ value: state.summary[`${row}_cpa_rate`] })
                      }).body}
                      {SummaryColumn({
                        id: 'cpa_percentage_rate',
                        slots: {
                          typography: { style: colorize((100 * state.summary[`${row}_cpa_rate`]) / state.summary[`${row}_revenue_rate`]) },
                          td: { className: props.classes.padrside }
                        },
                        value: dataFormatter.middlepercent({ value: (100 * state.summary[`${row}_cpa_rate`]) / state.summary[`${row}_revenue_rate`] })
                      }).body}
                      {SummaryColumn({
                        id: 'content_rate',
                        slots: { td: { className: props.classes.padsides } },
                        value: dataFormatter.currencyInteger({ value: state.summary[`${row}_content_rate`] })
                      }).body}
                      {SummaryColumn({
                        id: 'content_percentage_rate',
                        slots: {
                          typography: { style: colorize((100 * state.summary[`${row}_content_rate`]) / state.summary[`${row}_revenue_rate`]) },
                          td: { className: props.classes.padrside }
                        },
                        value: dataFormatter.middlepercent({ value: (100 * state.summary[`${row}_content_rate`]) / state.summary[`${row}_revenue_rate`] })
                      }).body}
                      {SummaryColumn({
                        id: 'search_rate',
                        slots: { td: { className: props.classes.padsides } },
                        value: dataFormatter.currencyInteger({ value: state.summary[`${row}_search_rate`] })
                      }).body}
                      {SummaryColumn({
                        id: 'search_percentage_rate',
                        slots: {
                          typography: { style: colorize((100 * state.summary[`${row}_search_rate`]) / state.summary[`${row}_revenue_rate`]) },
                          td: { className: props.classes.padrside }
                        },
                        value: dataFormatter.middlepercent({ value: (100 * state.summary[`${row}_search_rate`]) / state.summary[`${row}_revenue_rate`] })
                      }).body}
                      {SummaryColumn({ id: '-', value: '-', slots: { typography: { padding: '0 5px' } } }).body}
                      {SummaryColumn({ id: 'spend', value: dataFormatter.currencyInteger({ value: state.summary[`${row}_spend`] }) }).body}
                      {SummaryColumn({ id: '=', value: '=' }).body}
                      {SummaryColumn({ id: 'profit', value: dataFormatter.currencyInteger({ value: state.summary[`${row}_profit`] }) }).body}
                      {SummaryColumn({
                        id: 'margin', value: state.summary[`${row}_margin`] > 0 ?
                          dataFormatter.middlepercent({ value: state.summary[`${row}_margin`] }) : `(${dataFormatter.middlepercent({ value: state.summary[`${row}_margin`] }).slice(1)})`,
                        slots: {
                          td: { className: props.classes.borderRight },
                          typography: { style: colorize(state.summary[`${row}_margin`]) }
                        }
                      }).body}
                      {SummaryColumn({
                        id: 'display',
                        slots: { td: { className: props.classes.padsides } },
                        value: dataFormatter.currencyInteger({ value: state.summary[`${row}_display`] })
                      }).body}
                      {SummaryColumn({
                        id: 'display_percentage',
                        slots: {
                          typography: { style: colorize(state.summary[`${row}_display_percent`]) },
                          td: { className: props.classes.padrside }
                        },
                        value: state.summary[`${row}_display_percent`] > 0 ? dataFormatter.middlepercent({ value: state.summary[`${row}_display_percent`] }) : `(${dataFormatter.middlepercent({ value: state.summary[`${row}_display_percent`] }).slice(1)})`
                      }).body}
                      {SummaryColumn({
                        id: 'cpa',
                        slots: { td: { className: props.classes.padsides } },
                        value: dataFormatter.currencyInteger({ value: state.summary[`${row}_cpa`] })
                      }).body}
                      {SummaryColumn({
                        id: 'cpa_percentage',
                        slots: {
                          typography: { style: colorize(state.summary[`${row}_cpa_percent`]) },
                          td: { className: props.classes.padrside }
                        },
                        value: state.summary[`${row}_cpa_percent`] > 0 ? dataFormatter.middlepercent({ value: state.summary[`${row}_cpa_percent`] }) : `(${dataFormatter.middlepercent({ value: state.summary[`${row}_cpa_percent`] }).slice(1)})`
                      }).body}
                      {SummaryColumn({
                        id: 'content',
                        slots: { td: { className: props.classes.padsides } },
                        value: dataFormatter.currencyInteger({ value: state.summary[`${row}_content`] })
                      }).body}
                      {SummaryColumn({
                        id: 'content_percentage',
                        slots: {
                          typography: { style: colorize(state.summary[`${row}_content_percent`]) },
                          td: { className: props.classes.padrside }
                        },
                        value: state.summary[`${row}_content_percent`] > 0 ? dataFormatter.middlepercent({ value: state.summary[`${row}_content_percent`] }) : `(${dataFormatter.middlepercent({ value: state.summary[`${row}_content_percent`] }).slice(1)})`
                      }).body}
                      {SummaryColumn({
                        id: 'search',
                        slots: { td: { className: props.classes.padsides } },
                        value: dataFormatter.currencyInteger({ value: state.summary[`${row}_search`] })
                      }).body}
                      {SummaryColumn({
                        id: 'search_percentage',
                        slots: {
                          typography: { style: colorize(state.summary[`${row}_search_percent`]) },
                          td: { className: props.classes.padrside }
                        },
                        value: state.summary[`${row}_search_percent`] > 0 ? dataFormatter.middlepercent({ value: state.summary[`${row}_search_percent`] }) : `(${dataFormatter.middlepercent({ value: state.summary[`${row}_search_percent`] }).slice(1)})`
                      }).body}
                      {SummaryColumn({
                        id: 'video',
                        slots: { td: { className: props.classes.padsides } },
                        value: dataFormatter.currencyInteger({ value: state.summary[`${row}_video`] })
                      }).body}
                      {SummaryColumn({
                        id: 'video_percentage',
                        slots: {
                          typography: { style: colorize(state.summary[`${row}_video_percent`]) },
                          td: { className: props.classes.padrside }
                        },
                        value: state.summary[`${row}_video_percent`] > 0 ? dataFormatter.middlepercent({ value: state.summary[`${row}_video_percent`] }) : `(${dataFormatter.middlepercent({ value: state.summary[`${row}_video_percent`] }).slice(1)})`
                      }).body}
                      {SummaryColumn({
                        id: 'daily_profit',
                        component: <td>
                          <Typography align="right" inline={+true} className={props.classes.padsides} component="div">
                            <SparkChart data={state.chartData} field={`${row}`} type="currency" getColor={getChartColor} height={25} />
                          </Typography>
                        </td>
                      }).body}
                      {SummaryColumn({
                        id: 'last_synced',
                        slots: {
                          typography: { className: `${props.classes.padrside} ${props.classes.bold}` },
                          td: { className: props.classes.borderRight }
                        },
                        value: dataFormatter.fromNowShort({ value: state.summarySynced[`${row}_synced`] })
                      }).body}
                    </tr>
                  );
                })}

              {/* FOOTER */}
              <tr className={props.classes.borderTop}>
                <td>
                  <Typography align="left" inline={+true} className={`${props.classes.ucfirst} ${props.classes.padsides}`} />
                </td>

                {SummaryColumn({ id: 'revenue', value: dataFormatter.currencyInteger({ value: state.summaryTotal.revenue }) }).footer}
                {SummaryColumn({ id: 'juicyx_rate' }).footer}
                {SummaryColumn({ id: 'juicyx_profit', value: dataFormatter.currencyInteger({ value: summaryTotalJuicyx }) }).footer}
                {SummaryColumn({ id: 'cpa_rate', value: dataFormatter.currencyInteger({ value: state.summaryTotal.cpaRate }) }).footer}
                {SummaryColumn({
                  id: 'cpa_percentage_rate',
                  slots: { typography: { style: colorize((100 * state.summaryTotal.cpaRate) / summaryTotalJuicyx) } },
                  value: dataFormatter.middlepercent({ value: (100 * state.summaryTotal.cpaRate) / summaryTotalJuicyx })
                }).footer}
                {SummaryColumn({ id: 'content_rate', value: dataFormatter.currencyInteger({ value: state.summaryTotal.contentRate }) }).footer}
                {SummaryColumn({
                  id: 'content_percentage_rate',
                  slots: { typography: { style: colorize((100 * state.summaryTotal.contentRate) / summaryTotalJuicyx) } },
                  value: dataFormatter.middlepercent({ value: (100 * state.summaryTotal.contentRate) / summaryTotalJuicyx })
                }).footer}
                {SummaryColumn({ id: 'search_rate', value: dataFormatter.currencyInteger({ value: state.summaryTotal.searchRate }) }).footer}
                {SummaryColumn({
                  id: 'search_percentage_rate',
                  slots: { typography: { style: colorize((100 * state.summaryTotal.searchRate) / summaryTotalJuicyx) } },
                  value: dataFormatter.middlepercent({ value: (100 * state.summaryTotal.searchRate) / summaryTotalJuicyx })
                }).footer}
                {SummaryColumn({ id: '-', value: '-' }).footer}
                {SummaryColumn({ id: 'spend', value: dataFormatter.currencyInteger({ value: state.summaryTotal.spend }) }).footer}
                {SummaryColumn({ id: '=', value: '=' }).footer}
                {SummaryColumn({ id: 'profit', value: dataFormatter.currencyInteger({ value: state.summaryTotal.profit }) }).footer}
                {SummaryColumn({
                  id: 'margin',
                  slots: { typography: { style: colorize(state.summaryTotal.margin) } },
                  value: state.summaryTotal.margin > 0 ? dataFormatter.middlepercent({ value: state.summaryTotal.margin }) : `(${dataFormatter.middlepercent({ value: state.summaryTotal.margin }).slice(1)})`
                }).footer}

                {SummaryColumn({ id: 'display', value: dataFormatter.currencyInteger({ value: state.summaryTotal.display }) }).footer}
                {SummaryColumn({
                  id: 'display_percentage',
                  slots: { typography: { style: colorize(state.summaryTotal.display_percent) } },
                  value: state.summaryTotal.display_percent > 0 ? dataFormatter.middlepercent({ value: state.summaryTotal.display_percent }) : `(${dataFormatter.middlepercent({ value: state.summaryTotal.display_percent }).slice(1)})`
                }).footer}
                {SummaryColumn({ id: 'cpa', value: dataFormatter.currencyInteger({ value: state.summaryTotal.cpa }) }).footer}
                {SummaryColumn({
                  id: 'cpa_percentage',
                  slots: { typography: { style: colorize(state.summaryTotal.cpa_percent) } },
                  value: state.summaryTotal.cpa_percent > 0 ? dataFormatter.middlepercent({ value: state.summaryTotal.cpa_percent }) : `(${dataFormatter.middlepercent({ value: state.summaryTotal.cpa_percent }).slice(1)})`
                }).footer}
                {SummaryColumn({ id: 'content', value: dataFormatter.currencyInteger({ value: state.summaryTotal.content }) }).footer}
                {SummaryColumn({
                  id: 'content_percentage',
                  slots: { typography: { style: colorize(state.summaryTotal.content_percent) } },
                  value: state.summaryTotal.content_percent > 0 ? dataFormatter.middlepercent({ value: state.summaryTotal.content_percent }) : `(${dataFormatter.middlepercent({ value: state.summaryTotal.content_percent }).slice(1)})`
                }).footer}
                {SummaryColumn({ id: 'search', value: dataFormatter.currencyInteger({ value: state.summaryTotal.search }) }).footer}
                {SummaryColumn({
                  id: 'search_percentage',
                  slots: { typography: { style: colorize(state.summaryTotal.search_percent) } },
                  value: state.summaryTotal.search_percent > 0 ? dataFormatter.middlepercent({ value: state.summaryTotal.search_percent }) : `(${dataFormatter.middlepercent({ value: state.summaryTotal.search_percent }).slice(1)})`
                }).footer}

                {SummaryColumn({ id: 'video', value: dataFormatter.currencyInteger({ value: state.summaryTotal.video }) }).footer}
                {SummaryColumn({
                  id: 'video_percentage',
                  slots: { typography: { style: colorize(state.summaryTotal.video_percent) } },
                  value: state.summaryTotal.video_percent > 0 ? dataFormatter.middlepercent({ value: state.summaryTotal.video_percent }) : `(${dataFormatter.middlepercent({ value: state.summaryTotal.video_percent }).slice(1)})`
                }).footer}

                {SummaryColumn({ id: 'daily_profit', component: <td /> }).footer}
              </tr>
            </tbody>
          </table>
        </Loader>

      </AccordionDetails>
    </Accordion >
  );

};

SupplySummary.propTypes = {
  onChange: PropTypes.func,
  classes: PropTypes.object.isRequired,
  site: PropTypes.string,
  title: PropTypes.string,
  timezone: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  shouldLastSynced: PropTypes.bool,
  isAccordion: PropTypes.bool,
  disableAccordion: PropTypes.bool,
  accordionIcon: PropTypes.any, // Material Icons or null
  hideColumns: PropTypes.arrayOf(PropTypes.string)
};

SupplySummary.defaultProps = {
  onChange: () => { },
  site: '',
  title: '',
  shouldLastSynced: false,
  accordionIcon: <ExpandMoreIcon />,
  disableAccordion: false,
  isAccordion: true,
  hideColumns: []
};

export default withStyles(styles, { withTheme: true })(SupplySummary);
