import React, { useCallback, useEffect, useMemo, useState, useReducer } from 'react';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment-timezone';
import cacheman from 'core/Utilities/cacheman';
import PropTypes from 'prop-types';

const FilterContext = React.createContext();

const initialState = {
  site: '',
  timezone: 'America/Vancouver',
  timeframe: 'Today',
  startDate: moment().format('YYYY-MM-DD 00:00:00'),
  endDate: moment().format('YYYY-MM-DD 23:59:59'),
};

const initialShouldShow = [
  'site',
  'timeframe',
  'refresh'
];

const keyCache = 'filter';

function FilterProvider({ children }) {
  const [filter, setFilter] = useReducer(
    (oldState, newState) => ({ ...oldState, ...newState }),
    initialState,
  );
  const [searchParams, setSearchParams] = useSearchParams();

  const [shouldShow, setShouldShow] = useState(initialShouldShow);
  const [customFilterJSX, setCustomFilterJSX] = useState(undefined);
  const [shouldRefresh, setShouldRefresh] = useReducer(x => x + 1, 0);
  // const [shouldRefresh, setShouldRefresh] = useState(false);

  const getTimeframe = (startDate, endDate) => {
    const start = moment(startDate).format('YYYY-MM-DD');
    const end = moment(endDate).format('YYYY-MM-DD');
    const today = moment().format('YYYY-MM-DD');

    if (start === today && end === today) {
      return 'Today';
    }
    if (start === end && end === moment().subtract(1, 'days').format('YYYY-MM-DD')) {
      return 'Yesterday';
    }
    if (start === moment().subtract(2, 'days').format('YYYY-MM-DD') && end === today) {
      return 'Last 3 Days';
    }
    if (start === moment().subtract(6, 'days').format('YYYY-MM-DD') && end === today) {
      return 'Last 7 Days';
    }
    if (start === moment().subtract(29, 'days').format('YYYY-MM-DD') && end === today) {
      return 'Last 30 Days';
    }
    return 'Custom';
  };

  const updateDatesOnPath = useCallback((startDate, endDate) => {
    if (searchParams.get('startDate')) searchParams.set('startDate', moment(startDate).format('YYYY-MM-DD'));
    if (searchParams.get('endDate')) searchParams.set('endDate', moment(endDate).format('YYYY-MM-DD'));
    setSearchParams(searchParams.toString());
  }, [setSearchParams, searchParams]);

  const clearFilter = useCallback(() => {
    updateDatesOnPath(initialState.startDate, initialState.endDate);
    setFilter(initialState);
    cacheman.delete(keyCache, 'session');
  }, [updateDatesOnPath]);

  const numberApplied = useMemo(() => {
    let count = 0;
    Object.keys(filter).forEach((k) => {
      if (k === 'endDate' || k === 'timeframe') return;
      if (filter[k] !== initialState[k]) count += 1;
    });
    return count;
  }, [filter]);

  const updateStateFilter = useCallback((newState) => {
    if (newState.startDate && newState.endDate) {
      const timeframe = getTimeframe(newState.startDate, newState.endDate);
      updateDatesOnPath(newState.startDate, newState.endDate);
      setFilter({ ...newState, timeframe });
    } else {
      setFilter(newState);
    }
    cacheman.set(keyCache, newState, 10, 'session');
  }, [updateDatesOnPath]);

  const resetShouldShow = () => {
    setShouldShow(initialShouldShow);
  };

  const refreshComponents = useCallback(() => {
    setShouldRefresh();
  }, []);

  useEffect(() => {
    const startParam = searchParams.get('startDate');
    const endParam = searchParams.get('endDate');

    if (startParam && endParam) {
      const newFilter = {
        startDate: moment(startParam).format('YYYY-MM-DD 00:00:00'),
        endDate: moment(endParam).format('YYYY-MM-DD 23:59:59'),
        timeframe: getTimeframe(startParam, endParam)
      };
      setFilter(newFilter);
      cacheman.set(keyCache, newFilter, 10, 'session');
      return;
    }
    const cache = cacheman.get(keyCache, 'session');
    if (cache) {
      setFilter(cache);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = useMemo(() => {
    return {
      filter,
      updateStateFilter,
      clearFilter,
      numberApplied,
      shouldShow,
      setShouldShow,
      resetShouldShow,
      customFilterJSX,
      setCustomFilterJSX,
      shouldRefresh,
      refreshComponents
    };
  }, [clearFilter, customFilterJSX, filter, numberApplied, refreshComponents, shouldRefresh, shouldShow, updateStateFilter]);

  return <FilterContext.Provider value={value}>{children}</FilterContext.Provider>;
}

function useFilter() {
  const context = React.useContext(FilterContext);
  if (context === undefined) {
    throw new Error('useFilter must be used within a FilterProvider');
  }
  return context;
}

function FilterConsumer({ children }) {
  return (
    <FilterContext.Consumer>
      {context => {
        if (context === undefined) {
          throw new Error('FilterConsumer must be used within a FilterProvider');
        }
        return children(context);
      }}
    </FilterContext.Consumer>
  );
}

FilterProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

FilterConsumer.propTypes = {
  children: PropTypes.any.isRequired,
};

export { FilterProvider, useFilter, FilterConsumer, FilterContext };
