import React, { Fragment, useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { BarChart, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Surface, Symbols, ReferenceLine, Bar, Cell } from 'recharts';

import { dataFormatter } from 'core/Components/Tables/TableFilters';
import makeStyles from '@mui/styles/makeStyles';
import Loader from 'core/Components/Loader';

const useStyles = makeStyles((_theme) => ({
  paper: {
    margin: '0 auto',
    height: '300px !important',
    maxWidth: 1500,
  },
}));

const NewStackedBarChart = ({ data, sections, stackedSections, selectedSites, selectedSources, loading, maxBarSize, type, formatYValue, formatTooltip, itemSorter, showReferenceLine, showNegativeAsRed, offsetYTooltip, showValueOnTop }) => {
  const [activeBar, setActiveBar] = useState(null);
  const classes = useStyles();

  const sectionToShow = useMemo(() => {
    if (!sections) return [];
    return sections.filter(n => selectedSites.includes(n.key)).map(n => n.props);
  }, [sections, selectedSites]);

  const sourcesToShow = useMemo(() => {
    if (!stackedSections) return [];
    return stackedSections.filter(n => selectedSources.includes(n.key)).map(n => n);
  }, [stackedSections, selectedSources]);

  const renderCustomizedLegend = (_section) => {
    // const { payload } = section;
    return (
      <div className="customized-legend" style={{ display: 'flex', gap: '0.6rem', flexDirection: 'column' }}>
        <div style={{ display: 'flex', gap: '1rem', flexWrap: 'wrap' }}>
          {sourcesToShow.map((ss) => {
            return (
              <span key={ss.key}>
                <Surface width={10} height={10} viewBox={{ x: 0, y: 0, width: 10, height: 10 }}>
                  <Symbols cx={5} cy={5} type="circle" size={50} fill={ss.props.fill} />
                </Surface>
                <span>&nbsp;{ss.key}</span>
              </span>
            );
          })}
        </div>
      </div>
    );
  };

  const formatYAxis = (value) => {
    if (type) {
      return dataFormatter[type]({ value });
    }
    return formatYValue(`${value}`);
  };

  const coloredCell = (section, entry) => {
    const hasData = !!((entry.spend && entry.spend > 0));
    const color = !hasData ? '#D3D3D3' : entry[section.dataKey] > 0 ? '#0f8e0f' : '#d61a1a';
    return (
      <Cell
        {...section}
        fill={color}
        stroke={color}
      />
    );
  };

  const formatTooltipDefault = (val) => {
    if (type) {
      return dataFormatter[type]({ value: val });
    }
    return val;
  };

  const CustomTooltip = useCallback((data) => {
    const { active, payload } = data;

    if (active && payload) {
      const day = payload[0] ? payload[0].payload : undefined;
      if (!day) return <Fragment />;

      const date = day.date;
      const siteKey = Object.keys(day);
      delete siteKey[siteKey.indexOf('date')];

      return (
        <div
          key={date}
          style={{
            padding: '6px',
            backgroundColor: 'white',
            border: '1px solid grey',
            maxWidth: '100%'
          }}
        >
          <b>{date}</b>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {siteKey.flatMap(key => {
              if (!selectedSites.includes(key)) return [];
              const filteredSources = Object.entries(day[key]).filter(([source, _profit]) => selectedSources.includes(source));
              const sources = filteredSources.sort((a, b) => b[1] - a[1]);
              return (
                <div key={key} style={{ marginRight: '10px', padding: '0.2rem', background: activeBar && activeBar.dataKey === key ? '#CCCCCC' : undefined }} >
                  <b>{key}</b>
                  {sources.map(([source, revenue]) => {
                    return <p key={source}>{source}: {dataFormatter.currency({ value: revenue })}</p>;
                  })}
                </div>
              );
            })}
          </div>
        </div>
      );
    }
    return null;
  }, [activeBar, selectedSites, selectedSources]);

  const itemSorterDefault = (item) => {
    return item.value * -1;
  };

  const StackBars = useMemo(() => {
    return sectionToShow.map((siteKey) => sourcesToShow.map((sourceKey) => {
      return (
        <Bar
          // key={sourceKey.dataKey}
          dataKey={`${siteKey.dataKey}.${sourceKey.props.dataKey}`}
          stackId={siteKey.dataKey}
          onMouseMove={() => setActiveBar(siteKey)}
          onMouseLeave={() => setActiveBar(null)}
          label={showValueOnTop ? 'top' : undefined}
          style={{ position: 'relative', zIndex: 0 }}
        >
          {data.map((entry) => {
            return (
              <React.Fragment key={`cell-${entry}`}>
                {(sectionToShow.length === 1 && showNegativeAsRed) ? coloredCell(sourceKey, entry) : <Cell {...sourceKey} />}
              </React.Fragment>
            );
          })}
        </Bar>
      );
    }));
  }, [data, sectionToShow, showNegativeAsRed, showValueOnTop, sourcesToShow]);

  if (!sections) return <div />;
  return (
    <Loader loading={loading} noBlur noStyle>
      <ResponsiveContainer className={classes.paper}>
        <BarChart
          data={data}
          margin={{ top: 5, right: 30, left: 20, bottom: 10 }}
          maxBarSize={maxBarSize}
          barGap={2}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis
            tickFormatter={formatYAxis}
            domain={[0, (dataMax) => {
              if (dataMax < 1 && dataMax > 0) return dataMax * 1.10;
              return Math.ceil(dataMax * 1.05) || 1;
            }
            ]}
          />

          <Tooltip
            content={<CustomTooltip newActive={!!activeBar} newPayload={activeBar} />}
            formatter={formatTooltip || formatTooltipDefault}
            itemSorter={itemSorter || itemSorterDefault}
            position={{ y: offsetYTooltip, x: 0 }}
          />

          {showReferenceLine && <ReferenceLine y={0} stroke="#000" />}

          <Legend
            payload={sections.map(section => ({
              dataKey: section.key,
              color: section.props.stroke
            }))}
            content={renderCustomizedLegend}
          />

          {StackBars}

        </BarChart>
      </ResponsiveContainer>
    </Loader >
  );
};

NewStackedBarChart.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.array.isRequired,
  sections: PropTypes.array,
  stackedSections: PropTypes.array,
  maxBarSize: PropTypes.number,
  selectedSites: PropTypes.array,
  selectedSources: PropTypes.array,
  formatTooltip: PropTypes.func,
  itemSorter: PropTypes.func,
  formatYValue: PropTypes.func,
  type: PropTypes.string,
  showReferenceLine: PropTypes.bool,
  showNegativeAsRed: PropTypes.bool,
  showValueOnTop: PropTypes.bool,
  offsetYTooltip: PropTypes.number
};

NewStackedBarChart.defaultProps = {
  maxBarSize: 50,
  selectedSites: [],
  selectedSources: [],
  loading: false,
  sections: undefined,
  stackedSections: undefined,
  formatTooltip: undefined,
  itemSorter: undefined,
  formatYValue: (val) => {
    return val;
  },
  type: 'number',
  showReferenceLine: false,
  showNegativeAsRed: false,
  showValueOnTop: false,
  offsetYTooltip: 0
};

export { NewStackedBarChart };
