import React, { useCallback, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { isCancel } from 'axios';
import moment from 'moment';

import ListSubheader from '@mui/material/ListSubheader';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SettingsIcon from '@mui/icons-material/SettingsRounded';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import ListItemText from '@mui/material/ListItemText';
import FormControl from '@mui/material/FormControl';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import makeStyles from '@mui/styles/makeStyles';
import ReactSelect from 'core/Components/ReactSelect';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import ListItem from '@mui/material/ListItem';
import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Menu from '@mui/material/Menu';
import List from '@mui/material/List';

import ConfirmDialog from 'core/Components/Dialogs/ConfirmDialog';
import useCallbackCancel from 'hooks/useCallbackCancel';
import UtilsAPI from 'services/api/utils';
import authClient from 'services/auth';
import Loader from 'core/Components/Loader';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: 200,
  },
  titleFormControl: {
    paddingLeft: '15px',
  },
  saveButton: {
    color: '#42a7f5',
    fontWeight: 'bold',
  },
  fieldOuter: {
    display: 'block',
    width: '100%',
    minWidth: '150px',
  },
  fieldItem: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  fieldTitle: {
    justifyContent: 'flex-start',
  },
  fieldButton: {
    justifyContent: 'flex-end',
  },
  fieldDate: {
    display: 'block',
    fontSize: '9px',
    color: '#ccc',
  },
  containerLists: {
    display: 'flex',
    overflowY: 'auto',
  },
  list: {
    flex: 1,
  },
  adjustPlaceLabelForm: {
    margin: '0 5px',
  },
  actionButton: {
    color: 'rgba(0, 0, 0, 0.54)',
    '&:hover': {
      color: theme.palette.secondary.main
    }
  },
}));

const initialState = {
  selectedView: null,
  isLoading: false,
  isDialog: false,
  selected: null,
  viewOptions: [],
  title: '',
  currentHidden: [],
  currentOrder: [],
  currentFilters: [],
  currentWidths: [],
  anchorEl: null,
  isNewView: false,
  isApplyChanges: false,
  isRename: false,
};

const FieldSets = ({ defaultId, updateTable, tableKey, columns, hidden, order, filters, widths }) => {
  const classes = useStyles();
  const [state, setState] = useReducer((state, newState) => ({ ...state, ...newState }), initialState);
  const { selectedView, isLoading, isDialog, selected, viewOptions, title, currentHidden, currentOrder, currentFilters, currentWidths, anchorEl, isNewView } = state;

  const handleNewView = () => {
    setState({
      title: viewOptions.length === 0 ? 'Default' : `New View ${moment().tz('America/Vancouver').format('YYYY-MM-DD')}`,
      isDialog: true,
      isNewView: true,
      currentHidden: hidden,
      currentOrder: order,
      currentFilters: filters,
      currentWidths: widths,
    });
  };

  const handleClose = useCallback(() => {
    setState({ isDialog: false });
  }, []);

  const handleSelect = (newFieldSet) => {
    try {
      const fieldData = viewOptions.find(row => row.id === newFieldSet.value);
      if (fieldData) {
        setState({
          selected: {
            value: fieldData.id,
            label: fieldData.title,
          },
          selectedView: fieldData,
        });
        updateTable(fieldData);
      } else {
        setState({ selected: null });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const updateData = useCallbackCancel(async (cancelToken) => {
    try {
      const params = {
        // user: authClient.getProfile().given_name,
        table_key: tableKey
      };

      const results = await UtilsAPI.tables.list(params, cancelToken);
      if (results && results.data && results.data.length) {
        const newOptions = results.data.map(row => ({
          ...row,
          data: JSON.parse(row.data)
        }));

        setState({ viewOptions: newOptions, isLoading: false });
      } else {
        setState({ viewOptions: [], isLoading: false, selected: null });
      }
    } catch (error) {
      if (isCancel(error)) return;
      console.error(error);
    }
  }, [tableKey]);

  const handleSaveView = useCallback(async () => {
    setState({ isLoading: true });
    const id = isNewView ? null : selectedView ? selectedView.id : null;

    await UtilsAPI.tables.create({
      id,
      user: authClient.getProfile().given_name,
      table_key: tableKey,
      title,
      data: JSON.stringify({
        hidden: currentHidden,
        order: currentOrder,
        filters: currentFilters,
        widths: currentWidths,
      })
    });

    setState({ isLoading: false, isDialog: false });
    await updateData();
  }, [currentFilters, currentHidden, currentOrder, currentWidths, isNewView, selectedView, tableKey, title, updateData]);

  const applyCurrentChangesToView = async () => {
    setState({ isLoading: true });
    const newOrder = order.filter(row => !hidden.includes(row));
    // Get the missins columns from current order
    const missing = columns.filter(row => !hidden.includes(row.name) && !order.includes(row.name));

    await UtilsAPI.tables.create({
      id: selectedView ? selectedView.id : null,
      user: authClient.getProfile().given_name,
      table_key: tableKey,
      title: selectedView ? selectedView.title : 'No Title',
      data: JSON.stringify({
        hidden,
        order: [...newOrder, ...missing],
        filters,
        widths,
      })
    });

    setState({
      isLoading: false,
      isApplyChanges: false,
    });
    await updateData();
  };

  const handleRename = async () => {
    setState({ isLoading: true });

    await UtilsAPI.tables.create({
      id: selectedView ? selectedView.id : null,
      user: authClient.getProfile().given_name,
      table_key: tableKey,
      title,
    });

    setState({
      isLoading: false,
      isRename: false,
      selected: { ...selected, label: title },
    });
    await updateData();
  };

  const handleEditView = () => {
    setState({
      title: selectedView.title,
      isDialog: true,
      isNewView: false,
      currentHidden: selectedView.data.hidden,
      currentOrder: selectedView.data.order,
      currentFilters: selectedView.data.filters,
      currentWidths: selectedView.data.widths,
    });
  };

  const tryDelete = async () => {
    if (window.confirm(`Are you sure you want to delete: ${selectedView.title}?`)) {
      setState({ isLoading: true });
      await UtilsAPI.tables.delete([
        selectedView.id
      ]);
      setState({ selectedView: false, isLoading: false, isDialog: false });
      await updateData();
    }
  };

  const deleteFilter = (index) => {
    const newFilters = [...currentFilters];
    newFilters.splice(index, 1);
    setState({ currentFilters: newFilters });
  };

  const FilterList = () => {
    return currentFilters.map((value, i) => (
      <ListItem
        key={`${value.columnName} ${value.operation} ${value.value}`}
        secondaryAction={(
          <IconButton
            aria-label="delete filter"
            onClick={() => deleteFilter(i)}
          >
            <DeleteIcon />
          </IconButton>
        )}
      >
        <ListItemText primary={`${value.columnName} ${value.operation} ${value.value}`} />
      </ListItem>
    ));
  };

  const toggleVisibility = (value) => {
    if (currentHidden.includes(value)) {
      setState({ currentHidden: currentHidden.filter(row => row !== value) });
    } else {
      setState({ currentHidden: [...currentHidden, value] });
    }
  };

  const FieldList = () => {
    return columns.map((row) => (
      <ListItem
        key={row.title + row.name}
        secondaryAction={(
          <IconButton
            aria-label="toggle visibility"
            onClick={() => toggleVisibility(row.name)}
          >
            {!currentHidden.includes(row.name) ? <VisibilityIcon /> : <VisibilityOffIcon sx={{ color: '#128bcc' }} />}
          </IconButton>
        )}
      >
        <ListItemText primary={`${row.title}`} />
      </ListItem>
    ));
  };

  const handleOptionsClick = (event) => {
    setState({ anchorEl: event.currentTarget });
  };
  const handleCloseOptions = () => {
    setState({ anchorEl: null });
  };

  const OptionsMenu = () => {
    const hasViews = viewOptions && viewOptions.length > 0;
    return (
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={Boolean(anchorEl)}
        onClose={handleCloseOptions}
        onClick={handleCloseOptions}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={handleNewView}>
          Create new View
        </MenuItem>
        {hasViews && (
          <MenuItem onClick={() => setState({ isApplyChanges: true })}>
            Save Current Changes to Selected View
          </MenuItem>
        )}

        {hasViews && (
          <MenuItem onClick={handleEditView}>
            Edit Selected View
          </MenuItem>
        )}

        {hasViews && (
          <MenuItem onClick={() => setState({ isRename: true, title: selected.label })}>
            Rename Selected View
          </MenuItem>
        )}
        {hasViews && (
          <MenuItem onClick={tryDelete}>
            Delete Selected View
          </MenuItem>
        )}
      </Menu>
    );
  };

  // useEffect(() => {
  //   if (clearCache) {
  //     setState({ selected: null, selectedView: null });
  //   }
  // }, [clearCache])

  useEffect(() => {
    updateData();
  }, [updateData]);

  useEffect(() => {
    if (defaultId && viewOptions.length) {
      const foundOption = viewOptions.find(row => row.id === defaultId);
      if (foundOption) {
        setState({
          // Used on the select component
          selected: {
            value: foundOption.id,
            label: foundOption.title,
          },
          // The actual selected view data
          selectedView: foundOption,
        });

        // Updates the KinkTable with selected view data
        updateTable(foundOption);
      } else {
        setState({ selected: null, selectedView: null });
      }
    }
  }, [viewOptions, defaultId, updateTable]);

  const fieldOptions = viewOptions.map(row => ({ id: row.id, name: row.title })); // Format the data for the select component

  return (
    <React.Fragment>
      {!!fieldOptions.length && (
        <FormControl className={`${classes.formControl} ${classes.adjustPlaceLabelForm}`}>
          <FormControl required className={classes.formControl}>
            <ReactSelect
              label="Views"
              name="fieldOptions"
              data={selected}
              options={fieldOptions}
              handleChange={handleSelect}
            />
          </FormControl>
        </FormControl>
      )}

      <Tooltip title="Config Views">
        <IconButton className={classes.actionButton} onClick={handleOptionsClick} aria-label="Edit">
          <SettingsIcon sx={{ width: '24px', height: '22px' }} />
        </IconButton>
      </Tooltip>

      <Dialog
        open={isDialog}
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
      >
        <DialogTitle>
          {isNewView ? 'Create a New View' : `Edit "${selectedView ? selectedView.title : 'Current'}" View`}
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Loader loading={isLoading}>
            <FormControl required className={classes.titleFormControl} fullWidth>
              <TextField
                label="Title"
                variant="standard"
                fullWidth
                value={title}
                onChange={(e) => {
                  setState({ title: e.target.value });
                }}
              />
            </FormControl>

            {!!currentFilters.length && (
              <>
                <List
                  sx={{ width: '100%', maxHeight: '400px' }}
                  subheader={(
                    <ListSubheader component="div" sx={{ fontWeight: 'bold' }}>
                      Filters
                    </ListSubheader>
                  )}
                >
                  <FilterList />
                </List>
                <Divider />
              </>
            )}

            <List
              sx={{ width: '100%', maxHeight: '400px' }}
              subheader={(
                <ListSubheader component="div" sx={{ fontWeight: 'bold' }}>
                  Fields
                </ListSubheader>
              )}
            >
              <FieldList />
            </List>
          </Loader>
        </DialogContent>

        <DialogActions>
          {!isNewView && (<Button variant="outlined" sx={{ borderColor: '#aeaeae' }} onClick={tryDelete}>Delete</Button>)}
          <Button variant="outlined" sx={{ borderColor: '#aeaeae' }} onClick={handleClose}>Cancel</Button>
          <Button variant="outlined" sx={{ borderColor: '#aeaeae' }} onClick={handleSaveView}>Save</Button>
        </DialogActions>
      </Dialog>

      <ConfirmDialog // Apply Current Changes to View
        open={state.isApplyChanges}
        onCancel={() => setState({ isApplyChanges: false })}
        title="Apply Current Changes"
        onConfirm={applyCurrentChangesToView}
        promptText={`Are you sure you want to save all current changes to "${selectedView ? selectedView.title : 'NULL'}" View?`}
      />

      <ConfirmDialog // Rename Current View
        open={state.isRename}
        onCancel={() => setState({ isRename: false })}
        title="Rename View"
        onConfirm={handleRename}
        promptText=""
      >
        <FormControl required className={classes.titleFormControl} fullWidth>
          <TextField
            label="Title"
            variant="standard"
            fullWidth
            value={title}
            onChange={(e) => {
              setState({ title: e.target.value });
            }}
          />
        </FormControl>
      </ConfirmDialog>

      <OptionsMenu />
    </React.Fragment>
  );
};

FieldSets.propTypes = {
  updateTable: PropTypes.func.isRequired,
  defaultId: PropTypes.any,
  tableKey: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired,
  hidden: PropTypes.array.isRequired,
  order: PropTypes.array.isRequired,
  filters: PropTypes.array.isRequired,
  widths: PropTypes.array.isRequired,
};

FieldSets.defaultProps = {
  defaultId: null,
};

export default FieldSets;
