import React from 'react';
import PropTypes from 'prop-types';
import memoizeOne from 'memoize-one';
import './sticky-header.css';

import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ButtonBase from '@mui/material/ButtonBase';
import withStyles from '@mui/styles/withStyles';
import grey from '@mui/material/colors/grey';
import Tooltip from '@mui/material/Tooltip';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import { lighten } from '@mui/material';

import { Template, TemplatePlaceholder, TemplateConnector } from '@devexpress/dx-react-core';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableFilterRow,
  PagingPanel,
  TableColumnResizing,
  Toolbar as DXToolbar,
  SearchPanel,
  TableSummaryRow,
  ColumnChooser,
  TableColumnVisibility,
  TableSelection,
  DragDropProvider,
  TableColumnReordering,
  TableRowDetail,

} from '@devexpress/dx-react-grid-material-ui';
import {
  SortingState,
  IntegratedSorting,
  FilteringState,
  IntegratedFiltering,
  PagingState,
  SelectionState,
  IntegratedPaging,
  SearchState,
  DataTypeProvider,
  SummaryState,
  IntegratedSummary,
  IntegratedSelection,
  RowDetailState
} from '@devexpress/dx-react-grid';

import { filterData, fixNum, areEqual, objectsEqual } from 'core/Functions/utilities';
import { dataFilter, dataFormatter } from 'core/Components/Tables/TableFilters';
import UtilsAPI from 'services/api/utils';
import authClient from 'services/auth';
import FieldSets from 'core/Components/FieldSets';
import Loader from 'core/Components/Loader';
import { CleaningServices } from '@mui/icons-material';

const TitlebarActionStyles = theme => ({
  tooltip: {
    marginTop: 5,
  },
  actionButton: {
    color: 'rgba(0, 0, 0, 0.54)',
    '&:hover': {
      color: theme.palette.secondary.main
    }
  },
});

const TitlebarActions = withStyles(TitlebarActionStyles, { withTheme: true })((props) => {
  if (!props.actions) {
    return null;
  }
  return props.actions.map((a) => (
    <Tooltip title={a.tooltip} classes={{ tooltip: props.classes.tooltip }} key={a.tooltip}>
      <IconButton onClick={a.onClick} className={props.classes.actionButton}>
        {a.icon}
      </IconButton>
    </Tooltip>
  ));
});

const tableStyles = theme => ({
  tableStriped: {
    '& tbody tr:nth-of-type(odd)': {
      backgroundColor: grey[50],
    },
  },
  tableHover: {
    '& tbody tr:hover': {
      backgroundColor: lighten(theme.palette.primary.main, 0.9),
    },
  },
  tableStripedHover: {
    '& tbody tr:nth-of-type(odd)': {
      backgroundColor: grey[50],
    },
    '& tbody tr:hover': {
      backgroundColor: lighten(theme.palette.primary.main, 0.9),
    },
  },
});

const TableComponentBase = ({ classes, striped, hover, ...restProps }) => {
  if (hover && striped) {
    return (
      <Table.Table
        {...restProps}
        className={classes.tableStripedHover}
      />
    );
  }
  if (striped) {
    return (
      <Table.Table
        {...restProps}
        className={classes.tableStriped}
      />
    );
  }
  if (hover) {
    return (
      <Table.Table
        {...restProps}
        className={classes.tableHover}
      />
    );
  }
  return (
    <Table.Table
      {...restProps}
    />
  );
};

TableComponentBase.propTypes = {
  classes: PropTypes.object.isRequired,
  striped: PropTypes.bool.isRequired,
  hover: PropTypes.bool.isRequired,
};

const TableComponent = withStyles(tableStyles, { withTheme: true })(TableComponentBase);

const CustomCell = React.memo(({ value, column, style, row, ...restProps }) => {
  const colorize = {};
  if (column.colorize !== undefined && column.colorize) {
    if (typeof column.colorize === 'function') {
      colorize.color = column.colorize(value, row);
    } else if (value < 0) {
      colorize.color = 'rgb(200, 0, 0)';
    } else if (value === 100) {
      colorize.color = 'rgb(211,211,211)';
    } else {
      colorize.color = 'rgb(0, 200, 0)';
    }
  }

  let textAlign = 'left';
  if (column.textAlign !== undefined && column.textAlign) textAlign = column.textAlign;

  return (
    <Table.Cell
      value={value}
      style={{ ...colorize, ...style, textAlign }}
      {...restProps}
    />
  );
}, (prev, next) => {
  if (prev.value === undefined || prev.value === null) {
    return false;
  }

  if (typeof prev.value === 'object' && prev.value.$$typeof === undefined) {
    return prev.column.title === next.column.title
      && JSON.stringify(prev.value) === JSON.stringify(next.value);
  }

  const result = prev.value === next.value;
  return result;
});

CustomCell.propTypes = {
  column: PropTypes.object.isRequired,
  value: PropTypes.any,
  style: PropTypes.any,
  row: PropTypes.object.isRequired,
};

CustomCell.defaultProps = {
  value: null,
  style: null,
};

const CustomRow = React.memo(({ ...restProps }) => {
  return (
    <Table.Row
      {...restProps}
    />
  );
}, (prev, next) => {
  const result = JSON.stringify(prev.row) === JSON.stringify(next.row) && JSON.stringify(prev.children.map(r => r.key)) === JSON.stringify(next.children.map(r => r.key));
  return result;
});

const styles = theme => ({
  spacer: {
    flexGrow: 1,
  },
  tableContainer: {
    margin: '0 auto',
    maxWidth: '100%',
    paddingLeft: '10px',
    paddingRight: '10px',
    minWidth: 275,
  },
  paper: {
    overflow: 'hidden',
  },
  actionButton: {
    color: 'rgba(0, 0, 0, 0.54)',
    width: 'auto',
    paddingRight: theme.spacing(1),
    '&:hover': {
      color: theme.palette.secondary.main
    }
  },
  tooltip: {
    marginTop: 5,
  },
  selected: {
    margin: 25
  },

  summaryText: {
    color: 'rgba(0, 0, 0, 0.87)',
    margin: '8px 0',
    fontSize: '0.8125rem',
    fontWeight: 500,
  },
  subtitle: {
    fontSize: '12px',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  paddingToolbar: {
    paddingRight: '5px !important'
  }
});

class KinkTable extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      previousPage: 0,
      currentPage: 0,
      defaultPageSize: props.defaultPageSize,
      selectedRows: [],
      inlineFilters: [],
      hiddenColumnNames: this.getDefaultHiddenColumns(),
      columnOrder: this.getDefaultColumnOrder(),
      columnWidth: this.getDefaultColumnWidths(),
      // db filters/fields
      filterTimeout: false,
      filterId: false,
      fieldSetId: false,
      clearCache: false
    };
    this.tableRef = React.createRef();
  }

  async componentDidMount() {
    this.isComponentMounted = true;
    this.getFilters();
    if (this.props.showSummaryOnTop) {
      window.addEventListener('load', this.moveSummaryToTop);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    // console.log('props', areEqual(this.props, nextProps))
    // console.log('state', areEqual(this.state, nextState))
    // console.log('OLD Should update?', !areEqual(this.props, nextProps) || !areEqual(this.state, nextState))
    return !areEqual(this.props, nextProps) || !areEqual(this.state, nextState);
  }

  componentDidUpdate(prevProps) {
    if (!objectsEqual(prevProps.filters, this.props.filters)) {
      // Just a shallow copy wasn't enough, because it was an array of objects
      const inlineFilters = structuredClone(this.state.inlineFilters);
      // const inlineFilters = [...this.state.inlineFilters];
      if (this.props.filters) {
        this.props.filters.forEach(row => {
          const inlineFilter = inlineFilters.find(filter => filter.columnName === row.columnName);
          if (inlineFilter) {
            inlineFilter.value = row.value;
          } else {
            inlineFilters.push(row);
          }
        });
        this.onFilterChange(inlineFilters);
      }
    }

    if (this.props.showSummaryOnTop) {
      this.moveSummaryToTop();
    }
    // console.log('state change!');
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
    if (this.props.showSummaryOnTop) {
      window.removeEventListener('load', this.moveSummaryToTop);
    }
  }

  getFilters = async () => {
    // Retrieves the saved inline filters from database
    const results = await UtilsAPI.filters.list({
      user: authClient.getProfile().given_name,
      table_key: this.props.tableKey,
    });

    if (results && results.data && results.data.length) {
      const result = results.data[0];

      this.isComponentMounted && this.setState({ filterId: result.id, fieldSetId: result.user_table_data_id });

      const data = JSON.parse(result.data);
      const newInlineFilters = data || [];
      if (this.props.filters) {
        this.props.filters.forEach(row => {
          const foundFilter = newInlineFilters.find(filter => filter.columnName === row.columnName);
          if (foundFilter) {
            foundFilter.value = row.value;
          } else {
            newInlineFilters.push(row);
          }
        });
      }
      if (newInlineFilters.length) {
        this.onFilterChange(newInlineFilters, result.user_table_data_id);
      }
    }
  };

  CustomTable = (props) => (
    <TableComponent
      hover={this.props.hover}
      striped={this.props.striped}
      {...props}
    />
  );

  getSortingExtensions = memoizeOne((columns = this.props.columns) => {
    const baseExtensions = columns.filter(c => (
      c.type === 'number'
      || c.type === 'smallnumber'
      || c.type === 'percent'
      || c.type === 'currency'
      || c.type === 'nullcurrency'
      || c.type === 'smallcurrency'
      || c.type === 'smallpercent'
    ) && !c.compare)
      .map(c => ({
        columnName: c.name,
        compare: dataFilter.number
      }));
    return columns.filter(c => c.compare !== undefined)
      .map(c => ({
        columnName: c.name,
        compare: c.compare
      })).concat(baseExtensions);
  });

  getDefaultColumnWidths = (columns = this.props.columns) => {
    return columns.map(c => ({
      columnName: c.name,
      width: c.width || this.props.defaultColumnWidth
    }));
  };

  getDefaultColumnOrder = (columns = this.props.columns) => {
    return Object.values(columns.map(c => c.name));
  };

  changeColumnOrder = (newOrder) => {
    this.setState({ columnOrder: newOrder });
  };

  changeColumnWidth = (newWidth) => {
    this.setState({ columnWidth: newWidth });
  };

  getSortingStateExtensions = memoizeOne((columns = this.props.columns) => {
    return columns.filter(c => c.sortingEnabled !== undefined)
      .map(c => ({
        columnName: c.name,
        sortingEnabled: c.sortingEnabled
      }));
  });

  getDefaultHiddenColumns = (columns = this.props.columns) => {
    return columns.filter(c => c.hidden !== undefined)
      .map(c => c.name);
  };

  getHiddenColumns = memoizeOne((hiddenColumnNames, inlineFilters) => {
    return hiddenColumnNames.filter(name => {
      return !inlineFilters.find(row => row.columnName === name);
    });
  });

  getSummaryStateExtensions = memoizeOne((columns = this.props.columns) => {
    return columns.filter(c => c.summaryEnabled !== undefined)
      .map(c => ({
        columnName: c.name,
        type: c.summaryFunc || 'sum'
      }));
  });

  customFilter(value, filter) {
    if (value === undefined || !filter.value) {
      return true;
    }
    // customFilter(value, filter, row) {
    let negative = false;
    let exact = false;

    const searches = this.props.disableCommaFilter ? [filter.value.toLowerCase()] : filter.value.toLowerCase().split(',');
    for (let search of searches) {
      search = search.trim();
      switch (search.charAt(0)) {
        case '>':
          // console.log('wtf2', value, '>', search, fixNum(value) > fixNum(search));
          return fixNum(value) > fixNum(search);
        case '<':
          return fixNum(value) < fixNum(search);
        case '-':
          negative = true;
          search = search.substring(1);
          break;
        case '=':
          exact = true;
          search = search.substring(1);
          break;
        default:
          break;
      }

      search = search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

      if (exact) {
        search = `^${search}$`;
      }

      const regex = new RegExp(search, 'i');
      if (Array.isArray(value)) {
        for (const child of value) {
          if (negative && regex.test(child)) {
            return false;
          } if (regex.test(child)) {
            return true;
          }
        }
      } else if (negative && regex.test(value)) {
        return false;
      } else if (regex.test(value)) {
        return true;
      } else if (value && value.props && value.props.children && regex.test(value.props.children)) { // When the field is a <span/> or something like that
        return true;
      }
    }

    return negative;
  }

  getFilterStateExtensions = memoizeOne((columns = this.props.columns) => {
    const results = columns// .filter(c => c.filteringEnabled !== undefined || c.filter !== undefined)
      .map(c => {
        const extension = {
          columnName: c.name,
        };
        if (c.filteringEnabled !== undefined) {
          extension.filteringEnabled = c.filteringEnabled;
        }
        if (c.filter !== undefined && typeof (c.filter) === 'function') {
          extension.predicate = c.filter;
        } else {
          extension.predicate = this.customFilter.bind(this);
        }
        return extension;
      });
    return results;
  });

  getProviderExtensions = (columns = this.props.columns) => {
    const defaultFormatters = columns.filter(c => (c.type !== undefined) && !c.formatter/* && !c.getCellValue */)
      .map(c => ({
        columnName: c.name,
        formatter: dataFormatter[c.type]
      }));

    return columns.filter(c => c.formatter !== undefined)
      .map(c => ({
        columnName: c.name,
        formatter: c.formatter
      })).concat(defaultFormatters);
  };

  renderActionsColumn = (data) => {
    const { rowActions } = this.props;
    return (
      <div>
        {rowActions.map(action => (
          <ButtonBase
            onAuxClick={(e) => action.onClick(data.row, e)}
            onClick={(e) => action.onClick(data.row, e)}
            className={this.props.classes.actionButton}
            disableRipple
            key={action.tooltip}
          >
            <span title={action.tooltip}>
              {typeof action.icon === 'function' ? action.icon(data.row) : action.icon && (action.icon)}
              {action.image && (<img src={action.image} alt="" width={28} height={28} />)}
            </span>
          </ButtonBase>
        ))}
      </div>
    );
  };

  getFilteredData = memoizeOne((data, filters) => {
    return filterData(data, filters);
  });

  getColumnOrder = memoizeOne((columnOrder, hiddenColumns, columns = this.props.columns) => {
    // Gets the columns that are not hidden
    // const hiddenColumns = this.getHiddenColumns(this.state.hiddenColumnNames, this.state.inlineFilters);
    const showingColumns = columns.filter(c => hiddenColumns.some(hc => hc === c.name) === false);
    if (columnOrder.length && showingColumns.length) {
      // Checks if there are any columns that are not in the current order
      const missingFields = [...new Set([...showingColumns.map(c => c.name)].filter(x => !columnOrder.includes(x) && x !== 'actions'))];

      // If there are, add them to the end of the order
      if (missingFields && missingFields.length) {
        return [...columnOrder, ...missingFields];
      }
    }

    return columnOrder;
  });

  getColumnWidth = memoizeOne((columnWidth, columns = this.props.columns) => {
    // Gets the columns that are not hidden
    const hiddenColumns = this.getHiddenColumns(this.state.hiddenColumnNames, this.state.inlineFilters);
    const showingColumns = columns.filter(c => hiddenColumns.some(hc => hc === c.name) === false);
    if (columnWidth.length && showingColumns.length) {
      // Checks if there are any columns that are not in the current order
      const missingFields = showingColumns.filter(c => {
        const match = columnWidth.filter(row => row.columnName === c.name);
        return match.length === 0;// && x !== 'actions'
      }).map(c => ({
        columnName: c.name,
        width: c.width || this.props.defaultColumnWidth
      }));

      // If there are, add them to the end of the order
      if (missingFields && missingFields.length) {
        return [...columnWidth, ...missingFields];
      }
    }

    return columnWidth;
  });

  onCurrentPageChange = (currentPage) => {
    this.setState({ previousPage: this.state.currentPage, currentPage });
  };

  onPageSizeChange = (defaultPageSize) => {
    this.setState({ defaultPageSize });
  };

  onSelectionChange = (selectedRows) => {
    this.setState({ selectedRows });
  };

  onFilterChange = (inlineFilters, newFieldSetId = this.state.fieldSetId) => {
    const filterMatch = JSON.stringify(inlineFilters) === JSON.stringify(this.state.inlineFilters);
    if (!filterMatch) {
      if (inlineFilters.length !== this.state.inlineFilters.length && this.state.currentPage !== this.state.previousPage) {
        this.isComponentMounted && this.setState({ inlineFilters, currentPage: this.state.previousPage });
      } else {
        this.isComponentMounted && this.setState({ inlineFilters });
      }
    }

    // only send filters to server if it's been 200ms since last change
    // console.log('filters changed?', newFieldSetId !== this.state.fieldSetId || !filterMatch, newFieldSetId, '!=', this.state.fieldSetId, JSON.stringify(inlineFilters), '!=', JSON.stringify(this.state.inlineFilters))
    if (newFieldSetId !== this.state.fieldSetId || !filterMatch) {
      if (this.state.filterTimeout) {
        clearTimeout(this.state.filterTimeout);
      }
      const filterTimeout = setTimeout(async () => {
        const results = await UtilsAPI.filters.create({
          id: this.state.filterId || null,
          user: authClient.getProfile().given_name,
          table_key: this.props.tableKey,
          user_table_data_id: newFieldSetId || null,
          data: JSON.stringify(inlineFilters)
        });
        if (results && results.data && results.data.response === 'success') {
          this.isComponentMounted && this.setState({ filterId: results.data.id, fieldSetId: newFieldSetId });
        }
        // console.log('saved filters', results)
      }, 200);
      this.isComponentMounted && this.setState({ filterTimeout });
    } else {
      // console.log('filters not changed')
    }

    if (this.props.inlineFiltersCallback) {
      this.props.inlineFiltersCallback(inlineFilters);
    }
  };

  onHiddenColumnNamesChange = (hiddenColumnNames) => {
    this.setState({ hiddenColumnNames });
  };

  summaryCalculator = (type, rows, getValue) => {
    if (typeof type === 'function') {
      return type(rows);
    } if (type === 'sum') {
      if (!rows.length) {
        return null;
      }
      return rows.map(row => {
        const val = getValue(row);
        if (val === null || val === undefined) {
          return 0; // map nulls and undefineds as zero for summing
        }
        return Number(val);
      }).reduce((sum, a) => sum + a, 0);
    }
    // console.log('summaryCalculator', type, rows, getValue);
    return IntegratedSummary.defaultCalculator(type, rows, getValue);
  };

  summaryItem = ({ value, children }) => {
    let formatedValue;
    const type = children.props ? children.props.column ? children.props.column.type : undefined : undefined;
    if (type === 'currency') {
      formatedValue = dataFormatter.currency({ value });
    } else if (type === 'smallcurrency') {
      formatedValue = dataFormatter.smallcurrency({ value });
    } else {
      formatedValue = dataFormatter.number({ value });
    }

    return <span className={this.props.classes.summaryText}>{formatedValue}</span>;
  };

  updateFieldSets = (fieldData) => {
    if (fieldData.data.order) {
      const currentSet = new Set([...fieldData.data.order, ...fieldData.data.hidden]);

      const missingColumns = this.props.columns.flatMap(c => { // find the columns missing on current order
        if (!currentSet.has(c.name)) return { name: c.name, hidden: c.hidden };
        return [];
      });

      if (missingColumns.length) {
        missingColumns.forEach(row => { // find the index of the columns missing
          const index = this.props.columns.findIndex(c => c.name === row.name);
          fieldData.data.order.splice(index, 0, row.name); // add the column on correct index
          if (row.hidden) { // If new columns is marked as hidden, add it to hidden list
            fieldData.data.hidden.push(row.name);
          }
        });
      }
    }

    if (fieldData.data.widths) {
      const missingColumns = this.props.columns.flatMap(c => { // find the columns missing on current order
        const match = fieldData.data.widths.filter(row => row.columnName === c.name);
        if (match.length === 0) {
          return {
            columnName: c.name,
            width: c.width || this.props.defaultColumnWidth
          };
        }
        return [];
      });

      if (missingColumns.length) {
        missingColumns.forEach(row => { // find the index of the columns missing
          const index = this.props.columns.findIndex(c => c.name === row);
          fieldData.data.widths.splice(index, 0, row); // add the column on correct index
        });
      }
    }

    this.setState({
      fieldSet: fieldData.title,
      hiddenColumnNames: fieldData.data.hidden || [],
      columnOrder: fieldData.data.order || [],
      columnWidth: fieldData.data.widths || this.state.columnWidth
    });

    const inlineFilters = structuredClone(this.state.inlineFilters);
    if (fieldData.data.filters && fieldData.data.filters.length) {
      fieldData.data.filters.forEach(row => {
        const inlineFilter = inlineFilters.filter(filter => filter.columnName === row.columnName);
        if (inlineFilter && inlineFilter.length > 0) {
          inlineFilter[0].value = row.value;
        } else {
          inlineFilters.push(row);
        }
      });
    }
    this.onFilterChange(inlineFilters, fieldData.id);
  };

  moveSummaryToTop = () => {
    const divTable = this.tableRef.current;
    const table = divTable.querySelector('.Table-table');
    if (!table) return;
    const tfoot = table.querySelector('tfoot');
    if (!tfoot) return;
    const thead = table.querySelector('thead');
    if (!thead) return;

    const tr = tfoot.querySelector('tr');
    if (!tr) return;

    tr.classList.add('summary-top-style');
    thead.appendChild(tr);

    table.removeChild(tfoot);
  };

  clearFilters = () => {
    this.setState({ clearCache: true }, () => {
      if (this.state.filterId) {
        // console.log('deleting filter', this.state.filterId)
        UtilsAPI.filters.delete([this.state.filterId]);
      }
      this.onFilterChange([], this.state.fieldSetId);
      this.setState({
        clearCache: false,
        selectedRows: [],
        // hiddenColumnNames: this.getDefaultHiddenColumns()
      });
    });
  };

  getColumns = memoizeOne((displayColumns, rowActions, columns) => {
    const allColumns = columns.slice();
    let auxColumns = [];

    if (displayColumns) {
      displayColumns.forEach(column => {
        const selectedColumn = columns.filter(n => n.name === column)[0];
        if (selectedColumn) {
          auxColumns.push(selectedColumn);
        }
      });
    } else {
      auxColumns = allColumns.slice();
    }

    if (rowActions) {
      const actionColumn = {
        title: 'Actions',
        name: 'actions',
        width: this.props.actionColumnWidth,
        sortingEnabled: false,
        filteringEnabled: false,
        formatter: (data) => this.renderActionsColumn(data),
      };

      if (this.props.actionColumnFirst) {
        allColumns.unshift(actionColumn);
        auxColumns.unshift(actionColumn);
      } else {
        allColumns.push(actionColumn);
        auxColumns.push(actionColumn);
      }
    }
    return { displayColumns: auxColumns, allColumns };
  });

  getColumnsFiledSet = memoizeOne((columns) => {
    return columns.map(row => { return { name: row.name, title: row.title }; });
  });

  getDefaultTitleBarActions = memoizeOne((titlebarActions) => {
    return (titlebarActions || []).concat([
      {
        tooltip: 'Clear Filters',
        icon: <CleaningServices />,
        onClick: this.clearFilters
      },
    ]);
  });

  render() {
    const {
      classes,
      title,
      titlebarActions,
      rowActions,
    } = this.props;

    const data = this.getFilteredData(this.props.data || [], this.props.dataFilters);

    const { displayColumns, allColumns } = this.getColumns(this.props.displayColumns, rowActions, this.props.columns);

    const sortingExtensions = this.getSortingExtensions(allColumns);
    const summaryStateExtensions = this.getSummaryStateExtensions(allColumns);
    const filterStateExtensions = this.getFilterStateExtensions(allColumns);
    const sortingStateExtensions = this.getSortingStateExtensions(allColumns);

    const hiddenColumnNames = this.getHiddenColumns(this.state.hiddenColumnNames, this.state.inlineFilters);

    const columnsOrder = this.getColumnOrder(this.state.columnOrder, hiddenColumnNames, allColumns);
    const columnsWidth = this.getColumnWidth(this.state.columnWidth, allColumns);

    const defaultTitlebarActions = this.getDefaultTitleBarActions(titlebarActions);

    const columnsFieldSet = this.getColumnsFiledSet(this.props.columns);

    return (
      <div className={classes.tableContainer} ref={this.tableRef}>
        <Paper className={classes.paper}>
          {title
            && (
              <Toolbar className={classes.paddingToolbar}>
                <Typography variant="h6" color="inherit">{title}
                  {this.props.subtitle && (
                    <div className={classes.subtitle}>
                      {this.props.subtitle}
                    </div>
                  )}
                </Typography>

                <div className={classes.spacer} />

                {!!this.props.customActions && (
                  this.props.customActions
                )}

                <TitlebarActions actions={defaultTitlebarActions} />

                <FieldSets
                  profile={authClient.profile}
                  defaultId={this.state.fieldSetId}
                  updateTable={this.updateFieldSets}
                  clearCache={this.state.clearCache}
                  tableKey={this.props.tableKey}
                  columns={columnsFieldSet}
                  hidden={hiddenColumnNames}
                  order={this.state.columnOrder}
                  filters={this.state.inlineFilters}
                  widths={this.state.columnWidth}
                />
              </Toolbar>
            )}
          <Divider />
          {this.props.children || null}

          {(this.props.selection && this.state.selectedRows.length > 0) && (
            <span className={classes.selected}>
              Rows Selected: {this.state.selectedRows.length}
            </span>
          )}

          <Loader loading={this.props.loading} >
            <Grid
              rows={data}
              columns={displayColumns}
            >
              <DragDropProvider />

              {this.props.selection
                && (
                  <SelectionState
                    selection={this.props.selectedRows || this.state.selectedRows}
                    onSelectionChange={this.props.onSelectionChange || this.onSelectionChange}
                  />
                )}
              {this.props.search && <SearchState defaultValue={this.props.defaultSearch} />}

              {this.props.summary && <SummaryState totalItems={summaryStateExtensions} />}

              {(this.props.filtering || this.props.search)
                && (
                  <FilteringState
                    filters={this.state.inlineFilters}
                    onFiltersChange={this.onFilterChange}
                    columnExtensions={filterStateExtensions}
                  />
                )}
              {this.props.sorting
                && (
                  <SortingState
                    defaultSorting={[{
                      columnName: this.props.defaultSortColumn || '',
                      direction: this.props.defaultSortDirection || 'desc'
                    }]}
                    columnExtensions={sortingStateExtensions}
                  />
                )}

              {this.props.paging
                && (
                  <PagingState
                    defaultCurrentPage={0}
                    currentPage={this.props.currentPage || this.state.currentPage}
                    pageSize={this.state.defaultPageSize}
                    onCurrentPageChange={this.props.onCurrentPageChange || this.onCurrentPageChange}
                    onPageSizeChange={this.props.onPageSizeChange || this.onPageSizeChange}
                  />
                )}

              {(this.props.filtering || this.props.search) && <IntegratedFiltering columnExtensions={filterStateExtensions} />}
              {this.props.sorting && <IntegratedSorting columnExtensions={sortingExtensions} />}
              {this.props.selection && <IntegratedSelection />}
              {this.props.summary && <IntegratedSummary calculator={this.summaryCalculator} />}
              {this.props.paging && <IntegratedPaging />}
              {this.getProviderExtensions(allColumns).map(n => (
                <DataTypeProvider
                  formatterComponent={n.formatter}
                  for={[n.columnName]}
                  key={n.columnName}
                />
              ))}
              {this.props.rowDetail && (
                <RowDetailState defaultExpandedRowIds={[0, this.props.expandId]} />

              )}
              <Table
                cellComponent={CustomCell}
                rowComponent={CustomRow}
                tableComponent={this.CustomTable}
              />
              {this.props.rowDetail && <TableRowDetail contentComponent={this.props.rowDetail} defaultExpandedRowIds={[this.props.expandId]} />}
              {this.props.hidden
                && (
                  <TableColumnVisibility
                    onHiddenColumnNamesChange={this.onHiddenColumnNamesChange}
                    hiddenColumnNames={hiddenColumnNames}
                  />
                )}
              <TableColumnReordering
                order={columnsOrder}
                // order={this.state.columnOrder}
                onOrderChange={this.changeColumnOrder}
              />
              {!this.props.defaultWidth
                && (
                  <TableColumnResizing
                    columnWidths={columnsWidth}
                    onColumnWidthsChange={this.changeColumnWidth}
                  />
                )}

              <TableHeaderRow showSortingControls={this.props.sorting} />
              {this.props.selection
                && <TableSelection showSelectAll={this.props.showSelectAll} highlightRow />}
              {this.props.summary
                && <TableSummaryRow itemComponent={this.summaryItem} />}
              {this.props.filtering
                && <TableFilterRow />}
              {this.props.filtering
                && (
                  <Template name="root">
                    <TemplateConnector>
                      {({ rows: filteredData }) => {
                        if (this.props.onFilterDataChange) {
                          // must use timout here because we cant set state during a render
                          if (this.filterTimeout) {
                            clearTimeout(this.filterTimeout);
                          }
                          this.filterTimeout = setTimeout(() => {
                            this.props.onFilterDataChange(filteredData);
                          }, 1000);
                        }
                        return <TemplatePlaceholder />;
                      }}
                    </TemplateConnector>
                  </Template>
                )}

              {(this.props.search || this.props.hidden)
                && <DXToolbar />}
              {this.props.hidden
                && <ColumnChooser />}
              {this.props.search
                && <SearchPanel />}
              {this.props.paging
                && (
                  <PagingPanel
                    pageSizes={[5, 10, 15, 25, 50, 100, 500, 1000]}
                  />
                )}
            </Grid>
          </Loader>
        </Paper>
      </div>
    );
  }
}

KinkTable.propTypes = {
  /** unique key for caching */
  tableKey: PropTypes.string,
  /** Title given to the paper holding the table */
  title: PropTypes.string,
  /** Subtitle given to the paper holding the table */
  subtitle: PropTypes.string,
  /** Classes provided by withStyles, can be overriden */
  classes: PropTypes.object.isRequired,
  /** Array of rows to display in the table */
  data: PropTypes.array.isRequired,
  /** Array of column description/configuration */
  columns: PropTypes.arrayOf(PropTypes.shape({
    /** Title of the column */
    title: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.object.isRequired]),
    /** Key of the column in the data row */
    name: PropTypes.string.isRequired,
    /** Starting width of the column */
    width: PropTypes.number.isRequired,
    /** Enable summary for the column (default: true) */
    summaryEnabled: PropTypes.bool,
    /** Enable summary for the column (default: true) */
    summaryFunc: PropTypes.func,
    /** Enable filtering for the column (default: true) */
    filteringEnabled: PropTypes.bool,
    /** Enable sorting for the column (default: true) */
    sortingEnabled: PropTypes.bool,
    /** Default hidden State for the column (default: true) */
    hidden: PropTypes.bool,
    /** Colorize the column */
    colorize: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    /** Colorize the column */
    interpolate: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
    /** Custom formating function for the column */
    formatter: PropTypes.func,
    /** Custom compare function for the column used for sorting */
    compare: PropTypes.func,
    /** Type of data in the column to utilize default sort/render functions */
    type: PropTypes.oneOf(Object.keys(dataFormatter)),
    // type: PropTypes.oneOf(['number', 'percent', 'date', 'currency', 'nullcurrency', 'smallpercent', 'smallcurrency', 'date_time', 'fromNow', 'hour', 'boolean', 'smallnumber', 'text', 'age', 'formatPercent']),
  })).isRequired,
  /** Enable table paging (default: true) */
  paging: PropTypes.bool,
  /** Specify page size default */
  currentPage: PropTypes.number,
  /** Specify page size default */
  defaultPageSize: PropTypes.number,
  /** Change page callback */
  onCurrentPageChange: PropTypes.func,
  /** Change page size callback */
  onPageSizeChange: PropTypes.func,
  /** Change page size callback */
  onFilterDataChange: PropTypes.func,
  /** Change selection callback */
  onSelectionChange: PropTypes.func,
  /** Enable table sorting (default: true) */
  sorting: PropTypes.bool,
  /** Enable table summary (default: true) */
  summary: PropTypes.bool,
  /** Enable table filtering (default: true) */
  filtering: PropTypes.bool,
  /** Enable table filtering (default: true) */
  selection: PropTypes.bool,
  /** Allows to enable default filtering on any column */
  filters: PropTypes.arrayOf(PropTypes.shape({
    /** Column name to apply default filter to */
    columnName: PropTypes.string,
    /** Value of the default filter */
    value: PropTypes.string,
    /** Operation of the default filter */
    operation: PropTypes.string
  })),
  showSelectAll: PropTypes.bool,
  selectedRows: PropTypes.array,
  /** Allows to enable default filtering on any row value */
  dataFilters: PropTypes.object,
  /** Enable table searchbar (default: false) */
  search: PropTypes.bool,
  /** Default term in searchbar (default: null) */
  defaultSearch: PropTypes.string,
  /** Specify column to sort by default */
  defaultSortColumn: PropTypes.string,
  /** Specify direction to sort by default */
  defaultSortDirection: PropTypes.oneOf(['asc', 'desc']),
  /** Specify column width default */
  defaultColumnWidth: PropTypes.number,
  /** Specify if should use custom widths or table defaults */
  defaultWidth: PropTypes.bool,
  /** Children will be rendered underneath the title and above the table */
  children: PropTypes.node,
  /** Specify that data is loading to display a loading spinner */
  loading: PropTypes.bool,
  /** Array of action buttons to display in the top right of the titlebar */
  titlebarActions: PropTypes.arrayOf(PropTypes.shape({
    /** Tooltip of the action button */
    tooltip: PropTypes.string,
    /** onClick callback for the action */
    onClick: PropTypes.func,
    /** JSX Icon Element */
    icon: PropTypes.element,
    /** JSX Function */
    jsx: PropTypes.func
  })),
  /** Array of action buttons to display as the most right column in each row */
  rowActions: PropTypes.oneOfType([PropTypes.bool, PropTypes.arrayOf(PropTypes.shape({
    /** Tooltip of the action button */
    tooltip: PropTypes.string,
    /** onClick callback for the action */
    onClick: PropTypes.func,
    /** JSX Icon Element */
    icon: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
    /** JSX Icon Element */
    image: PropTypes.element
  }))]),
  /** Width to use for the action column */
  actionColumnWidth: PropTypes.number,
  /** Width to use for the action column */
  actionColumnFirst: PropTypes.bool,
  /** Called when a row is double clicked, passes back (row, event) */
  // onRowDoubleClick: PropTypes.func,
  /** Used to select which columns names should be displayed. If not set, all columns will be displayed. */
  displayColumns: PropTypes.arrayOf(PropTypes.string),
  /** Display the table with striped rows */
  striped: PropTypes.bool,
  /** Highlight the row currently hovered by the mouse */
  hover: PropTypes.bool,
  hidden: PropTypes.bool,
  /** Default hidden State for the column (default: true) */
  showSummaryOnTop: PropTypes.bool,
  /** JSX to add to Title Toolbar (when you want to add something different then a icon) */
  customActions: PropTypes.any,
  /** A function to retrive the inlineFilters of the table */
  inlineFiltersCallback: PropTypes.func,
  /** disables , in filters that allows for multiple searches */
  disableCommaFilter: PropTypes.bool,
  expandId: PropTypes.any,
  rowDetail: PropTypes.any,
};

KinkTable.defaultProps = {
  tableKey: null,
  title: null,
  defaultSortColumn: '',
  defaultSortDirection: 'desc',
  defaultColumnWidth: 100,
  titlebarActions: null,
  customActions: null,
  rowActions: null,
  children: null,
  loading: false,
  paging: true,
  currentPage: null,
  defaultPageSize: 15,
  onCurrentPageChange: null,
  onPageSizeChange: null,
  onFilterDataChange: null,
  onSelectionChange: null,
  sorting: true,
  summary: false,
  hidden: false,
  filtering: true,
  selection: false,
  search: false,
  defaultSearch: '',
  filters: null,
  defaultWidth: false,
  showSelectAll: true,
  selectedRows: null,
  dataFilters: {},
  actionColumnFirst: false,
  actionColumnWidth: 150,
  // onRowDoubleClick: null,
  displayColumns: null,
  striped: true,
  hover: true,
  // quartiles: 4,
  subtitle: undefined,
  showSummaryOnTop: true,
  inlineFiltersCallback: null,
  disableCommaFilter: false,
  rowDetail: null,
  expandId: 0,
};

export default withStyles(styles, { withTheme: true })(KinkTable);
