import React from 'react';
import PropTypes from 'prop-types';
import { HexColorPicker } from 'react-colorful';

import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CloseIcon from '@mui/icons-material/Close';

const styles = () => ({
  root: {
    overflow: 'visible',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '10px'
  },
  selected: {
    backgroundColor: 'red'
  }
});

class ThemePicker extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      color: '#ffffff',
      section: 'primary',
      sections: {},
    };
  }

  setSection = (section) => {
    this.setState({ section });
  }

  setColor = (color) => {
    const sections = this.state.sections;
    sections[this.state.section] = color
    this.setState({ sections });

    const palette = {}

    Object.keys(this.state.sections).forEach(section => {
      palette[section] = {
        main: sections[section]
      }
    })

    this.props.setTheme({
      palette
    })
  }

  render() {
    const { classes } = this.props;
    return (
      <Dialog open={this.props.show} maxWidth="lg">
        <Card>
          <CardHeader
            action={<IconButton onClick={() => this.props.hide()}><CloseIcon /></IconButton>}
            title="Theme Picker"
          />
        </Card>
        <div className={classes.root} color="textSecondary">
          <HexColorPicker color={this.state.color} onChange={this.setColor} />
        </div>
        {false && (
          <React.Fragment>
            <Button onClick={() => this.setSection('primary')} className={this.state.section === 'primary' ? 'selected' : ''}>primary</Button>
            <Button onClick={() => this.setSection('secondary')} className={this.state.section === 'secondary' ? 'selected' : ''}>secondary</Button>
          </React.Fragment>
        )}
        <DialogContent>
          <DialogActions>
            <Button onClick={() => this.props.hide()} color="primary">Close</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    );
  }
}

ThemePicker.propTypes = {
  classes: PropTypes.object.isRequired,
  setTheme: PropTypes.func.isRequired,
  hide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

export default withStyles(styles)(ThemePicker);
