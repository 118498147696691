import React from 'react';

import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';

import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import Button from '@mui/material/Button';

import VersionAPI from 'services/api/version';

const styles = theme => ({
  content: {
    backgroundColor: theme.palette.primary.dark,
  },
  buttonReload: {
    backgroundColor: 'rgb(255, 255, 255)',
    '&.MuiButton-root:hover': {
      backgroundColor: 'rgb(255, 255, 255 ,0.8)'
    },
  }
});

class VersionCheck extends React.Component {

  constructor() {
    super();
    this.state = {
      hash: null,
      message: '',
      updateAvailable: false,
    };

    this.start();
  }

  start = async () => {
    if (process.env.NODE_ENV === 'production') {
      await this.getHash();
      this.checkVersionRecursive();
    }
  }

  getHash = async () => {
    const response = await VersionAPI.bearclaw.get();
    this.setState({
      hash: response.data.hash
    });
  }

  isClientOutdated = hash => {
    return this.state.hash !== hash;
  }

  checkVersionRecursive = () => {
    VersionAPI.bearclaw.get()
      .then(response => {
        if (this.isClientOutdated(response.data.hash)) {
          this.setState({ updateAvailable: true, message: response.data.message });
        } else {
          // Check again in 2 minutes.. yes.. aggressive.. but fuck it right?
          setTimeout(this.checkVersionRecursive, 120000);
        }
      });
  }

  handleRefresh = () => {
    window.location.reload();
  }

  render() {

    const { classes } = this.props;

    return (

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={this.state.updateAvailable}
      >
        <SnackbarContent
          className={classes.content}
          aria-describedby="client-snackbar"
          message={(
            <span id="client-snackbar" className={classes.message}>
              There is a new version available! <br /> {this.state.message}
            </span>
          )}
          action={(
            <Button className={classes.buttonReload} variant="outlined" color="primary" size="small" onClick={this.handleRefresh}>
              Reload
            </Button>
          )}
        />
      </Snackbar>
    );
  }
}

VersionCheck.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(VersionCheck);
