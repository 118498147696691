import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DialogContentText from '@mui/material/DialogContentText';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import FormHelperText from '@mui/material/FormHelperText';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import RefreshIcon from '@mui/icons-material/Refresh';
import FormControl from '@mui/material/FormControl';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import withStyles from '@mui/styles/withStyles';
import Input from '@mui/material/Input';
import Divider from '@mui/material/Divider';

import { FilterConsumer } from 'contexts/FilterProvider';
import { getImage } from 'core/Functions/utilities';
import config from 'core/Utilities/config';

const styles = () => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 15px',
    gap: '1.5rem',
    backgroundColor: '#fff',
    borderRadius: '20px',
    marginRight: '30px',
    height: 75
  },
  formControl: {
    // margin: theme.spacing(3),
    width: 155,
  },
  formControlSite: {
    // margin: theme.spacing(3),
    width: 180,
  },
  formControlSmall: {
    display: 'flex',
  },
  filterApplied: {
    position: 'absolute',
    right: '5px',
    top: '5px',
    background: 'red',
    color: '#fff',
    borderRadius: '35px',
    height: '15px',
    width: '15px',
    fontSize: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  label: {
    color: 'rgba(0, 0, 0, 0.54)',
    padding: 0,
    fontSize: '1rem',
    lineHeight: 1,
    fontWeight: 700,
    transform: 'translate(0, 1.5px) scale(0.75)',
    transformOrigin: 'top left',
    paddingLeft: 20,
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif'
  },
  containerSites: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  modalBody: {
    gap: '1rem',
    display: 'flex',
    padding: '15px 0',
    position: 'relative',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
});

class FilterDesktop extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      startDate: props.filter.startDate ? moment(props.filter.startDate) : moment(),
      endDate: props.filter.endDate ? moment(props.filter.endDate) : moment(),
      timeframe: props.filter.timeframe ? props.filter.timeframe : 'Custom',
      timeframes: {
        Today: [moment(), moment()],
        Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 3 Days': [moment().subtract(2, 'days'), moment()],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 14 Days': [moment().subtract(13, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Week': [moment().startOf('week'), moment().endOf('week')],
        'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
        Custom: []
      },
      // timezone: props.filter.timezone || 'America/Vancouver',
      site: props.filter.site,
    };
  }

  isComponentMounted = false;

  componentDidMount() {
    this.isComponentMounted = true;
  }

  componentDidUpdate(prevProps) {
    if (this.isComponentMounted && (prevProps.filter !== this.props.filter
      || prevProps.CustomFilter !== this.props.CustomFilter)) {
      this.updateFromFilter();
    }
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
  }

  updateFromFilter() {
    this.setState({
      site: this.props.filter.site,
      startDate: moment(this.props.filter.startDate),
      endDate: moment(this.props.filter.endDate),
      timeframe: this.props.filter.timeframe || 'Custom',
      // timezone: this.props.filter.timezone || 'America/Vancouver',
    });
  }

  getTimeframe = (startDate, endDate) => {
    const start = moment(startDate).format('YYYY-MM-DD');
    const end = moment(endDate).format('YYYY-MM-DD');
    const today = moment().format('YYYY-MM-DD');

    if (start === today && end === today) {
      return 'Today';
    }
    if (start === end && end === moment().subtract(1, 'days').format('YYYY-MM-DD')) {
      return 'Yesterday';
    }
    if (start === moment().subtract(2, 'days').format('YYYY-MM-DD') && end === today) {
      return 'Last 3 Days';
    }
    if (start === moment().subtract(6, 'days').format('YYYY-MM-DD') && end === today) {
      return 'Last 7 Days';
    }
    if (start === moment().subtract(29, 'days').format('YYYY-MM-DD') && end === today) {
      return 'Last 30 Days';
    }
    return 'Custom';
  };

  showModal = () => {
    this.setState({ showModal: true });
  };

  hideModal = () => {
    this.setState({ showModal: false });
  };

  applyModal = () => {
    this.setState({ timeframe: 'Custom', showModal: false }, () => {
      this.applyChanges();
    });
  };

  cancelModal = () => {
    this.setState({ startDate: moment(this.props.filter.startDate), endDate: moment(this.props.filter.endDate) });
    this.hideModal();
  };

  handleChangeStart = (date) => {
    this.setState({ startDate: moment(date) });
  };

  handleChangeEnd = (date) => {
    this.setState({ endDate: moment(date) });
  };

  handleShiftStart = () => {
    this.setState({ timeframe: 'Custom', startDate: this.state.startDate.add(1, 'days'), endDate: this.state.endDate.add(1, 'days') }, () => {
      this.applyChanges();
    });
    // this.updateDate();
  };

  handleShiftEnd = () => {
    this.setState({ timeframe: 'Custom', startDate: this.state.startDate.subtract(1, 'days'), endDate: this.state.endDate.subtract(1, 'days') }, () => {
      this.applyChanges();
    });
    // this.updateDate();
  };

  dateRange = () => {
    if (this.state.startDate.isSame(this.state.endDate, 'day')) {
      return this.state.startDate.format('MMMM D, YYYY');
    }
    return this.state.startDate.format('MMMM D, YYYY') + ' - ' + this.state.endDate.format('MMMM D, YYYY');
  };

  onChangeTimeframe = (value, key) => {
    if (value === 'Custom') {
      this.showModal();
    } else {
      const newState = {};
      newState[key] = value;
      this.setState(newState, () => {
        this.hideModal();
        const startDate = this.state.timeframes[this.state.timeframe][0];
        const endDate = this.state.timeframes[this.state.timeframe][1];
        this.setState({ startDate, endDate }, () => {
          this.applyChanges();
        });
      });
    }
  };

  onChangeForm = (e) => {
    const newState = {};
    const accessor = e.target.id ? e.target.id : e.target.name;
    newState[accessor] = e.target.value;
    this.setState(newState, () => {
      this.applyChanges();
    });
    // this.props.changeFilter({ ...this.props.filter, ...newState });
  };

  // updateDate = () => {
  //   // update date text if modal is visible
  //   if (this.state.showModal) {
  //     this.refs.dates.innerHTML = this.dateRange();
  //   }
  // }

  getFormattedDate = () => {
    if (this.state.startDate.diff(this.state.endDate, 'days') === 0) {
      const startDate = moment(this.state.startDate).startOf('day').format('YYYY-MM-DD');
      return `${startDate}`;
    }
    const startDate = moment(this.state.startDate).startOf('day').format('YYYY-MM-DD');
    const endDate = moment(this.state.endDate).endOf('day').format('YYYY-MM-DD');
    return `${startDate} - ${endDate}`;
  };

  isDateToday = () => {
    return moment(this.state.endDate).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD');
  };

  applyChanges = () => {
    const newStartDate = moment(this.state.startDate).startOf('day').format('YYYY-MM-DD HH:mm:ss');
    const newEndDate = moment(this.state.endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss');
    const newTimeframe = this.getTimeframe(newStartDate, newEndDate);
    const newSite = this.state.site === 'sundaymarket' ? '' : this.state.site;

    // report new dates back up the line
    this.props.changeFilter({ site: newSite, startDate: newStartDate, endDate: newEndDate, timeframe: newTimeframe });
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <FilterConsumer>
          {({ numberApplied, clearFilter, shouldShow, refreshComponents }) => (
            <React.Fragment>

              <div className={classes.formControlSmall}>
                <Tooltip title="Clear Filter" placement="bottom">
                  <IconButton className={classes.titlebarIcons} onClick={clearFilter}>
                    <ClearIcon />
                    {numberApplied > 0 ? (
                      <div className={classes.filterApplied}>
                        {numberApplied}
                      </div>
                    ) : undefined}
                  </IconButton>
                </Tooltip>

                {!!shouldShow.includes('refresh')
                  && (
                    <Tooltip title="Refresh" placement="bottom">
                      <IconButton onClick={refreshComponents} className={classes.titlebarIcons}>
                        <RefreshIcon />
                      </IconButton>
                    </Tooltip>
                  )}
              </div>

              {!!shouldShow.includes('site')
                && (
                  <FormControl className={classes.formControlSite}>
                    <InputLabel htmlFor="select-site">Site</InputLabel>
                    <Select
                      value={this.props.filter.site ? this.props.filter.site : 'sundaymarket'}
                      input={<Input id="select-site" name="site" />}
                      onChange={this.onChangeForm}
                    >
                      {Object.values(config.sites).map(item => (
                        <MenuItem key={item.label} value={item.value}>
                          <span className={classes.containerSites}>
                            <img src={getImage(item.value)} alt={item.value} width={25} height={25} />{item.label}
                          </span>
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText margin="dense" />
                  </FormControl>
                )}

              {!!this.props.CustomFilter && this.props.CustomFilter}

              {!!shouldShow.includes('timeframe')
                && (
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="select-timeframe">Timeframe</InputLabel>
                    <Select
                      value={this.props.filter.timeframe}
                      input={<Input id="select-timeframe" name="timeframe" />}
                      renderValue={(id) => Object.keys(this.state.timeframes).filter((n) => n === id)[0]}
                    // onChange={this.onChangeTimeframe}
                    >
                      {Object.keys(this.state.timeframes).map(item => (
                        <MenuItem key={item} value={item} onClick={() => this.onChangeTimeframe(item, 'timeframe')}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText margin="dense">{this.getFormattedDate()}</FormHelperText>
                  </FormControl>
                )}

              {!!shouldShow.includes('timeframe')
                && (
                  <FormControl required className={classes.formControlSmall}>
                    <div className={classes.label}>Day</div>
                    <div className={classes.formControlSmall}>
                      <IconButton onClick={() => this.handleShiftEnd()}>
                        <ChevronLeftIcon />
                      </IconButton>
                      <IconButton onClick={() => this.handleShiftStart()} disabled={this.isDateToday()}>
                        <ChevronRightIcon />
                      </IconButton>
                    </div>
                  </FormControl>
                )}

            </React.Fragment>
          )}
        </FilterConsumer>

        <Dialog
          fullWidth
          maxWidth="sm"
          open={this.state.showModal}
          onClose={this.hideModal}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title" sx={{ paddingBottom: 0 }}>
            Choose Daterange
          </DialogTitle>

          <DialogContent>
            <DialogContentText>
              {this.dateRange()}
            </DialogContentText>
            <Divider />
            <div className={classes.modalBody}>
              <ReactDatePicker
                inline
                showMonthYearDropdown
                name="startDate"
                selected={this.state.startDate.toDate()}
                onChange={this.handleChangeStart}
              />
              <ReactDatePicker
                inline
                showMonthYearDropdown
                name="endDate"
                selected={this.state.endDate.toDate()}
                onChange={this.handleChangeEnd}
              />
            </div>
            <Divider />

          </DialogContent>

          <DialogActions>
            <Button variant="outlined" sx={{ borderColor: '#aeaeae' }} onClick={() => this.cancelModal()}>Cancel</Button>
            <Button variant="outlined" sx={{ borderColor: '#aeaeae' }} onClick={() => this.applyModal()}>Apply</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

FilterDesktop.propTypes = {
  classes: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  changeFilter: PropTypes.func.isRequired,
  CustomFilter: PropTypes.any,
};

FilterDesktop.defaultProps = {
  CustomFilter: undefined
};

export default withStyles(styles, { withTheme: true })(FilterDesktop);
