import { isMobile } from 'react-device-detect';
import { precisionRound } from 'core/Functions/utilities';

const moment = require('moment');

export const dataFilter = {
  number: (a, b) => {
    if (typeof a === 'string') {
      a = a.replace(/[^\d.-]/g, '');
    }
    if (typeof b === 'string') {
      b = b.replace(/[^\d.-]/g, '');
    }
    if (Number.isNaN(Number(a))) {
      a = 0;
    }
    if (Number.isNaN(Number(b))) {
      b = 0;
    }
    return Number(a) < Number(b) ? -1 : 1;
  },
};

export const dataFormatter = {
  text: (data) => (data.value ? String(data.value) : ''),
  percent: (data) => (Number.isNaN(Number(data.value)) ? 0 : Number(data.value).toFixed(2)) + '%',
  middlepercent: (data) => (Number.isNaN(Number(data.value)) ? 0 : Number(data.value).toFixed(isMobile ? 1 : 2)) + '%',
  smallpercent: (data) => (Number.isNaN(Number(data.value)) ? 0 : Number(data.value).toFixed(0)) + '%',
  formatPercent: (data) => (Number.isNaN(Number(data.value)) ? 0 : (data.value * 100).toFixed(2)) + '%',
  number: (data) => {
    // console.log('data', data.value, typeof data.value);
    if (data.value === null || data.value === undefined || Number.isNaN(data.value) || data.value === 'NaN') {
      if (data.showOnNull) return '0';
      return '';
    }
    return String(data.value).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  },
  smallnumber: (data) => {
    let value = data.value;
    if (data.value === null || data.value === undefined || Number.isNaN(data.value)) {
      if (data.showOnNull) return '0';
      return '';
    }
    if (!Number.isNaN(value)) {
      value = Number(value).toFixed(0);
    }
    return String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  },
  currency: (data) => {
    let value = data.value;
    if (value === null || value === undefined) {
      return '';
    }
    if (Number.isNaN(Number(value))) {
      value = 0;
    }
    if (value >= 1000) {
      value = Number(value).toLocaleString('en', {
        useGrouping: true,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
    } else {
      value = Number(value).toFixed(2);
    }
    return '$' + value;
  },
  currencyInteger: (data) => {
    let value = data.value;
    if (Number.isNaN(Number(value))) {
      value = 0;
    }
    if (value >= 1000) {
      value = Number(value).toLocaleString('en', {
        useGrouping: true,
        minimumFractionDigits: isMobile ? 0 : 2,
        maximumFractionDigits: isMobile ? 0 : 2
      });
    } else {
      value = Number(value).toFixed(isMobile ? 0 : 2);
    }
    return '$' + value;
  },
  nullcurrency: (data) => (Number.isNaN(Number(data.value)) || Number(data.value) === 0 ? '' : '$' + Number(data.value).toFixed(2)),
  smallcurrency: (data) => {
    const value = data.value;
    if (value === null || value === undefined) {
      return '';
    }
    return '$' + (Number.isNaN(Number(value)) ? 0 : precisionRound(value, 3));
  },
  date: (data) => (data.value ? new Date(data.value).toLocaleDateString() : ''),
  date_time: (data) => {
    return moment.utc(data.value).format('YYYY-MM-DD, ha');// + ' - ' + new Date(data.value).toLocaleString();
  },
  boolean: (data) => `${data.value === undefined ? '' : (data.value === 1 ? '✅' : '❌')}`,
  fromNow: (data, timezone = false) => {
    let formatted = '';
    if (data.value) {
      if (!timezone || !Object.keys(timezone).length) {
        formatted = `${moment(data.value).fromNow()}`;
        return formatted === 'Invalid date' ? '' : `${formatted} `;
      }
      const trimmed = data.value.substr(0, 19); // strip off utc timezone
      formatted = `${moment.tz(trimmed, 'America/Vancouver').fromNow()} `;
      // console.log(trimmed, formatted);
    }
    return formatted === 'Invalid date' ? '' : `${formatted.replace('minutes', 'min')} `;
  },
  fromNowShort: (data, timezone = false) => {
    let formatted = '';
    if (data.value) {
      if (!timezone || !Object.keys(timezone).length) {
        formatted = `${moment(data.value).fromNow()} `;
        formatted = formatted.replace('minutes', 'min').replace('seconds', 'sec').replace('second', 'sec').replace('minute', 'min');
        return formatted === 'Invalid date' ? '' : `${formatted} `;
      }
      const trimmed = data.value.substr(0, 19); // strip off utc timezone
      formatted = `${moment.tz(trimmed, 'America/Vancouver').fromNow()} `;
      // console.log(trimmed, formatted);
    }
    return formatted === 'Invalid date' ? '' : `${formatted.replace('minutes', 'min')} `;
  },
  fromNowFuture: (data) => {
    const fromNow = moment(moment().format('YYYY-MM-DD ' + moment(data.value).format('HH:mm') + ':00'));
    if (fromNow < moment()) {
      fromNow.add(1, 'days');
    }
    return fromNow === 'Invalid date' ? '' : `${fromNow.fromNow()} `;
  },
  hour: (data) => {
    const formatted = moment(data.value).format('h:mmA');
    const fromNow = moment(moment().format('YYYY-MM-DD ' + moment(data.value).format('HH:mm') + ':00'));
    if (fromNow < moment()) {
      fromNow.add(1, 'days');
    }
    return formatted === 'Invalid date' ? '' : `${formatted} (${fromNow.fromNow()})`;
  },
  dateHour: (data) => {
    const formatted = moment.tz(data.value, 'YYYY-MM-DD HH:mm', 'America/Vancouver').format('YYYY-MM-DD hA');
    return formatted === 'Invalid date' ? '' : formatted;
  },
  age: (data, referenceDate = false) => {
    const date = moment(data.value); // Gets the data and convert to moment
    if (!date.isValid()) return ''; // Check if it was a valid value

    let dateToCompare = moment(); // Initialize as today
    if (referenceDate) dateToCompare = moment(referenceDate); // If a reference date was setted, use it instead of Today
    if (!dateToCompare.isValid()) return ''; // Check if has a valid moment value
    const diffDays = dateToCompare.diff(date, 'days'); // DO the difference between the dates

    return `${diffDays} Days`;
  }
};
