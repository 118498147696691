import request from './request';

const WebstatsAPI = {
  gazillions: {
    getSyncedSummary: (params, cancelToken) => request({ url: '/webstats/gazillions/synced', method: 'get', params, cancelToken }),
    getCampaignDemandSummary: (params, cancelToken) => request({ url: '/webstats/gazillions/campaign/demand/summary', method: 'get', params, cancelToken }),

    getCampaignStats: (params, cancelToken) => request({ url: '/webstats/gazillions/campaigns', method: 'get', params, cancelToken }),
    getAdStats: (params, cancelToken) => request({ url: '/webstats/gazillions/ads', method: 'get', params, cancelToken }),

    getCampaignDailyStats: (params, cancelToken) => request({ url: '/webstats/gazillions/campaigns/daily', method: 'get', params, cancelToken }),
    getCampaignHourlySessions: (params, cancelToken) => request({ url: '/webstats/gazillions/campaigns/hourly', method: 'get', params, cancelToken }),
    getCampaignSessionsDaily: (params, cancelToken) => request({ url: '/webstats/gazillions/campaigns/campaign_report/daily', method: 'get', params, cancelToken }),

    getSitesHourly: (params, cancelToken) => request({ url: '/webstats/gazillions/site/hourly', method: 'get', params, cancelToken }),

    getSourceStatsDaily: (params, cancelToken) => request({ url: '/webstats/gazillions/sources/daily', method: 'get', params, cancelToken }),
    getVideoSourceDaily: (params, cancelToken) => request({ url: '/webstats/gazillions/video-sources/daily', method: 'get', params, cancelToken }),
    getStatsDaily: (params, cancelToken) => request({ url: '/webstats/gazillions/chart/daily', method: 'get', params, cancelToken }),
    getSitesDaily: (params, cancelToken) => request({ url: '/webstats/gazillions/sites/daily', method: 'get', params, cancelToken }),
    getCampaignStatsDaily: (params, cancelToken) => request({ url: '/webstats/gazillions/campaigns/chart/daily', method: 'get', params, cancelToken }),
    getCampaignStatsHourly: (params, cancelToken) => request({ url: '/webstats/gazillions/campaigns/chart/hourly', method: 'get', params, cancelToken }),
    getSessionsSummaryHourly: (params, cancelToken) => request({ url: '/webstats/gazillions/sessions/chart/hourly', method: 'get', params, cancelToken }),

    getAggregatedCampaignStatsDaily: (params, cancelToken) => request({ url: '/webstats/gazillions/campaigns/aggregated/daily', method: 'get', params, cancelToken }),

    getTopAds: (params, cancelToken) => request({ url: '/webstats/gazillions/ads/top', method: 'get', params, cancelToken }),

    getAdsReport: (params, cancelToken) => request({ url: '/webstats/gazillions/ads/adsreport', method: 'get', params, cancelToken }),

    getTopAdByCampaign: (params, cancelToken) => request({ url: '/webstats/gazillions/ads/top/campaign', method: 'get', params, cancelToken }),
    getAdUrlStats: (params, cancelToken) => request({ url: '/webstats/gazillions/ads/urls', method: 'get', params, cancelToken }),
    getCampaignUrlStats: (params, cancelToken) => request({ url: '/webstats/gazillions/campaigns/urls', method: 'get', params, cancelToken }),
    getCampaignPublisherStats: (params, cancelToken) => request({ url: '/webstats/gazillions/campaigns/publishers', method: 'get', params, cancelToken }),
    getSplitTestStats: (params, cancelToken) => request({ url: '/webstats/gazillions/split-tests', method: 'get', params, cancelToken }),
    getCampaignSplitTestStats: (params, cancelToken) => request({ url: '/webstats/gazillions/split-tests/campaign', method: 'get', params, cancelToken }),
    getCallsReport: (params, cancelToken) => request({ url: '/webstats/gazillions/calls_report', method: 'get', params, cancelToken }),
    getCallsKeywordsReport: (params, cancelToken) => request({ url: '/webstats/gazillions/calls_keyword_report', method: 'get', params, cancelToken }),

    getCampaignAdUnitStats: (params, cancelToken) => request({ url: '/webstats/gazillions/campaigns/adunits', method: 'get', params, cancelToken }),
    getCampaignDemandStats: (params, cancelToken) => request({ url: '/webstats/gazillions/campaigns/demand', method: 'get', params, cancelToken }),
    getCampaignBrowserStats: (params, cancelToken) => request({ url: '/webstats/gazillions/campaigns/browsers', method: 'get', params, cancelToken }),
    getCampaignDeviceStats: (params, cancelToken) => request({ url: '/webstats/gazillions/campaigns/device', method: 'get', params, cancelToken }),
    getCampaignDeviceBrowserOsStats: (params, cancelToken) => request({ url: '/webstats/gazillions/campaigns/browser_device_os', method: 'get', params, cancelToken }),

    getDemandGeoStatsAy: (params, cancelToken) => request({ url: '/webstats/gazillions/demand-geo-browser-ay', method: 'get', params, cancelToken }),
    getBrowserGeoStatsAy: (params, cancelToken) => request({ url: '/webstats/gazillions/browser-geo-ay', method: 'get', params, cancelToken }),
    getGoogleSearchTermsStats: (params, cancelToken) => request({ url: '/webstats/gazillions/google-search-terms', method: 'get', params, cancelToken }),

    /**
     * SUPER LEGACY CODE
      ##########
      # PROPER #
      ##########
      getSovrnSourceStats: (params, cancelToken) => request({ url: '/webstats/gazillions/source/sovrn', method: 'get', params, cancelToken }),
      getDemandGeoStatsSovrn: (params, cancelToken) => request({ url: '/webstats/gazillions/demand-geo-browser-sovrn', method: 'get', params, cancelToken }),
      getBrowserGeoStatsSovrn: (params, cancelToken) => request({ url: '/webstats/gazillions/browser-geo-sovrn', method: 'get', params, cancelToken }),
      getDeviceHourly: (params, cancelToken) => request({ url: '/webstats/gazillions/device_hourly_chart', method: 'get', params, cancelToken }),
      getDeviceCPMHourly: (params, cancelToken) => request({ url: '/webstats/gazillions/chart/device_cpm_hourly', method: 'get', params, cancelToken }),
      getCampaignDeviceCPMHourly: (params, cancelToken) => request({ url: '/webstats/gazillions/campaigns/chart/device_cpm_hourly', method: 'get', params, cancelToken }),
      updateCustomRPS: (data, cancelToken) => request({ url: '/webstats/gazillions/rps/custom-rps', method: 'put', data, cancelToken }),

      ###########
      # BIDDERS #
      ###########
      getBidderDailyAdjustments: (params, cancelToken) => request({ url: '/webstats/gazillions/bidders/charts/daily_adjusments', method: 'get', params, cancelToken }),
      getBidderHourlyAdjustments: (params, cancelToken) => request({ url: '/webstats/gazillions/bidders/charts/hourly_adjusments', method: 'get', params, cancelToken }),
      createCustomPlacement: (data, cancelToken) => request({ url: '/webstats/gazillions/14daysreport/custom-placement', method: 'post', data, cancelToken }),
      updateGooglePublisher: (data, cancelToken) => request({ url: '/webstats/gazillions/source/google_publisher', method: 'post', data, cancelToken }),
      getGooglePublisher: (params, cancelToken) => request({ url: '/webstats/gazillions/source/google_publisher', method: 'get', params, cancelToken }),
      getGoogleWhitelist: (params, cancelToken) => request({ url: '/webstats/gazillions/google/whitelist', method: 'get', params, cancelToken }),
      deleteGoogleWhitelist: (data, cancelToken) => request({ url: '/webstats/gazillions/google/whitelist/delete', method: 'post', data, cancelToken }),
      saveGoogleWhitelist: (data, cancelToken) => request({ url: '/webstats/gazillions/google/whitelist/save', method: 'post', data, cancelToken }),
      updateCustomRPSPlacements: (data, cancelToken) => request({ url: '/webstats/gazillions/14daysreport/custom-rps', method: 'put', data, cancelToken }),
      get14DayReportRPS: (params, cancelToken) => request({ url: '/webstats/gazillions/14_day_rps', method: 'get', params, cancelToken }),
    get14DaySourceBrowserOsCountry: (params, cancelToken) => request({ url: '/webstats/gazillions/14_day_source_browser_os_country_clicks', method: 'get', params, cancelToken }),
      */

    getDeviceCPMDaily: (params, cancelToken) => request({ url: '/webstats/gazillions/chart/device_cpm_daily', method: 'get', params, cancelToken }),
    getDeviceCPMDailyAy: (params, cancelToken) => request({ url: '/webstats/gazillions/chart/device_cpm_daily_ay', method: 'get', params, cancelToken }),

    getSiteSourceDaily: (params, cancelToken) => request({ url: '/webstats/gazillions/chart/site_source_device_daily', method: 'get', params, cancelToken }),

    get14DayReport: (params, cancelToken) => request({ url: '/webstats/gazillions/14daysreport', method: 'get', params, cancelToken }),

    // getFinanceReport: (params, cancelToken) => request({ url: '/webstats/gazillions/finance', method: 'get', params, cancelToken }),
    // getFinanceDailySpendReport: (params, cancelToken) => request({ url: '/webstats/gazillions/finance/daily_spend', method: 'get', params, cancelToken }),
    getExcoRevenue: (params, cancelToken) => request({ url: '/webstats/gazillions/exco', method: 'get', params, cancelToken }),
    getProfitBySource: (params, cancelToken) => request({ url: '/webstats/gazillions/charts/profit_source', method: 'get', params, cancelToken }),
    getSiteSourceProfitable: (params, cancelToken) => request({ url: '/webstats/gazillions/site-source/profitable', method: 'get', params, cancelToken }),

    getCampaignDeviceBrowserOsStatsDaily: (params, cancelToken) => request({ url: '/webstats/gazillions/campaign/charts/device_browser_os', method: 'get', params, cancelToken }),

    getGoogleAssets: (params, cancelToken) => request({ url: '/webstats/gazillions/google_assets', method: 'get', params, cancelToken }),
    getGoogleAssetsByContent: (params, cancelToken) => request({ url: '/webstats/gazillions/google_assets/content', method: 'get', params, cancelToken }),
    getCampaignPublisherHourly: (params, cancelToken) => request({ url: '/webstats/gazillions/campaigns/chart/publisher_hourly', method: 'get', params, cancelToken }),

    getAssertiveSourceStats: (params, cancelToken) => request({ url: '/webstats/gazillions/source/assertive', method: 'get', params, cancelToken }),
  },
};

export default WebstatsAPI;
