import { IconButton, Tooltip } from '@mui/material';
import React, { Fragment, useCallback } from 'react';
import PropTypes from 'prop-types';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useFacebookAccount } from './FacebookAccountProvider';
import AccountsAPI from '../../../services/api/accounts';

const FacebookAccountTableRow = ({ data }) => {
  const { setState, setIsLoading, fetchData, setSnackBar } = useFacebookAccount();

  const handleOnDeleteFacebookAccount = useCallback(async (account) => {
    const response = window.confirm('Do you really like to delete?');
    if (!response) return;
    try {
      setIsLoading('deleting-account');
      await AccountsAPI.facebook.deleteAccount(account);
      await fetchData();
      setSnackBar({ message: 'Account successfully deleted.', status: 'success', open: true });
    } catch (error) {
      if (error?.response?.data?.[0] > 300) {
        setSnackBar({ message: error?.response?.data?.[1], status: 'error', open: true });
      }
    }
    setIsLoading(undefined);
  }, [fetchData, setIsLoading, setSnackBar]);

  return (
    <Fragment>
      <Tooltip title="Edit account">
        <IconButton onClick={() => setState({ action: 'editing', account: { ...data } })}>
          <EditIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Tooltip title="Delete account">
        <IconButton onClick={() => handleOnDeleteFacebookAccount(data)}>
          <DeleteIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </Fragment>
  );
};

FacebookAccountTableRow.propTypes = {
  data: PropTypes.object.isRequired,
};

export default FacebookAccountTableRow;
