import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import { Routes, Route, Navigate } from 'react-router-dom';

import Rollbar from 'rollbar';
import { Provider, ErrorBoundary } from '@rollbar/react';

// Components and Utilities
import NavController from 'core/Components/NavController/NavController';
import { FilterProvider } from 'contexts/FilterProvider';
import VersionCheck from 'core/Components/VersionCheck';
import cacheman from 'core/Utilities/cacheman';
import MobLinks from 'core/Components/MobLinks';
import Loading from 'core/Components/Loading';
import config from 'core/Utilities/config';

// Styles
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import SettingsIcon from '@mui/icons-material/SettingsRounded';
import EngineeringIcon from '@mui/icons-material/Engineering';
import CampaignIcon from '@mui/icons-material/Campaign';
import ConstructionIcon from '@mui/icons-material/Construction';
import AssessmentIcon from '@mui/icons-material/Assessment';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import CssBaseline from '@mui/material/CssBaseline';
import GavelIcon from '@mui/icons-material/Gavel';
import HomeIcon from '@mui/icons-material/Home';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { deepmerge } from '@mui/utils';

// Auth
import authClient, { capturePath, getRedirectPage } from 'services/auth';
import NotFound from 'core/Components/NotFound';
import Callback from 'core/Auth/Callback';
import Login from 'core/Auth/Login';

// Images/Logos
import adgardenLogo from 'images/AdGarden-v2.png';

// Routes
import ListCampaigns from 'components/WebStats/Lists/ListCampaigns';
import AccountsUi from './components/Accounts';

// Lazy Imported Routes
const ContentDashboard = React.lazy(() => import('components/Builders/ContentDashboard'));
const DashboardWebStats = React.lazy(() => import('components/WebStats/DashboardWebStats'));
const GodDashboard = React.lazy(() => import('components/Dashboard/GodDashboard'));
const WebStats = React.lazy(() => import('components/WebStats/WebStats'));
const Content = React.lazy(() => import('components/Content/Content'));
const RulesUi = React.lazy(() => import('components/Rules/RulesUi'));
const AutoBuilder = React.lazy(() => import('components/AutoBuilders/AutoBuilders'));
const ReportsUi = React.lazy(() => import('components/Reports/ReportsUi'));
const Adgarden = React.lazy(() => import('components/Adgarden/Adgarden'));

function RequireAuth({ children, title }) {
  const isAuthenticated = authClient.isAuthenticated();
  capturePath(window.location.pathname + window.location.search);

  useEffect(() => {
    document.title = title || 'Sunday Market';
  }, [title]);

  return isAuthenticated ? children : <Navigate to="/login" />;
}

RequireAuth.propTypes = {
  children: PropTypes.any.isRequired,
  title: PropTypes.string,
};

RequireAuth.defaultProps = {
  title: '',
};

function LoginPage() {
  const isAuthenticated = authClient.isAuthenticated();
  const url = getRedirectPage();
  return isAuthenticated ? <Navigate to={url} /> : <Login />;
}

const MobRouter = () => (
  <Routes>
    <Route path="/login" element={<LoginPage />} />
    <Route path="/callback" element={<Callback />} />
    <Route exact path="/" element={(<RequireAuth title="Sunday Market"> <GodDashboard /> </RequireAuth>)} />
    <Route path="/dashboard/*" element={(<RequireAuth title="Sunday Market"> <GodDashboard /> </RequireAuth>)} />
    <Route path="/campaigns/*" element={(<RequireAuth title="Campaigns"> <ListCampaigns /> </RequireAuth>)} />
    <Route path="/webstats/*" element={(<RequireAuth title="Campaign Breakdown"> <WebStats /> </RequireAuth>)} />
    <Route path="/content/*" element={(<RequireAuth title="Content"> <Content /> </RequireAuth>)} />
    <Route path="/adgarden/*" element={(<RequireAuth title="Adgarden"> <Adgarden /> </RequireAuth>)} />
    <Route path="/rules/*" element={(<RequireAuth title="Rules"> <RulesUi /> </RequireAuth>)} />
    <Route path="/autobuilder/*" element={(<RequireAuth title="Auto Builders"> <AutoBuilder /> </RequireAuth>)} />
    <Route path="/builders/*" element={(<RequireAuth title="Builders"> <ContentDashboard /> </RequireAuth>)} />
    <Route path="/reports/*" element={(<RequireAuth title="Reports"> <ReportsUi /> </RequireAuth>)} />
    <Route path="/accounts/*" element={(<RequireAuth title="Accounts"> <AccountsUi /> </RequireAuth>)} />

    <Route path="/server-stats/*" element={(<RequireAuth title="Server Stats"> <DashboardWebStats /> </RequireAuth>)} />
    <Route path="*" element={<NotFound />} />
  </Routes>
);

const navItems = [
  isMobile ? <MobLinks to="/" icon={<HomeIcon />} label="Home" key="sundaymarket_home" /> : undefined,
  <MobLinks to="/campaigns" icon={<CampaignIcon />} label="Campaign Manager" key="sundaymarket_webstats" />,
  <MobLinks to="/adgarden" image={adgardenLogo} imageHeigth="auto" label="Adgarden" key="adgarden" />,
  <MobLinks to="/builders/images" icon={<ConstructionIcon />} label="Builders" key="builders" />,
  <MobLinks to="/content" icon={<MenuBookIcon />} label="Content" key="content" />,
  <MobLinks to="/rules/facebook" icon={<GavelIcon />} label="Rules UI" key="ibot_rules_ui" />,
  <MobLinks to="/autobuilder" icon={<EngineeringIcon />} label="Auto Builders" key="ibot_settings" />,
  <MobLinks to="/reports/" icon={<AssessmentIcon />} label="Reports" key="reports_ui" />,
  <MobLinks to="/accounts" icon={<ManageAccountsIcon />} label="Accounts" key="accounts" />,
  <MobLinks to="/server-stats" icon={<SettingsIcon />} label="Server Stats" key="server_stats" />,
];

const unprotected = ['/login', '/callback'];

const rollbarConfig = {
  accessToken: '8615182ecdee4800b4bba3a81ab85692',
  environment: process.env.NODE_ENV,
  server: {
    root: 'https://buyers.amob.io/',
    branch: 'main'
  },
  captureUncaught: true,
  captureUnhandledRejections: true,
};

const rollbar = new Rollbar(rollbarConfig);

// overwrite console.error
const originalConsoleError = console.error;
console.error = (...args) => {
  if (args && typeof args === 'object' && args.some(row => typeof row === 'string' && row.includes('`item.column.title` of type `object` supplied to `Item`, expected `string`'))) {
    return;
  }

  if (window.rollbar !== undefined) {
    rollbar.error(args);
  }
  originalConsoleError(...args);
};

window.rollbar = rollbar;

class App extends React.Component {

  constructor() {
    super();

    const themeOverrides = cacheman.get('theme', 'local');
    const theme = createTheme(deepmerge(config.theme, themeOverrides || {}));

    this.state = {
      theme
    };
  }

  // Triggered on page reload
  async componentDidMount() {
    // Ignore auth on unprotected routes
    const path = window.location.href;
    if (unprotected.includes(path)) {
      return;
    }

    // Check for valid Auth0 session
    try {
      await authClient.silentAuth();
    } catch (err) {
      // Auth failed, redirect to login
      if (err.error !== 'login_required') {
        console.log(err.error);
      }
      // Trigger re-render to display error message
      this.forceUpdate();
    }
  }

  setTheme = (theme) => {
    cacheman.set('theme', theme, 60 * 24 * 365);
    this.setState({ theme: createTheme(deepmerge(config.theme, theme)) });
  };

  render() {
    return (
      <Provider config={rollbarConfig}>
        <ErrorBoundary>
          <ThemeProvider theme={this.state.theme}>
            <StyledEngineProvider injectFirst>

              <FilterProvider>
                <div id="amob" style={{ minHeight: '100%' }}>
                  <CssBaseline />

                  <NavController navItems={navItems} setTheme={this.setTheme}>
                    <React.Suspense fallback={<Loading />}>
                      <MobRouter />
                    </React.Suspense>
                  </NavController>

                  {authClient.isAuthenticated() && <VersionCheck />}
                </div>

              </FilterProvider>
            </StyledEngineProvider>
          </ThemeProvider >
        </ErrorBoundary>
      </Provider>
    );
  }
}

export default App;

RequireAuth.propTypes = {
  children: PropTypes.any.isRequired
};
