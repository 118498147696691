/* eslint-disable no-bitwise */
import { Parser } from '@json2csv/plainjs';

import sundaymarket from 'images/SUNDAY_MARKET_V2.png';
import investingfuel from 'images/investingfuel.gif';
import kaleandcardio from 'images/kaleandcardio.png';
import gazillions from 'images/gazillions.png';
import mdrntoday from 'images/mdrntoday.png';
import heroInvesting from 'images/heroinvesting.png';
import followSports from 'images/followsports.png';
import factfeed from 'images/factfeed.png';
import petfools from 'images/petfools.jpg';
import travelroo from 'images/travelroo.jpg';
import burnyourday from 'images/burnyourday.png';
import quantanswers from 'images/quantanswers.png';
import quineapig from 'images/quineapig.png';
import sportsmound from 'images/sportsmound.png';
import latestinsports from 'images/latestinsports.png';

import config from 'core/Utilities/config';

// TODO: split this in organized files
/**
 * Filters the given data based on the specified filters..
 * Filters should be passed as follows
 * @param data Array of objects
 * @param filters which keys to filter to data on.
 * @returns {Array}
 */
export const filterData = (data, filters) => {
  if (filters === undefined) {
    return data;
  }

  const filteredData = [];

  data.forEach((item) => {
    let foundMatch = true;
    Object.keys(item).forEach((column) => {
      if (filters[column] === undefined || filters[column].value === '' || filters[column].value.length === 0) {
        return;
      }
      if (typeof filters[column].value === 'string') {
        /**
         * If multiple columns are specified we need to mark this is a match found if either of the
         * columns find a match
         */
        if (filters[column].cols !== undefined && Array.isArray(filters[column].cols)) {
          foundMatch = false;
          filters[column].cols.forEach((col) => {
            if (item[col].toString().toLowerCase().includes(filters[column].value.toLowerCase())) {
              foundMatch = true;
            }
          });
        }
        /**
         * Otherwise we need to report no match found whenever a column doesn't match
         */
        else if (!item[column].toString().toLowerCase().includes(filters[column].value.toLowerCase())) {
          foundMatch = false;
        }
      } else if (typeof filters[column].value === 'number') {
        if (filters[column].cols !== undefined && Array.isArray(filters[column].cols)) {
          foundMatch = false;
          filters[column].cols.forEach((col) => {
            if (Number(item[col]) === filters[column].value) {
              foundMatch = true;
            }
          });
        } else if (Number(item[column]) !== filters[column].value) {
          foundMatch = false;
        }
      } else if (Array.isArray(filters[column].value)) {
        if (!filters[column].value.includes(item[column])) {
          foundMatch = false;
        }
      }
    });
    if (foundMatch) {
      filteredData.push(item);
    }
  });

  return filteredData;
};

export const openLink = (event, destination, context) => {
  if (event.ctrlKey || event.metaKey || event.button === 1) {
    window.open(destination, '_blank');
  } else {
    context.props.history.push(destination);
  }
};

export const openLinkV2 = (event, destination, navigate) => {
  if (event.ctrlKey || event.metaKey || event.button === 1) {
    window.open(destination, '_blank');
  } else {
    navigate(destination);
  }
};

export const copyToClipboard = (text) => {
  const textArea = document.createElement('textarea');

  textArea.style.position = 'fixed';
  textArea.style.top = 0;
  textArea.style.left = 0;
  textArea.style.width = '2em';
  textArea.style.height = '2em';
  textArea.style.padding = 0;
  textArea.style.border = 'none';
  textArea.style.outline = 'none';
  textArea.style.boxShadow = 'none';
  textArea.style.background = 'transparent';
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  let success = false;
  try {
    const successful = document.execCommand('copy');
    success = successful ? 'successful' : 'unsuccessful';
  } catch (err) {
    console.log('Error copying to clipboard...');
  }

  document.body.removeChild(textArea);

  return success;
};

export const fixNum = (num) => {
  return num ? Number(`${num}`.replace(/[^0-9.-]+/g, '')) : 0;
  // return num ? Number(`${num}`.replace(/[^0-9\.-]+/g, '')) : 0;
};

export const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const capitalizeSentence = (sentence) => {
  const words = sentence.split(' ');
  for (let i = 0; i < words.length; i++) {
    const word = words[i];
    words[i] = word.charAt(0).toUpperCase() + word.slice(1);
  }
  return words.join(' ');
};

export const colorize = (value) => {
  value = String(value);
  value = Number(value.replace('$', '').replace('%', ''));
  const style = {};
  if (value < 0) {
    style.color = 'rgb(200, 0, 0)';
  } else if (value === 100) {
    style.color = 'rgb(211,211,211)';
  } else {
    style.color = 'rgb(0, 200, 0)';
  }
  return style;
};

export const getImage = (siteValue) => {
  const siteValueFormated = siteValue.toLowerCase();
  let site = '';
  const matchSite = Object.values(config.sites).find((site) => site.value === siteValueFormated || site.domain === siteValueFormated || site.code === siteValueFormated);
  if (matchSite) site = matchSite.label;
  switch (site.toLowerCase()) {
    case 'gazillions':
      return gazillions;
    case 'investingfuel':
      return investingfuel;
    case 'mdrntoday':
      return mdrntoday;
    case 'kaleandcardio':
      return kaleandcardio;
    case 'heroinvesting':
      return heroInvesting;
    case 'followsports':
      return followSports;
    case 'factfeed':
      return factfeed;
    case 'petfools':
      return petfools;
    case 'travelroo':
      return travelroo;
    case 'burnyourday':
      return burnyourday;
    case 'quantanswers':
      return quantanswers;
    case 'quineapig':
      return quineapig;
    case 'sportsmound':
      return sportsmound;
    case 'latestinsports':
      return latestinsports;
    default:
      return sundaymarket;
  }
};

export const getSites = () => {
  const sites = [];
  Object.values(config.sites).forEach(row => (
    sites.push({
      id: row.code, name: row.label === 'Sunday Market' ? 'All' : row.label
    })
  ));
  return sites;
};

export const getFormattedSiteName = (siteValue) => {
  const matchSite = Object.values(config.sites).find((site) => site.value === siteValue || site.domain === siteValue || site.code === siteValue);
  if (matchSite) return matchSite.label;
  return '';
};

export const getTaboolaAccountName = (accountId) => {
  const match = config.taboolaAccounts.find((account) => account.account_id === accountId);
  if (match) return match.name;
  return accountId;
};

export const calculateEstimatedMargin = (cpc, sessions, revenue, clickloss) => {
  let loss = 0.15; // 15% fallback
  // if (clickloss < 0) {
  //   loss = 0; // 0% if click_loss_7day is negative
  // } else if (clickloss > 0) {
  //   loss = clickloss / 100;
  // }
  if (clickloss) loss = clickloss / 100;
  const estimatedClicks = sessions + (sessions * loss);
  const estimatedCost = estimatedClicks * cpc;
  const estimatedProfit = revenue - estimatedCost;
  const estimatedMargin = (estimatedProfit / revenue) * 100;

  if (Number.isNaN(estimatedMargin) || !Number.isFinite(estimatedMargin)) return '0.00';
  return estimatedMargin.toFixed(2);
};

export const getRandomColor = () => {
  return Math.floor(Math.random() * 16777215).toString(16);
};

export const customSort = ({ data, sortBy }) => {
  const sortByObject = sortBy.reduce((obj, item, index) => {
    return {
      ...obj,
      [item]: index
    };
  }, {});

  return data.sort((a, b) => sortByObject[a.sortStatus] - sortByObject[b.sortStatus]);
};

export const getDefaultBrowser = (os, browser) => {
  const browserLowerCase = browser.toLowerCase();
  if (os === 'IOS') {
    if (browserLowerCase === 'google' || browserLowerCase === 'pinterest' || browserLowerCase === 'facebook' || browserLowerCase.includes('safari')) {
      return 'Safari';
    }
    if (browserLowerCase.includes('chrome')) {
      return 'Chrome';
    }
  } else if (os === 'Android') {
    if (browserLowerCase.includes('chrome') || browserLowerCase === 'facebook' || browserLowerCase === 'pinterest') {
      return 'Chrome';
    }
    if (browserLowerCase.includes('mozilla')) {
      return 'Mozilla';
    }
  } else if (os === 'Windows') {
    if (browserLowerCase.includes('firefox')) {
      return 'Mozilla';
    }
  }

  return browser;
};

export const getSiteByName = (campaignName) => {
  const split = campaignName.startsWith('dc') ? campaignName.split('_')[0] + '_' + campaignName.split('_')[1] : campaignName.split('_')[0];

  const matchSite = Object.values(config.sites).find((site) => site.code === split);
  if (matchSite) return matchSite.value;
  return '';
};

export const parseData = (data) => {
  const opts = {};
  const parser = new Parser(opts);
  return parser.parse(data);
};

export const shallowDiffers = (prev, next) => {
  for (const attribute in prev) {
    if (!(attribute in next)) {
      return true;
    }
  }
  for (const attribute in next) {
    if (prev[attribute] !== next[attribute]) {
      return true;
    }
  }
  return false;
};

export function areEqual(prevProps, nextProps) {
  const { style: prevStyle, ...prevRest } = prevProps;
  const { style: nextStyle, ...nextRest } = nextProps;

  return (
    !shallowDiffers(prevStyle, nextStyle) && !shallowDiffers(prevRest, nextRest)
  );
}

export const objectsEqual = (firstObject, secondObject) => {
  if ((!firstObject || typeof firstObject !== 'object') && (!secondObject || typeof secondObject !== 'object')) return true;
  if ((!firstObject || typeof firstObject !== 'object') && typeof secondObject === 'object') return false;
  if (typeof firstObject === 'object' && (!secondObject || typeof secondObject !== 'object')) return false;
  if (!firstObject || !secondObject) return false;

  return Object.keys(firstObject).length === Object.keys(secondObject).length
    && Object.keys(firstObject).every(p => firstObject[p] === secondObject[p]);
};

export const countries = Object.freeze([
  { name: 'All', id: '' },
  { name: 'United States', id: 'United States' },
  { name: 'Canada', id: 'Canada' },
  { name: 'United Kingdom', id: 'United Kingdom' },
  { name: 'Germany', id: 'Germany' },
  { name: 'Australia', id: 'Australia' },
]);

export const deviceColorsMap = {
  Android: '#4fcee0',
  'Mac OS X': '#60d949',
  Mac: '#60d949',
  Windows: '#633aa8',
  iOS: '#ED1B01',
  IOS: '#ED1B01',
  'Chrome OS': '#F7FF33',
  Linux: '#ff80ff'
};

export const generateColorByName = (name) => {
  // Generate a hash code from the string
  let hash = 0;
  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }

  // Convert the hash code into a hexadecimal color value
  let color = '#';
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    color += value.toString(16).padStart(2, '0');
  }

  return color;
};

export function precisionRound(number, precision = 2) {
  const factor = 10 ** precision;
  return Math.round(Number(number) * factor) / factor;
}

export const deepClone = (obj, hash = new WeakMap()) => {
  // Return primitives and functions as is
  if (obj === null || typeof obj !== 'object') return obj;

  // Handle Date
  if (obj instanceof Date) return new Date(obj);

  // Handle RegExp
  if (obj instanceof RegExp) return new RegExp(obj.source, obj.flags);

  // Handle Buffer (if in a Node.js environment)
  if (typeof Buffer !== 'undefined' && Buffer.isBuffer(obj)) return Buffer.from(obj);

  // Check for circular references
  if (hash.has(obj)) return hash.get(obj);

  // Create the cloned object based on its constructor
  const clone = new obj.constructor();
  hash.set(obj, clone);

  for (const key in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(key)) {
      clone[key] = deepClone(obj[key], hash);
    }
  }

  return clone;
};

export const stringToColour = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = '';
  for (let j = 0; j < 3; j++) {
    const value = (hash >> (j * 8)) & 0xFF;
    colour += ('00' + value.toString(16)).slice(-2);
  }
  return colour;
};

/**
 * @description Update nested objects mostly state
 * @param {object} obj - state object
 * @param {string} path - string seperated with . to access key nested object
 * @param {*} value - new value to update
 * @returns {object} - updated state object
*/
export const updateNestedObject = (obj, path, value) => {
  const keys = path.split('.').filter((key) => key);
  const newObj = deepClone(obj);

  keys.reduce((acc, key, index) => {
    if (index === keys.length - 1) {
      acc[key] = value;
    } else {
      acc[key] = acc[key] ? { ...acc[key] } : {};
    }
    return acc[key];
  }, newObj);
  return newObj;
};

export const returnNestedValue = (obj, path) => {
  const keys = path.split('.');
  return keys.reduce((acc, key) => acc[key], obj);
};

// i dont like this function, one day i'd like to fix all the filenames better
// filename1 is the new upload with the builder key on the front and size on the end
// filename2 is the original upload without the size attached
// returns `gab_abc_1_2_3_asdf_300x250.jpg` matches `abc_1_2_3_asdf.jpg`
export const matchFileName = (filename1, filename2) => {
  const match1 = filename1.split('_').slice(0, -1).join('_'); // remove size and filetype
  const match2 = filename2.split('_').slice(0, -1).join('_'); // remove size and filetype
  return filename1.includes(filename2.split('.').shift()) || match1 === match2;
};
