import React, { Fragment, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { ComposedChart as ComposedChartRechart, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Surface, Symbols, ReferenceLine, Bar, Line, Cell } from 'recharts';
import { isMobile } from 'react-device-detect';

import { darken, alpha } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { dataFormatter } from 'core/Components/Tables/TableFilters';
import Loader from 'core/Components/Loader';
import { capitalizeSentence } from 'core/Functions/utilities';

const useStyles = makeStyles((theme) => ({
  paper: (props) => ({
    margin: '0 auto',
    height: `${props.height}px !important`,
    maxWidth: 1500,
    '& .recharts-cartesian-axis': {
      fontSize: '1rem',
      [theme.breakpoints.down('md')]: {
        fontSize: '0.8rem',
      },
    }
  }),
  ucfirst: {
    textTransform: 'capitalize',
    fontSize: isMobile ? '0.7rem' : 'inherit',
  },
  container: {
    display: 'flex',
    padding: isMobile ? '0 0.5rem' : '0 1rem',
    width: '100%',
    flexDirection: isMobile ? 'column' : 'row',
  },
  responsiveContainer: {
    display: 'flex',
    flexDirection: isMobile ? 'column' : 'row',
  }
}));

const ComposedChart = ({
  data,
  height = 300,
  sections = [],
  hidden = [],
  loading = false,
  maxBarSize = 50,
  // type = 'number',
  formatYValue = (val) => {
    return val;
  },
  formatTooltip = (value, name, props) => { // value, name, props
    let newValue = value;
    if (name.includes('profit') || name.includes('revenue') || name.includes('spend')) {
      newValue = dataFormatter.currency({ value });
    } else if (Number.isInteger(value)) {
      newValue = dataFormatter.number({ value });
    }
    const newName = capitalizeSentence(name.replace('_', ' '));
    return [newValue, newName, props];
  },
  showReferenceLine = false,
  showNegativeAsRed = false,
  offsetYTooltip = 0,
  children,
}) => {
  const [focusBar, setFocusBar] = useState(null);
  const [mouseLeave, setMouseLeave] = useState(true);
  const [disabledSection, setDisabledSection] = useState(hidden);
  const classes = useStyles();

  const handleClick = (dataKey) => {
    if (disabledSection.includes(dataKey)) {
      setDisabledSection(disabledSection.filter(obj => obj !== dataKey));
    } else {
      setDisabledSection(disabledSection.concat(dataKey));
    }
  };

  const renderCustomizedLegend = (section) => {
    const { payload } = section;

    return (
      <div className="customized-legend">
        {payload.map((entry) => {
          const { dataKey, color } = entry;

          if (dataKey.includes('Avg')) {
            return (null);
          }

          const active = disabledSection.includes(dataKey);
          const style = {
            marginRight: 10,
            color: active ? '#AAA' : '#000'
          };

          return (
            <span
              key={dataKey}
              onClick={() => handleClick(dataKey)}
              style={style}
              className={classes.ucfirst}
              aria-hidden="true"
            >
              <Surface width={10} height={10} viewBox={{ x: 0, y: 0, width: 10, height: 10 }}>
                <Symbols cx={5} cy={5} type="circle" size={50} fill={color} />
                {active && (
                  <Symbols
                    cx={5}
                    cy={5}
                    type="circle"
                    size={25}
                    fill="#FFF"
                  />
                )}
              </Surface>
              <span>&nbsp;{dataKey}</span>
            </span>
          );
        })}
      </div>
    );
  };

  const formatYAxis = (value, type) => {
    switch (type) {
      case 'currency':
        return dataFormatter.currency({ value });
      case 'percent':
        return dataFormatter.percent({ value });
      case 'number':
        return dataFormatter.number({ value });
      default:
        return formatYValue(`${value}`);
    }
  };

  const sectionToShow = useMemo(() => {
    if (!sections) return [];
    return sections.filter(n => !disabledSection.includes(n.key));
  }, [sections, disabledSection]);

  const { maxSessionsClicks, maxProfit } = useMemo(() => {
    let maxSessionsClicks = 0;
    let maxProfit = 0;

    data.forEach((item) => {
      // Checks sessions and clicks
      let sessionsClicks = item.total_sessions || 0;
      if (sessionsClicks < item.clicks) sessionsClicks = item.clicks;

      if (sessionsClicks > maxSessionsClicks) {
        maxSessionsClicks = sessionsClicks;
      }

      // Checks revenue, spend and profit
      let leftYAxisMax = item.profit;
      if (!disabledSection.includes('spend') && item.spend > leftYAxisMax) {
        leftYAxisMax = item.spend;
      }
      if (!disabledSection.includes('revenue') && item.revenue > leftYAxisMax) {
        leftYAxisMax = item.revenue;
      }

      if (leftYAxisMax > maxProfit) {
        maxProfit = leftYAxisMax;
      }
    });

    return { maxSessionsClicks, maxProfit };
  }, [data, disabledSection]);

  if (!sections) {
    return <Fragment />;
  }

  const coloredCell = (section, entry, index) => {
    const hasData = !!((entry.spend && entry.spend > 0));
    let color = !hasData ? '#D3D3D3' : entry[section.barDataKey] > 0 ? '#0f8e0f' : '#d61a1a';
    color = (focusBar === index || mouseLeave) ? color : alpha(color, 0.5);
    return (
      <Cell
        {...section}
        fill={color}
        stroke={color}
      />
    );
  };

  const barSectionToShow = sectionToShow.filter((section) => section.barDataKey !== undefined);

  return (
    <Loader loading={loading} noBlur noStyle>
      <ResponsiveContainer className={classes.paper} height={height}>
        <ComposedChartRechart
          data={data}
          margin={{ top: 5, right: 30, left: 20, bottom: 7 }}
          maxBarSize={maxBarSize}
          barGap={2}
          onMouseMove={(state) => {
            if (state.isTooltipActive) {
              setFocusBar(state.activeTooltipIndex);
              setMouseLeave(false);
            } else {
              setFocusBar(null);
              setMouseLeave(true);
            }
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis yAxisId="left" orientation="left" tickFormatter={(value) => formatYAxis(value, 'currency')} domain={[0, () => Math.ceil(maxProfit * 1.05) || 1]} /> {/* If the data is not a number, it may show bar bigger than the 'maxValue' */}
          <YAxis yAxisId="right" orientation="right" tickFormatter={(value) => formatYAxis(value, 'number')} domain={[0, () => Math.ceil(maxSessionsClicks * 1.05) || 1]} />
          <Tooltip formatter={formatTooltip} position={{ y: offsetYTooltip }} cursor />

          {showReferenceLine && <ReferenceLine y={0} stroke="#000" />}

          <Legend
            payload={sections.map(section => ({
              value: 1,
              dataKey: section.key,
              color: section.stroke
            }))}
            content={renderCustomizedLegend}
          />

          {barSectionToShow.map((section) => (
            <Bar key={section.barDataKey} dataKey={section.barDataKey} fill={section.fill} yAxisId="left">
              {data.map((entry, index) => (
                <React.Fragment key={`cell-${entry}`}>
                  {barSectionToShow.length === 1 && showNegativeAsRed ? coloredCell(section, entry, index) : <Cell {...section} />}
                </React.Fragment>
              ))}
            </Bar>
          ))}

          {sectionToShow.map((section) => (
            section.lineDataKey !== undefined && <Line key={`${section.lineDataKey}`} dataKey={`${section.lineDataKey}`} dot={false} fill={darken(section.fill, 0.3)} stroke={darken(section.stroke, 0.5)} yAxisId="right" />
          ))}

          <ReferenceLine y={0} yAxisId="left" stroke="#000" />

        </ComposedChartRechart>
      </ResponsiveContainer>
      <div>
        {children}
      </div>
    </Loader>
  );
};

ComposedChart.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.array.isRequired,
  sections: PropTypes.array,
  height: PropTypes.number,
  maxBarSize: PropTypes.number,
  hidden: PropTypes.array,
  formatTooltip: PropTypes.func,
  itemSorter: PropTypes.func,
  formatYValue: PropTypes.func,
  // type: PropTypes.string,
  showReferenceLine: PropTypes.bool,
  showNegativeAsRed: PropTypes.bool,
  showValueOnTop: PropTypes.bool,
  offsetYTooltip: PropTypes.number,
  children: PropTypes.any,
};

ComposedChart.defaultProps = {
  height: 300,
  maxBarSize: 50,
  hidden: [],
  loading: false,
  sections: undefined,
  formatTooltip: undefined,
  itemSorter: undefined,
  formatYValue: (val) => {
    return val;
  },
  // type: 'number',
  showReferenceLine: false,
  showNegativeAsRed: true,
  showValueOnTop: false,
  offsetYTooltip: 0,
  children: undefined,
};

export default ComposedChart;
