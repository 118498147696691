import React from 'react';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import authClient, { getLogoutMessage } from 'services/auth';

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  button: {
    margin: theme.spacing(1)
  },
  center: {
    textAlign: 'center',
    paddingTop: theme.spacing(10)
  },
  message: {
    paddingTop: theme.spacing(2)
  }
});

const Login = props => {
  const { classes } = props;

  return (
    <div className={classes.root}>
      <div className={classes.center}>
        <Button variant="contained" size="large" onClick={() => authClient.signIn()}>Log In</Button>
        <div className={classes.message}>{getLogoutMessage() || ''}</div>
      </div>
    </div>
  );
};

Login.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Login);
