import moment from 'moment';

export const hours = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10',
  '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];

export const getDateRange = (startDate, endDate) => {
  const dates = [];

  const currDate = moment(startDate).startOf('day');
  const lastDate = moment(endDate).startOf('day');

  dates.push(currDate.format('YYYY-MM-DD'));
  while (currDate.add(1, 'days').diff(lastDate) < 0) {
    dates.push(currDate.format('YYYY-MM-DD'));
  }
  dates.push(lastDate.format('YYYY-MM-DD'));

  return dates;
};

export const aggregateHourlyData = (key, startDate, endDate, data, hourlyData) => {
  const baseTraffic = {};
  const daysBetween = moment(endDate).diff(moment(startDate), 'days');
  if (daysBetween > 1) {
    const dates = getDateRange(startDate, endDate);
    hourlyData.forEach(row => {
      if (baseTraffic[row[key]] === undefined) {
        baseTraffic[row[key]] = {};
        dates.forEach(date => {
          baseTraffic[row[key]][date] = {
            impressions: 0,
            clicks: 0,
            conversions: 0,
            revenue: 0
          };
        });
      }
      const date = row.hour.substr(0, 10);
      baseTraffic[row[key]][date].impressions += row.impressions;
      baseTraffic[row[key]][date].clicks += row.clicks;
      baseTraffic[row[key]][date].conversions += row.conversions;
      baseTraffic[row[key]][date].revenue += row.revenue;
    });
  } else {
    hourlyData.forEach(row => {
      if (baseTraffic[row[key]] === undefined) {
        baseTraffic[row[key]] = {};
        hours.forEach(hour => {
          baseTraffic[row[key]][hour] = {
            impressions: 0,
            clicks: 0,
            conversions: 0,
            revenue: 0
          };
        });
      }
      const hour = moment(row.hour).tz('UTC').format('HH');
      baseTraffic[row[key]][hour].impressions += row.impressions;
      baseTraffic[row[key]][hour].clicks += row.clicks;
      baseTraffic[row[key]][hour].conversions += row.conversions;
      baseTraffic[row[key]][hour].revenue += row.revenue;
    });
  }

  data.forEach(row => {
    if (baseTraffic[row[key]] !== undefined) {
      Object.keys(baseTraffic[row[key]]).forEach(date => {
        baseTraffic[row[key]][date].ecpm = baseTraffic[row[key]][date].impressions === 0 ? 0 : ((baseTraffic[row[key]][date].revenue / baseTraffic[row[key]][date].impressions) * 1000).toFixed(2)
      })
      row.aggregatedData = baseTraffic[row[key]];
      //  console.log('wtf', daysBetween, row);
      // row.trafficData = Object.keys(baseTraffic[row[key]]).map(hoxur => ({ stamp: hour, count: baseTraffic[row[key]][hour].count }))
      //  .sort((a, b) => a.stamp.localeCompare(b.stamp));
    } else {
      row.aggregatedData = {};
      // row.trafficData = [];
    }
  });

  return data;
};

export const aggregateHourlyCampaignData = (startDate, endDate, data, hourlyData) => {
  const baseTraffic = {};
  const daysBetween = moment(endDate).diff(moment(startDate), 'days');
  if (daysBetween > 1) {
    const dates = getDateRange(startDate, endDate);
    hourlyData.forEach(row => {
      const key = `${row.site}_${row.source}_${row.campaign}`
      if (baseTraffic[key] === undefined) {
        baseTraffic[key] = {};
        dates.forEach(date => {
          baseTraffic[key][date] = { sessions: 0, revenue: 0, spend: 0, profit: 0 }
        });
      }
      const date = row.date.substr(0, 10);
      baseTraffic[key][date].sessions += row.total_sessions;
      baseTraffic[key][date].revenue += row.total_revenue;
      baseTraffic[key][date].spend += row.spend;
      baseTraffic[key][date].profit += row.profit;
    });
  } else {
    hourlyData.forEach(row => {
      const key = `${row.site}_${row.source}_${row.campaign}`
      if (baseTraffic[key] === undefined) {
        baseTraffic[key] = {};
        hours.forEach(hour => {
          baseTraffic[key][hour] = { sessions: 0, revenue: 0, spend: 0, profit: 0 }
        });
      }

      const hour = `${row.hour}`.padStart(2, '0');
      baseTraffic[key][hour].sessions += row.total_sessions;
      baseTraffic[key][hour].revenue += row.total_revenue;
      baseTraffic[key][hour].spend += row.spend;
      baseTraffic[key][hour].profit += row.profit;
    });
  }

  data.forEach(row => {
    const key = `${row.site}_${row.source}_${row.campaign}`
    if (row.aggregatedData === undefined) {
      row.aggregatedData = {}
    }
    if (baseTraffic[key] !== undefined) {
      row.aggregatedData = baseTraffic[key];
      //  console.log('wtf', daysBetween, row);
      // row.trafficData = Object.keys(baseTraffic[row[key]]).map(hoxur => ({ stamp: hour, count: baseTraffic[row[key]][hour].count }))
      //  .sort((a, b) => a.stamp.localeCompare(b.stamp));
    } else {
      row.aggregatedData = {};
      // row.trafficData = [];
    }
  });

  return data;
}

export default {
  getDateRange,
  aggregateHourlyData,
  aggregateHourlyCampaignData
};
