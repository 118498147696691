import axios from 'axios';
import authClient from 'services/auth';

let BASE_URL = 'http://localhost:3030';
// let BASE_URL = 'https://api-v2.amob.io';

if (process.env.NODE_ENV === 'production') {
  BASE_URL = 'https://api-v2.amob.io';
}

// Set the base url (will only be applied for relative paths)
const client = axios.create({
  baseURL: BASE_URL
});

// const requests = [];

/**
 * Wrapper for axios request.
 * @param {object} options - axios config
 * @param {object} [extraHeaders] - additional headers
 * @param {boolean} [useAuth] - send auth token with request
 * @param {string} [contentType]
 * @returns {Promise}
 */
function request(options, extraHeaders, useAuth = true, contentType) {

  if (useAuth && !authClient.isAuthenticated()) {
    return Promise.reject(new Error('Unable to complete request: missing access token.'));
  }

  const token = authClient.getAccessToken();
  const headers = {
    'Content-Type': contentType || 'application/json',
    ...extraHeaders,
    ...(useAuth && { Authorization: 'Bearer ' + token })
  };

  const config = { headers, ...options };
  /*
  const key = JSON.stringify(options);
  const matches = requests.filter(row => row === key);
  if (matches.length > 0) {
    console.log('ERR', key, 'Run', matches.length, 'Times');
  }
  requests.push(key);
  if (requests.length > 100) {
    requests.shift();
  }
  */
  return client(config);
}

export default request;
