import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export default function MiniTable(data) {
  if (data.data && Object.keys(data.data).length === 0) return <div />;

  data.data.sort((a, b) => {
    // Turn your strings into dates, and then subtract them
    // to get a value that is either negative, positive, or zero.
    return (new Date(b.date) - new Date(a.date));
  })

  /* Stripe the table */
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 15

    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
      color: theme.palette.common.purple
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 1,
    },
  }));

  const getColour = (value) => {
    if (value <= 0) {
      return 'rgb(255, 0, 0)';
    }
    return 'rgb(0, 200, 0)';

  }

  const getSpendBudgetColor = (data) => {
    const budgetNumber = Number(data.budget);
    const spendNumber = Number(data.spend);
    const difference = budgetNumber - spendNumber;
    if (budgetNumber === 0) {
      return 'rgb(211, 211, 211)';
    }
    if (spendNumber >= budgetNumber) {
      return 'rgb(255, 0, 0)';
    }
    if (difference <= (budgetNumber * (0.15))) {
      return 'rgb(255, 128, 0)';
    }
    if (difference <= (budgetNumber * (0.25))) {
      return 'rgb(189 174 19)';
    }
    return '';
  }
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700, paddingBottom: 0 }} size="small">
        <TableHead>
          <TableRow>
            <StyledTableCell>Date</StyledTableCell>
            <StyledTableCell align="right">Budget</StyledTableCell>
            <StyledTableCell align="right">Spend</StyledTableCell>
            <StyledTableCell align="right">Total Profit</StyledTableCell>
            <StyledTableCell align="right">Total Margin</StyledTableCell>
            <StyledTableCell align="right">CPC</StyledTableCell>
            <StyledTableCell align="right">Bid Target</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.data.map((row) => (
            <StyledTableRow key={Math.random()}>
              <StyledTableCell component="th" scope="row">
                {row.date.split('T')[0]}
              </StyledTableCell>
              <StyledTableCell align="right">${row.budget?.toFixed(0)}</StyledTableCell>
              <StyledTableCell align="right" style={{ color: getSpendBudgetColor(row) }}>${row.spend}</StyledTableCell>
              <StyledTableCell align="right" style={{ color: getColour(row?.totalProfit) }}>${row?.totalProfit.toFixed(2)}</StyledTableCell>
              {/* ${Number.isNaN(row.totalProfit) ? row.totalProfit.toFixed(2) : '0' }</StyledTableCell> */}
              <StyledTableCell align="right" style={{ color: getColour(row.totalMargin) }}>{Number.isNaN(row.totalMargin) ? '0' : row.totalMargin}%</StyledTableCell>
              <StyledTableCell align="right">${row.cpc?.toFixed(2)}</StyledTableCell>
              <StyledTableCell align="right">${row.bidTarget?.toFixed(2)}</StyledTableCell>

            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
