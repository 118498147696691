import { Box, Button, Grid, LinearProgress, Modal, TextField, Tooltip, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import AccountsAPI from '../../../services/api/accounts';
import { useFacebookAccount } from './FacebookAccountProvider';

const useStyles = makeStyles(() => ({
  editModalContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '60%',
  },
  editModalBox: {
    width: '100%',
    padding: '20px',
    background: 'white',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-15%, -50%)',
  },
  editHeader: {
    marginBottom: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& svg': { cursor: 'pointer' },
  },
  dialogInput: { width: '100%' },
  dialogCreateButton: { width: '100%', margin: '10px 0' },
}));

const FacebookAccountActionModal = () => {
  const { state, setState, loading, setIsLoading, fetchData, setSnackBar } = useFacebookAccount();
  const classes = useStyles();

  async function handleSaveFacebookAccount(isEditing = false) {
    const action = isEditing ? 'editAccount' : 'createAccount';
    const loadingMessage = isEditing ? 'editing-account' : 'creating-account';
    const successMessage = `Account successfully ${isEditing ? 'edited' : 'created'}.`;

    setIsLoading(loadingMessage);

    try {
      await AccountsAPI.facebook[action](state.account);
      setSnackBar({ message: successMessage, status: 'success', open: true });
    } catch (error) {
      const errorMessage = error?.response?.data?.[1];
      if (error?.response?.data?.[0] > 300 && errorMessage) {
        setSnackBar({ message: errorMessage, status: 'error', open: true });
        return;
      }
    }

    await fetchData();
    setState(undefined);
    setIsLoading(undefined);
  }

  function handleOnChangeInput(e, key) {
    const value = e.target.value;
    setState((oldState) => {
      if (!oldState) oldState = { account: {} };
      oldState.account[key] = value;
      return { ...oldState };
    });
  }

  const isOpen = useMemo(() => {
    return Boolean(state?.account);
  }, [state]);

  return (
    <Modal open={isOpen} onClose={() => setState(undefined)} className={classes.editModalContainer}>
      <Box className={classes.editModalBox}>
        <div className={classes.editHeader} >
          <Typography variant="h6" component="h2">
            {state?.action === 'editing' ? 'Editing' : 'Creating'} account
          </Typography>

          <Tooltip title="Clear all fields">
            <CleaningServicesIcon onClick={() => setState({ account: {} })} />
          </Tooltip>
        </div>

        <Grid container gap={1} className={classes.uploader}>
          <Grid item xs={12}>
            <TextField
              className={classes.dialogInput}
              label="Name"
              onChange={(e) => handleOnChangeInput(e, 'name')}
              InputLabelProps={{ variant: 'outlined' }}
              value={state?.account?.name || ''}
            />
          </Grid>

          <Grid item xs={12} >
            <TextField
              className={classes.dialogInput}
              label="Site"
              onChange={(e) => handleOnChangeInput(e, 'site')}
              InputLabelProps={{ variant: 'outlined' }}
              value={state?.account?.site || ''}
            />
          </Grid>

          <Grid item xs={12} >
            <TextField
              className={classes.dialogInput}
              label="Site Key"
              onChange={(e) => handleOnChangeInput(e, 'site_key')}
              InputLabelProps={{ variant: 'outlined' }}
              value={state?.account?.site_key || ''}
            />
          </Grid>

          <Grid item xs={12} >
            <TextField
              className={classes.dialogInput}
              label="Account Id"
              onChange={(e) => handleOnChangeInput(e, 'account_id')}
              InputLabelProps={{ variant: 'outlined' }}
              value={state?.account?.account_id || ''}
            />
          </Grid>

          <Grid item xs={12} >
            <TextField
              className={classes.dialogInput}
              label="Access Token"
              onChange={(e) => handleOnChangeInput(e, 'access_token')}
              InputLabelProps={{ variant: 'outlined' }}
              value={state?.account?.access_token || ''}
            />
          </Grid>

          <Grid item xs={12} >
            <TextField
              className={classes.dialogInput}
              label="Page Id"
              onChange={(e) => handleOnChangeInput(e, 'page_id')}
              InputLabelProps={{ variant: 'outlined' }}
              value={state?.account?.page_id || ''}
            />
          </Grid>
        </Grid>

        <Button
          disabled={loading === 'creating-account' || loading === 'editing-account'}
          onClick={() => handleSaveFacebookAccount(state?.action === 'editing')}
          size="small"
          variant="contained"
          color="primary"
          className={classes.dialogCreateButton}
        >
          {state?.action === 'editing' ? 'Edit' : 'Create'} account
        </Button>
        {(loading === 'creating-account' || loading === 'editing-account') && <LinearProgress />}
      </Box>
    </Modal>
  );
};

// FacebookAccountActionModal.propTypes = {
// };

export default FacebookAccountActionModal;
