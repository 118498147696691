import React, { useEffect, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import ImagerHover from 'core/Components/ImageHover';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import IconButton from '@mui/material/IconButton';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  customPaddingArrows: {
    padding: '2px'
  }
});

const ImageSlider = ({ width, height, autoPlay, autoPlayTime, images, widthHover, heightHover }) => {
  const [items, setItems] = useState(images);
  const [slide, setSlide] = useState(0);
  const classes = useStyles();

  useEffect(() => {
    setItems(images);
  }, [images]);

  const hasMoreImages = useMemo(() => {
    if (items.length > 1) return true;
    return false;
  }, [items.length]);

  const changeSlide = useCallback((direction = 1) => {
    let slideNumber = 0;

    if (slide + direction < 0) {
      slideNumber = items.length - 1;
    } else {
      slideNumber = (slide + direction) % items.length;
    }

    setSlide(slideNumber);
  }, [items.length, slide]);

  useEffect(() => {
    if (!autoPlay) return;

    const interval = setInterval(() => {
      changeSlide(1);
    }, autoPlayTime);

    // eslint-disable-next-line consistent-return
    return () => {
      clearInterval(interval);
    };
  }, [autoPlay, autoPlayTime, changeSlide, items.length, slide]); // when images change we start timer

  return (
    <div className={classes.container}>
      {hasMoreImages
        && (
          <IconButton className={classes.customPaddingArrows} onClick={() => changeSlide(-1)}>
            <ChevronLeftIcon />
          </IconButton>
        )}

      <ImagerHover alt="" image={items[slide]} width={width} height={height} widthHover={widthHover} heightHover={heightHover} />
      {hasMoreImages
        && (
          <IconButton className={classes.customPaddingArrows} onClick={() => changeSlide(1)}>
            <ChevronRightIcon />
          </IconButton>
        )}

    </div >
  );
};

ImageSlider.propTypes = {
  images: PropTypes.array,
  autoPlay: PropTypes.bool,
  autoPlayTime: PropTypes.number,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  widthHover: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  heightHover: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ImageSlider.defaultProps = {
  images: [],
  autoPlay: false,
  autoPlayTime: 5000,
  width: '50px',
  height: 'auto',
  widthHover: 'auto',
  heightHover: 'auto',
};

function propsAreEqual(prev, next) {
  const result = JSON.stringify(prev.images) === JSON.stringify(next.images);
  return result;
}

export default React.memo(ImageSlider, propsAreEqual);
