import React, { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useLocation, Link } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import makeStyles from '@mui/styles/makeStyles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Box } from '@mui/material';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  stickyHeader: {
    position: 'fixed',
    zIndex: 100000,
  },
  selected: {
    '&.MuiTab-root': {
      color: 'inherit',
      opacity: 0.7,
      whiteSpace: 'nowrap',
    },
    '&.Mui-selected': {
      color: theme.palette.secondary.main,
      opacity: 1,
    },
    '&:focus': {
      outline: 'none',
      borderBottom: 'none',
      textDecoration: 'none',
    }
  },
}));

const SimpleTabs = ({ children, options, defaultSelected,
  // useIcons
}) => {
  const selected = useRef(defaultSelected);
  const { pathname, search } = useLocation();
  const classes = useStyles();

  const handleChange = (event, name) => {
    selected.current = name;
  };
  const allTabs = useMemo(() => {
    const tabs = [];

    for (const tab of options) {
      const props = {
        value: tab.value,
        label: tab.label,
        key: tab.value,
      };

      tabs.push(<Tab {...props} classes={{ root: classes.selected }} component={Link} to={`/${tab.value}`} />);
    }
    if ((pathname + search).substring(1) !== selected.current) {
      selected.current = (pathname + search).substring(1);
    }
    // some routes have optional /:id? params, so we need to remove them to match the tab value
    selected.current = selected.current.replace(/(.*?)(\/[\d,]+)/, '$1')
    return tabs;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classes.selected, options,
    // useIcons
  ])

  return (
    <div className={classes.root}>
      {!!allTabs.length && (
        <AppBar position="static" className={classes.stickyHeader}>
          <Tabs
            value={selected.current}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons={false}
          >
            {allTabs}
          </Tabs>
        </AppBar>
      )}
      <Box pt={4}>
        {children}
      </Box>
    </div>
  );
}

SimpleTabs.propTypes = {
  options: PropTypes.array.isRequired,
  defaultSelected: PropTypes.string.isRequired,
  children: PropTypes.object.isRequired,
  useIcons: PropTypes.bool
};

SimpleTabs.defaultProps = {
  useIcons: false,
};

export default SimpleTabs;
