import React, { useEffect, useReducer, useMemo } from 'react';
import PropTypes from 'prop-types';

import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import DialogTitle from '@mui/material/DialogTitle';
import makeStyles from '@mui/styles/makeStyles';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';

import ReactSelect from 'core/Components/ReactSelect';

const useStyles = makeStyles({
  containerDrawer: {
    display: 'flex',
    padding: '0 1rem',
    gap: '1rem',
    width: '550px',
    height: '225px'
  },
  subtitle: {
    fontSize: '10px'
  },
  containerEditable: {
    width: '100%'
  },
  rowDisplay: {
    display: 'flex',
    gap: '1rem'
  },
  containerRow: {
    padding: '1rem 10px'
  },
  containerButtons: {
    justifyContent: 'flex-end',
    display: 'flex',
    gap: '1rem',
    padding: '10px'
  },
  flexStart: {
    alignItems: 'flex-start',
  },
});

const strategiesDisplay = [
  { name: 'Target CPA', id: 'TARGET_CPA' },
  { name: 'Maximize Conversions', id: 'MAXIMIZE_CONVERSIONS' },
  { name: 'Maximize Clicks', id: 'MAXIMIZE_CLICKS' }
];

const initialState = {
  newBudget: '',
  strategy: { value: 0, label: '' },
  bid: '',
  bidTarget: ''
};

const DrawerBudgetBatch = ({ isDrawer, handleCancelDrawer, dataToEditBatch, handleApplyNewBudget }) => {
  const [state, setState] = useReducer((oldState, newState) => ({ ...oldState, ...newState }), initialState);
  const { newBudget, strategy, bid, bidTarget } = state;
  const classes = useStyles();

  /** This component receivs an array with a lot of values but all of them MUST be have the same source */
  const editingSource = useMemo(() => {
    if (dataToEditBatch.length === 0) return null;
    return dataToEditBatch[0].source;
  }, [dataToEditBatch]);
  // console.log('editingSource:', editingSource)

  useEffect(() => {
    if (isDrawer) {
      setState(initialState);
    }
  }, [isDrawer]);

  const handleChangeBudget = (event) => {
    const value = event.target.value;
    if (Number.isFinite(Number(value)) && !Number.isNaN(Number(value))) {
      setState({ newBudget: value });
    }
  };

  const handleChangeBid = (event) => {
    const value = event.target.value;
    if (Number.isFinite(Number(value)) && !Number.isNaN(Number(value))) {
      setState({ bid: value });
    }
  };

  const handleChangeBidTarget = (event) => {
    const value = event.target.value;
    if (Number.isFinite(Number(value)) && !Number.isNaN(Number(value))) {
      setState({ bidTarget: value });
    }
  };

  const handleSelectChange = key => selectedEntry => {
    if (key === 'strategy') {
      setState({ strategy: selectedEntry });
    }
  };

  const handleApply = async () => {

    // console.log('dataToEditBatch', dataToEditBatch)

    await Promise.all(dataToEditBatch.map(dataToEdit => {
      let data = {};
      if (newBudget && Number.isFinite(Number(newBudget)) && !Number.isNaN(Number(newBudget))) {
        data = { ...data, newBudget };
      }

      if (strategy.value !== 0) {
        data = { ...data, newStrategy: strategy.value };
      }

      if (bidTarget && Number.isFinite(Number(bidTarget)) && !Number.isNaN(Number(bidTarget))) {
        data = { ...data, newBidTarget: bidTarget };
      }

      if (bid && Number.isFinite(Number(bid)) && !Number.isNaN(Number(bid))) {
        const url = dataToEdit.url?.replace(/(bid=[\d.]+)/, `bid=${bid}`);
        if (dataToEdit.url !== url) {
          data = { ...data, newUrl: url };
        }
      }

      // console.log('saving', dataToEdit, data)
      return handleApplyNewBudget(dataToEdit, data);
    }));
  };

  const hasError = useMemo(() => {
    const btarget = Number(bidTarget);
    if (editingSource === 'ga') {
      if (strategy.value === 'TARGET_ROAS' && Number.isFinite(btarget) && !Number.isNaN(btarget) && btarget <= 0) {
        return { bidTarget: 'Bid Target must be greater than 0 for TARGET_ROAS' };
      }
    }

    return null;
  }, [bidTarget, editingSource, strategy.value]);

  return (
    <Dialog
      maxWidth="xl"
      open={isDrawer}
      onClose={handleCancelDrawer}
    >
      <DialogTitle id="responsive-dialog-title" sx={{ paddingBottom: 0, textAlign: 'center' }}>
        Batch Campaign Adjuster
        <div className={classes.subtitle}>Editing {dataToEditBatch.length} campaigns</div>
      </DialogTitle>

      <Divider sx={{ margin: '10px 0', backgroundColor: '#adabab' }} />

      <div className={classes.containerDrawer}>

        <div className={classes.containerEditable}>
          <div className={`${classes.rowDisplay} ${classes.containerRow}`}>
            <TextField
              label="New Budget"
              value={newBudget}
              onChange={handleChangeBudget}
              variant="standard"
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
            />
          </div>

          <div className={`${classes.rowDisplay} ${classes.containerRow} ${classes.flexStart}`}>
            {editingSource === 'ga'
              && (
                <FormControl required fullWidth>
                  <ReactSelect
                    label="Strategy"
                    name="strategy"
                    data={dataToEditBatch}
                    options={dataToEditBatch.advertising_type === 'DISPLAY' ? strategy : strategiesDisplay}
                    handleChange={handleSelectChange('strategy')}
                  />
                </FormControl>
              )}
            <TextField
              label={editingSource === 'ga' ? 'Revenue Pixel(bid=)' : 'Current CPC'}
              value={bid}
              onChange={handleChangeBid}
              variant="standard"
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
            />
            <TextField
              label={editingSource === 'ga' ? 'Bid Target' : 'New CPC'}
              value={bidTarget}
              onChange={handleChangeBidTarget}
              error={Boolean(hasError) && Boolean(hasError.bidTarget)}
              helperText={hasError && hasError.bidTarget}
              variant="standard"
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
            />
          </div>

        </div>
      </div>

      <Divider sx={{ margin: '10px 0', backgroundColor: '#adabab' }} />

      <div className={classes.containerButtons}>
        <Button variant="outlined" color="error" onClick={handleCancelDrawer}>Cancel</Button>
        <Button
          variant="outlined"
          color="success"
          onClick={handleApply}
          disabled={Boolean(hasError)}
        >
          Apply
        </Button>
      </div>

    </Dialog>
  );
};

DrawerBudgetBatch.propTypes = {
  dataToEditBatch: PropTypes.array.isRequired,
  isDrawer: PropTypes.bool.isRequired,
  handleCancelDrawer: PropTypes.func.isRequired,
  handleApplyNewBudget: PropTypes.func.isRequired,
};

function propsAreEqual(prev, next) {
  return JSON.stringify(prev.dataToEditBatch) === JSON.stringify(next.dataToEditBatch) && prev.isDrawer === next.isDrawer;
}

export default React.memo(DrawerBudgetBatch, propsAreEqual);
