import React, { useMemo, useReducer, useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useRollbarPerson } from '@rollbar/react';
import { isMobile } from 'react-device-detect';
import PropTypes from 'prop-types';

import DarkModeIcon from '@mui/icons-material/DarkMode';
import PaletteIcon from '@mui/icons-material/Palette';
import LogoutIcon from '@mui/icons-material/Logout';
import IconButton from '@mui/material/IconButton';
import makeStyles from '@mui/styles/makeStyles';
import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person';
import { darken } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import List from '@mui/material/List';

import sundayMarketLogo from 'images/sundaymarketlogo.png';
import sundayMarket from 'images/sundaymarket.png';
// import juicyxCompleteLogo from 'images/juicyx.png';
import adgardenCompleteLogo from 'images/AdGarden.png';
// import juicyxLogo from 'images/juicyxlogo.png';
import adgardenLogo from 'images/AdGarden-v2.png';

import TitleBarButtons from 'core/Components/NavController/TitleBarButtons';
import ThemePicker from 'core/Components/ThemePicker';
import authClient from 'services/auth';
import config from 'core/Utilities/config';
import { Avatar, Menu, MenuItem, Tooltip, } from '@mui/material';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    zIndex: 1,
    position: 'absolute',
    display: 'flex',
    minHeight: '100%',
    width: '100%',
    height: 'auto',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    position: 'fixed',
  },
  appBarShift: {
    zIndex: theme.zIndex.drawer + 1,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    position: 'fixed',
  },
  menuButton: {
    marginLeft: 8,
    padding: isMobile ? '0' : '',
    // marginRight: 36,
  },
  drawerPaper: {
    position: 'fixed',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    height: 'calc(100%)',
    backgroundColor: darken(theme.palette.primary.main, 0.2),
    color: theme.palette.primary.contrastText,
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    height: 'calc(100%)',
    backgroundColor: darken(theme.palette.primary.main, 0.2),
    color: theme.palette.primary.contrastText,
  },
  toolbarMobile: {
    display: 'flex',
    padding: '0 8px',
    alignItems: 'center',
    justifyContent: 'flex-end',
    ...theme.mixins.toolbar,
  },
  toolbarDesktop: {
    height: 80,
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    overflowX: 'auto',
    paddingLeft: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(9),
    },
    [theme.breakpoints.down('md')]: {
      paddingLeft: '0px',
    },
    transition: theme.transitions.create('padding', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  contentShift: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    overflowX: 'auto',
    paddingLeft: drawerWidth,
    transition: theme.transitions.create('padding', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  spacer: {
    flexGrow: 1,
  },
  logo: {
    color: 'white',
    fontSize: '40px',
    overflowX: 'auto',
  },
  containerHeader: {
    justifyContent: 'space-between',
    gap: '0.5rem',
    height: !isMobile && '80px',
  },
  flexContainer: {
    display: 'flex',
  },
  flexGrow: {
    flexGrow: 1,
  },
  flexButton: {
    width: '32px',
    alignSelf: 'center',
  },
  accountMenu: {
    justifyContent: 'center',
    display: 'flex',
    padding: theme.spacing(1),
    margin: theme.spacing(1),
  },

}));

const darkModeColors = {
  palette: {
    primary: {
      light: '#000000',
      main: '#000000',
      dark: '#000000',
      contrastText: '#fff',
    },
    secondary: {
      light: '#92f9ff',
      main: '#5cc6d0',
      dark: '#1a959f',
      contrastText: '#fff',
    },
    navIcon: {
      main: '#5cc6d0',
    },
  },
};

const initialState = {
  open: false,
  darkMode: false,
  showThemePicker: false,
};

const NavDrawer = (props) => {
  const [state, setState] = useReducer(
    (oldState, newState) => ({ ...oldState, ...newState }),
    initialState
  );
  const [currentUser, setCurrentUser] = useState('');
  const { pathname } = useLocation();
  const classes = useStyles();
  useRollbarPerson({ user: currentUser });

  useEffect(() => {
    (() => {
      const user
        = authClient
          && authClient.getProfile()
          && authClient.getProfile().given_name ?
          authClient.getProfile().given_name
          : '';
      setCurrentUser(user);
    })();
  }, []);

  const handleDrawerOpen = () => {
    setState({ open: true });
  };

  const handleDrawerClose = () => {
    setState({ open: false });
  };

  const toggleThemePicker = () => {
    setState({ showThemePicker: !state.showThemePicker });
  };

  const showThemePicker = (showThemePicker) => {
    setState({ showThemePicker });
  };

  const toggleDarkMode = () => {
    const darkMode = !state.darkMode;
    setState({ darkMode });
    props.setTheme(darkMode ? darkModeColors : config.theme);
  };

  const setTheme = (theme) => {
    props.setTheme(theme);
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderDrawer = () => {
    const classDrawer = `${classes.drawerPaper} ${!state.open ? classes.drawerPaperClose : ''}`;

    return (
      <Drawer
        variant="permanent"
        classes={{
          paper: classDrawer,
        }}
        open={state.open}
      >
        <div
          className={isMobile ? classes.toolbarMobile : classes.toolbarDesktop}
        />

        <Divider />

        <List className={classes.flexGrow}>
          {props.navItems}

        </List>
        <div className={classes.accountMenu}>
          <Tooltip title="Account Menu" placement="right-end">
            <Avatar>
              <IconButton
                onClick={handleClick}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
              >
                <PersonIcon />
              </IconButton>
            </Avatar>
          </Tooltip>
        </div>
        <Menu
          className={classes.flexContainer}
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&::before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                bgcolor: 'background.paper',
                zIndex: 0,
              },
            },
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <MenuItem>
            <IconButton
              sx={{
                '&.MuiButtonBase-root:hover': {
                  bgcolor: 'transparent'
                }
              }}
              size="small"
              onClick={toggleThemePicker}
            >
              <PaletteIcon /> ThemePicker
            </IconButton>
          </MenuItem>
          <MenuItem>
            <IconButton
              sx={{
                '&.MuiButtonBase-root:hover': {
                  bgcolor: 'transparent'
                }
              }}
              size="small"
              onClick={toggleDarkMode}
            >
              <DarkModeIcon /> Dark Mode
            </IconButton>
          </MenuItem>
          <MenuItem>
            <IconButton
              sx={{
                '&.MuiButtonBase-root:hover': {
                  bgcolor: 'transparent'
                }
              }}
              size="small"
              onClick={() => authClient.signOut()}
            >
              <LogoutIcon /> Logout
            </IconButton>
          </MenuItem>
        </Menu>
      </Drawer>
    );
  };

  const sundayLogo = useMemo(() => {
    const isJuicy = pathname.includes('adgarden');
    if (isMobile) {
      return isJuicy ? adgardenLogo : sundayMarketLogo;
    }
    return isJuicy ? adgardenCompleteLogo : sundayMarket;
  }, [pathname]);

  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        className={`${classes.appBar} ${state.open ? classes.appBarShift : ''}`}
      >
        <Toolbar disableGutters className={classes.containerHeader}>
          {authClient.isAuthenticated() ? (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={state.open ? handleDrawerClose : handleDrawerOpen}
              className={classes.menuButton}
            >
              {isMobile ? (
                <img src={sundayLogo} className="logoimg" alt="logo" />
              ) : (
                <MenuIcon />
              )}
            </IconButton>
          ) : (
            <div style={{ paddingLeft: 20 }} />
          )}

          {!isMobile && (
            <Link to="/" className={`${classes.logo} logotext`}>
              <img src={sundayLogo} className="logoimg" alt="logo" />
            </Link>
          )}

          <div className={classes.spacer} />
          {authClient.isAuthenticated() && <TitleBarButtons />}
        </Toolbar>
        <ThemePicker
          setTheme={setTheme}
          show={state.showThemePicker}
          hide={() => showThemePicker(false)}
        />
      </AppBar>

      {authClient.isAuthenticated() && renderDrawer()}

      <main className={state.open ? classes.contentShift : classes.content}>
        <div
          className={isMobile ? classes.toolbarMobile : classes.toolbarDesktop}
        />
        {props.children}
      </main>
    </div>
  );
};

NavDrawer.propTypes = {
  navItems: PropTypes.array.isRequired,
  children: PropTypes.object.isRequired,
  setTheme: PropTypes.func.isRequired,
};

export default NavDrawer;
