import React, { useCallback, useEffect, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'recharts';
import moment from 'moment';
import Axios from 'axios';

import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Accordion from '@mui/material/Accordion';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import makeStyles from '@mui/styles/makeStyles';
import MenuItem from '@mui/material/MenuItem';
import Input from '@mui/material/Input';

import { deepClone, generateColorByName, getRandomColor } from 'core/Functions/utilities';
import { NewStackedBarChart } from 'core/Components/Charts/NewStackedBarChart';
import useCallbackCancel from 'hooks/useCallbackCancel';
import WebstatsAPI from 'services/api/webstats';
import JuicyXAPI from 'services/api/juicyx';
import useRefresh from 'hooks/useRefresh';
import config from 'core/Utilities/config';

import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

const useStyles = makeStyles((_theme) => ({
  card: {
    flexGrow: 1,
    overflow: 'auto',
    paddingBottom: '5px',
    whiteSpace: 'nowrap',
    zIndex: 0
  },
  formControlSmall: {
    width: 110,
  },
  accordionSummary: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '1rem',
    justifyContent: 'space-between',
    width: '100%',
  }
}));

const websites = [{ value: null, label: 'All' }, ...Object.values(config.sites).flatMap(row => {
  if (row.value === 'sundaymarket') return [];
  return { value: row.value, label: row.label };
})];
const availableSites = websites.map(row => row.value);

const sources = [
  { value: null, label: 'All' },
  { value: 'ga', label: 'Google', color: '#b2d772' },
  { value: 'fb_jp', label: 'Meta', color: '#0095ff' },
  { value: 'me', label: 'Baidu', color: '#0ec0dc' },
  { value: 'ob', label: 'Outbrain', color: '#F28482' },
  { value: 'mg', label: 'Mgid', color: '#00964B' },
  { value: 'tb', label: 'Taboola', color: '#FFC53A' },
  // { value: 'msn', label: 'Msn' },
  // { value: 'gm', label: 'Yahoo' },
];
const availableSources = sources.map(row => row.value);

const initialState = {
  isLoading: false,
  data: [],
  sections: [],
  sourceSections: [],
  source: { value: null, label: 'All' },
  website: { value: null, label: 'All' },
  startDate: moment().subtract(6, 'days').startOf('day').format('YYYY-MM-DD 00:00:00'),
  endDate: moment().endOf('day').format('YYYY-MM-DD 23:59:59'),
};

const MIN_PROFIT = 5; // $5

const SiteSourceProfitable = ({ title, timezone, startDate, endDate, site, ...props }) => {
  const [state, setState] = useReducer((oldState, newState) => ({ ...oldState, ...newState }), { ...deepClone(initialState), ...props });
  const { isLoading, data, sections, source, website } = state;

  const [selectedWebsites, setSelectedWebsites] = useState(availableSites);
  const [selectedSources, setSelectedSources] = useState(availableSources);

  const classes = useStyles();

  if (startDate && state.startDate !== startDate) {
    setState({ startDate });
  }

  if (endDate && state.endDate !== endDate) {
    setState({ endDate });
  }

  const getColor = () => {
    return Math.floor(Math.random() * 16777215).toString(16);
  };

  const handleSelectWebsiteChange = (event) => {
    event.stopPropagation();

    const { target: { value }, } = event;
    const isAllSeletedInMemory = selectedWebsites.includes(null);
    const isAllClicked = value.includes(null);

    if (isAllSeletedInMemory && !isAllClicked) {
      /** Uncheck All! Should uncheck everybody */
      // console.log('Uncheck All! Should uncheck everybody')
      setSelectedWebsites([]);
    } else if (!isAllSeletedInMemory && isAllClicked) {
      /** Checked All! Should check everybody */
      // console.log('Checked All! Should check everybody')
      setSelectedWebsites(availableSites);
    } else if (isAllSeletedInMemory && isAllClicked && value.length < selectedWebsites.length) {
      /** Unchecked random, should uncheck All */
      // console.log('Unchecked random, should uncheck All')
      setSelectedWebsites(value.filter(v => v !== null));
    } else if (!isAllSeletedInMemory && !isAllClicked) {
      /** Checked random */
      if (value.length + 1 === availableSites.length) {
        /** All random are checked, but All is not. Should check All */
        // console.log('All random are checked, bug All is not, should check All')
        setSelectedWebsites(availableSites);
      } else {
        /** Just add the new one to the array */
        // console.log('Just add the new one to the array')
        setSelectedWebsites(value);
      }
    } else {
      setSelectedSources(value);
      console.log('UNKNOWN CASE');

      console.log('isAllClicked:', isAllClicked);
      console.log('isAllSeletedInMemory:', isAllSeletedInMemory);

      console.log('value:', value);
      console.log('selectedWebsites:', selectedWebsites);
    }

  };

  const handleSelectSourceChange = (event) => {
    event.stopPropagation();

    const { target: { value }, } = event;
    const isAllSeletedInMemory = selectedSources.includes(null);
    const isAllClicked = value.includes(null);

    if (isAllSeletedInMemory && !isAllClicked) {
      /** Uncheck All! Should uncheck everybody */
      // console.log('Uncheck All! Should uncheck everybody')
      setSelectedSources([]);
    } else if (!isAllSeletedInMemory && isAllClicked) {
      /** Checked All! Should check everybody */
      // console.log('Checked All! Should check everybody')
      setSelectedSources(availableSources);
    } else if (isAllSeletedInMemory && isAllClicked && value.length < selectedSources.length) {
      /** Unchecked random, should uncheck All */
      // console.log('Unchecked random, should uncheck All')
      setSelectedSources(value.filter(v => v !== null));
    } else if (!isAllSeletedInMemory && !isAllClicked) {
      /** Checked random */
      if (value.length + 1 === availableSources.length) {
        /** All random are checked, but All is not. Should check All */
        // console.log('All random are checked, bug All is not, should check All')
        setSelectedSources(availableSources);
      } else {
        /** Just add the new one to the array */
        // console.log('Just add the new one to the array')
        setSelectedSources(value);
      }
    } else {
      setSelectedSources(value);
      console.log('UNKNOWN CASE');

      console.log('isAllClicked:', isAllClicked);
      console.log('isAllSeletedInMemory:', isAllSeletedInMemory);

      console.log('value:', value);
      console.log('selectedSources:', selectedSources);
    }
  };

  const updateSectionsBar = useCallback((fields) => {
    const sections = [];
    fields.forEach((field, i) => {
      if (field === 'date') {
        return;
      }

      let color = false;
      if (!site) {
        color = config.sites[field.toLowerCase()]?.color || getColor();
      } else {
        color = '00A1E4';
        if (i === 1) {
          color = 'DC0073';
        } else if (i === 2) {
          color = 'd3d3d3';
        }
      }

      sections.push((
        <Bar key={field} dataKey={field} fill={`#${color}`} stroke={`#${color}`} />
      ));
    });
    return sections;
  }, [site]);

  const updateStackedBar = useCallback((fields) => {
    const sections = [];
    fields.sort((a, b) => a.localeCompare(b));
    fields.forEach((field) => {
      const sourceColor = sources.find(row => row.value === field)?.color;
      let color = sourceColor || generateColorByName(field) || getRandomColor();
      if (!color.startsWith('#')) {
        color = `#${color}`;
      }

      sections.push((
        <Bar key={field} dataKey={field} fill={color} stroke={color} />
      ));
    });
    return sections;
  }, []);

  const recursiveCleanObject = useCallback((obj, min = MIN_PROFIT) => {
    if (Array.isArray(obj)) {
      obj.forEach((item, index) => {
        if (typeof item === 'object') {
          recursiveCleanObject(item);
        } else if (typeof item === 'number' && item < min) {
          delete obj[index];
        }
      });
    } else if (typeof obj === 'object' && obj !== null) {
      Object.keys(obj).forEach(key => {
        if (typeof obj[key] === 'object') {
          recursiveCleanObject(obj[key]);
        } else if (typeof obj[key] === 'number' && obj[key] < min) {
          delete obj[key];
        }
      });
    }
  }, []);

  const updateData = useCallbackCancel(async (cancelToken) => {
    try {
      setState({ isLoading: true });

      const chartData = {};

      let initialDate = state.startDate;
      if (moment(initialDate).format('YYYY-MM-DD') === moment(state.endDate).format('YYYY-MM-DD')) {
        initialDate = moment(state.endDate).subtract(6, 'days').format('YYYY-MM-DD 00:00:00');
      }

      const selectedSite = website?.value || site || null;
      const params = { timezone, startDate: initialDate, endDate, site: selectedSite, source: source.value };

      await Promise.all([
        WebstatsAPI.gazillions.getSiteSourceProfitable(params, cancelToken),
        JuicyXAPI.data.getSiteConversionsPerDay(params, cancelToken)
      ]).then(([response, juicyResponse]) => {

        const stats = response.data.results;
        if (stats && stats.length) {
          stats.forEach((row) => {

            if (!availableSites.includes(row.site)) {
              return;
            }

            const date = row.date.substr(0, 10);
            if (chartData[date] === undefined) {
              chartData[date] = { date };
            }

            if (chartData[date][row.site] === undefined) {
              chartData[date][row.site] = {};
            }

            if (chartData[date][row.site][row.source] === undefined) {
              chartData[date][row.site][row.source] = 0;
            }

            chartData[date][row.site][row.source] += row.profit;
            chartData[date][row.site][row.source] = Number(chartData[date][row.site][row.source].toFixed(2));
          });
        }

        const juicyStats = juicyResponse.data;
        if (juicyStats && juicyStats.length) {
          juicyStats.forEach((juicyRow) => {

            if (juicyRow.domain.includes('dc_pf')) juicyRow.domain = 'direct';
            if (juicyRow.domain.includes('dc_sc')) juicyRow.domain = 'search';

            const shouldIncludeSite = availableSites.includes(juicyRow.domain);
            const isValidSource = Boolean(juicyRow.source);
            /** Skip in case one of these conditions are not satisfied */
            if (!isValidSource || !shouldIncludeSite) return;

            const date = juicyRow.date.substr(0, 10);
            if (!chartData[date]) {
              chartData[date] = { date };
            }

            if (!chartData[date][juicyRow.domain]) {
              chartData[date][juicyRow.domain] = {};
            }

            const currentProfit = chartData[date][juicyRow.domain][juicyRow.source] || 0;
            const juicyProfit = juicyRow.revenue * juicyRow.rate;
            chartData[date][juicyRow.domain][juicyRow.source] = currentProfit + juicyProfit;
          });
        }

        /** Cleaning/organizing data */
        const fields = [];
        const sourceFields = [];
        recursiveCleanObject({ obj: chartData });

        /** Extracting fields(sites) from cleanedChartData */
        fields.push(...Object.values(chartData).flatMap(value => {
          return Object.keys(value).flatMap(k => {
            if (k === 'date') return []; // skip date
            return k;
          });
        }));

        /** Extracting sourceFields(sources) from cleanedChartData */
        sourceFields.push(...Object.values(chartData).flatMap(value => {
          return Object.values(value).flatMap(v => {
            if (typeof v === 'string') return []; // skip date
            return Object.keys(v);
          });
        }));

        const sections = updateSectionsBar(Array.from(new Set(fields))); // remove duplicates sites
        const stackedBar = updateStackedBar(Array.from(new Set(sourceFields))); // remove duplicates sources
        const data = Object.values(chartData);
        const formattedData = data.sort((a, b) => a.date - b.date).map((row) => ({ ...row, date: moment(row.date).format('MM/DD') }));
        setState({ sections, sourceSections: stackedBar, data: formattedData, isLoading: false });
      });
    } catch (error) {
      if (Axios.isCancel(error)) return;
      console.error(error);
    }

  }, [state.startDate, state.endDate, website?.value, site, timezone, endDate, source.value, recursiveCleanObject, updateSectionsBar, updateStackedBar]);

  useEffect(() => {
    updateData();
  }, [updateData]);

  useRefresh(updateData);

  return (
    <Accordion
      style={{ margin: '8px', height: '100%' }}
      className={classes.card + 'AccordionRoot'}
      expanded={state.isAccordion}
      onChange={props.disableAccordion ? undefined : () => setState({ isAccordion: !state.isAccordion })}
    >
      <AccordionSummary
        style={{ cursor: props.disableAccordion ? 'default' : 'pointer' }}
        expandIcon={props.accordionIcon ? props.accordionIcon : undefined}
      >
        <div className={classes.accordionSummary}>
          <Typography variant="h6" color="inherit">
            {title}
          </Typography>

          {state.isAccordion && (
            <div style={{ display: 'flex', gap: '1rem', justifyContent: 'flex-end' }}>
              <FormControl className={classes.formControlSmall} >
                <InputLabel id="select-websites" htmlFor="select-website">Site</InputLabel>
                <Select
                  labelId="select-websites"
                  id="websites"
                  multiple
                  value={selectedWebsites}
                  input={<Input id="select-website" name="website" />}
                  onChange={handleSelectWebsiteChange}
                  renderValue={(selected) => `${selected.filter(s => s !== null).length} selected`}
                  onClick={(e) => e.stopPropagation()}
                >
                  {websites.map((ws) => {
                    return (
                      <MenuItem key={`websites-${ws.label}`} value={ws.value}>
                        <Checkbox
                          style={{ padding: '0 0.5rem 0 0' }}
                          size="small"
                          checked={selectedWebsites.indexOf(ws.value) > -1}
                        />
                        <ListItemText primary={ws.label} />
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>

              <FormControl className={classes.formControlSmall} >
                <InputLabel id="select-sources" htmlFor="select-source">Source</InputLabel>
                <Select
                  labelId="select-sources"
                  id="sources"
                  multiple
                  value={selectedSources}
                  input={<Input id="select-source" name="source" />}
                  onChange={handleSelectSourceChange}
                  renderValue={(selected) => `${selected.filter(s => s !== null).length} selected`}
                  onClick={(e) => e.stopPropagation()}
                >
                  {sources.map((ws) => {
                    return (
                      <MenuItem key={`sources-${ws.label}`} value={ws.value}>
                        <Checkbox
                          style={{ padding: '0 0.5rem 0 0' }}
                          size="small"
                          checked={selectedSources.indexOf(ws.value) > -1}
                        />
                        <ListItemText primary={ws.label} />
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          )}
        </div>
      </AccordionSummary>

      <AccordionDetails>
        <NewStackedBarChart
          data={data}
          selectedSites={selectedWebsites}
          selectedSources={selectedSources}
          sections={sections}
          stackedSections={state.sourceSections}
          type="currency"
          showReferenceLine
          loading={isLoading}
          offsetYTooltip={310}
        />
      </AccordionDetails>
    </Accordion >
  );
};

SiteSourceProfitable.propTypes = {
  timezone: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  title: PropTypes.string,
  site: PropTypes.string,
  disableAccordion: PropTypes.bool,
  accordionIcon: PropTypes.any, // Material Icons or null
  isAccordion: PropTypes.bool,
};

SiteSourceProfitable.defaultProps = {
  title: '',
  site: '',
  accordionIcon: <ExpandMoreIcon />,
  disableAccordion: false,
  isAccordion: false,
};

export default SiteSourceProfitable;
