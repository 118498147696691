import React, { Fragment, useState } from 'react';
import { isMobile } from 'react-device-detect';
import moment from 'moment-timezone';

import { useFilter } from 'contexts/FilterProvider';
import FilterDesktop from 'core/Components/FilterDesktop';
import FilterMobile from 'core/Components/FilterMobile';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import FilterListIcon from '@mui/icons-material/FilterList';
import RefreshIcon from '@mui/icons-material/Refresh';
import FormControl from '@mui/material/FormControl';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import makeStyles from '@mui/styles/makeStyles';
import Tooltip from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';

const useStyles = makeStyles({
  titlebarIcons: {
    color: 'white',
  },
  filterApplied: {
    position: 'absolute',
    right: '5px',
    top: '5px',
    background: 'red',
    borderRadius: '35px',
    height: '15px',
    width: '15px',
    fontSize: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const TitleBarButtons = () => {
  const { filter, updateStateFilter, numberApplied, clearFilter, shouldShow, customFilterJSX, refreshComponents } = useFilter();
  const [isFilter, setIsFilter] = useState(false);
  const classes = useStyles();

  const handleShiftStart = () => {
    const startDate = moment(filter.startDate).add(1, 'days').format('YYYY-MM-DD 00:00:00');
    const endDate = moment(filter.endDate).add(1, 'days').format('YYYY-MM-DD 23:59:59');
    updateStateFilter({ endDate, startDate });
  };

  const handleShiftEnd = () => {
    const startDate = moment(filter.startDate).subtract(1, 'days').format('YYYY-MM-DD 00:00:00');
    const endDate = moment(filter.endDate).subtract(1, 'days').format('YYYY-MM-DD 23:59:59');
    updateStateFilter({ endDate, startDate });
  };

  const isDateToday = () => {
    return moment(filter.endDate).tz(filter.timezone).format('YYYY-MM-DD') === moment().tz(filter.timezone).format('YYYY-MM-DD');
  };

  return (
    <Fragment>
      {isMobile ? (
        <div>
          <FormControl>
            <div>
              <IconButton className={classes.titlebarIcons} onClick={() => handleShiftEnd()}>
                <ChevronLeftIcon />
              </IconButton>
              <IconButton className={classes.titlebarIcons} onClick={() => handleShiftStart()} disabled={isDateToday()}>
                <ChevronRightIcon />
              </IconButton>
            </div>
          </FormControl>

          <Tooltip title="Clear Filter" placement="bottom">
            <IconButton className={classes.titlebarIcons} onClick={clearFilter}>
              <ClearIcon />
              {numberApplied > 0 ? (
                <div className={classes.filterApplied}>
                  {numberApplied}
                </div>
              ) : undefined}
            </IconButton>
          </Tooltip>

          <Tooltip title="Filter" placement="bottom">
            <IconButton className={classes.titlebarIcons} onClick={() => setIsFilter(!isFilter)}>
              <FilterListIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Refresh" placement="bottom">
            <IconButton onClick={refreshComponents} className={classes.titlebarIcons}>
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        </div>
      )
        : (
          <div>
            <FilterDesktop changeFilter={updateStateFilter} filter={filter} CustomFilter={customFilterJSX} />
          </div>
        )}

      {isMobile
        && (
          <Dialog open={isFilter}>
            <FilterMobile changeFilter={updateStateFilter} filter={filter} handleCloseModal={() => setIsFilter(false)} shouldShow={shouldShow} CustomFilter={customFilterJSX} />
          </Dialog>
        )}
    </Fragment>
  );
};

export default TitleBarButtons;
