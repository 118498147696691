import React, { Fragment, useEffect } from 'react';

import makeStyles from '@mui/styles/makeStyles';

import KinkTable from 'core/Components/Tables/KinkTable';
import { Button, LinearProgress } from '@mui/material';
import FacebookAccountActionModal from './FacebookAccountActionModal';
import { FacebookAccountProvider, useFacebookAccount } from './FacebookAccountProvider';
import FacebookAccountTableRow from './FacebookAccountTableRow';
import SnackbarSave from '../../../core/Components/SnackbarSave';

const useStyles = makeStyles({
  dialogCreateButton: { width: '20%', margin: '10px' },
  KinkTableWrapper: {
    '& >div': { padding: 0 },
  },
});

const FacebookAccountsList = () => {
  const { fetchData, loading, setState, snackBar, setSnackBar, accountList } = useFacebookAccount();
  const classes = useStyles();

  const columns = [
    { title: 'Name', name: 'name', width: 200 },
    { title: 'Site', name: 'site', width: 200 },
    { title: 'Site Key', name: 'site_key', width: 100 },
    { title: 'Account Id', name: 'account_id', width: 150 },
    { title: 'Access Token', name: 'access_token', width: 300 },
    { title: 'Page Id', name: 'page_id', width: 150 },
    { title: 'Actions', name: 'actions', width: 150, filteringEnabled: false, sortingEnabled: false,
      formatter: ({ row }) => (<FacebookAccountTableRow data={row} />)
    }
  ];

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Fragment>
      <SnackbarSave open={snackBar.open} handleClose={() => setSnackBar({ open: false })} message={snackBar.message} status={snackBar.status} />

      {loading === 'fetching-data' && <LinearProgress />}
      <FacebookAccountActionModal />
      <div className={classes.KinkTableWrapper}>
        <KinkTable
          hidden
          tableKey="facebook_accounts"
          data={accountList}
          columns={columns}
        >
          <Button
            onClick={() => setState({ action: 'creating', account: {} })}
            size="small"
            variant="contained"
            color="primary"
            className={classes.dialogCreateButton}
          >
            Create new account
          </Button>
        </KinkTable>
      </div>
    </Fragment>
  );
};

const FacebookAccountsView = () => {
  return (
    <FacebookAccountProvider>
      <FacebookAccountsList />
    </FacebookAccountProvider>
  );
};
export default FacebookAccountsView;
