import React, { useEffect, useCallback, useReducer } from 'react';
import { isMobile } from 'react-device-detect';
import PropTypes from 'prop-types';
import moment from 'moment';

import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Input from '@mui/material/Input';

import ComposedHourlyStatsChart from 'core/Components/Charts/ComposedHourlyStatsChart';
import useCallbackCancel from 'hooks/useCallbackCancel';
import { getDateRange } from 'core/Components/Charts/ChartUtils';
import WebstatsAPI from 'services/api/webstats';
import { deepClone } from 'core/Functions/utilities';
import useRefresh from 'hooks/useRefresh';
import config from 'core/Utilities/config';

const useStyles = makeStyles((_theme) => ({
  card: {
    overflow: 'auto',
    paddingBottom: '5px',
    whiteSpace: 'nowrap',
    zIndex: 0,
  },
  containerDropdown: {
    display: 'flex',
    alignItems: 'center',
    gap: isMobile ? '2rem 1rem' : '2rem',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    marginRight: '1.5rem'
  },
  formControlSite: {
    width: 180,
  },
  containerSites: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    height: 18
  },
  h6Size: {
    width: 300
  },
  accordionSummary: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    gap: '1rem',
    width: '100%'
  }
}));

const sources = [
  { value: 'all', label: 'All' },
  { value: 'ga', label: 'Google' },
  { value: 'fb_jp', label: 'Meta' },
  { value: 'ob', label: 'Outbrain' },
  { value: 'mg', label: 'MGID' },
  // { value: 'gm', label: 'Yahoo' },
  { value: 'me', label: 'Baidu' },
  { value: 'tb', label: 'Taboola' },
];

const auxFieldsLine = ['clicks', 'total_sessions',];
const auxFieldsBar = ['total_revenue', 'spend', 'profit'];
const auxHidden = ['total_revenue', 'spend'];

const hours = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];

const initialState = {
  startDate: moment().subtract(6, 'days').startOf('day').format('YYYY-MM-DD 00:00:00'),
  endDate: moment().endOf('day').format('YYYY-MM-DD 23:59:59'),
  source: '',
  loading: false,
  stats: [],
};

const SourceHourlyChart = ({ startDate, endDate, timezone, site, supplyFilter, ...props }) => {
  const [state, setState] = useReducer((oldState, newState) => ({ ...oldState, ...newState }), { ...deepClone(initialState), ...props });
  const [sections, setSections] = React.useState([]);
  const classes = useStyles();

  if (startDate && state.startDate !== startDate) {
    setState({ startDate });
  }
  if (endDate && state.endDate !== endDate) {
    setState({ endDate });
  }

  const updateSections = useCallback((fields, type) => {
    const auxSections = [];
    fields.forEach((field) => {
      if (field === 'date') {
        return;
      }

      const defaultColors = {
        clicks: '2323ec',
        assertive_sessions: 'eccf3a',
        total_sessions: 'eccf3a',
        profit: '0095ff',
      };
      const color = defaultColors[field] || config.sites[field]?.color;

      auxSections.push({
        key: field,
        [`${type}DataKey`]: field,
        fill: `#${color}`,
        stroke: `#${color}`
      });
    });

    return auxSections;
  }, []);

  const updateData = useCallbackCancel(async (cancelToken) => {
    setState({ loading: true });

    let intialDate = state.startDate;
    if (moment(intialDate).format('YYYY-MM-DD') === moment(state.endDate).format('YYYY-MM-DD')) {
      intialDate = moment(state.endDate).subtract(3, 'days').format('YYYY-MM-DD 00:00:00');
    }

    const dates = getDateRange(intialDate, state.endDate);

    const chartData = {};
    for (const date of dates) {
      for (const hour of hours) {
        const key = moment(`${date} ${hour}`).format('M/D ha');
        chartData[key] = { date: key };
      }
    }

    const results = supplyFilter.reduce((acc, filter) => {
      if (filter.columnName === 'site') {
        if (filter.operation === 'equal') acc.sitesEqual = filter.value;
        else if (filter.operation === 'not_equal') acc.sitesNotEqual = filter.value.split('-').join('');
        else if (filter.operation === 'contains') acc.sitesContain = filter.value
      } else if (filter.columnName === 'campaign') {
        if (filter.operation === 'equal') acc.campaignsEqual = filter.value;
        else if (filter.operation === 'not_equal') acc.campaignsNotEqual = filter.value.split('-').join('');
        else if (filter.operation === 'contains') acc.campaignsContain = filter.value
      }

      return acc
    }, {})

    const params = { timezone, startDate: intialDate, endDate: state.endDate, site, source: state.source === 'all' ? null : state.source, ...results };
    const response = await WebstatsAPI.gazillions.getCampaignStatsHourly(params, cancelToken);
    if (response && response.data && response.data.results) {
      const data = response.data.results;
      const auxFields = auxFieldsLine.concat(auxFieldsBar);
      if (data && data.length) {
        data.forEach(row => {
          const key = row.pst_timestamp;

          auxFields.forEach(field => {
            const subkey = `${field}`;

            if (chartData[key] === undefined) {
              return;
            }
            if (chartData[key][subkey] === undefined) {
              chartData[key][subkey] = 0;
            }
            chartData[key][subkey] += row[field];
          });
        });
      }
    }

    const totalData = Object.values(chartData);
    totalData.forEach(row => {
      Object.keys(row).forEach(key => {
        if (key === 'date') {
          return;
        }
        const value = row[key];
        if (Number(value) === value) {
          row[key] = Number(value.toFixed(2));
        }
      });
    });
    totalData.sort((a, b) => Number(a.date) - Number(b.date));

    const bar = updateSections(auxFieldsLine, 'line');
    const line = updateSections(auxFieldsBar, 'bar');
    setSections([...bar, ...line]);
    setState({ loading: false, stats: totalData });
  }, [site, state.endDate, state.source, state.startDate, supplyFilter, timezone, updateSections]);

  const getSources = () => {
    return sources.map(key => ({
      label: key.label,
      value: key.value
    }));
  };

  const onChangeSource = (e) => {
    setState({ source: e.target.value });
  };

  useRefresh(updateData);

  useEffect(() => {
    updateData();
  }, [updateData]);

  return (
    <Accordion
      style={{ margin: '8px', height: '100%' }}
      className={classes.card + 'AccordionRoot'}
      expanded={state.isAccordion}
      onChange={props.disableAccordion ? undefined : () => setState({ isAccordion: !state.isAccordion })}
    >
      <AccordionSummary
        style={{ cursor: props.disableAccordion ? 'default' : 'pointer' }}
        expandIcon={props.accordionIcon ? props.accordionIcon : undefined}
      >
        <div className={classes.accordionSummary}>
          <Typography variant="h6" color="inherit" className={classes.h6Size}>
            Hourly Revenue Stats
          </Typography>

          {state.isAccordion && (
            <div className={classes.containerDropdown}>
              <FormControl className={classes.formControlSite}>
                <InputLabel htmlFor="select-source">Source</InputLabel>
                <Select
                  value={state.source || 'all'}
                  input={<Input id="select-source" name="source" />}
                  onChange={onChangeSource}
                  onClick={(e) => e.stopPropagation()}
                >
                  {getSources().map(item => (
                    <MenuItem key={item.label} value={item.value} onClick={(e) => e.stopPropagation()}>
                      <span className={classes.containerSites}>
                        {item.label}
                      </span>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}
        </div>
      </AccordionSummary>

      <AccordionDetails>
        <ComposedHourlyStatsChart
          loading={state.loading}
          data={state.stats}
          sections={sections}
          height={300}
          offsetYTooltip={210}
          hidden={auxHidden}
        />
      </AccordionDetails>
    </Accordion >
  );
};

SourceHourlyChart.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  timezone: PropTypes.string,
  site: PropTypes.string,
  disableAccordion: PropTypes.bool,
  accordionIcon: PropTypes.any, // Material Icons or null
  isAccordion: PropTypes.bool,
  supplyFilter: PropTypes.array
};

SourceHourlyChart.defaultProps = {
  site: undefined,
  startDate: undefined,
  endDate: undefined,
  timezone: 'America/Vancouver',
  accordionIcon: <ExpandMoreIcon />,
  disableAccordion: false,
  isAccordion: false,
  supplyFilter: {}
};

export default SourceHourlyChart;
