export default class cacheman {
  /**
   * get an item from either session of local storage
   * @param key string - a key to associate the data with in storage
   * @param storage string - which storage to use, either 'local' or 'sessions'
   * @returns {boolean}
   */
  static get = (key, storage = 'local') => {
    const cacheHit = storage === 'session' ? sessionStorage.getItem(key) : localStorage.getItem(key);
    if (cacheHit) {
      const item = JSON.parse(cacheHit);
      return item.expiry > Date.now() ? item.data : false;
    }
    return false;
  };

  /**
   * Store data in either session or local storage
   * @param key string - a key to associate the data with
   * @param newData - anything that we can run through JSON.stringify
   * @param duration number - how many minutes this should be stored in the cache for, default is 5
   * @param storage - which storage to use, default is 'local'
   */
  static set = (key, newData, duration = 5, storage = 'local') => {
    const item = {
      expiry: Date.now() + (duration * 60000),
      data: newData
    };
    if (storage === 'session') {
      sessionStorage.setItem(key, JSON.stringify(item));
    } else {
      localStorage.setItem(key, JSON.stringify(item));
    }
  }

  static delete = (key, storage = 'local') => {
    if (storage === 'session') {
      sessionStorage.clear(key);
    } else if (storage === 'local') localStorage.clear(key);
  };
}
