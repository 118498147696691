import React from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';

import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import ListItem from '@mui/material/ListItem';
import Tooltip from '@mui/material/Tooltip';

const useStyles = makeStyles((theme) => ({
  active: {
    borderRight: '4px solid',
    color: theme.palette.secondary.dark,
  },
  icon: {
    color: theme.palette.secondary.main,
    minWidth: 'unset',
    marginRight: 16,
    width: '26px',
    height: '26px',
    '& svg': {
      width: '26px',
      height: '26px',
    }
  },
  navText: {
    color: theme.palette.primary.contrastText,
    textDecoration: 'none',
    textTransform: 'uppercase',
    fontSize: '1rem',
  },
  marginRight: {
    marginRight: '15px'
  },
  listItem: {
    padding: '0 16px',
    margin: 0
  }
}));

/**
 * Top-level navigation links.
 */
const MobLinks = (props) => {
  const location = useLocation();
  const classes = useStyles();
  const baseRouteMatch = props.to.split('/')[1];
  const currentRouteMatch = location.pathname.split('/')[1];
  const match = baseRouteMatch && currentRouteMatch && baseRouteMatch === currentRouteMatch;

  return (
    <Link to={props.to}>
      <ListItem className={match ? classes.active : ''} >
        {props.icon && (
          <Tooltip title={props.label} placement="right-end">
            <ListItemIcon classes={{ root: classes.icon }} >
              {props.icon}
            </ListItemIcon>
          </Tooltip>
        )}

        {props.image && (
          <Tooltip title={props.label} placement="right-end">
            <img className={classes.marginRight} alt="" src={props.image} width={26} height={props.imageHeigth} />
          </Tooltip>
        )}

        <ListItemText disableTypography className={classes.listItem}>
          <Typography variant="body1" className={classes.navText} >
            {props.label}
          </Typography>
        </ListItemText>
      </ListItem >
    </Link>
  )
};

MobLinks.propTypes = {
  to: PropTypes.string.isRequired,
  icon: PropTypes.any,
  image: PropTypes.any,
  imageHeigth: PropTypes.any,
  label: PropTypes.string.isRequired,
};

MobLinks.defaultProps = {
  icon: false,
  image: false,
  imageHeigth: '26',
};

export default MobLinks;
