import React from 'react';

import withStyles from '@mui/styles/withStyles';

import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';

const styles = () => ({
  container: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  children: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  childrenBlurred: {
    position: 'relative',
    filter: 'blur(1.5px)',
    width: '100%',
    height: '100%',
  },
  spinnerContainer: {
    position: 'absolute',
    margin: 'auto',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  }
});

const Loader = (props) => {
  const { classes, noStyle } = props;

  return (
    <div className={!noStyle ? classes.container : ''}>
      <div className={props.loading && !props.noBlur ? classes.childrenBlurred : !noStyle ? classes.children : ''}>
        {props.children}
      </div>
      {props.loading && (
        <div className={classes.spinnerContainer}>
          <CircularProgress />
          <div>
            Loading...
          </div>
        </div>
      )}
    </div>
  );
};

Loader.propTypes = {
  loading: PropTypes.bool.isRequired,
  noBlur: PropTypes.bool,
  noStyle: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

Loader.defaultProps = {
  noBlur: false,
  noStyle: false,
};

export default withStyles(styles, { withTheme: true })(Loader);
