import React, { useMemo, useReducer, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import FileSaver from 'file-saver';
import moment from 'moment';
import Axios from 'axios';

import EditIcon from '@mui/icons-material/Edit';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';

import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import DownloadIcon from '@mui/icons-material/Download';
import ChartIcon from '@mui/icons-material/InsertChart';
import ExportIcon from '@mui/icons-material/SaveAlt';
import InsightsIcon from '@mui/icons-material/Insights';
import makeStyles from '@mui/styles/makeStyles';

import { openLinkV2, getSiteByName, fixNum, parseData, generateColorByName } from 'core/Functions/utilities';
import { aggregateHourlyCampaignData } from 'core/Components/Charts/ChartUtils';
import DialogBudgetBatch from 'core/Components/Dialogs/DialogBudgetBatch';
import useCallbackCancel from 'hooks/useCallbackCancel';
import ConfirmDialog from 'core/Components/Dialogs/ConfirmDialog';
import DialogBudget from 'core/Components/Dialogs/DialogBudget';
import ImageSlider from 'core/Components/ImageSlider';
import { useFilter } from 'contexts/FilterProvider';
import { Skeleton, Tooltip } from '@mui/material';
import WebstatsAPI from 'services/api/webstats';
import SparkChart from 'core/Components/Charts/SparkChart';
import KinkTable from 'core/Components/Tables/KinkTable';
import JuicyXAPI from 'services/api/juicyx';
import useRefresh from 'hooks/useRefresh';
import BBotAPI from 'services/api/bbot';
import authClient from 'services/auth';

const useStyles = makeStyles({
  campaignTextPause: {
    color: '#64b187',
    fontWeight: 600,
  },
  formControl: {
    margin: '10px',
    border: '1px solid black'
  }
});

const initialState = {
  data: [],
  sections: [],
  showNoSpend: true,
  loading: false,
  isDrawer: false,
  dataToEdit: {},
  campaignToPause: [],
  showCloseToBudget: false,
  minSessions: 0,
  isDrawerBatch: false,
  dataToEditBatch: [],
  isBatch: false,
  isBatchPause: false,
  selectedRows: [],
  isPauseCampaign: false,
};

const RenderDeviceChart = ({ data/* , sections  */ }) => {
  if (!data) return <Skeleton animation="wave" />;
  const totalValue = Object.values(data).reduce((accumulator, currentValue) => {
    if (typeof currentValue !== 'number') return accumulator;
    return accumulator + currentValue;
  }, 0);
  return (
    <div style={{ display: 'flex', width: '100%', height: '20px' }}>
      {
        Object.entries(data).flatMap(([key, value]) => {
          if (typeof value !== 'number') return [];
          const widthPercent = (value / totalValue) * 100;
          return (
            <Tooltip key={key} title={`${key} ${widthPercent.toFixed(2)}%`} arrow>
              <div style={{ height: '100%', width: `${widthPercent}%`, backgroundColor: generateColorByName(key) }} />
            </Tooltip>
          );
        })
      }
    </div >
    // <StackedBarChart data={data} sections={sections} height={30} offsetYTooltip={-20} />
  );
};

RenderDeviceChart.propTypes = {
  // sections: PropTypes.array.isRequired,
  // eslint-disable-next-line react/require-default-props
  data: PropTypes.object,
};

const WebstatsTableCampaigns = ({ adReport, title, filters, handleToggleGraphics }) => {
  const [state, setState] = useReducer((oldState, newState) => ({ ...oldState, ...newState }), initialState);
  const { filter } = useFilter();
  const { startDate, endDate, timezone, site } = filter;
  const { search } = useLocation();
  const navigate = useNavigate();
  const classes = useStyles();
  const {
    data,
    sections,
    showNoSpend,
    loading,
    isDrawer,
    dataToEdit,
    campaignToPause,
    showCloseToBudget,
    // minSessions,
    selectedRows,
    isDrawerBatch,
    dataToEditBatch,
    isPauseCampaign
  } = state;

  const filterAge = (value, filterTable) => {
    let negative = false;

    const searches = filterTable.value.split(',');

    let date = moment(value); // Gets the data and convert to moment
    if (!date.isValid()) date = null; // Check if it was a valid value

    const dateToCompare = moment(); // Initialize as today
    const diffDays = dateToCompare.diff(date, 'days'); // DO the difference between the dates

    for (let search of searches) {
      search = search.trim();
      switch (search.charAt(0)) {
        case '>':
          return fixNum(diffDays) > fixNum(search);
        case '<':
          return fixNum(diffDays) < fixNum(search);
        case '-':
          negative = true;
          search = search.substring(1);
          break;
        default:
          break;
      }
      search = search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      const regex = new RegExp(search, 'i');

      if (negative && regex.test(diffDays)) {
        return false;
      }
      if (regex.test(diffDays)) {
        return true;
      }
    }
    return negative;
  };

  const formatDataChart = (data) => {
    const aux = { aggregatedData: {} };
    if (data) {
      aux.aggregatedData = { ...data };
    }
    return aux;
  };

  const loadCampaignHourlySessions = (newData, cancelToken) => {
    if (newData.length === 0) return;

    const source = new URLSearchParams(search).get('source');
    const campaign = new URLSearchParams(search).get('campaign');
    const params = { timezone, startDate, endDate, site, source, campaign };
    const daysBetween = moment(endDate).diff(moment(startDate), 'days');

    // When we are showing just one day, the Session Hourly column shows Sessions by hour otherwise, it shows by day
    // const time = new Date().getTime();
    // console.time(`HourlySessionCall ${time}`)
    const endpoint = daysBetween > 1 ? WebstatsAPI.gazillions.getCampaignSessionsDaily(params, cancelToken) : WebstatsAPI.gazillions.getCampaignHourlySessions(params, cancelToken);
    endpoint.then(hourlySessionsResponse => {
      if (hourlySessionsResponse.data && hourlySessionsResponse.data.results) {
        const aggregated = aggregateHourlyCampaignData(startDate, endDate, newData, hourlySessionsResponse.data.results);
        setState({ data: aggregated });
      }
    })
      .catch(error => {
        console.error('Failed to fetch campaign hourly sessions:', error);
      }).finally(() => {
        // console.timeEnd(`HourlySessionCall ${time}`)
      });
  };

  const loadCampaignDeviceStats = (newData, cancelToken) => {
    if (newData.length === 0) return;

    const source = new URLSearchParams(search).get('source');
    const campaign = new URLSearchParams(search).get('campaign');
    const params = { timezone, startDate, endDate, site, source, campaign };
    // const time = new Date().getTime();
    // console.time(`DeviceStatsCall ${time}`)
    WebstatsAPI.gazillions.getCampaignDeviceStats({ ...params, groupByDate: false }, cancelToken)
      .then(responseDevice => {
        const dataDevice = responseDevice.data.results;
        const fields = [];
        const aggregated = newData.map((row) => {
          row.deviceData = {};
          dataDevice.filter(rowDevice => rowDevice.source === row.source && rowDevice.campaign === row.campaign).forEach(rowDevice => {
            const key = `${rowDevice.os}-${rowDevice.browser}`.split(' ').join('');
            row.deviceData[key] = rowDevice.assertive_sessions;
            if (!fields.includes(key)) fields.push(key);
          });
          return row;
        });

        // const sections = updateSectionsBar(fields);
        setState({ data: aggregated/* , sections */ });
      })
      .catch(error => {
        console.error('Failed to fetch campaign device stats:', error);
      }).finally(() => {
        // console.timeEnd(`DeviceStatsCall ${time}`)
      });

  };

  const renderSessionsChart = useCallback((trafficData) => {
    if (!trafficData) return <Skeleton animation="wave" />;

    return (
      <SparkChart data={formatDataChart(trafficData)} field="sessions" highlightCurrentHour />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadCampaignHourlySessions]);

  const renderProfitChart = useCallback((trafficData, source) => {
    if (!trafficData) return <Skeleton animation="wave" />;

    // const available = Object.keys(trafficData).filter(hour => trafficData[hour].sessions > 0).sort();
    // if (entry.stamp === available[available.length - 1])  return '#DEDEDE'

    // Remove todays last hour data because its unreliable (at least for google)
    if (moment(startDate).isSame(moment(), 'day')) {
      const hourNow = moment().format('HH');
      const lastHour = moment().subtract(1, 'hour').format('HH');
      trafficData[hourNow] = { revenue: 0, spend: 0, profit: 0, sessions: 0 };
      if (source === 'ga') trafficData[lastHour] = { revenue: 0, spend: 0, profit: 0, sessions: 0 };
    }
    return (
      <SparkChart
        getColor={(entry) => {
          // if (entry.stamp === available[available.length]) {
          // if (entry.stamp === available[available.length - 1]) {
          //   return '#DEDEDE'
          // }
          const color = entry.data.spend === 0 ? '#D3D3D3' : (entry.count < 0 ? '#B22222' : '#009933');
          return color;
        }}
        data={formatDataChart(trafficData)}
        field="profit"
        type="currency"
      />
    );
  }, [startDate]);

  const columns = useMemo(() => {
    let columns = [];
    columns = columns.concat([
      { title: 'Image', name: 'image', width: 100, filteringEnabled: false, getCellValue: row => <ImageSlider images={row.image} widthHover={300} /> },
      { title: 'Site', name: 'site', width: 110, hidden: site !== '' },
    ]);
    columns = columns.concat([
      { title: 'Source', name: 'source', width: 50 },
      { title: 'Age', name: 'start_time', width: 80, type: 'age', compare: (a, b) => (new Date(b).getTime() - new Date(a).getTime()), filter: filterAge },
      { title: 'Campaign', name: 'campaign', width: 200 },
    ]);
    if (adReport) {
      columns.push({ title: 'Ad', name: 'ad', width: 150 });
    }

    columns = columns.concat([
      { title: 'Title', name: 'title', width: 50 },
      { title: 'Sessions Hourly', name: 'sessionTrends', width: 110, filteringEnabled: false, sortingEnabled: false, formatter: (data) => renderSessionsChart(data.row.aggregatedData) },
      { title: 'Profit Hourly', name: 'adEcpmTrends', width: 110, filteringEnabled: false, sortingEnabled: false, formatter: (data) => renderProfitChart(data.row.aggregatedData, data.row.source) },
      { title: 'Devices', name: 'devicesTrend', width: 120, filteringEnabled: false, sortingEnabled: false, formatter: (data) => <RenderDeviceChart data={data.row.deviceData} sections={sections} /> },
      { title: 'Sessions', name: 'totalSessions', type: 'number', width: 100, summaryEnabled: true },
      { title: 'AY Sessions', name: 'assertive_sessions', type: 'number', width: 100, summaryEnabled: true },
      {
        title: 'AY PPS', name: 'assertive_pps', type: 'smallnumber', width: 80, getCellValue: row => (row.assertive_pageviews / row.assertive_sessions),
        summaryEnabled: true, summaryFunc: (rows) => {
          const pageviews = rows.map(row => row.assertive_pageviews || 0).reduce((a, b) => a + b, 0);
          const sessions = rows.map(row => row.assertive_sessions || 0).reduce((a, b) => a + b, 0);
          return (pageviews / sessions).toFixed(0);
        }
      },
      { title: 'Last Modified Date', name: 'last_modified_date', type: 'text', width: 100, hidden: true, getCellValue: row => moment(row.last_modified).tz('America/Vancouver').format('YYYY-MM-DD HH:mm') },
      { title: 'Last Modification', name: 'last_modification', type: 'text', width: 100, hidden: true, getCellValue: row => (row.last_modification === 'UPDATE' ? 'BUDGET' : row.last_modification) },
      { title: 'AY APS', name: 'assertive_aps', type: 'smallnumber', width: 80 },
      { title: 'Bid Strategy', name: 'bidStrategy', type: 'text', width: 80 },
      { title: 'Bid Target', name: 'bidTarget', type: 'smallcurrency', width: 80, getCellValue: row => (row.bidTarget === 0 ? null : row.bidTarget) },
      { title: 'Campaign Type', name: 'advertising_type', type: 'text', width: 150 },
      { title: 'Revenue Pixel(bid=)', name: 'bid_equals', type: 'text', width: 80 },
      { title: 'tCPA', name: 'target_cpa', type: 'text', width: 80 },
      { title: 'tROAS', name: 'target_roas', type: 'text', width: 80 },
      { title: 'Conversion Goal', name: 'conversion_goal', type: 'text', width: 80 },
      {
        title: 'DayParting',
        name: 'hasDayParting',
        type: 'text',
        width: 103,
        getCellValue: row => {
          if (row.hasDayParting) return 'ON';
          return '';
        }
      },
      {
        title: 'Whitelist',
        name: 'hasWhitelist',
        type: 'text',
        width: 103,
        getCellValue: row => {
          if (row.hasWhitelist) return 'ON';
          return '';
        }
      },
      {
        title: 'Ad Strength',
        name: 'ad_strength',
        type: 'text',
        width: 90,
        hidden: true,
        colorize: (value) => {
          if (value === 'UNSPECIFIED') {
            return 'rgb(204, 204, 0)';
          }
          if (value === 'POOR') {
            return 'rgb(255, 0, 0)';
          }
          if (value === 'AVERAGE') {
            return 'rgb(255, 128, 0)';
          }
          if (value === 'GOOD') {
            return 'rgb(0, 128, 255)';
          }
          if (value === 'EXCELLENT') {
            return 'rgb(0, 200, 0)';
          }
          return 'rgb(211, 211, 211)';
        }
      },
      {
        title: 'Budget', name: 'budget', type: 'text', width: 80,
        getCellValue: row => {
          return `$${Number(row.spend).toFixed(0)}/$${Number(row.budget).toFixed(0)}`;
        },
        formatter: (data) => {
          const budgetNumber = Math.round(Number(data.row.budget));
          const spendNumber = Math.round(Number(data.row.spend));
          const difference = budgetNumber - spendNumber;
          let color = '';
          if (difference <= (budgetNumber * (0.25))) {
            color = 'rgb(189 174 19)';
          }
          if (difference <= (budgetNumber * (0.15))) {
            color = 'rgb(255, 128, 0)';
          }
          if (spendNumber >= budgetNumber) {
            color = 'rgb(255, 0, 0)';
          }
          if (budgetNumber === 0) {
            color = 'rgb(211, 211, 211)';
          }
          return (
            <div>
              <span style={{ color }}>${spendNumber}</span>/${budgetNumber}
            </div>
          );
        }
      },
      { title: 'CPA Conversions', name: 'cpa_conversions', type: 'number', width: 70 },
      { title: 'CPA Clicks', name: 'cpa_clicks', type: 'number', width: 70 },
      { title: 'Search Conversions', name: 'search_conversions', type: 'number', width: 70 },
      { title: 'Search Clicks', name: 'search_clicks', type: 'number', width: 70 },
      { title: 'CPA RPS', name: 'cpa_rps', type: 'currency', width: 70 },
      { title: 'Search RPS', name: 'search_rps', type: 'currency', width: 70 },
      { title: 'CPA %', name: 'cpa_percent', type: 'percent', width: 70, getCellValue: row => ((row.cpa_revenue && row.siteRevenue) > 0 ? ((row.cpa_revenue / row.siteRevenue) * 100) : 0) },
      { title: 'Search %', name: 'search_percent', type: 'percent', width: 70, getCellValue: row => ((row.search_revenue && row.siteRevenue) > 0 ? ((row.search_revenue / row.siteRevenue) * 100) : 0) },

      // all revenues
      { title: 'Total Revenue', name: 'totalRevenue', type: 'currency', width: 90, summaryEnabled: true },
      { title: 'Site Revenue', name: 'siteRevenue', type: 'currency', width: 100, summaryEnabled: true },
      { title: 'AY Revenue', name: 'assertive_revenue', type: 'currency', width: 90, summaryEnabled: true, hidden: true },
      { title: 'Outbrain Revenue', name: 'outbrain_revenue', type: 'currency', width: 90, summaryEnabled: true, hidden: true },
      { title: 'Exco Revenue', name: 'exco_revenue', type: 'currency', width: 90, summaryEnabled: true, hidden: true },
      { title: 'Google Revenue', name: 'google_revenue', type: 'currency', width: 90, summaryEnabled: true, hidden: true },
      { title: 'Mgid Revenue', name: 'mgid_revenue', type: 'currency', width: 90, summaryEnabled: true, hidden: true },
      { title: 'NotifyAI Revenue', name: 'notifyai_revenue', type: 'currency', width: 90, summaryEnabled: true, hidden: true },
      { title: 'Vidazoo Revenue', name: 'vidazoo_revenue', type: 'currency', width: 90, summaryEnabled: true, hidden: true },
      { title: 'AY Vidazoo Revenue', name: 'assertive_vidazoo_revenue', type: 'currency', width: 90, summaryEnabled: true, hidden: true },
      { title: 'AY Display Revenue', name: 'diplayRevenue', type: 'currency', width: 90, summaryEnabled: true, hidden: true },
      { title: 'RevContent Revenue', name: 'revcontent_revenue', type: 'currency', width: 90, summaryEnabled: true, hidden: true },
      { title: 'Content Rec Revenue', name: 'content_recommendation_revenue', type: 'currency', width: 90, summaryEnabled: true },
      {
        title: 'CPA Revenue', name: 'cpa_revenue', type: 'currency', width: 100, summaryEnabled: true, colorize: (value) => {
          if (Number(value) > 0) {
            return '';
            // return 'rgb(0, 200, 0)';
          }
          if (Number(value) < 0) {
            return 'rgb(255, 0, 0)';
          }
          return 'rgb(211, 211, 211)';
        }
      },
      {
        title: 'Search Revenue', name: 'search_revenue', type: 'currency', width: 100, summaryEnabled: true, colorize: (value) => {
          if (Number(value) > 0) {
            return '';
            // return 'rgb(0, 200, 0)';
          }
          if (Number(value) < 0) {
            return 'rgb(255, 0, 0)';
          }
          return 'rgb(211, 211, 211)';
        }
      },
      { title: 'Search eCPM', name: 'search_cpm', type: 'currency', width: 100 },
      // all spends
      { title: 'Spend(TZ)', name: 'spend', type: 'currency', width: 100, summaryEnabled: true },
      {
        title: 'Spend', name: 'spend_pst', type: 'currency', width: 110, summaryEnabled: true
      },
      // all profits
      { title: 'Total Profit', name: 'totalProfit', type: 'currency', width: 90, summaryEnabled: true },
      { title: 'Site Profit', name: 'siteProfit', type: 'currency', width: 90, summaryEnabled: true },
      // all margins
      {
        title: 'Total Margin',
        name: 'totalMargin',
        type: 'percent',
        width: 90,
        colorize: (value) => {
          if (+value === 100 || +value === 0) {
            return 'rgb(211, 211, 211)';
          } if (+value < 0) {
            return 'rgb(255, 0, 0)';
          } if (+value > 0) {
            return 'rgb(0, 200, 0)';
          }
          return 'rgb(211, 211, 211)';
        },
        summaryEnabled: true, summaryFunc: (rows) => {
          const totalProfit = rows.map(row => row.totalProfit || 0).reduce((a, b) => a + b, 0);
          const totalRevenue = rows.map(row => row.totalRevenue || 0).reduce((a, b) => a + b, 0);
          return `${((totalProfit / totalRevenue) * 100).toFixed(2)}%`;
        }
      },
      {
        title: 'Site Margin',
        name: 'siteMargin',
        type: 'percent',
        width: 90,
        colorize: (value) => {
          if (+value === 100 || +value === 0) {
            return 'rgb(211, 211, 211)';
          } if (+value < 0) {
            return 'rgb(255, 0, 0)';
          } if (+value > 0) {
            return 'rgb(0, 200, 0)';
          }
          return 'rgb(211, 211, 211)';
        }, hidden: true,
        summaryEnabled: true, summaryFunc: (rows) => {
          const siteProfit = rows.map(row => row.siteProfit || 0).reduce((a, b) => a + b, 0);
          const siteRevenue = rows.map(row => row.siteRevenue || 0).reduce((a, b) => a + b, 0);
          return `${((siteProfit / siteRevenue) * 100).toFixed(2)}%`;
        }
      },

      {
        title: 'CPC', name: 'cpc', type: 'smallcurrency', width: 70,
        summaryEnabled: true, summaryFunc: (rows) => {
          const spend = rows.map(row => (row.spend_pst > 0 ? row.spend_pst : row.spend)).reduce((a, b) => a + b, 0);
          // const spend = rows.map(row => (row.spend_pst > 1 ? row.spend_pst : row.spend)).reduce((a, b) => a + b, 0);
          const clicks = rows.map(row => row.clicks || 0).reduce((a, b) => a + b, 0);
          return `${(spend / clicks).toFixed(3)}`;
        }
      },
      {
        title: 'CPS', name: 'cps', type: 'smallcurrency', width: 70, hidden: true,
        summaryEnabled: true, summaryFunc: (rows) => {
          const spend = rows.map(row => (row.spend_pst > 0 ? row.spend_pst : row.spend)).reduce((a, b) => a + b, 0);
          // const spend = rows.map(row => (row.spend_pst > 1 ? row.spend_pst : row.spend)).reduce((a, b) => a + b, 0);
          const sessions = rows.map(row => row.totalSessions || 0).reduce((a, b) => a + b, 0);
          return `${(spend / sessions).toFixed(3)}`;
        }
      },
      // { title: 'eCPC', name: 'ecpc', type: 'currency', width: 100 },
      {
        title: 'Click Loss', name: 'click_loss', type: 'smallpercent', width: 80, colorize: (value) => {
          if (value <= 0) {
            return 'rgb(211, 211, 211)';
          } if (value < 20) {
            return 'rgb(0, 200, 0)';
          } if (value < 30) {
            return 'rgb(0, 128, 255)';
          } if (value < 40) {
            return 'rgb(204, 204, 0)';
          } if (value < 50) {
            return 'rgb(255, 128, 0)';
          } if (value <= 100) {
            return 'rgb(255, 0, 0)';
          }
          return 'rgb(255, 0, 0)';
        },
        hidden: true
      },
      { title: 'Clicks', name: 'clicks', type: 'number', width: 100, summaryEnabled: true, hidden: true, },
      { title: 'Conversions', name: 'conversions', type: 'number', width: 100, summaryEnabled: true, hidden: true, },
      { title: 'Conversion Rate', name: 'conversion_rate', type: 'percent', width: 100, summaryEnabled: true, hidden: true, getCellValue: row => (row.conversions / row.clicks).toFixed(2) },
      { title: 'Cost Per Conversion', name: 'cost_per_conversion', type: 'smallcurrency', width: 100, summaryEnabled: true, hidden: true, getCellValue: row => (row.conversions ? row.spend / row.conversions : null) },
      { title: 'CPA Cost/Conversion', name: 'cpa_cost_conversion', type: 'smallcurrency', width: 100, summaryEnabled: true, hidden: true, getCellValue: row => (row.spend && row.cpa_conversions > 0 ? row.spend / row.cpa_conversions : null) },
      { title: 'Search Cost/Conversion', name: 's_cost_conversion', type: 'smallcurrency', width: 100, summaryEnabled: true, hidden: true, getCellValue: row => (row.spend && row.search_conversions > 0 ? row.spend / row.search_conversions : null) },
      { title: 'Conv. Value/cost', name: 'conv_value_cost', width: 100, hidden: true, type: 'formatPercent' },
      // { title: 'Sessions Hourly', name: 'sessionTrends', width: 130, filteringEnabled: false, sortingEnabled: false, formatter: (data) => this.renderTrendChart(data.row.sessionTrafficData) },
      { title: 'Google Sessions', name: 'google_sessions', type: 'number', width: 100, summaryEnabled: true, hidden: true },
      { title: 'AY APP', name: 'assertive_app', type: 'number', width: 80, hidden: true },

      { title: 'AY %', name: 'assertive_sessions_percent', type: 'percent', width: 115 },

      { title: 'AY Rev%', name: 'assertive_revenue_percent', type: 'percent', width: 75, hidden: true },
      { title: 'Outbrain Rev%', name: 'outbrain_revenue_percent', type: 'percent', width: 75, hidden: true },
      { title: 'Exco Rev%', name: 'exco_revenue_percent', type: 'percent', width: 75, hidden: true },
      { title: 'Google Rev%', name: 'google_revenue_percent', type: 'percent', width: 75, hidden: true },
      { title: 'Mgid Rev%', name: 'mgid_revenue_percent', type: 'percent', width: 75, hidden: true },
      { title: 'NotifyAI Rev%', name: 'notifyai_revenue_percent', type: 'percent', width: 75, hidden: true },
      { title: 'Vidazoo Rev%', name: 'vidazoo_revenue_percent', type: 'percent', width: 75, hidden: true },

      {
        title: 'RPS', name: 'rps', type: 'smallcurrency', width: 90,
        summaryEnabled: true, summaryFunc: (rows) => {
          const revenue = rows.map(row => row.siteRevenue || 0).reduce((a, b) => a + b, 0);
          const sessions = rows.map(row => row.totalSessions || 0).reduce((a, b) => a + b, 0);
          return `${(revenue / sessions).toFixed(3)}`;
        }, colorize: (value, row) => {
          if (value < row.cpc) {
            return 'rgb(255, 128, 0)';
          }
          return 'rgb(0, 200, 0)';
        }
      },
      {
        title: 'RPS AY', name: 'assertive_rps', type: 'smallcurrency', width: 90, hidden: true,
        summaryEnabled: true, summaryFunc: (rows) => {
          const revenue = rows.map(row => row.assertive_revenue || 0).reduce((a, b) => a + b, 0);
          const sessions = rows.map(row => row.assertive_sessions || 0).reduce((a, b) => a + b, 0);
          return `${(revenue / sessions).toFixed(3)}`;
        }
      },
      { title: 'RPS Outbrain', name: 'outbrain_rps', type: 'smallcurrency', width: 90, hidden: true },
      { title: 'RPS Exco', name: 'exco_rps', type: 'smallcurrency', width: 90, hidden: true },
      { title: 'RPS Google', name: 'google_rps', type: 'smallcurrency', width: 90, hidden: true },
      { title: 'RPS Mgid', name: 'mgid_rps', type: 'smallcurrency', width: 90, hidden: true },
      { title: 'RPS NotifyAI', name: 'notifyai_rps', type: 'smallcurrency', width: 90, hidden: true },
      { title: 'RPS Vidazoo', name: 'vidazoo_rps', type: 'smallcurrency', width: 90, hidden: true },
      { title: 'AY RPS Vidazoo', name: 'assertive_vidazoo_rps', type: 'smallcurrency', width: 90, hidden: true },
      { title: 'AY RPS Display', name: 'displayRps', type: 'smallcurrency', width: 90, hidden: true },
      { title: 'RPS RevContent', name: 'revcontent_rps', type: 'smallcurrency', width: 90, hidden: true },

      // rev per session rps session / 1000
      { title: 'Session Profit', name: 'session_profit', type: 'smallcurrency', width: 90, hidden: true },
      { title: 'Session Margin', name: 'session_margin', type: 'smallpercent', width: 90, hidden: true },

      { title: 'CPM AY', name: 'assertive_cpm', type: 'smallcurrency', width: 90, hidden: true },
      { title: 'CPM Outbrain', name: 'outbrain_cpm', type: 'smallcurrency', width: 90, hidden: true },
      { title: 'CPM Mgid', name: 'mgid_cpm', type: 'smallcurrency', width: 90, hidden: true },

      { title: 'Pageviews AY', name: 'assertive_pageviews', type: 'number', width: 90, summaryEnabled: true, hidden: true },
      // { title: 'Pageviews Google', name: 'google_pageviews', type: 'number', width: 90, summaryEnabled: true, hidden: true },

      { title: 'Avg Time On Page', name: 'avgTimeOnPage', width: 90, getCellValue: row => `${row.avgTimeOnPage} sec`, hidden: true },
      { title: 'Avg Session Duration', name: 'avgSessionDuration', width: 90, getCellValue: row => `${(row.avgSessionDuration / 60).toFixed(1)} min`, hidden: true },

      { title: 'AY Viewable', name: 'assertive_activeViewable', type: 'percent', width: 100, hidden: true },

      { title: 'Date', name: 'date', width: 100, getCellValue: row => (row.date ? row.date.substr(0, 10) : ''), hidden: true },
      { title: 'Total Ads', name: 'eligible_ads', width: 100, hidden: true },
      { title: 'Static Ads', name: 'static_ads', width: 100, hidden: true },
      { title: 'Responsive Ads', name: 'responsive_ads', width: 100, hidden: true },
      { title: 'Conversion Target', name: 'conversion_target', width: 100, hidden: true },
      { title: '&ev', name: '&ev', width: 100, hidden: true, getCellValue: (row) => (row && row.url ? row.url.includes('&ev') ? 'On' : null : null) },
      { title: 'Avg Search Value', name: 'avg_search_value', width: 100, type: 'smallcurrency', hidden: true, getCellValue: (row) => (row && row.search_conversions && row.search_revenue ? row.search_revenue / row.search_conversions : null) },
      { title: 'Search Conv Rate', name: 'search_conv_rate', width: 100, type: 'percent', hidden: true, getCellValue: (row) => (row && row.search_conversions && row.clicks ? row.search_conversions / row.clicks : null) },
      { title: 'PPC', name: 'payment_mode', width: 100, hidden: true, getCellValue: (row) => (row.payment_mode ? row.payment_mode === 'CONVERSIONS' ? 'ON' : 'OFF' : null) },
      {
        title: 'CTR', name: 'ctr', type: 'percent', width: 70, hidden: true, colorize: (value) => {
          if (value < 0.5) {
            return 'rgb(255, 0, 0)';
          } if (value < 0.75) {
            return 'rgb(255 156 47)';
          } if (value < 1.0) {
            return 'rgb(68 194 210)';
          }
          return 'rgb(0, 200, 0)';
        },
        summaryEnabled: true, summaryFunc: (rows) => {
          const impressions = rows.map(row => row.impressions || 0).reduce((a, b) => a + b, 0);
          const clicks = rows.map(row => row.clicks || 0).reduce((a, b) => a + b, 0);
          return `${((clicks / impressions) * 100).toFixed(2)}%`;
        }
      },
      { title: 'Calls', name: 'calls', type: 'number', hidden: true, width: 70, summaryEnabled: true },
      { title: 'Over 3 Min', name: 'over_3_min', type: 'number', hidden: true, width: 70, summaryEnabled: true },
      { title: 'Over 7 Min', name: 'over_7_min', type: 'number', hidden: true, width: 70, summaryEnabled: true },
      {
        title: '3 Min %', name: '3_min_over_%', type: 'percent', hidden: true, width: 100, getCellValue: (row) => (row && row.over_3_min && row.calls ? (row.over_3_min / row.calls) * 100 : null),
        summaryEnabled: true, summaryFunc: (rows) => {
          const validCalls = rows.map(row => row.calls || 0).reduce((a, b) => a + b, 0);
          const over3MinSum = rows.map(row => row.over_3_min || 0).reduce((a, b) => a + b, 0);
          return `${((over3MinSum / validCalls) * 100).toFixed(2)}%`;
        }
      },
      {
        title: '7 Min %', name: '7_min_over_%', type: 'percent', hidden: true, width: 100, getCellValue: (row) => (row && row.over_7_min && row.calls ? (row.over_7_min / row.calls) * 100 : null),
        summaryEnabled: true, summaryFunc: (rows) => {
          const validCalls = rows.map(row => row.calls || 0).reduce((a, b) => a + b, 0);
          const over7MinSum = rows.map(row => row.over_7_min || 0).reduce((a, b) => a + b, 0);
          return `${((over7MinSum / validCalls) * 100).toFixed(2)}%`;
        }
      },
    ]);

    return columns;
  }, [adReport, renderProfitChart, renderSessionsChart, site, sections]);

  const onReportStats = useCallback((event, row) => {
    const siteAux = site || row.site;
    const location = (row.campaign.includes('dc_pf') || row.campaign.includes('dc_sc')) ? 'direct-campaign' : 'campaign';
    openLinkV2(
      event,
      `/webstats/${location}/?site=${siteAux}&source=${row.source}&campaign=${row.campaign}&startDate=${startDate.substr(0, 10)}&endDate=${endDate.substr(0, 10)}`,
      navigate
    );
  }, [endDate, navigate, site, startDate]);

  // const onReportPublishers = useCallback((event, row) => {
  //   const siteAux = site || row.site;
  //   const adReportAux = adReport ? `&ad=${row.ad}` : '';
  //   openLinkV2(
  //     event,
  //     `/webstats/publishers/?site=${siteAux}&source=${row.source}&campaign=${row.campaign}${adReportAux}&startDate=${startDate.substr(0, 10)}&endDate=${endDate.substr(0, 10)}`,
  //     navigate
  //   );
  // }, [adReport, endDate, navigate, site, startDate]);

  const updateData = useCallbackCancel(async (cancelToken) => {
    setState({ loading: true });

    const source = new URLSearchParams(search).get('source');
    const campaign = new URLSearchParams(search).get('campaign');
    const params = { timezone, startDate, endDate, site, source, campaign };

    Promise.all([
      adReport ? WebstatsAPI.gazillions.getAdStats(params, cancelToken) : WebstatsAPI.gazillions.getCampaignStats(params, cancelToken),
      JuicyXAPI.data.getConversionsCampaign(params, cancelToken),
      WebstatsAPI.gazillions.getTopAdByCampaign(params, cancelToken),
    ]).then(([
      response,
      conversionsCampaignResponse,
      topAdsCampaignResponse,
    ]) => {

      const juicyCPAToAdd = conversionsCampaignResponse.data;
      const topAdStats = topAdsCampaignResponse.data;

      let data = response.data.results;

      if (data && data.length) {
        if (source && campaign) {
          data = data.filter(row => row.source === source && row.campaign === campaign);
        }
        data = data.map(row => {
          row.siteByName = getSiteByName(row.campaign); // Used to filter the campaigns that juicy sent to another site
          row.image = [];
          row.cpa_revenue = 0;
          row.search_revenue = 0;
          const topAdIndex = topAdStats.findIndex(ta => ta.site === row.site && ta.campaign === row.campaign && ta.source === row.source);
          if (topAdIndex !== -1) {
            const resImg = topAdStats[topAdIndex].image_url;
            let images = [];

            try {
              const imagesAux = JSON.parse(resImg);
              images = imagesAux;
            } catch (error) {
              images = resImg;
            }

            if (typeof images === 'string') {
              images = [images];
            }
            // move the logo to the back so its not just a big table of fire pictures
            row.image = images.reverse();
            row.ad_strength = topAdStats[topAdIndex].ad_strength;
            topAdStats.splice(topAdIndex, 1);
          }

          if (juicyCPAToAdd && juicyCPAToAdd.length) {
            juicyCPAToAdd.forEach(juicy => {
              if (juicy.campaign === row.campaign) {
                row[`${juicy.category}_revenue`] = juicy.revenue;
                row[`${juicy.category}_clicks`] = juicy.clicks;
                row[`${juicy.category}_conversions`] = juicy.conversions;
                row[`${juicy.category}_rps`] = row[`${juicy.category}_revenue`] / row[`${juicy.category}_clicks`];
                if (juicy.campaign === row.campaign) row[`${juicy.category}_cpm`] = juicy.cpm;
              }
            });
          }

          const allKeys = Object.keys(row);

          row.siteRevenue = 0;
          // Gets the revenue from all the keys that ends with _revenue
          // if we add a new field on the cronut project, its going to automatically be summed
          const revenueKeys = allKeys.filter(key => key.endsWith('_revenue'));
          revenueKeys.forEach(key => {
            // TODO: We temporarily need to remove vidazoo revenue while we are debugging vidazoo vs ay_vidazoo, otherwise we would double count (After we remove the vidazoo_revenue colkumn we can delete this line)
            if (key === 'vidazoo_revenue') return;
            if (key === 'content_revenue' || key === 'cpa_revenue' || key === 'search_revenue') return;
            row.siteRevenue += row[key];
          });

          row.diplayRevenue = row.siteRevenue - row.assertive_vidazoo_revenue;
          row.displayRps = row.diplayRevenue / row.totalSessions;

          // Calculates the percentage of each revenue
          revenueKeys.forEach(key => {
            if (key === 'content_revenue') return; // || key === 'cpa_revenue' || key === 'search_revenue') return;
            row[`${key}_percent`] = (row[key] / row.siteRevenue) * 100;
          });

          if (row.source === 'mg') {
            row.siteProfit = row.siteRevenue - row.spend;
          } else {
            row.siteProfit = row.siteRevenue - row.spend_pst;
          }

          row.totalProfit = row.siteProfit + row.cpa_revenue + row.search_revenue;
          row.siteMargin = (100 * (row.siteProfit / row.siteRevenue)).toFixed(0);
          row.totalRevenue = (row.siteRevenue + row.cpa_revenue + row.search_revenue);
          row.totalMargin = (100 * (row.totalProfit / row.totalRevenue)).toFixed(0);

          // Sum all sources of impressions to calculate CPM
          const impressionsKeys = allKeys.filter(key => key.endsWith('_impressions'));
          row.totalImpressions = 0;
          impressionsKeys.forEach(key => {
            row.totalImpressions += row[key];
          });

          row.cpm = (row.siteRevenue / row.totalImpressions) * 1000;

          // Sum all sources of sessions to calculate RPS
          const sessionsKeys = allKeys.filter(key => key.endsWith('_sessions'));
          row.totalSessions = 0;
          sessionsKeys.forEach(key => {
            row.totalSessions += row[key];
          });

          row.rps = row.siteRevenue / (row.totalSessions);
          row.assertive_sessions_percent = (row.assertive_sessions && row.totalSessions) > 0 ? ((row.assertive_sessions / row.totalSessions) / row.totalSessions) * 100 : 0;
          row.session_profit = row.rps - row.cps;
          row.session_margin = (100 * ((row.rps - row.cps) / row.rps)).toFixed(0);
          row.click_loss = row.clicks === 0 ? 0 : (100 * ((row.clicks - row.totalSessions) / row.clicks)).toFixed(0);

          return row;
        });

        data = data.filter(row => row.campaign === 'unknown' || row.source === 'dv' || row.siteByName === row.site); // Used to filter the campaigns that juicy sent to another site
      }

      setState({ data, loading: false });
      loadCampaignHourlySessions(data, cancelToken);
      loadCampaignDeviceStats(data, cancelToken);
    }).catch(error => {
      if (Axios.isCancel(error)) return;
      console.error(error);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adReport, endDate, search, site, startDate, timezone]);

  const getReportByDay = useCallback(async () => {
    setState({ loading: true });

    const source = new URLSearchParams(search).get('source');
    const campaign = new URLSearchParams(search).get('campaign');

    const params = {
      timezone,
      startDate,
      endDate,
      site,
      source,
      campaign
    };

    let res = false;

    await Promise.all([
      WebstatsAPI.gazillions.getCampaignDailyStats(params),
      JuicyXAPI.data.getConversionsCampaign(params),
    ]).then(([
      response,
      conversionsCampaignResponse,
    ]) => {
      const juicyCPAToAdd = conversionsCampaignResponse.data;
      let data = response.data.results;

      if (data && data.length) {
        if (source && campaign) {
          data = data.filter(row => row.source === source && row.campaign === campaign);
        }

        data = data.map(row => {
          if (juicyCPAToAdd && juicyCPAToAdd.length) {
            juicyCPAToAdd.forEach(juicy => {
              if (juicy.campaign === row.campaign) {
                row[`${juicy.category}_revenue`] = juicy.revenue;
                row[`${juicy.category}_clicks`] = juicy.clicks;
                row[`${juicy.category}_conversions`] = juicy.conversions;
                row[`${juicy.category}_rps`] = row[`${juicy.category}_revenue`] / row[`${juicy.category}_clicks`];
              }
            });
          }

          row.siteByName = getSiteByName(row.campaign); // Used to filter the campaigns that juicy sent to another site
          row.image = [];
          row.cpa_revenue = 0;
          row.search_revenue = 0;
          const allKeys = Object.keys(row);

          row.siteRevenue = 0;
          const revenueKeys = allKeys.filter(key => key.endsWith('_revenue'));
          revenueKeys.forEach(key => {
            if (key === 'content_revenue' || key === 'cpa_revenue' || key === 'search_revenue') return;
            row.siteRevenue += row[key];
          });

          revenueKeys.forEach(key => {
            if (key === 'content_revenue' || key === 'cpa_revenue' || key === 'search_revenue') return;
            row[`${key}_percent`] = (row[key] / row.siteRevenue) * 100;
          });

          if (row.source === 'mg') {
            row.siteProfit = row.siteRevenue - row.spend;
          } else {
            row.siteProfit = row.siteRevenue - row.spend_pst;
          }

          row.totalProfit = row.siteProfit + row.cpa_revenue + row.search_revenue;
          row.siteMargin = (100 * (row.siteProfit / row.siteRevenue)).toFixed(0);
          row.totalRevenue = (row.siteRevenue + row.cpa_revenue + row.search_revenue);
          row.totalMargin = (100 * (row.totalProfit / row.totalRevenue)).toFixed(0);

          const impressionsKeys = allKeys.filter(key => key.endsWith('_impressions'));
          row.totalImpressions = 0;
          impressionsKeys.forEach(key => {
            row.totalImpressions += row[key];
          });

          row.cpm = (row.siteRevenue / row.totalImpressions) * 1000;

          const sessionsKeys = allKeys.filter(key => key.endsWith('_sessions'));
          row.totalSessions = 0;
          sessionsKeys.forEach(key => {
            row.totalSessions += row[key];
          });

          row.rps = row.siteRevenue / (row.totalSessions);
          row.assertive_sessions_percent = (row.assertive_sessions / row.totalSessions) * 100;
          row.session_profit = row.rps - row.cps;
          row.session_margin = (100 * ((row.rps - row.cps) / row.rps)).toFixed(0);
          row.click_loss = row.clicks === 0 ? 0 : (100 * ((row.clicks - row.totalSessions) / row.clicks)).toFixed(0);
          return row;
        });

        data = data.filter(row => row.campaign === 'unknown' || row.source === 'dv' || row.siteByName === row.site); // Used to filter the campaigns that juicy sent to another site
      }
      setState({ loading: false });
      res = data;
    }).catch(error => {
      if (Axios.isCancel(error)) return;
      console.error(error);
    });
    return res;
  }, [endDate, search, site, startDate, timezone]);

  const exportToCSV = useCallback(() => {
    const csvData = parseData(data);
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf8;' });

    FileSaver.saveAs(blob, 'gazillions_' + Date.now() + '.csv');
  }, [data]);

  const exportToCSVByDay = useCallback(async () => {
    const dataByDay = await getReportByDay();
    const csvData = parseData(dataByDay);
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf8;' });

    FileSaver.saveAs(blob, 'gazillions_day_' + Date.now() + '.csv');
  }, [getReportByDay]);

  const filteredData = useMemo(() => {
    if (showCloseToBudget) {
      return data.filter(row => (row.budget - row.spend <= row.budget * (0.25)) || (row.spend >= row.budget));
    }
    if (showNoSpend) {
      return data.filter(row => {
        if (row.campaign.startsWith('dc_pf_')) {
          return true;
        }
        return row.spend >= 0.01;
      });
    }

    return data;
  }, [data, showNoSpend, showCloseToBudget]);

  const dateRange = () => {
    if (moment().format('YYYY-MM-DD') === moment(startDate).format('YYYY-MM-DD')) {
      return 'Today';
    } if (moment().subtract(1, 'day').format('YYYY-MM-DD') === moment(startDate).format('YYYY-MM-DD')) {
      return 'Yesterday';
    } if (moment(startDate).format('YYYY-MM-DD') !== moment(endDate).format('YYYY-MM-DD')) {
      return `${moment(startDate).format('YYYY-MM-DD')} - ${moment(endDate).format('YYYY-MM-DD')}`;
    }
    return moment(startDate).format('YYYY-MM-DD');
  };

  const handleApplyNewBudget = useCallback(async (dataToEdit, campaignChanges) => {
    const profile = await authClient.getProfile();

    const commonData = {
      site: dataToEdit.site,
      source: dataToEdit.source,
      campaign: dataToEdit.campaign,
      user: profile.given_name || '',
      start_date: moment().tz('America/Vancouver').format('YYYY-MM-DD HH:mm'),
      end_date: moment().tz('America/Vancouver').format('YYYY-MM-DD HH:mm'),
    };

    if (campaignChanges && (campaignChanges.newStrategy || campaignChanges.newBidTarget)) {
      const data = {
        initial_budget: campaignChanges.newBidTarget !== null ? campaignChanges.newBidTarget : undefined,
        max_budget: dataToEdit.bidTarget,
        fallback_budget: dataToEdit.bidStrategy,
        type: 'STRATEGY',
        value: campaignChanges.newStrategy || dataToEdit.bidStrategy,
      };

      try {
        await BBotAPI.job.create({ ...commonData, ...data });
      } catch (er) {
        console.error(er);
      }
    }

    if (campaignChanges && campaignChanges.newBudget) {
      const data = {
        initial_budget: dataToEdit.budget,
        type: 'UPDATE',
        value: Number(campaignChanges.newBudget).toFixed(2),
      };

      try {
        await BBotAPI.job.create({ ...commonData, ...data });
      } catch (er) {
        console.error(er);
      }
    }
    if (campaignChanges && campaignChanges.newHasDayParting) {
      if (campaignChanges.newHasDayParting !== 'add' && campaignChanges.newHasDayParting !== 'remove') {
        return;
      }
      const data = {
        initial_budget: dataToEdit.budget,
        type: 'DAY_PARTING',
        max_budget: campaignChanges.max_budget,
        fallback_budget: campaignChanges.fallback_budget,
        value: campaignChanges.newHasDayParting === 'add' ? true : campaignChanges.newHasDayParting === 'remove' ? false : undefined,
      };
      try {
        await BBotAPI.job.create({ ...commonData, ...data });
      } catch (er) {
        console.error(er);
      }
    }

    if (campaignChanges && campaignChanges.newHasWhitelist) {
      if (campaignChanges.newHasWhitelist !== 'add' && campaignChanges.newHasWhitelist !== 'remove') {
        return;
      }

      const data = {
        initial_budget: dataToEdit.budget,
        type: 'WHITELIST',
        value: campaignChanges.newHasWhitelist === 'add' ? true : campaignChanges.newHasWhitelist === 'remove' ? false : undefined,
      };

      try {
        await BBotAPI.job.create({ ...commonData, ...data });
      } catch (er) {
        console.error(er);
      }
    }

    if (campaignChanges && campaignChanges.newUrl) {
      const data = {
        initial_budget: dataToEdit.budget,
        type: 'UPDATE_URL',
        max_budget: dataToEdit.url,
        value: campaignChanges.newUrl,
      };

      try {
        await BBotAPI.job.create({ ...commonData, ...data });
      } catch (er) {
        console.error(er);
      }
    }

    if (campaignChanges && (campaignChanges.newConversionTarget)) {
      const data = {
        initial_budget: dataToEdit.budget,
        type: 'CONVERSION_TARGET',
        max_budget: dataToEdit.conversion_target,
        value: campaignChanges.newConversionTarget,
      };

      try {
        await BBotAPI.job.create({ ...commonData, ...data });
      } catch (er) {
        console.error(er);
      }
    }
    setState({ isDrawer: false, isDrawerBatch: false });
  }, []);

  const pauseCampaign = async (campaignsToPause) => {
    const profile = await authClient.getProfile();
    let paused = 0;
    for await (const pauseCampaign of campaignsToPause) {
      const data = {
        site: pauseCampaign.site,
        source: pauseCampaign.source,
        campaign: pauseCampaign.campaign,
        initial_budget: pauseCampaign.budget,
        user: profile.given_name || '',
        type: 'PAUSE',
        value: null,
        start_date: moment().tz('America/Vancouver').format('YYYY-MM-DD HH:mm'),
        end_date: moment().tz('America/Vancouver').format('YYYY-MM-DD HH:mm'),
      };
      try {
        const results = await BBotAPI.job.create(data);
        if (results) {
          paused++;
        }
      } catch (er) {
        console.error(er);
      }
    }
    console.log('Paused', `${paused}/${campaignToPause.length}`, 'campaigns');
    setState({ campaignToPause: [], isPauseCampaign: false });
  };

  const tryBatchUpdate = useCallback(() => {
    setState({ isBatch: true });
    if (!selectedRows.length) {
      setState({ isBatch: false });
      alert('Select Campaigns For Batch Update');
      return;
    }

    const dataToEditBatch = filteredData.filter((row, i) => selectedRows.includes(i));

    const sources = [...new Set(dataToEditBatch.map(row => row.source))];

    const bidStrategy = [...new Set(dataToEditBatch.map(row => row.bidStrategy))];
    const campaignType = [...new Set(dataToEditBatch.map(row => row.advertising_type))];
    const hasTarget = [...new Set(dataToEditBatch.map(row => row.bidTarget))];

    const hasNotSupported = sources.find(s => s !== 'ga' && s !== 'me');
    if (hasNotSupported) {
      setState({ isBatch: false });
      alert('Only Google and Baidu Campaigns Are Supported For Batch Update');
      return;
    }

    const hasGoogle = sources.find(s => s === 'ga');
    const hasBaidu = sources.find(s => s === 'me');
    if (hasBaidu && hasGoogle) {
      setState({ isBatch: false });
      alert('Only one Source At a Time Is Supported For Batch Update');
      return;
    }
    // all camps to update need to e matching type, bid strat and have a target
    if (campaignType.length === 1) {
      if (!hasTarget.every(element => element > 0)) {
        setState({ isBatch: false });
        alert('Campaigns must have a target CPA for batch update');
        return;
      }
      if (!bidStrategy.length > 1) {
        setState({ isBatch: false });
        alert('Only same bid strategy campaigns are supported for batch update');
        return;
      }
    } else {
      alert('Only same campaign type campaigns are supported for batch update');
      return;
    }

    setState({ isDrawerBatch: true, dataToEditBatch, isBatch: false });

  }, [filteredData, selectedRows]);

  const tryBatchPause = useCallback(() => {
    setState({ isBatchPause: true });
    if (!selectedRows.length) {
      setState({ isBatchPause: false });
      return;
    }
    const campaignToPause = filteredData.filter((row, i) => selectedRows.includes(i));
    if (campaignToPause.length === 0) {
      setState({ isBatchPause: false });
      alert('Select Campaigns For Batch Pause');
      return;
    }

    setState({ isBatchPause: false, campaignToPause });

  }, [filteredData, selectedRows]);

  const handleSelectChange = useCallback((rows) => {
    setState({ selectedRows: rows });
  }, []);

  const titlebarActions = useMemo(() => {
    const isTimeframe = moment(endDate).diff(moment(startDate), 'days');

    const bars = [
      {
        tooltip: 'Graphic Charts',
        icon: <InsightsIcon />,
        onClick: () => handleToggleGraphics()
      },
      {
        tooltip: 'Batch Update',
        icon: <EditIcon />,
        onClick: () => tryBatchUpdate()
      },
      {
        tooltip: 'Batch Pause',
        icon: <PauseCircleIcon />,
        onClick: () => tryBatchPause()
      },
      {
        tooltip: showNoSpend ? 'Show No Spending' : 'Hide No Spending',
        icon: <MoneyOffIcon sx={{ color: showNoSpend ? 'inherit' : 'red' }} />,
        onClick: () => setState({ showNoSpend: !showNoSpend })
      },
      {
        tooltip: 'Show Close To Budget',
        icon: <HourglassEmptyIcon sx={{ color: !showCloseToBudget ? 'inherit' : 'red' }} />,
        onClick: () => setState({ showCloseToBudget: !showCloseToBudget })
      },
      {
        tooltip: 'Export CSV',
        icon: <ExportIcon />,
        onClick: exportToCSV
      }
    ];

    if (isTimeframe > 0) {
      bars.push({
        tooltip: 'Export CSV BY DAY',
        icon: <DownloadIcon />,
        onClick: exportToCSVByDay
      });
    }
    return bars;
  }, [endDate, tryBatchUpdate, tryBatchPause,
    // openChangeLog,
    exportToCSV, exportToCSVByDay, showNoSpend, showCloseToBudget, startDate, handleToggleGraphics]);

  const rowActions = useMemo(() => {
    return [{
      tooltip: 'Campaign Stats',
      icon: <ChartIcon />,
      onClick: (row, event) => onReportStats(event, row)
    },
    {
      tooltip: 'Change Budget',
      icon: <EditIcon />,
      onClick: (row) => setState({ isDrawer: true, dataToEdit: row, campaignToPause: [row] })
    },
    ];
  }, [onReportStats]);

  useEffect(() => {
    updateData();
  }, [updateData]);

  useRefresh(updateData);

  const titleTable = `${adReport ? 'Ad' : 'Campaign'} ${title ? `${title} ` : ''}Report - ${dateRange()}`;
  return (
    <>
      <KinkTable
        tableKey="campaign_report"
        title={titleTable}
        titlebarActions={titlebarActions}
        rowActions={rowActions}
        filters={filters}
        data={filteredData}
        columns={columns}
        defaultSortColumn="spend"
        loading={loading}
        actionColumnWidth={100}
        filtering
        summary
        striped
        hover
        hidden
        actionColumnFirst
        defaultPageSize={50}
        selection
        onSelectionChange={handleSelectChange}
        selectedRows={selectedRows}
      />

      <DialogBudget
        handleApplyNewBudget={handleApplyNewBudget}
        isDrawer={isDrawer}
        isScheduler={false}
        handleCancelDrawer={() => setState({ isDrawer: false })}
        dataToEdit={dataToEdit}
        handlePauseCampaign={() => setState({ isPauseCampaign: true })}
      />

      <DialogBudgetBatch
        handleApplyNewBudget={handleApplyNewBudget}
        isDrawer={isDrawerBatch}
        isScheduler={false}
        handleCancelDrawer={() => setState({ isDrawerBatch: false })}
        dataToEditBatch={dataToEditBatch}
      />

      <ConfirmDialog
        open={state.isPauseCampaign}
        onCancel={() => setState({ isPauseCampaign: false, campaignToPause: [] })}
        onConfirm={() => pauseCampaign(campaignToPause)}
        okText="Confirm"
        promptText={(
          <>
            <span>Are you sure you want to pause this {campaignToPause.length} campaigns:</span>
            <br />
            {campaignToPause.map((campaign, index) => {
              return (
                <React.Fragment key={campaign.campaign}>
                  <span className={classes.campaignTextPause}>{campaign.campaign}</span>
                  {index !== campaignToPause.length - 1 && <br />}
                </React.Fragment>
              );
            })}
          </>
        )}
      />
    </>
  );
};

WebstatsTableCampaigns.propTypes = {
  filters: PropTypes.array.isRequired,
  adReport: PropTypes.bool,
  title: PropTypes.string,
  handleToggleGraphics: PropTypes.func.isRequired,
};

WebstatsTableCampaigns.defaultProps = {
  adReport: false,
  title: '',
};

export default WebstatsTableCampaigns;
