import request from './request';

const CronutJuicyAPI = {
  stats: {
    getSummaryMonthly: (params, cancelToken) => request({ url: '/cronut_juicy/site/monthly', method: 'get', params, cancelToken }),
    getSourceSummaryData: (params, cancelToken) => request({ url: '/cronut_juicy/sources/summary', method: 'get', params, cancelToken }),

    getSpendRevenueSummary: (params, cancelToken) => request({ url: '/cronut_juicy/gazillions/summary', method: 'get', params, cancelToken }),
    getSourceProfitDaily: (params, cancelToken) => request({ url: '/cronut_juicy/gazillions/site/source-profit', method: 'get', params, cancelToken }),
  },
};

export default CronutJuicyAPI;
