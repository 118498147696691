import React from 'react';
import { Routes as Switch, Route, Navigate } from 'react-router-dom';

// Styles
import Box from '@mui/material/Box';

// Components
import SimpleTabs from 'core/Components/SimpleTabs';
import FacebookAccountsView from './FacebookAccountsView';

// Routes

const options = [
  { value: 'accounts/facebook', label: 'Facebook' },
];

const Routes = () => {
  return (
    <Switch>
      <Route
        path="/"
        exact
        element={<Navigate to="facebook" />}
      />

      <Route
        path="/facebook"
        exact
        element={<FacebookAccountsView />}
      />

    </Switch>
  );
};

const AccountsUi = () => {
  return (
    <SimpleTabs
      options={options}
      defaultSelected="/accounts"
      useIcons
    >
      <Box sx={{ margin: '30px 10px 10px 10px' }}>
        <Routes />
      </Box>
    </SimpleTabs>
  );
};

export default AccountsUi;
