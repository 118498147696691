import request from './request';

const routes = Object.freeze({
  bearclaw: '/version/bearclaw',
});

const VersionAPI = {
  bearclaw: {
    get: () => request({ url: routes.bearclaw, method: 'get' }),
  },
};

export default VersionAPI;
