import React, { useCallback, useMemo, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import AccountsAPI from '../../../services/api/accounts';

const FacebookAccountContext = React.createContext();

export const snackBarInitialState = { open: false, message: undefined, severity: undefined };
function FacebookAccountProvider({ children }) {
  const [snackBar, setSnackBar] = useReducer((oldState, newState) => ({ ...oldState, ...newState }), snackBarInitialState);
  const [loading, setIsLoading] = useState();
  const [accountList, setAccountList] = useState([]);
  const [state, setState] = useState();

  const fetchData = useCallback(async (cancelToken) => {
    setIsLoading('fetching-data');
    const params = {};
    try {
      const response = await AccountsAPI.facebook.listAccounts(params, cancelToken);
      const data = response.data;
      setAccountList(data);
    } catch (error) {
      if (error?.response?.data?.[0] > 300) {
        setSnackBar({ message: error?.response?.data?.[1], status: 'error', open: true });
      }
    }

    setIsLoading(undefined);
  }, []);

  const value = useMemo(() => {
    return { state, setState, fetchData, loading, setIsLoading, snackBar, setSnackBar, accountList };
  }, [state, fetchData, loading, snackBar, accountList]);

  return <FacebookAccountContext.Provider value={value}>{children}</FacebookAccountContext.Provider>;
}

function useFacebookAccount() {
  const context = React.useContext(FacebookAccountContext);
  if (context === undefined) {
    throw new Error('useFacebookAccount must be used within a FacebookAccountProvider');
  }
  return context;
}

FacebookAccountProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

export { FacebookAccountProvider, useFacebookAccount, FacebookAccountContext };
