import React, { useCallback, useEffect, useReducer, useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import FormControlLabel from '@mui/material/FormControlLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';

import { dataFormatter } from 'core/Components/Tables/TableFilters';
import { useFilter } from 'contexts/FilterProvider';
import ReactSelect from 'core/Components/ReactSelect';
import SparkChart from 'core/Components/Charts/SparkChart';

import ListCampaignsAdjuster from 'components/WebStats/Lists/ListCampaignsAdjuster';

import { isMobile } from 'react-device-detect';
import ListCampaignHistory from 'components/WebStats/Lists/ListCampaignHistory';

const useStyles = makeStyles({
  containerDrawer: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '0 1rem',
    gap: '1rem',
    // justifyContent: 'space-evenly'
  },
  containerTable: {
    border: '1px solid #adabab',
    borderRadius: '2px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flex: '0',
    // padding: '1rem'
  },
  containerEditable: {
    border: '1px solid #adabab',
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  textFieldDisabled: {
    paddingBottom: '4px',
    '& label.Mui-disabled': {
      color: '#555555',
    },
    '& input.Mui-disabled': {
      color: '#a49b9b',
      '-webkit-text-fill-color': 'unset'
    }
  },
  rowDisplay: {
    display: 'flex',
    gap: '1rem'
  },
  containerRow: {
    padding: '1rem 10px'
  },
  containerButtons: {
    justifyContent: isMobile ? 'flex-start' : 'flex-end',
    display: 'flex',
    gap: '1rem',
    padding: '10px'
  },
  containerChanges: {
    marginBottom: '1rem',
  },
  flexEnd: {
    alignItems: 'flex-end',
  },
  flexStart: {
    alignItems: 'flex-start',
  },
  pauseDiv: {
    display: 'flex',
    flex: 'auto'
  }
});

const strategies = [
  { name: 'Target CPA', id: 'TARGET_CPA' },
  { name: 'MAXIMIZE CONVERSIONS', id: 'MAXIMIZE_CONVERSIONS' },
  { name: 'MAXIMIZE CLICKS', id: 'TARGET_SPEND' },
  { name: 'TARGET ROAS', id: 'TARGET_ROAS' }
];

const strategiesOutbrain = [
  { name: 'Target CPA', id: 'TARGET_CPA_FULLY_AUTOMATED' },
  { name: 'MAXIMIZE CONVERSIONS', id: 'MAX_CONVERSION_FULLY_AUTOMATED' },
  { name: 'CPC', id: 'CPC' },
  // { name: 'TRAFFIC', id: 'TRAFFIC' },
];

const strategiesTaboola = [
  { name: 'SMART', id: 'SMART' },
  { name: 'MAX_CONVERSIONS', id: 'MAX_CONVERSIONS' },
  { name: 'TARGET_CPA', id: 'TARGET_CPA' },
  // { name: 'FIXED', id: 'FIXED' },
];

const conversionTaboola = [
  { id: '1C', name: '1C' },
  { id: '2C', name: '2C' },
  { id: '3C', name: '3C' },
  { id: '4C', name: '4C' },
  { id: '5C', name: '5C' },
  { id: '6C', name: '6C' },
  { id: '7C', name: '7C' },
  { id: '8C', name: '8C' },
  { id: '9C', name: '9C' },
  { id: '10C', name: '10C' },
  { id: '11C', name: '11C' },
  { id: '12C', name: '12C' },
  { id: '13C', name: '13C' },
  { id: '14C', name: '14C' },
  { id: '15C', name: '15C' },
  { id: '16C', name: '16C' },
  { id: '17C', name: '17C' },
  { id: '18C', name: '18C' },
  { id: '19C', name: '19C' },
  { id: '20C', name: '20C' },
  { id: '21C', name: '21C' },
  { id: '22C', name: '22C' },
  { id: '23C', name: '23C' },
  { id: '24C', name: '25C' },
  { id: '25C', name: '24C' },
  { id: '26C', name: '26C' },
  { id: '27C', name: '27C' },
  { id: '28C', name: '28C' },
  { id: '29C', name: '29C' },
  { id: '30C', name: '30C' }
];

// const columns = [
//   { title: 'Site', name: 'site', width: 120 },
//   { title: 'Source', name: 'source', width: 100 },
//   { title: 'Campaign', name: 'campaign', width: 250 },
//   { title: 'Type', name: 'type', width: 165 },
//   { title: 'Old Budget', name: 'oldBudget', type: 'smallcurrency', width: 110 },
//   { title: 'New Budget', name: 'newBudget', type: 'smallcurrency', width: 110 },
//   { title: 'Old Value', name: 'oldValue', width: 110 },
//   { title: 'New Value', name: 'newValue', width: 110 },
//   { title: 'Date', name: 'start_date', width: 145 },
//   { title: 'Completed', name: 'proceced', width: 50, getCellValue: row => (row.proceced ? 'Yes' : '') },
//   { title: 'User', name: 'user', width: 145 },
// ];

const initialState = {
  newBudget: 0,
  strategy: { value: 0, label: '' },
  bidTarget: 0,
  budgetHistory: [],
  currentStrategy: '',
  currentConversion: '',
  conversionTarget: '',
  currentTarget: 0,
  hasDayParting: false,
  removeDayParting: false,
  applyDayParting: false,
  hasWhitelist: false,
  removeWhitelist: false,
  resetWhitelist: false,
  url: '',
  hourOne: 23,
  hourTwo: 14
};

// TODO: This component is used in two places, be sure to check both with changes
// WARNING!!!! --- this has two parent components (ListCampaignDaily and WebStats) be sure to check both with changes

const DrawerBudget = ({ isDrawer, handleCancelDrawer, dataToEdit, handleApplyNewBudget, handlePauseCampaign }) => {
  const [state, setState] = useReducer((oldState, newState) => ({ ...oldState, ...newState }), initialState);
  const { newBudget, strategy, bidTarget, currentStrategy, currentConversion, conversionTarget, currentBid, removeDayParting, applyDayParting, removeWhitelist, resetWhitelist, url, hourOne, hourTwo } = state;
  const { filter } = useFilter();
  const { startDate } = filter;
  const classes = useStyles();

  const getStrategy = useCallback(() => {
    let strategy = { id: '', name: '' };
    let conversionTarget = { id: '', name: '' };

    if (dataToEdit.source === 'ga') {
      const found = strategies.find(row => row.id === dataToEdit.bidStrategy);
      if (found) strategy = found;
    } else if (dataToEdit.source === 'ob') {
      const found = strategiesOutbrain.find(row => row.id === dataToEdit.bidStrategy);
      if (found) strategy = found;
    } else if (dataToEdit.source === 'tb') {
      const found = strategiesTaboola.find(row => row.id === dataToEdit.bidStrategy);
      if (found) strategy = found;

      const foundConv = conversionTaboola.find(row => row.id === dataToEdit.conversion_target);
      if (foundConv) conversionTarget = foundConv;
    }

    const currentBid = dataToEdit.bidTarget || 0;
    setState({
      strategy: { label: strategy.name, value: strategy.id },
      currentStrategy: dataToEdit.bidStrategy,
      conversionTarget: { label: conversionTarget.name, value: conversionTarget.id },
      currentConversion: dataToEdit.conversion_target,
      currentBid,
      newBudget: Number(dataToEdit.budget).toFixed(2),
      bidTarget: currentBid,
      hasDayParting: Boolean(dataToEdit.hasDayParting),
      hasWhitelist: Boolean(dataToEdit.hasWhitelist),
      url: dataToEdit.url,
    });
  }, [dataToEdit.bidStrategy, dataToEdit.bidTarget, dataToEdit.budget, dataToEdit.conversion_target, dataToEdit.hasDayParting, dataToEdit.hasWhitelist, dataToEdit.source, dataToEdit.url]);

  const handleChangeBudget = (event) => {
    const value = event.target.value;
    if (Number.isFinite(Number(value)) && !Number.isNaN(Number(value))) {
      setState({ newBudget: value });
    }
  };

  const handleHourChange = (event) => {
    const value = event.target.value;
    const field = event.target.name;

    if (value >= 0 && value <= 23) {
      if (field === 'hourOne') {
        setState({ hourOne: value });
      } else {
        setState({ hourTwo: value });
      }
    }

  };

  const handleChangeBidTarget = (event) => {
    const value = event.target.value;
    if (Number.isFinite(Number(value)) && !Number.isNaN(Number(value))) {
      setState({ bidTarget: value });
    }
  };

  const formatDataChart = (data) => {
    const aux = { aggregatedData: {} };
    if (data) {
      aux.aggregatedData = { ...data };
    }
    return aux;
  };

  const renderSessionsChart = useCallback((trafficData, width) => {
    return (
      <SparkChart data={formatDataChart(trafficData)} field="sessions" highlightCurrentHour width={width} />
    );
  }, []);

  const renderProfitChart = useCallback((trafficData, width = 100) => {
    if (trafficData) {
      // const available = Object.keys(trafficData).filter(hour => trafficData[hour].sessions > 0).sort();
      // if (entry.stamp === available[available.length - 1]) {
      //   return '#DEDEDE'
      // }

      // Remove todays last hour data because its unreliable (at least for google)
      if (moment(startDate).isSame(moment(), 'day')) {
        const hourNow = moment().format('HH');
        const lastHour = moment().subtract(1, 'hour').format('HH');
        trafficData[hourNow] = { revenue: 0, spend: 0, profit: 0, sessions: 0 };
        trafficData[lastHour] = { revenue: 0, spend: 0, profit: 0, sessions: 0 };
      }

      return (
        <SparkChart
          getColor={(entry) => {
            // if (entry.stamp === available[available.length]) {
            // if (entry.stamp === available[available.length - 1]) {
            //   return '#DEDEDE'
            // }
            const color = entry.data.spend === 0 ? '#D3D3D3' : (entry.count < 0 ? '#B22222' : '#009933');
            return color;
          }}
          data={formatDataChart(trafficData)}
          field="profit"
          type="currency"
          width={width}
        />
      );
    }
    return null;
  }, [startDate]);

  const ChartsDrawer = React.memo(({ data }) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center', gap: '1rem', justifyContent: 'space-evenly' }}>
        <div>
          <Typography variant="body1" sx={{ padding: '2px', fontWeight: 700, lineHeight: '1rem', fontSize: '0.8rem', color: '#555555' }}>Sessions</Typography>
          {renderSessionsChart(data.aggregatedData, 150)}
        </div>
        <div>
          <Typography variant="body1" sx={{ padding: '2px', fontWeight: 700, lineHeight: '1rem', fontSize: '0.8rem', color: '#555555' }}>Profit</Typography>
          {renderProfitChart(data.aggregatedData, 150)}
        </div>
      </div>
    );
  }, (prev, next) => {
    const result = JSON.stringify(prev.data.aggregatedData) === JSON.stringify(next.data.aggregatedData);
    return result;
  });

  ChartsDrawer.propTypes = {
    data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired
  };

  const handleSelectChange = key => selectedEntry => {
    if (key === 'strategy') {
      let bidTarget = 0;
      if (dataToEdit.source === 'ga') {
        if (selectedEntry.value === 'TARGET_CPA') bidTarget = dataToEdit.bidTarget || '';
        else bidTarget = 0;
      } else if (dataToEdit.source === 'ob') {
        if (selectedEntry.value === 'CPC' || selectedEntry.value === 'TARGET_CPA_FULLY_AUTOMATED') bidTarget = dataToEdit.bidTarget || '';
        else bidTarget = 0;
      } else if (dataToEdit.source === 'tb') {
        if (selectedEntry.value === 'SMART' || selectedEntry.value === 'MAX_CONVERSIONS') bidTarget = dataToEdit.bidTarget || '';
        else bidTarget = 0;
      }

      setState({ strategy: selectedEntry, bidTarget });
    } else if (key === 'conversionTarget') {
      setState({ conversionTarget: selectedEntry });
    }
  };

  useEffect(() => {
    if (isDrawer) {
      setState(initialState);
      getStrategy();
    }
  }, [getStrategy, isDrawer]);

  const doesItWorkForThisSource = (source) => {
    if (source === 'ob' || source === 'fb_jp' || source === 'ga' || source === 'mg' || source === 'gm' || source === 'me' || source === 'tb') {
      return false;
    }
    return true;
  };

  const optionsStrategy = useMemo(() => {
    if (dataToEdit.source === 'ga') return strategies;
    if (dataToEdit.source === 'ob') return strategiesOutbrain;
    if (dataToEdit.source === 'tb') return strategiesTaboola;
    return [{ id: 'Er', label: 'Er' }];
  }, [dataToEdit.source]);

  const disabledNewTarget = useMemo(() => {
    // if (dataToEdit.source === 'ga') {
    //   return !(strategy.value === 'TARGET_CPA');
    // }
    if (dataToEdit.source === 'ga' && dataToEdit.advertising_type === 'DISPLAY' && strategy.value === 'MAXIMIZE_CONVERSIONS') {
      return true;
    }

    if (dataToEdit.source === 'ob') {
      return !(strategy.value === 'TARGET_CPA_FULLY_AUTOMATED' || strategy.value === 'CPC');
    }
    return false;
  }, [dataToEdit.advertising_type, dataToEdit.source, strategy.value]);

  const handleApply = () => {

    let data = {};
    if (Number.isFinite(Number(newBudget)) && !Number.isNaN(Number(newBudget)) && (Number(dataToEdit.budget).toFixed(2) !== newBudget)) data = { ...data, newBudget };

    if (dataToEdit.source === 'ga') {
      if (dataToEdit.bidStrategy !== strategy.value || dataToEdit.bidTarget !== Number(bidTarget)) {
        data = { ...data, newStrategy: strategy.value, newBidTarget: bidTarget, fallback_budget: hourOne, max_budget: hourTwo };
      }

      if (removeDayParting) {
        data = { ...data, newHasDayParting: 'remove' };
      } else if (applyDayParting) {
        if (hourOne && hourTwo) {
          data = { ...data, newHasDayParting: 'add', fallback_budget: hourOne, max_budget: hourTwo };
        }
        else {
          data = { ...data, newHasDayParting: 'add', fallback_budget: 23, max_budget: 14 };
        }
      }

      if (removeWhitelist) {
        data = { ...data, newHasWhitelist: 'remove' };
      } else if (resetWhitelist) {
        data = { ...data, newHasWhitelist: 'add' };
      }

      if (dataToEdit.url !== url) {
        data = { ...data, newUrl: url };
      }

    } else if (dataToEdit.source === 'ob') {
      if (dataToEdit.bidStrategy !== strategy.value || dataToEdit.bidTarget !== Number(bidTarget)) {
        data = { ...data, newStrategy: strategy.value, newBidTarget: bidTarget };
      }
    } else if (dataToEdit.source === 'tb') {
      if (dataToEdit.bidStrategy !== strategy.value || Number(dataToEdit.bidTarget) !== Number(bidTarget)) {
        data = { ...data, newStrategy: strategy.value, newBidTarget: bidTarget };
      }

      if (dataToEdit.conversion_target !== conversionTarget.value) {
        data = { ...data, newConversionTarget: conversionTarget.value };
      }
    } else if (dataToEdit.source === 'me') {
      if (dataToEdit.bidTarget !== Number(bidTarget)) {
        data = { ...data, newBidTarget: bidTarget };
      }
    }
    handleApplyNewBudget(dataToEdit, data);
  };

  const checkUrlCampaign = useMemo(() => {
    const originalName = dataToEdit.campaign;
    if (url !== dataToEdit.url) {
      // Get the camapign name from the url, in the utm_campaign parameter
      const urlParams = new URLSearchParams(url);
      const campaign = urlParams.get('utm_campaign');
      if (campaign && campaign !== originalName) {
        return true;
      }
    }

    return false;
  }, [dataToEdit.campaign, dataToEdit.url, url]);

  const hasError = useMemo(() => {
    const btarget = Number(bidTarget);
    if (dataToEdit.source === 'ga') {
      if (strategy.value === 'TARGET_ROAS' && Number.isFinite(btarget) && !Number.isNaN(btarget) && btarget <= 0) {
        return { bidTarget: 'Bid Target must be greater than 0 for TARGET_ROAS' };
      }
    }

    return null;
  }, [bidTarget, dataToEdit.source, strategy.value]);
  return (
    <Dialog maxWidth="xl" open={isDrawer} onClose={handleCancelDrawer}>
      <DialogTitle
        id="responsive-dialog-title"
        sx={{ paddingBottom: 0, textAlign: 'center' }}
      >
        Campaign Adjuster
      </DialogTitle>

      <Divider sx={{ margin: '10px 0', backgroundColor: '#adabab' }} />

      <div className={classes.containerDrawer}>
        <div className={classes.containerTable}>
          <ListCampaignsAdjuster campaign={dataToEdit.campaign} source={dataToEdit.source} site={dataToEdit.site} />
        </div>
        <div className={classes.containerEditable}>
          <div>
            <div
              className={`${classes.rowDisplay} ${classes.containerRow}`}
              style={{ borderBottom: '1px solid #adabab' }}
            >
              <TextField
                label="Current Budget"
                value={dataFormatter.currency({ value: dataToEdit.budget })}
                variant="standard"
                className={classes.textFieldDisabled}
                disabled
              />
              <TextField
                label="New Budget"
                value={newBudget}
                onChange={handleChangeBudget}
                variant="standard"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
              <TextField
                label="Difference"
                value={dataFormatter.percent({
                  value: (newBudget * 100) / dataToEdit.budget - 100,
                })}
                variant="standard"
                className={classes.textFieldDisabled}
                disabled
              />
            </div>

            {dataToEdit.source === 'me' && (
              <React.Fragment>
                <div
                  className={`${classes.rowDisplay} ${classes.containerRow}`}
                  style={{ paddingBottom: 0 }}
                >
                  <TextField
                    label="Current CPC"
                    value={dataFormatter.smallcurrency({ value: currentBid })}
                    variant="standard"
                    className={classes.textFieldDisabled}
                    disabled
                    sx={{ flex: 6 }}
                  />
                  <TextField
                    label="New CPC"
                    value={bidTarget}
                    onChange={handleChangeBidTarget}
                    variant="standard"
                    disabled={disabledNewTarget}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    sx={{ flex: 6 }}
                  />
                </div>
              </React.Fragment>
            )}

            {strategy.value && (
              <React.Fragment>
                <div
                  className={`${classes.rowDisplay} ${classes.containerRow}`}
                  style={{ paddingBottom: 0 }}
                >
                  <TextField
                    label="Current Strategy"
                    value={currentStrategy}
                    variant="standard"
                    className={classes.textFieldDisabled}
                    disabled
                    sx={{ flex: 6 }}
                  />
                  <TextField
                    label="Current Target"
                    value={dataFormatter.smallcurrency({ value: currentBid })}
                    variant="standard"
                    className={classes.textFieldDisabled}
                    disabled
                    sx={{ flex: 3 }}
                  />
                </div>

                <div
                  className={`${classes.rowDisplay} ${classes.containerRow} ${classes.flexStart}`}
                >
                  <FormControl required fullWidth>
                    <ReactSelect
                      label="New Strategy"
                      name="strategy"
                      data={strategy}
                      options={(dataToEdit.advertising_type === 'SEARCH' || dataToEdit.advertising_type === 'PERFORMANCE_MAX') ? [{ id: 'MAXIMIZE_CONVERSIONS', name: 'MAXIMIZE CONVERSIONS' }] : optionsStrategy}
                      handleChange={handleSelectChange('strategy')}
                    />
                  </FormControl>

                  <TextField
                    label="New Target"
                    error={Boolean(hasError) && Boolean(hasError.bidTarget)}
                    helperText={hasError && hasError.bidTarget}
                    value={bidTarget}
                    onChange={handleChangeBidTarget}
                    variant="standard"
                    disabled={disabledNewTarget}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />
                </div>

                {dataToEdit.source === 'tb' && (
                  <div
                    className={`${classes.rowDisplay} ${classes.containerRow} ${classes.flexEnd}`}
                  >
                    <TextField
                      label="Current Conversion"
                      value={currentConversion}
                      variant="standard"
                      className={classes.textFieldDisabled}
                      disabled
                    />

                    <FormControl required fullWidth>
                      <ReactSelect
                        label="New Conversion"
                        name="conversionTarget"
                        data={conversionTarget}
                        options={conversionTaboola}
                        handleChange={handleSelectChange('conversionTarget')}
                      />
                    </FormControl>
                  </div>
                )}
              </React.Fragment>
            )}

            {dataToEdit.source === 'ga' && (
              <>
                <div
                  className={classes.rowDisplay}
                  style={{ padding: '0 10px' }}
                >
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={removeDayParting}
                        color="primary"
                        onChange={() => setState({
                          removeDayParting: !state.removeDayParting,
                        })}
                        disabled={
                          !state.hasDayParting || state.applyDayParting
                        }
                      />
                    )}
                    label="Remove Day Parting"
                  />
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={applyDayParting}
                        color="primary"
                        onChange={() => setState({
                          applyDayParting: !state.applyDayParting,
                        })}
                        disabled={
                          state.hasDayParting || state.removeDayParting
                        }
                      />
                    )}
                    label="Apply Day Parting"
                  />
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={removeWhitelist}
                        color="primary"
                        onChange={() => setState({
                          removeWhitelist: !state.removeWhitelist,
                        })}
                        disabled={!state.hasWhitelist || state.resetWhitelist}
                      />
                    )}
                    label="Remove Whitelist"
                  />
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={resetWhitelist}
                        color="primary"
                        onChange={() => setState({ resetWhitelist: !state.resetWhitelist })}
                        disabled={state.removeWhitelist}
                      />
                    )}
                    label="Reset Whitelist From DB"
                  />
                </div>
                <div className={`${classes.rowDisplay} ${classes.containerRow}`} >
                  <TextField
                    value={hourOne}
                    name="hourOne"
                    hidden={!applyDayParting}
                    onChange={handleHourChange}
                    onKeyDown={handleHourChange}
                    label="Start Hour"
                    type="number"
                    variant="standard"
                    size="small"
                    helperText="Hours must be between 0 and 23"

                  />
                  <TextField
                    value={hourTwo}
                    name="hourTwo"
                    hidden={!applyDayParting}
                    onChange={handleHourChange}
                    onKeyDown={handleHourChange}
                    label="End Hour"
                    type="number"
                    variant="standard"
                    size="small"
                    helperText="Hours must be between 0 and 23"
                  />
                </div>
                <div className={`${classes.rowDisplay} ${classes.containerRow}`}>
                  <TextField
                    label={`URL ${dataToEdit.url !== url ? '(Changed)' : ''}`}
                    value={url}
                    onChange={(event) => setState({ url: event.target.value })}
                    variant="standard"
                    fullWidth
                    error={checkUrlCampaign}
                    helperText={
                      checkUrlCampaign
                      && `Campaign in URL is different from original name (${dataToEdit.campaign})`
                    }
                  />
                </div>
              </>
            )}
          </div>
          <div className={classes.containerButtons}>
            <div className={classes.pauseDiv}>
              <Button
                variant="contained"
                color="warning"
                onClick={handlePauseCampaign}
              >
                Pause
              </Button>
            </div>
            <Button
              variant="outlined"
              color="error"
              onClick={handleCancelDrawer}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              color="success"
              onClick={handleApply}
              disabled={Boolean(hasError) || doesItWorkForThisSource(dataToEdit.source)}
            >
              Apply
            </Button>
          </div>
        </div>

      </div>
      <Divider sx={{ margin: '10px 0', backgroundColor: '#adabab' }} />
      <div className={classes.containerChanges}>
        <ListCampaignHistory camp={dataToEdit.campaign} />
      </div>
    </Dialog >
  );
};

DrawerBudget.propTypes = {
  dataToEdit: PropTypes.object.isRequired,
  isDrawer: PropTypes.bool.isRequired,
  handleCancelDrawer: PropTypes.func.isRequired,
  handleApplyNewBudget: PropTypes.func.isRequired,
  handlePauseCampaign: PropTypes.func,
};

DrawerBudget.defaultProps = {
  handlePauseCampaign: () => { },
};

function propsAreEqual(prev, next) {
  return JSON.stringify(prev.dataToEdit) === JSON.stringify(next.dataToEdit) && prev.isDrawer === next.isDrawer;
}

export default React.memo(DrawerBudget, propsAreEqual);
