import React, { useState } from 'react';
import PropTypes from 'prop-types';

import WebstatsTableCampaigns from 'components/WebStats/Tables/WebstatsTableCampaigns';
import CampaignSummary from 'components/WebStats/Summaries/CampaignSummary';
import DateSelectedMobile from 'components/WebStats/DateSelectedMobile';
import { useFilter } from 'contexts/FilterProvider';
import { capitalize } from 'core/Functions/utilities';

const SUPPLY = {
  facebook: 'fb_jp',
  keywee_est: '_k',
  keywee_pst: 'fb_k_p',
  lockerdome: 'ld',
  baidu: 'me',
  google: 'ga',
  dv360: 'dv',
  yahoo: 'gm',
  mgid: 'mg',
  outbrain: 'ob',
  taboola: 'tb',
  outbrain_engage: 'ob',
  gazillions: 'gz_',
  investingfuel: 'if_',
  mdrntoday: 'mt_',
  heroinvesting: 'hi_',
  followsports: 'fs_',
  kaleandcardio: 'kc_',
  smarter: 'sr_',
  faqtoids: 'fq_',
  simpli: 'si_',
  burnyourday: 'bd_',
  sportsmound: 'sp_',
  quantanswers: 'qa_',
  latestinsports: 'ls_',
  consumeradvisor: 'cn_',
  organic: '',
};

const ListCampaigns = ({ adReport, title }) => {
  const [filters, setFilters] = useState([]);
  const [graphicsOpen, setGraphicsOpen] = useState(true);
  const { filter } = useFilter();

  const handleOnChange = (e) => {
    const accessor = e.target.id ? e.target.id : e.target.name;
    let value = e.target.value;

    if (accessor === 'supplyFilter') {
      const filter = Object.keys(SUPPLY).find(row => row === value);
      if (filter) {
        value = SUPPLY[filter];
      }
      const filters = [{
        columnName: 'source',
        value,
        operation: 'contains'
      }];

      setFilters(filters);
    } else if (accessor === 'campaignFilter') {
      const filters = []
      if (value === 'direct') {
        filters.push({ columnName: 'site', operation: 'equal', value: 'direct' })
        filters.push({ columnName: 'campaign', operation: 'contains', value: 'dc_pf' })
      } else if (value === 'content') {
        filters.push({ columnName: 'site', operation: 'not_equal', value: '-direct,-search' })
        filters.push({ columnName: 'campaign', operation: 'equal', value: '' })
      } else if (value === 'search') {
        filters.push({ columnName: 'site', operation: 'equal', value: 'search' })
        filters.push({ columnName: 'campaign', operation: 'contains', value: 'dc_sc' })
      } else {
        filters.push({ columnName: 'site', operation: 'equal', value: '' })
        filters.push({ columnName: 'campaign', operation: 'equal', value: '' })
      }

      setFilters(filters);
    }
  };

  const handleToggleGraphics = () => {
    setGraphicsOpen(prev => !prev);
  };

  return (
    <>
      <DateSelectedMobile />
      {graphicsOpen && (
        <CampaignSummary
          supplyFilter={filters}
          title={capitalize(filter.site) || 'Supply'}
          onChange={handleOnChange}
        />
      )}

      <WebstatsTableCampaigns
        adReport={adReport}
        title={title}
        filters={filters}
        handleToggleGraphics={handleToggleGraphics}
      />
    </>
  );
};

ListCampaigns.propTypes = {
  adReport: PropTypes.bool,
  title: PropTypes.string,
};

ListCampaigns.defaultProps = {
  adReport: false,
  title: '',
};

export default ListCampaigns;
