import React from 'react';

const style = {
  textAlign: 'center'
};

const NotFound = () => (
  <div id="404" style={style}>
    <h1>404</h1>
    <h4>This page does not exist.</h4>
  </div>
);

export default NotFound;
