import request from './request';

const AccountsAPI = {
  facebook: {
    listAccounts: (params) => request({
      url: '/accounts/facebook',
      method: 'get',
      params
    }),
    deleteAccount: (data) => request({
      url: '/accounts/facebook',
      method: 'delete',
      data
    }),
    editAccount: (data) => request({
      url: '/accounts/facebook',
      method: 'put',
      data
    }),
    createAccount: (data) => request({
      url: '/accounts/facebook',
      method: 'post',
      data
    }),
  }
};

export default AccountsAPI;
