/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { FixedSizeList as List } from 'react-window';
import AsyncSelect from 'react-select/async'

import withStyles from '@mui/styles/withStyles';
import Select from 'react-select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import NoSsr from '@mui/material/NoSsr';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import CancelIcon from '@mui/icons-material/Cancel';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import Divider from '@mui/material/Divider';

const styles = theme => ({
  input: {
    display: 'flex',
    padding: '2px',
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16,
  },
  paper: {
    position: 'absolute',
    zIndex: 99999,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
});

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

const InputComponent = React.forwardRef((props, ref) => <div ref={ref} {...props} />);

function Control(props) {
  return (
    <TextField
      fullWidth
      id="standard-basic"
      variant="standard"
      InputProps={{
        inputComponent: InputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          ref: props.inputRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Option(props) {
  return (
    <MenuItem
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={props.selectProps.classes.chip}
      onDelete={props.isRemoveMulti && props.removeProps.onClick}
      deleteIcon={props.isRemoveMulti && <CancelIcon {...props.removeProps} />}
    />
  );
}

function DropdownIndicator(props) {
  return (
    <div > {props.selectProps.menuIsOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}</div>
  );
}

function Menu(props) {
  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

function MenuList(props) {
  const { options, children, getValue } = props;
  const height = children.length && typeof children[0].props.label === 'object' ? 60 : 45
  const [value] = getValue();
  const initialOffset = options.indexOf(value) * height;
  const itemHeight = (options.length * height) + (height / 2);
  const maxHeight = (props.maxHeight > itemHeight ? itemHeight : props.maxHeight) || height;

  return (
    <List
      height={maxHeight}
      itemCount={children.length || 0}
      itemSize={height}
      initialScrollOffset={initialOffset}
    >
      {({ index, style }) => (
        <div style={style}>
          {children[index]}
          <Divider />
        </div>
      )}
    </List>
  );
}

const components = {
  Control,
  Menu,
  MenuList,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
  DropdownIndicator
};

class ReactSelect extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      inputValue: '',
      data: props.data,
    };
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)) {
      this.setState({ data: this.props.data });
    }
  }

  handleInputChange(value) {
    // console.log('wt333f', value, this.state);
    // store data in the state
    if (this.props.isMulti) {
      if (Math.abs(value.length - this.state.inputValue.length) === 1) {
        this.setState({ inputValue: value });
      }
    } else {
      this.setState({ inputValue: value });
    }

    // and push changes upwards
    this.props.handleInputChange(value);
  }

  handleChange(value) {
    // store data in the state
    if (this.props.setRowData) {
      this.setState({ data: value });
    }

    // and push changes upwards
    this.props.handleChange(value);
  }

  isFieldValid() {
    // console.log('isFieldValid', this.state.data);
    return this.state.data && this.state.data.value;
  }

  render() {
    const { classes, theme } = this.props;
    const selectStyles = {
      input: base => ({
        ...base,
        color: theme.palette.text.primary,
        '& input': {
          font: 'inherit',
        },
      }),
    };

    const SelectType = this.props.isAsync ? AsyncSelect : Select;

    const options = this.props.options ? this.props.options.map(item => ({ value: item.id, label: item.name })) : [];

    return (
      <NoSsr>
        <Grid container direction="row" alignItems="center">
          <Grid item xs={12 - this.props.buttons.length * 2}>
            <SelectType
              textFieldProps={{
                label: this.props.label,
                InputLabelProps: {
                  shrink: true,
                  required: this.props.required,
                  error: this.props.required && this.props.validateField && !this.isFieldValid()
                },
              }}
              cacheOptions
              loadOptions={this.props.asyncGet}
              isDisabled={this.props.disabled}
              arrowRenderer={this.ArrowRenderer}
              isMulti={this.props.isMulti}
              closeMenuOnSelect={this.props.closeMenuOnSelect}
              classes={classes}
              styles={selectStyles}
              components={components}
              inputValue={this.state.inputValue}
              value={this.state.data}
              onInputChange={(e) => this.handleInputChange(e)}
              onChange={(e) => this.handleChange(e)}
              options={options}
              placeholder={this.props.placeholder}
            />
          </Grid>
          <Grid item xs={2}>
            {this.props.buttons.map((button) => {
              return (
                <Button key={button.key} {...button} className={this.props.classes.button} disabled={this.state.disabled}>
                  {button.title} {this.state.disabled && <CircularProgress size={18} />}
                </Button>
              );
            })}
          </Grid>
        </Grid>
      </NoSsr>
    );
  }
}

ReactSelect.defaultProps = {
  validateField: false,
  isMulti: false,
  closeMenuOnSelect: true,
  setRowData: true,
  isAsync: false,
  handleInputChange: () => { },
  asyncGet: () => { },
  required: false,
  disabled: false,
  label: '',
  buttons: [],
  placeholder: '',
  data: {},
  isRemoveMulti: true,
};

ReactSelect.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  setRowData: PropTypes.bool,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  validateField: PropTypes.bool,
  isMulti: PropTypes.bool,
  closeMenuOnSelect: PropTypes.bool,
  isAsync: PropTypes.bool,
  asyncGet: PropTypes.func,
  handleInputChange: PropTypes.func,
  handleChange: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  buttons: PropTypes.array,
  placeholder: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  isRemoveMulti: PropTypes.bool,
};

export default withStyles(styles, { withTheme: true })(ReactSelect);
