import React from 'react';
import moment from 'moment-timezone';

import PropTypes from 'prop-types';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import FormHelperText from '@mui/material/FormHelperText';
import ListItemText from '@mui/material/ListItemText';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
// import Button from '@mui/material/Button';
import { getImage } from 'core/Functions/utilities';

import withStyles from '@mui/styles/withStyles';

// import cacheman from 'core/Utilities/cacheman';
import config from 'core/Utilities/config';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  formControl: {
    margin: theme.spacing(3),
    width: 230,
  },
  formControlSmall: {
    alignItems: 'center'
  },
  timezoneFormControl: {
    margin: theme.spacing(3),
    width: 230,
  },
  containerButton: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1rem',
  },
  applyBtn: {
    backgroundColor: '#e46868',
    color: '#fff',
    width: '100px'
  },
  cancelBtn: {
    backgroundColor: '#2d862d',
    color: '#fff',
    width: '100px'
  },
  containerSites: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  }
});

class FilterMobile extends React.Component {
  constructor(props) {
    super(props);

    const isToday = (props.filter.startDate || props.filter.endDate)
      && moment(props.filter.startDate).format('YYYY-MM-DD') === moment(props.filter.endDate).format('YYYY-MM-DD')
      && moment(props.filter.startDate).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD');
    const isYesterday = (props.filter.startDate || props.filter.endDate)
      && moment(props.filter.startDate).format('YYYY-MM-DD') === moment(props.filter.endDate).format('YYYY-MM-DD')
      && moment(props.filter.startDate).format('YYYY-MM-DD') === moment().subtract(1, 'days').format('YYYY-MM-DD');

    this.state = {
      showModal: false,
      startDate: props.filter.startDate ? moment(props.filter.startDate) : moment(),
      endDate: props.filter.endDate ? moment(props.filter.endDate) : moment(),
      timeframe: isToday ? 'Today' : (isYesterday ? 'Yesterday' : 'Custom'),
      timeframes: {
        Today: [moment(), moment()],
        Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 3 Days': [moment().subtract(2, 'days'), moment()],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 14 Days': [moment().subtract(13, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Week': [moment().startOf('week'), moment().endOf('week')],
        'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
        Custom: []
      },
      // timezone: props.filter.timezone || 'America/Vancouver',
      sites: props.filter.site || 'sundaymarket',
    };
  }

  showModal = () => {
    this.setState({ showModal: true });
  };

  hideModal = () => {
    this.setState({ showModal: false });
  };

  applyModal = () => {
    this.setState({ timeframe: 'Custom', showModal: false });
  };

  cancelModal = () => {
    this.setState({ showModal: false });
  };

  handleChangeStart = (date) => {
    this.setState({ startDate: moment(date) });
  };

  handleChangeEnd = (date) => {
    this.setState({ endDate: moment(date) });
  };

  handleShiftStart = () => {
    this.setState({ timeframe: 'Custom', startDate: this.state.startDate.add(1, 'days'), endDate: this.state.endDate.add(1, 'days') });
  };

  handleShiftEnd = () => {
    this.setState({ timeframe: 'Custom', startDate: this.state.startDate.subtract(1, 'days'), endDate: this.state.endDate.subtract(1, 'days') });
  };

  dateRange = () => {
    if (this.state.startDate.isSame(this.state.endDate, 'day')) {
      return this.state.startDate.format('MMMM D, YYYY');
    }
    return this.state.startDate.format('MMMM D, YYYY') + ' - ' + this.state.endDate.format('MMMM D, YYYY');
  };

  onChangeTimeframe = (value, key) => {
    if (value === 'Custom') {
      this.showModal();
    } else {
      const newState = {};
      newState[key] = value;
      this.setState(newState, () => {
        this.hideModal();
        const startDate = this.state.timeframes[this.state.timeframe][0];
        const endDate = this.state.timeframes[this.state.timeframe][1];
        this.setState({ startDate, endDate });
      });
      // this.setState(newState, () => {
      //   this.hideModal();
      //   const startDate = this.state.timeframes[this.state.timeframe][0];
      //   const endDate = this.state.timeframes[this.state.timeframe][1];
      //   this.setState({ startDate, endDate }, () => {
      //     this.applyChanges();
      //   });
      // });
    }
  };

  onChangeSite = (e) => {
    const newState = {};
    const accessor = e.target.id ? e.target.id : e.target.name;
    newState[accessor] = e.target.value;
    this.setState(newState);
  };

  getFormattedDate = () => {
    if (this.state.startDate.diff(this.state.endDate, 'days') === 0) {
      const startDate = moment(this.state.startDate).startOf('day').format('YYYY-MM-DD');
      return `${startDate}`;
    }
    const startDate = moment(this.state.startDate).startOf('day').format('YYYY-MM-DD');
    const endDate = moment(this.state.endDate).endOf('day').format('YYYY-MM-DD');
    return `${startDate} - ${endDate}`;
  };

  isDateToday = () => {
    return moment(this.state.endDate).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD');
  };

  applyChanges = () => {
    this.props.handleCloseModal();
    const newStartDate = moment(this.state.startDate).startOf('day').format('YYYY-MM-DD HH:mm:ss');
    const newEndDate = moment(this.state.endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss');
    const newSite = this.state.sites === 'sundaymarket' ? '' : this.state.sites;

    // report new dates back up the line
    this.props.changeFilter({ site: newSite, startDate: newStartDate, endDate: newEndDate, timeframe: this.state.timeframe });
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>

        {!!this.props.shouldShow.includes('site')
          && (
            <FormControl className={classes.timezoneFormControl}>
              <InputLabel htmlFor="select-site">Site</InputLabel>
              <Select
                value={this.state.sites}
                input={<Input id="select-site" name="sites" />}
                onChange={this.onChangeSite}
              >
                {Object.values(config.sites).map(item => (
                  <MenuItem key={item.label} value={item.value}>
                    <span className={classes.containerSites}>
                      <img src={getImage(item.value)} alt={item.value} width={25} height={25} />{item.label}
                    </span>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

        {!!this.props.CustomFilter && this.props.CustomFilter}

        {!!this.props.shouldShow.includes('timeframe')
          && (
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="select-timeframe">Timeframe</InputLabel>
              <Select
                value={this.state.timeframe}
                input={<Input id="select-timeframe" name="timeframe" />}
                renderValue={(id) => Object.keys(this.state.timeframes).filter((n) => n === id)[0]}
              // onChange={this.onChangeTimeframe}
              >
                {this.state.timeframes ? Object.keys(this.state.timeframes).map(item => (
                  <MenuItem key={item} value={item} onClick={() => this.onChangeTimeframe(item, 'timeframe')}>
                    <ListItemText primary={item} />
                  </MenuItem>
                )) : ''}
              </Select>
              <FormHelperText>{this.getFormattedDate()}</FormHelperText>
            </FormControl>
          )}

        {!!this.props.shouldShow.includes('timeframe')
          && (
            <FormControl required className={classes.formControlSmall}>
              <div>
                <IconButton onClick={() => this.handleShiftEnd()}>
                  <ChevronLeftIcon />
                </IconButton>
                <IconButton onClick={() => this.handleShiftStart()} disabled={this.isDateToday()}>
                  <ChevronRightIcon />
                </IconButton>
              </div>
            </FormControl>
          )}

        <div className={classes.containerButton}>
          <Button className={classes.applyBtn} onClick={this.props.handleCloseModal}>Cancel</Button>
          <Button className={classes.cancelBtn} onClick={this.applyChanges}>Apply</Button>
        </div>

        <Dialog
          fullWidth
          maxWidth="sm"
          open={this.state.showModal}
          onClose={this.hideModal}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title" sx={{ paddingBottom: 0 }}>
            Choose Daterange
          </DialogTitle>

          <DialogContent>
            <DialogContentText>
              {this.dateRange()}
            </DialogContentText>
            <Divider />
            <div className={classes.modalBody}>
              <ReactDatePicker
                inline
                showMonthYearDropdown
                name="startDate"
                selected={this.state.startDate.toDate()}
                onChange={this.handleChangeStart}
              />
              <ReactDatePicker
                inline
                showMonthYearDropdown
                name="endDate"
                selected={this.state.endDate.toDate()}
                onChange={this.handleChangeEnd}
              />
            </div>
            <Divider />

          </DialogContent>

          <DialogActions>
            <Button variant="outlined" sx={{ borderColor: '#aeaeae' }} onClick={() => this.cancelModal()}>Cancel</Button>
            <Button variant="outlined" sx={{ borderColor: '#aeaeae' }} onClick={() => this.applyModal()}>Apply</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

FilterMobile.propTypes = {
  classes: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  changeFilter: PropTypes.func.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  shouldShow: PropTypes.array.isRequired,
  CustomFilter: PropTypes.any,
};

FilterMobile.defaultProps = {
  CustomFilter: undefined
};

export default withStyles(styles, { withTheme: true })(FilterMobile);
