import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import SiteSourceProfitable from 'components/WebStats/Charts/SiteSourceProfitable';
import SourceHourlyChart from 'components/WebStats/Charts/SourceHourlyChart';
import { useFilter } from 'contexts/FilterProvider';

import SupplySummary from 'components/WebStats/Summaries/SupplySummary';

const useStyles = makeStyles((theme) => ({
  campaignFiltersContainer: {
    display: 'flex',
    marginBottom: theme.spacing(2)
  },
  padsides: {
    paddingLeft: '5px',
    paddingRight: '5px'
  },
}));

const CampaignSummary = (props) => {
  const { filter } = useFilter();
  const classes = useStyles();

  const onChange = (e) => {
    if (props.onChange !== undefined) {
      props.onChange(e);
    }
  };

  return (
    <div className={classes.campaignFiltersContainer} >
      {/* <Grid container> */}
      <Grid container columns={30} display="flex">
        <Grid item sm="auto" lg={10} xl={8}>
          <SupplySummary
            title="Summary"
            site="summary"
            timezone={filter.timezone}
            startDate={filter.startDate}
            endDate={filter.endDate}
            onChange={onChange}
            disableAccordion
            accordionIcon={null}
            hideColumns={
              ['search_rate', 'search_percentage_rate', 'content_rate',
                'content_percentage_rate', 'cpa_rate', 'cpa_percentage_rate', 'juicyx_rate',
                'juicyx_profit', 'last_synced', 'half_profit', 'half_margin', 'display', 'display_percentage', 'cpa', 'cpa_percentage', 'content',
                'content_percentage', 'search', 'search_percentage', 'video', 'video_percentage', 'daily_profit',
              ]
            }
          />
        </Grid>

        <Grid item sm="auto" lg={20} xl={11}>
          <SiteSourceProfitable
            title="Profit Source Site Daily"
            site={filter.site}
            timezone={filter.timezone}
            startDate={filter.startDate}
            endDate={filter.endDate}
            showNegativeAsRed
            disableAccordion
            isAccordion
            accordionIcon={null}
          />
        </Grid>

        <Grid item sm lg={30} xl={11}>
          <SourceHourlyChart
            supplyFilter={props.supplyFilter}
            site={filter.site}
            timezone={filter.timezone}
            startDate={filter.startDate}
            endDate={filter.endDate}
            showNegativeAsRed
            isAccordion
            disableAccordion
            accordionIcon={null}
          />
        </Grid>
      </Grid>
    </div>
  );
};

CampaignSummary.propTypes = {
  onChange: PropTypes.func,
  supplyFilter: PropTypes.array
  // title: PropTypes.string,
};

CampaignSummary.defaultProps = {
  onChange: () => { },
  supplyFilter: {}
  // title: '',
};

export default CampaignSummary;
