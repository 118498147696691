import { isMobile } from 'react-device-detect';
import React, { useMemo } from 'react';
import moment from 'moment-timezone';

import { useFilter } from 'contexts/FilterProvider';

import makeStyles from '@mui/styles/makeStyles';
import Card from '@mui/material/Card';

const useStyles = makeStyles({
  card: {
    marginTop: 5,
    flexGrow: 1,
    minHeight: 30,
    margin: '5px 8px 0 8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const DateSelectedMobile = () => {
  const { filter } = useFilter();
  const classes = useStyles();

  const dateSelected = useMemo(() => {
    const startDate = moment(filter.startDate).format('YYYY-MM-DD');
    const endDate = moment(filter.endDate).format('YYYY-MM-DD');
    if (startDate === endDate) {
      return startDate;
    }
    return `${startDate} - ${endDate}`;

  }, [filter.startDate, filter.endDate]);

  return (
    <React.Fragment>
      {isMobile
        && (
          <Card className={classes.card} align="center">
            {dateSelected}
          </Card>
        )}
    </React.Fragment>
  );
};

export default DateSelectedMobile;
