import React, { useMemo } from 'react';
import { Tooltip, BarChart, Bar, Cell } from 'recharts';
import PropTypes from 'prop-types';
import moment from 'moment';

import { lighten } from '@mui/material';

import { dataFormatter } from 'core/Components/Tables/TableFilters';
import { useFilter } from 'contexts/FilterProvider';

const tooltipStyle = {
  tooltip: {
    backgroundColor: 'rgba(250,250,250,0.5)',
    boxShadow: '2px 2px rgba(0,0,0,0.1)',
    borderRadius: '2px',
    color: 'black',
    fontWeight: 'bold',
  },
  tooltipNow: {
    backgroundColor: 'rgba(250,250,250,0.5)',
    boxShadow: '2px 2px rgba(0,0,0,0.1)',
    borderRadius: '2px',
    color: '#0ec0dc',
    fontWeight: 'bold',
  },
  label: {
    margin: '0px'
  },
  value: {
    margin: '0px'
  }
};

const colors = {
  impressions: '#995D81',
  clicks: '#6689A1',
  conversions: '#EB8258',
  sessions: 'black',
  revenue: '#85bb65',
  ecpm: '#A7C7E7',
};

const types = {
  impressions: 'number',
  clicks: 'number',
  conversions: 'number',
  sessions: 'number',
  revenue: 'currency',
  ecpm: 'smallcurrency',
};

const highlights = {
  impressions: lighten('#995D81', 0.5),
  clicks: lighten('#6689A1', 0.5),
  conversions: lighten('#EB8258', 0.5),
  sessions: '#0ec0dc',
};

const SparkChart = ({ data, type, field, getColor, onClick, highlightCurrentHour, width, height }) => {
  const { filter } = useFilter();
  const currentHour = moment().format('HH');
  const currentDate = moment().format('YYYY-MM-DD');

  const fillColor = (entry) => {
    if (getColor instanceof Function) {
      return getColor(entry);
    }
    const filterDate = moment(filter.startDate).format('YYYY-MM-DD');
    if (highlightCurrentHour) {
      return entry.stamp === currentDate || (entry.stamp === currentHour && filterDate === currentDate) ? highlights[field] : colors[field];
    }
    return colors[field];
  };

  const CustomTooltip = ({ active, payload }) => {
    const { filter } = useFilter();
    if (active && payload && payload.length) {
      const filterDate = moment(filter.startDate).format('YYYY-MM-DD');
      const isNow = payload[0].payload.stamp === currentDate || (payload[0].payload.stamp === currentHour && filterDate === currentDate);
      return (
        <div style={(isNow && highlightCurrentHour) ? tooltipStyle.tooltipNow : tooltipStyle.tooltip}>
          <p style={tooltipStyle.label}>{`${payload[0].payload.stamp}`}</p>
          <p style={tooltipStyle.value}>{`${dataFormatter[types[field] || type]({ value: payload[0].value })}`}</p>
        </div>
      );
    }

    return null;
  };

  const formattedData = useMemo(() => {
    if (!data || !data.aggregatedData) {
      return [];
    }

    const aggregatedData = data.aggregatedData;
    return Object.keys(aggregatedData).map(key => ({ stamp: key, count: aggregatedData[key][field], data: aggregatedData[key] }))
      .sort((a, b) => a.stamp.localeCompare(b.stamp));
  }, [data, field]);

  CustomTooltip.propTypes = {
    active: PropTypes.bool,
    payload: PropTypes.array
  };

  CustomTooltip.defaultProps = {
    active: undefined,
    payload: undefined
  };

  return (
    <BarChart
      width={width}
      height={height}
      data={formattedData}
      onClick={onClick}
    >
      <Bar
        dataKey="count"
        minPointSize={1}
        isAnimationActive={false}
      >
        {formattedData.map((entry) => (
          <Cell
            fill={fillColor(entry)}
            key={'cell-' + entry.stamp}
          />
        ))}
      </Bar>
      <Tooltip content={<CustomTooltip />} />
    </BarChart>
  );
};

SparkChart.propTypes = {
  field: PropTypes.string.isRequired,
  type: PropTypes.string,
  data: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  getColor: PropTypes.func,
  highlightCurrentHour: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
};

SparkChart.defaultProps = {
  type: 'number',
  onClick: () => { },
  getColor: null,
  highlightCurrentHour: false,
  width: 100,
  height: 30,
};

function propsAreEqual(prev, next) {
  const result = JSON.stringify(prev.data.aggregatedData) === JSON.stringify(next.data.aggregatedData);
  return result;
}

export default React.memo(SparkChart, propsAreEqual);
// export default withStyles(styles)(SparkChart);
