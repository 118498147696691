const theme = {
  palette: {
    primary: {
      light: '#565674',
      main: '#5c8cd0',
      dark: '#040221',
      contrastText: '#fff',
    },
    secondary: {
      light: '#92f9ff',
      main: '#5cc6d0',
      dark: '#1a959f',
      contrastText: '#fff',
    },
    navIcon: {
      main: '#5cc6d0'
    },
  },
  root: {
    maxWidth: 1500,
  },
  spacing: 8,
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: '0px 1px 5px 0px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 3px 1px -2px rgb(0 0 0 / 12%)'
        },
      }
    },
    MuiTypography: {
      styleOverrides: {
        body1: {
          fontSize: '0.875rem',
          letterSpacing: '0.01071em',
        },
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          padding: '4px 8px',
          color: 'rgba(0, 0, 0, 0.87)',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.08)'
          }
        },
        textPrimary: {
          color: '#5c8cd0',
        },
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: '12px',
        },
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        standart: {
          transform: 'translate(0, 1.5px) scale(0.75)',
          color: 'rgba(0, 0, 0, 0.54)',
          fontWeight: 700,
          lineHeight: '1rem'
        },
      },
      defaultProps: {
        // The props to change the default for.
        variant: 'standart',
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          minHeight: '1em',
          lineHeight: '1em',
          margin: '4px 0 0 0',
        },
      }
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          paddingTop: 11,
          paddingBottom: 11,
        },
      }
    },
    MuiInput: {
      styleOverrides: {
        root: {
          lineHeight: '1.1875em'
        },
        input: {
          height: 'unset'
        },
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '4px',
          fontSize: '0.8125rem',
          letterSpacing: 'unset',
        },
        head: {
          height: '56px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          color: 'rgba(0, 0, 0, 0.54)',
          fontSize: '0.75rem',
          fontWeight: 500,
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          height: '48px',
        },
      }
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: '#5cc6d0',
        },
      }
    },
  }
};

const config = Object.freeze({
  version: '2.0.0',
  theme,
  timezones: [
    'America/Vancouver',
    'America/New_York',
    'UTC'
  ],
  sites: {
    sundaymarket: {
      label: 'Sunday Market',
      value: 'sundaymarket',
      domain: undefined,
      code: undefined
    },
    search: {
      label: 'Search Campaign',
      value: 'search',
      domain: '???.com',
      code: 'dc_sc'
    },
    direct: {
      label: 'Direct Campaign',
      value: 'direct',
      domain: '???.com',
      code: 'dc_pf',
      color: '2A2FB4'
    },
    gazillions: {
      value: 'gazillions',
      domain: 'gazillions.com',
      code: 'gz',
      font: 'Product Sans Bold.ttf',
      whiteWatermark: '360x100_gazillions.png',
      blackWatermark: '360x100_gazillions.png',
      label: 'Gazillions',
      color: 'd4af37',
      ghostTag: '#GAZILLIONS'
    },
    investingfuel: {
      value: 'investingfuel',
      domain: 'investingfuel.com',
      code: 'if',
      font: 'Yahoo Sans Bold Regular.ttf',
      whiteWatermark: '360x100_investingfuel.png',
      blackWatermark: '360x100_investingfuel.png',
      label: 'InvestingFuel',
      color: 'B22222',
      ghostTag: '#INVESTINGFUEL'
    },
    kaleandcardio: {
      value: 'kaleandcardio',
      domain: 'kaleandcardio.com',
      code: 'kc',
      font: 'Yahoo Sans Bold Regular.ttf',
      whiteWatermark: '360x100_kaleandcardio.png',
      blackWatermark: '360x100_kaleandcardio.png',
      label: 'KaleAndCardio',
      color: '0095ff',
      ghostTag: '#KALEANDCARDIO'
    },
    mdrntoday: {
      value: 'mdrntoday',
      domain: 'mdrntoday.com',
      code: 'mt',
      font: 'NotoSans-Bold.ttf',
      whiteWatermark: 'mdrntodaywhite.png',
      blackWatermark: 'mdrntodayblack.png',
      label: 'MDRNToday',
      color: '0ec0dc',
      ghostTag: '#MDRNTODAY'
    },
    followsports: {
      value: 'followsports',
      domain: 'followsports.com',
      code: 'fs',
      font: 'Roboto-Bold.ttf',
      whiteWatermark: '360x100_followsports.png',
      blackWatermark: '360x100_followsports.png',
      label: 'FollowSports',
      color: 'FFC53A',
      ghostTag: '#FOLLOWSPORTS'
    },
    heroinvesting: {
      value: 'heroinvesting',
      domain: 'heroinvesting.com',
      code: 'hi',
      font: 'Yahoo Sans Bold Regular.ttf',
      whiteWatermark: '360x100_heroinvesting.png',
      blackWatermark: '360x100_heroinvesting.png',
      label: 'HeroInvesting',
      color: '00964B',
      ghostTag: '#HEROINVESTING'
    },
    travelroo: {
      value: 'travelroo',
      domain: 'travelroo.com',
      code: 'tr',
      font: 'Yahoo Sans Bold Regular.ttf',
      whiteWatermark: '360x100_travelroo.png',
      blackWatermark: '360x100_travelroo.png',
      label: 'TravelRoo',
      color: 'ff9d04',
      ghostTag: '#TRAVELROO'
    },
    petfools: {
      value: 'petfools',
      domain: 'petfools.com',
      code: 'pf',
      font: 'NotoSans-Bold.ttf',
      whiteWatermark: 'petfools.png',
      blackWatermark: 'petfools.png',
      label: 'PetFools',
      color: '745e2b',
      ghostTag: '#PETFOOLS'
    },
    factfeed: {
      value: 'factfeed',
      domain: 'factfeed.com',
      code: 'ff',
      font: 'Yahoo Sans Bold Regular.ttf',
      whiteWatermark: '360x100_factfeed.png',
      blackWatermark: '360x100_factfeed.png',
      label: 'FactFeed',
      color: 'F28482',
      ghostTag: '#FACTFEED'
    },
    quantanswers: {
      value: 'quantanswers',
      domain: 'quantanswers.com',
      code: 'qa',
      font: 'Yahoo Sans Bold Regular.ttf',
      whiteWatermark: '360x100_quantanswers.png',
      blackWatermark: '360x100_quantanswers.png',
      label: 'QuantAnswers',
      color: 'A0D568',
      ghostTag: '#QUANTANSWERS'
    },

    quineapig: {
      value: 'quineapig',
      domain: 'quineapig.com',
      code: 'qp',
      font: 'Yahoo Sans Bold Regular.ttf',
      whiteWatermark: '360x100_quineapig.png',
      blackWatermark: '360x100_quineapig.png',
      label: 'Quineapig',
      color: '705AD7',
      ghostTag: '#QUINEAPIG'
    },
    sportsmound: {
      value: 'sportsmound',
      domain: 'sportsmound.com',
      code: 'sp',
      font: 'Yahoo Sans Bold Regular.ttf',
      whiteWatermark: '360x100_sportsmound.png',
      blackWatermark: '360x100_sportsmound.png',
      label: 'SportsMound',
      color: 'E82B2B',
      ghostTag: '#SPORTSMOUND'
    },
    burnyourday: {
      value: 'burnyourday',
      domain: 'burnyourday.com',
      code: 'bd',
      font: 'Yahoo Sans Bold Regular.ttf',
      whiteWatermark: '360x100_burnyourday.png',
      blackWatermark: '360x100_burnyourday.png',
      label: 'BurnYourDay',
      color: 'FF9503',
      ghostTag: '#BURNYOURDAY'
    },
    latestinsports: {
      value: 'latestinsports',
      domain: 'latestinsports.com',
      code: 'ls',
      font: 'Yahoo Sans Bold Regular.ttf',
      whiteWatermark: '360x100_latestinsports.png',
      blackWatermark: '360x100_latestinsports.png',
      label: 'LatestInSports',
      color: '02457F',
      ghostTag: '#LATESTINSPORTS'
    },
    topaddictionhelp: {
      value: 'topaddictionhelp',
      domain: 'topaddictionhelp.com',
      code: 'ta',
      font: 'Yahoo Sans Bold Regular.ttf',
      whiteWatermark: undefined,
      blackWatermark: undefined,
      label: 'TopAddictionHelp',
    },
    bestaddictionrehabs: {
      value: 'bestaddictionrehabs',
      domain: 'bestaddictionrehabs.com',
      code: 'ba',
      font: 'Yahoo Sans Bold Regular.ttf',
      whiteWatermark: undefined,
      blackWatermark: undefined,
      label: 'BestAddictionRehabs',
    },
    americarehabs: {
      value: 'americarehabs',
      domain: 'americarehabs.com',
      code: 'ar',
      font: 'Yahoo Sans Bold Regular.ttf',
      whiteWatermark: undefined,
      blackWatermark: undefined,
      label: 'AmericaRehabs',
    }
  },
  sources: {
    fb_k: 'Keywee',
    ga_k: 'Keywee',
    fb_jp: 'Facebook',
    ld: 'Lockerdome',
    me: 'Baidu',
    ga: 'Google',
    dv: 'Dv360',
    gm: 'Yahoo',
    mg: 'MGID',
    ob: 'Outbrain',
    tb: 'Taboola',
  },
  types: [
    'campaigns',
    'warnings',
    'problems',
  ],

  taboolaAccounts: [
    {
      name: 'Sunday Market - SC',
      account_id: 'taboolaaccount-teamaudiencemobcom',
    },
    {
      name: 'KaleandCardio - SC',
      account_id: 'followsports-sc',
    },
    {
      name: 'Follow Sports - SC',
      account_id: 'followsports-1-sc',
    },
    {
      name: 'MDRNToday - SC',
      account_id: 'followsports-2-sc',
    },
    {
      name: 'HeroInvesting - SC',
      account_id: 'followsports-3-sc',
    },
    {
      name: 'InvestingFuel - SC',
      account_id: 'sundaymarket-1-sc',
    },
    {
      name: 'Travelroo - SC',
      account_id: 'sundaymarket-2-sc',
    },
    {
      name: 'Petfools - SC',
      account_id: 'sundaymarket-3-sc',
    },
    {
      name: 'Gazillions - SC',
      account_id: 'sundaymarket-4-sc',
    },
    {
      name: 'FactFeed - SC',
      account_id: 'sundaymarket-5-sc',
    },
    {
      name: 'SearchAds - SC',
      account_id: 'sundaymarket-searchads-sc',
    },
  ],

  googleAccounts: [
    {
      Name: 'MDRNToday',
      Site: 'mdrntoday.com',
      domain: 'mdrntoday',
      SiteKey: 'mt',
      CustomerId: '1281796231',
    },
    {
      Name: 'HeroInvesting',
      Site: 'heroinvesting.com',
      domain: 'heroinvesting',
      SiteKey: 'hi',
      CustomerId: '5110063739',
    },
    {
      Name: 'FollowSports',
      Site: 'followsports.com',
      domain: 'followsports',
      SiteKey: 'fs',
      CustomerId: '6876240752',
    },
    {
      Name: 'PetFools',
      Site: 'petfools.com',
      domain: 'petfools',
      SiteKey: 'pf',
      CustomerId: '4971835364',
    },
    {
      Name: 'FactFeed',
      Site: 'factfeed.com',
      domain: 'factfeed',
      SiteKey: 'ff',
      CustomerId: '7022509333',
    },
    {
      Name: 'TravelRoo',
      Site: 'travelroo.com',
      domain: 'travelroo',
      SiteKey: 'tr',
      CustomerId: '4029297256',
    },
    {
      Name: 'Gazillions - S2C',
      Site: 'gazillions.com',
      domain: 'gazillions',
      SiteKey: 'gz',
      CustomerId: '9768630491',
    },
    {
      Name: 'BurnYourDay',
      Site: 'burnyourday.com',
      domain: 'burnyourday',
      SiteKey: 'bd',
      CustomerId: '1670898788',
    },
    {
      Name: 'LatestInSports',
      Site: 'latestinsports.com',
      domain: 'latestinsports',
      SiteKey: 'ls',
      CustomerId: '5045945009',
    },
    {
      Name: 'SportsMound',
      Site: 'sportsmound.com',
      domain: 'sportsmound',
      SiteKey: 'sp',
      CustomerId: '2022410035',
    },
    {
      Name: 'QuantAnswers',
      Site: 'quantanswers.com',
      domain: 'quantanswers',
      SiteKey: 'qa',
      CustomerId: '8082861733',
    }
  ]
});

export default config;
