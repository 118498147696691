import request from './request';
import authClient from '../auth';

const routes = Object.freeze({
  settings: '/juicyx/settings',
  devices: '/juicyx/devices',
  geos: '/juicyx/geos',
  browsers: '/juicyx/browsers',
  sites: '/juicyx/sites',
  sources: '/juicyx/sources',
  content: '/juicyx/content',
  offers: '/juicyx/offers',
  images: '/juicyx/images',
  headlines: '/juicyx/headlines',
  ads: '/juicyx/ads',
  data: '/juicyx/data',
  brain: '/juicyx/brain',
});

const juicyxAPI = {
  settings: {
    getAll: (params, cancelToken) => request({ url: `${routes.settings}/list`, method: 'get', params, cancelToken }),
    create: (data) => request({ url: `${routes.settings}`, method: 'post', data }),
    update: (data) => request({ url: `${routes.settings}`, method: 'put', data }),
    deleteBatch: (data) => request({ url: `${routes.settings}/batch`, method: 'delete', data }),
  },
  devices: {
    getAll: (params, cancelToken) => request({ url: `${routes.devices}/list`, method: 'get', params, cancelToken }),
    create: (data) => request({ url: `${routes.devices}`, method: 'post', data }),
    update: (data) => request({ url: `${routes.devices}`, method: 'put', data }),
  },
  geos: {
    getAll: (params, cancelToken) => request({ url: `${routes.geos}/list`, method: 'get', params, cancelToken }),
    getStates: (params, cancelToken) => request({ url: `${routes.geos}/states`, method: 'get', params, cancelToken }),
    create: (data) => request({ url: `${routes.geos}`, method: 'post', data }),
    update: (data) => request({ url: `${routes.geos}`, method: 'put', data }),
  },
  browsers: {
    getAll: (params, cancelToken) => request({ url: `${routes.browsers}/list`, method: 'get', params, cancelToken }),
    create: (data) => request({ url: `${routes.browsers}`, method: 'post', data }),
    update: (data) => request({ url: `${routes.browsers}`, method: 'put', data }),
  },
  sites: {
    getAll: (params, cancelToken) => request({ url: `${routes.sites}/list`, method: 'get', params, cancelToken }),
    getStats: (params, cancelToken) => request({ url: `${routes.sites}/stats`, method: 'get', params, cancelToken }),
    getHourly: (params, cancelToken) => request({ url: `${routes.sites}/hourly`, method: 'get', params, cancelToken }),
    get: (id) => request({ url: `${routes.sites}/get/${id}`, method: 'get' }),
    create: (data) => request({ url: `${routes.sites}`, method: 'post', data }),
    update: (data) => request({ url: `${routes.sites}`, method: 'put', data }),
    updateBatch: (data) => request({ url: `${routes.sites}/batch`, method: 'put', data }),
    deleteBatch: (data) => request({ url: `${routes.sites}/batch`, method: 'delete', data }),
    getAvailableSites: (params, cancelToken) => request({ url: `${routes.sites}/sites_sites`, method: 'get', params, cancelToken }),
    updateAvailableSites: (data) => request({ url: `${routes.sites}/sites_sites`, method: 'put', data }),
    getBlacklistedTags: (params, cancelToken) => request({ url: `${routes.sites}/blacklist-tags`, method: 'get', params, cancelToken }),
    updateBlacklistedTags: (data) => request({ url: `${routes.sites}/blacklist-tags`, method: 'put', data }),
    getDomainStats: (params, cancelToken) => request({ url: `${routes.sites}/domain_stats`, method: 'get', params, cancelToken }),
  },
  sources: {
    getAll: (params, cancelToken) => request({ url: `${routes.sources}/list`, method: 'get', params, cancelToken }),
    getStats: (params, cancelToken) => request({ url: `${routes.sources}/stats`, method: 'get', params, cancelToken }),
    getStatsMonth: (params, cancelToken) => request({ url: `${routes.sources}/stats/month`, method: 'get', params, cancelToken }),
    getStatsSite: (params, cancelToken) => request({ url: `${routes.sources}/stats/site`, method: 'get', params, cancelToken }),
    getHourly: (params, cancelToken) => request({ url: `${routes.sources}/hourly`, method: 'get', params, cancelToken }),
    create: (data) => request({ url: `${routes.sources}`, method: 'post', data }),
    update: (data) => request({ url: `${routes.sources}`, method: 'put', data }),
    updateBatch: (data) => request({ url: `${routes.sources}/batch`, method: 'put', data }),
    deleteBatch: (data) => request({ url: `${routes.sources}/batch`, method: 'delete', data }),
  },
  content: {
    getAll: (params, cancelToken) => request({ url: `${routes.content}/list`, method: 'get', params, cancelToken }),
    getStats: (params, cancelToken) => request({ url: `${routes.content}/stats`, method: 'get', params, cancelToken }),
    getHourly: (params, cancelToken) => request({ url: `${routes.content}/hourly`, method: 'get', params, cancelToken }),
    get: (id) => request({ url: `${routes.content}/get/${id}`, method: 'get' }),
    create: (data) => request({ url: `${routes.content}`, method: 'post', data }),
    update: (data) => request({ url: `${routes.content}`, method: 'put', data }),
    updateBatch: (data) => request({ url: `${routes.content}/batch`, method: 'put', data }),
    deleteBatch: (data) => request({ url: `${routes.content}/batch`, method: 'delete', data }),
    getAllContentSite: (params, cancelToken) => request({ url: `${routes.content}/all_content_site`, method: 'get', params, cancelToken }),
    getContentSite: (params, cancelToken) => request({ url: `${routes.content}/content_site`, method: 'get', params, cancelToken }), // only returns content not cpa
    getContentHeadlineHistory: (params, cancelToken) => request({ url: `${routes.content}/content_headline_history`, method: 'get', params, cancelToken }),
    createUpdateContentHeadline: (data, cancelToken) => request({ url: `${routes.content}/content_headline_history`, method: 'post', data, cancelToken }),
    getAggregated: (params, cancelToken) => request({ url: `${routes.content}/aggregated`, method: 'get', params, cancelToken }),
  },
  offers: {
    getAll: (params, cancelToken) => request({ url: `${routes.offers}/list`, method: 'get', params, cancelToken }),
    getStats: (params, cancelToken) => request({ url: `${routes.offers}/stats`, method: 'get', params, cancelToken }),
    getGeoStats: (params, cancelToken) => request({ url: `${routes.offers}/geo_stats`, method: 'get', params, cancelToken }),
    getWhitelistStats: (params, cancelToken) => request({ url: `${routes.offers}/whitelist`, method: 'get', params, cancelToken }),
    getHourly: (params, cancelToken) => request({ url: `${routes.offers}/hourly`, method: 'get', params, cancelToken }),
    get: (id) => request({ url: `${routes.offers}/get/${id}`, method: 'get' }),
    getOfferContentSite: (params, cancelToken) => request({ url: `${routes.offers}/offer_content_site`, method: 'get', params, cancelToken }),
    create: (data) => request({ url: `${routes.offers}`, method: 'post', data }),
    update: (data) => request({ url: `${routes.offers}`, method: 'put', data }),
    updateBatch: (data) => request({ url: `${routes.offers}/batch`, method: 'put', data }),
    deleteBatch: (data) => request({ url: `${routes.offers}/batch`, method: 'delete', data }),
    unDeleteBatch: (data) => request({ url: `${routes.offers}/batch-undelete`, method: 'put', data }),
    updateBlacklistedStates: (data) => request({ url: `${routes.offers}/blacklist`, method: 'put', data }),
    getAggregated: (params, cancelToken) => request({ url: `${routes.offers}/aggregated`, method: 'get', params, cancelToken }),
    updateProduct: (data) => request({ url: `${routes.offers}/product`, method: 'put', data }),
    directCampaignSiteOffers: (params, cancelToken) => request({ url: `${routes.offers}/site_offers`, method: 'get', params, cancelToken }),
  },
  images: {
    getAll: (params, cancelToken) => request({ url: `${routes.images}/list`, method: 'get', params, cancelToken }),
    getStats: (params, cancelToken) => request({ url: `${routes.images}/stats`, method: 'get', params, cancelToken }),
    getHourly: (params, cancelToken) => request({ url: `${routes.images}/hourly`, method: 'get', params, cancelToken }),
    get: (id) => request({ url: `${routes.images}/get/${id}`, method: 'get' }),
    create: (data) => request({
      url: `${routes.images}`, method: 'post', data: {
        ...data,
        username: authClient.getProfile().given_name
      }
    }),
    update: (data) => request({ url: `${routes.images}`, method: 'put', data }),
    upload: (data, onUploadProgress) => request({
      url: `${routes.images}/upload`,
      onUploadProgress,
      method: 'put',
      data
    }),
    uploadFacebook: (data, onUploadProgress) => request({
      url: `${routes.images}/upload/facebook_builder`,
      onUploadProgress,
      method: 'put',
      data
    }),
    uploadGoogle: (data, onUploadProgress) => request({
      url: `${routes.images}/upload/google_builder`,
      onUploadProgress,
      method: 'put',
      data
    }),
    uploadYahoo: (data, onUploadProgress) => request({
      url: `${routes.images}/upload/yahoo_builder`,
      onUploadProgress,
      method: 'put',
      data
    }),
    uploadBaidu: (data, onUploadProgress) => request({
      url: `${routes.images}/upload/baidu_builder`,
      onUploadProgress,
      method: 'put',
      data
    }),
    uploadMgid: (data, onUploadProgress) => request({
      url: `${routes.images}/upload/mgid_builder`,
      onUploadProgress,
      method: 'put',
      data
    }),
    uploadOutbrain: (data, onUploadProgress) => request({
      url: `${routes.images}/upload/outbrain_builder`,
      onUploadProgress,
      method: 'put',
      data
    }),
    uploadTaboola: (data, onUploadProgress) => request({
      url: `${routes.images}/upload/taboola_builder`,
      onUploadProgress,
      method: 'put',
      data
    }),
    updateBatch: (data) => request({ url: `${routes.images}/batch`, method: 'put', data }),
    deleteBatch: (data) => request({ url: `${routes.images}/batch`, method: 'delete', data }),
    getContentImages: (params, cancelToken) => request({ url: `${routes.images}/content`, method: 'get', params, cancelToken }),
    updateContentImages: (data) => request({ url: `${routes.images}/content`, method: 'put', data }),
  },
  headlines: {
    getAll: (params, cancelToken) => request({ url: `${routes.headlines}/list`, method: 'get', params, cancelToken }),
    getStats: (params, cancelToken) => request({ url: `${routes.headlines}/stats`, method: 'get', params, cancelToken }),
    getHourly: (params, cancelToken) => request({ url: `${routes.headlines}/hourly`, method: 'get', params, cancelToken }),
    get: (id) => request({ url: `${routes.headlines}/get/${id}`, method: 'get' }),
    create: (data) => request({
      url: `${routes.headlines}`, method: 'post', data: {
        ...data,
        username: authClient.getProfile().given_name
      }
    }),
    update: (data) => request({ url: `${routes.headlines}`, method: 'put', data }),
    updateBatch: (data) => request({ url: `${routes.headlines}/batch`, method: 'put', data }),
    deleteBatch: (data) => request({ url: `${routes.headlines}/batch`, method: 'delete', data }),
    getContentHeadlines: (params, cancelToken) => request({ url: `${routes.headlines}/content`, method: 'get', params, cancelToken }),
    updateContentHeadlines: (data) => request({ url: `${routes.headlines}/content`, method: 'put', data }),
  },
  ads: {
    // getAll: (params, cancelToken) => request({ url: `${routes.ads}/list`, method: 'get', params, cancelToken }),
    getStats: (params, cancelToken) => request({ url: `${routes.ads}/stats`, method: 'get', params, cancelToken }),
    getDailyTopAds: (params, cancelToken) => request({ url: `${routes.ads}/daily-top-ads`, method: 'get', params, cancelToken }),
    getContentImageHeadlineStats: (params, cancelToken) => request({ url: `${routes.ads}/ads-stats`, method: 'get', params, cancelToken }),
    getContentImageHeadlineDaily: (params, cancelToken) => request({ url: `${routes.ads}/ads-history-daily`, method: 'get', params, cancelToken }),
    getHourly: (params, cancelToken) => request({ url: `${routes.ads}/hourly`, method: 'get', params, cancelToken }),
    getData: (params, cancelToken) => request({ url: `${routes.ads}/data`, method: 'get', params, cancelToken }),
    get: (id) => request({ url: `${routes.ads}/get/${id}`, method: 'get' }),
    create: (data) => request({
      url: `${routes.ads}`, method: 'post', data: {
        ...data,
        username: authClient.getProfile().given_name
      }
    }),
    update: (data) => request({ url: `${routes.ads}`, method: 'put', data }),
    updateBatch: (data) => request({ url: `${routes.ads}/batch`, method: 'put', data }),
    deleteBatch: (data) => request({ url: `${routes.ads}/batch`, method: 'delete', data }),
    getAdOffers: (params, cancelToken) => request({ url: `${routes.ads}/offers`, method: 'get', params, cancelToken }),
    updateAdOffers: (data) => request({ url: `${routes.ads}/offers`, method: 'put', data }),
    updateAdOffer: (data) => request({ url: `${routes.ads}/offer`, method: 'put', data }),
    updateAdOffersBatch: (data) => request({ url: `${routes.ads}/offers/batch`, method: 'put', data }),
    createHistoryContentHeadImage: (data, cancelToken) => request({ url: `${routes.ads}/history-content-headline-image`, method: 'post', data, cancelToken }),
    getAdsBySite: (params, cancelToken) => request({ url: `${routes.ads}/ads-site`, method: 'get', params, cancelToken }),
  },
  data: {
    getClicks: (params, cancelToken) => request({ url: `${routes.data}/clicks`, method: 'get', params, cancelToken }),
    getClicksPaginated: (params, cancelToken) => request({ url: `${routes.data}/clicks/paginated`, method: 'get', params, cancelToken }),
    getConversions: (params, cancelToken) => request({ url: `${routes.data}/conversions`, method: 'get', params, cancelToken }),
    getConversionsPerDay: (params, cancelToken) => request({ url: `${routes.data}/source_conversions_daily`, method: 'get', params, cancelToken }),
    getSiteConversionsPerDay: (params, cancelToken) => request({ url: `${routes.data}/site_source_conversions_daily`, method: 'get', params, cancelToken }),
    getConversionsCampaign: (params, cancelToken) => request({ url: `${routes.data}/source_campaign_conversions`, method: 'get', params, cancelToken }),
    getConversionsCampaignPerDay: (params, cancelToken) => request({ url: `${routes.data}/source_campaign_conversions_day`, method: 'get', params, cancelToken }),
    getHourlyData: (params, cancelToken) => request({ url: `${routes.data}/hourly_data`, method: 'get', params, cancelToken }),
    getPlacementClickStats: (params, cancelToken) => request({ url: `${routes.data}/placement_stats`, method: 'get', params, cancelToken }),
    get14DayConversion: (params, cancelToken) => request({ url: `${routes.data}/conversion_picker`, method: 'get', params, cancelToken }),
    get14DaySiteSourceBrowserOsCountry: (params, cancelToken) => request({ url: `${routes.data}/site_source_browser_os_country`, method: 'get', params, cancelToken }),
  },
  categories: {
    getStats: (params, cancelToken) => request({ url: `${routes.data}/category_details`, method: 'get', params, cancelToken }),
  },
  brain: {
    getStats: (params, cancelToken) => request({ url: `${routes.brain}/stats`, method: 'get', params, cancelToken }),
  }
};

export default juicyxAPI;
