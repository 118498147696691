import React from 'react';
import PropTypes from 'prop-types';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Portal from '@mui/material/Portal';

const Alert = React.forwardRef((props, ref) => {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SnackbarSave = ({ open, handleClose, message, status }) => {
  const finalMessage = message || 'Default Message';

  return (
    <Portal>
      <Snackbar
        open={open}
        autoHideDuration={4000} // 4s
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }}
        onClick={handleClose}
      >
        <Alert severity={status} sx={{ width: '100%', whiteSpace: 'pre-wrap' }}>
          {finalMessage}
        </Alert>
      </Snackbar>
    </Portal>
  )
}

SnackbarSave.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  message: PropTypes.string,
  status: PropTypes.oneOf(['error', 'warning', 'info', 'success']),
};

SnackbarSave.defaultProps = {
  message: '',
  status: 'info',
};

export default SnackbarSave;
