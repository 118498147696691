import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import authClient, { getRedirectPage } from 'services/auth';

const Callback = () => {
  const navigate = useNavigate();

  const handleRedirect = useCallback(async () => {
    try {
      await authClient.handleAuthentication();
      const url = getRedirectPage();
      navigate(url);
    } catch (err) {
      console.log(err);
      navigate('/login');
    }
  }, [navigate])

  useEffect(() => {
    handleRedirect();
  }, [handleRedirect])

  return (
    <div style={{ textAlign: 'center', marginTop: '5em' }}>
      <div style={{ color: '#337ab7' }}>
        Logging in...
      </div>
    </div>
  );
}

export default Callback;
