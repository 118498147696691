import request from './request';

const routes = Object.freeze({
  tables: '/utils/table_data',
  filters: '/utils/table_filters',
});

const UtilsAPI = {
  tables: {
    list: (params, cancelToken) => request({ url: routes.tables + '/list', method: 'get', params, cancelToken }),
    create: data => request({ url: routes.tables + '/create', method: 'post', data }),
    delete: data => request({ url: routes.tables + '/delete', method: 'delete', data })
  },
  filters: {
    list: params => request({ url: routes.filters + '/list', method: 'get', params }),
    create: data => request({ url: routes.filters + '/create', method: 'post', data }),
    delete: data => request({ url: routes.filters + '/delete', method: 'delete', data })
  },
};

export default UtilsAPI;
